import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { HighlightViewer, HighlightToolbars, BoundingBoxSectionViewer } from '../PdfViewerWithToolbar.Types';
import { BoundingBox, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import {
    AnnotationAttributeHighlightOriginalPartsNumber,
    AnnotationAttributeType,
    AnnotationAttributeTypeValues,
    getHighlightId,
    isModifiedHighlight,
    isNewHighlight,
    isStandardAnnotation,
} from './Annotations/Drawing/ViewerAnnotations/Attributes';
import { CacheProvider, EmotionCache } from '@emotion/react';
import Log from '../Logger';
import { ThemeProvider } from 'UI';
import { GetPageAndCoordinatesFromBoundingBoxSection, OrderByHighlightPosition } from '../Functions';
import ShowHideAnnotationElements from './Annotations/ShowHideAnnotationElements';

const useToolbars = (
    webViewerInstance: WebViewerInstance | null,
    highlightToolbars: HighlightToolbars,
    selectedHighlightIds: Array<string>,
    emotionCache: EmotionCache,
    viewerAnnotationsUpdated: number,
    addPart: () => void,
    splittedHighlightId: string | null,
    splitAnnotation: (id: string) => void,
    cancelSplitAnnotation: () => void
) => {
    const [highlightToolbarRef, setHighlightToolbarRef] = useState<React.RefObject<HTMLDivElement>>();
    const { newHighlightToolbar, highlightToolbar, deps: highlightToolbarsDeps } = highlightToolbars;
    const showHideAnnotationElements = useCallback(
        (id: string, show: boolean) => {
            if (webViewerInstance) {
                ShowHideAnnotationElements(webViewerInstance, show, id);
            }
        },
        [webViewerInstance]
    );
    const rootRenderWrapper = useCallback(
        (root: Root, children: React.ReactNode) => {
            root.render(
                <ThemeProvider>
                    <CacheProvider value={emotionCache}>{children}</CacheProvider>
                </ThemeProvider>
            );
        },
        [emotionCache]
    );

    useEffect(() => {
        if (webViewerInstance) {
            // Rendering fake toolbar for getting width for right annotationPopup.update below. Without that PdfViewer will display toolbar in incorrect place.
            if (highlightToolbarRef?.current) {
                const toRemove = webViewerInstance.UI.iframeWindow.document.getElementsByClassName('to-remove');
                toRemove[0].remove();
            }
            const doc = webViewerInstance.UI.iframeWindow.document;
            const container = doc.querySelector(`.content`);
            const newDiv = doc.createElement('div');
            newDiv.style.position = 'absolute';
            newDiv.style.visibility = 'hidden';
            newDiv.classList.add('to-remove');
            container!.appendChild(newDiv);
            const root = createRoot(newDiv);

            if (selectedHighlightIds.length > 1) {
                const emptyRef = createRef<HTMLDivElement>();
                rootRenderWrapper(root, <div ref={emptyRef} />);
                setHighlightToolbarRef(emptyRef);
            } else {
                const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
                if (annot) {
                    if (isNewHighlight(annot)) {
                        rootRenderWrapper(
                            root,
                            newHighlightToolbar.render({
                                clearSelection: () => {},
                                getNewHighlight: async () => await getNewHighlight(webViewerInstance),
                                setHighlightToolbarRef,
                                addPart: () => {},
                            })
                        );
                    } else if (isStandardAnnotation(annot)) {
                        rootRenderWrapper(
                            root,
                            highlightToolbar.render({
                                setHighlightToolbarRef,
                                showHideAnnotationElements,
                                addPart: () => {},
                                getHighlightViewer: async () => ({} as HighlightViewer),
                                splitAnnotation: () => {},
                                cancelSplitAnnotation: () => {},
                                isAnnotationSplitted: splittedHighlightId !== null,
                            })
                        );
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstance, highlightToolbarsDeps, selectedHighlightIds, viewerAnnotationsUpdated, splittedHighlightId]);

    useEffect(() => {
        if (webViewerInstance) {
            const clearSelection = () => {
                const annots = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a));
                Log(webViewerInstance, 'ACTION', ['clearSelection', 'deleteAnnotations'], [annots]);
                webViewerInstance.Core.annotationManager.deleteAnnotations(annots);
            };

            webViewerInstance.UI.annotationPopup.update([
                {
                    type: 'customElement',
                    // It isn't imposible to pass ReactComponent because WebViewer doesn't support hooks in 'customElement'
                    render: () => {
                        const doc = webViewerInstance.UI.iframeWindow.document;
                        const newDiv = doc.createElement('div');
                        const width = highlightToolbarRef?.current?.offsetWidth;
                        if (!width || width === 0) return newDiv;
                        newDiv.style.width = `${width}px`;
                        const root = createRoot(newDiv);

                        if (selectedHighlightIds.length > 1) {
                            rootRenderWrapper(root, <div />);
                        } else {
                            const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
                            if (annot) {
                                if (isNewHighlight(annot)) {
                                    rootRenderWrapper(
                                        root,
                                        newHighlightToolbar.render({
                                            clearSelection,
                                            getNewHighlight: async () => await getNewHighlight(webViewerInstance),
                                            setHighlightToolbarRef: () => {},
                                            addPart,
                                        })
                                    );
                                } else if (isStandardAnnotation(annot)) {
                                    rootRenderWrapper(
                                        root,
                                        highlightToolbar.render({
                                            setHighlightToolbarRef: () => {},
                                            showHideAnnotationElements,
                                            addPart,
                                            getHighlightViewer: () => getHighlightViewer(webViewerInstance),
                                            splitAnnotation: () => splitAnnotation(getHighlightId(annot)),
                                            cancelSplitAnnotation,
                                            isAnnotationSplitted: splittedHighlightId !== null,
                                        })
                                    );
                                }
                            }
                        }

                        return newDiv;
                    },
                },
            ]);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, highlightToolbarRef]);
};

const getHighlightViewer = async (instance: WebViewerInstance): Promise<HighlightViewer> => {
    const id = getHighlightId(instance.Core.annotationManager.getSelectedAnnotations()[0]);
    const annots = instance.Core.annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === id && isStandardAnnotation(a));
    return await getHighlightFromAnnots(instance, annots);
};

const getNewHighlight = async (instance: WebViewerInstance): Promise<HighlightViewer> => {
    const annots = instance.Core.annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a));
    return getHighlightFromAnnots(instance, annots);
};

const getHighlightFromAnnots = async (instance: WebViewerInstance, annots: Array<Core.Annotations.Annotation>): Promise<HighlightViewer> => {
    const { documentViewer } = instance.Core;
    let selectionType: SelectionType | null = null;
    let boundingBoxSections: Array<BoundingBoxSectionViewer> = [];
    let statement = '';

    if (annots.length > 0) {
        const annotType = AnnotationAttributeType.get(annots[0]);

        switch (annotType) {
            case 'textAnnotation':
                selectionType = SelectionType.Text;
                break;
            case 'boxAnnotation':
                selectionType = SelectionType.Shape;
                break;
        }

        for (const annot of annots) {
            const pageNumber = annot.getPageNumber();
            const pageWidth = documentViewer.getPageWidth(pageNumber);
            const pageHeight = documentViewer.getPageHeight(pageNumber);
            const annotStatement = await getStatement(instance, annot, annotType);
            statement += annotStatement + ' ';
            boundingBoxSections.push({
                pageNumber,
                pageSize: {
                    height: pageHeight,
                    width: pageWidth,
                },
                boundingBoxes: getBoundingBoxes(annotType, annot),
                statement: annotStatement,
            });
        }
    }

    boundingBoxSections = boundingBoxSections.sort((a, b) =>
        OrderByHighlightPosition(GetPageAndCoordinatesFromBoundingBoxSection(a), GetPageAndCoordinatesFromBoundingBoxSection(b))
    );

    const originalPartsNumber = AnnotationAttributeHighlightOriginalPartsNumber.get(annots[0]);
    const isModified = annots.some((a) => isModifiedHighlight(a)) || originalPartsNumber !== annots.length;

    return {
        selectionType: selectionType!,
        boundingBoxSections,
        statement,
        isModified,
    };
};

const getTextUnderAnnot = async (webViewerInstance: WebViewerInstance, annot: Core.Annotations.Annotation) => {
    const xfdf = await webViewerInstance.Core.annotationManager.exportAnnotations({ annotationList: [annot] });
    const doc = await webViewerInstance.Core.documentViewer.getDocument().getPDFDoc();
    const textDataResult = await webViewerInstance.Core.PDFNet.runWithCleanup(async () => {
        // lock the document before a write operation
        // runWithCleanup will auto unlock when complete
        doc.lock();

        // import annotations to PDFNet
        const fdfDoc = await webViewerInstance.Core.PDFNet.FDFDoc.createFromXFDF(xfdf);
        await doc.fdfUpdate(fdfDoc);

        const page = await doc.getPage(annot.PageNumber);
        const rect = await page.getCropBox();
        const annotation = await page.getAnnot(0);
        const te = await webViewerInstance.Core.PDFNet.TextExtractor.create();
        te.begin(page, rect);
        const textData = await te.getTextUnderAnnot(annotation);
        te.destroy();
        return textData;
    }, process.env.REACT_APP_APRYSE_KEY || '');

    return textDataResult;
};

const getBoundingBoxes = (annotType: AnnotationAttributeTypeValues, annot: Core.Annotations.Annotation) => {
    let boundingBoxes: Array<BoundingBox> = [];
    switch (annotType) {
        case 'textAnnotation':
            const quads: Array<Core.Math.Quad> = (annot as Core.Annotations.TextHighlightAnnotation).Quads;
            for (let i = 0; i < quads.length; i++) {
                const quad = quads[i];
                boundingBoxes.push({
                    topLeft: {
                        x: quad.x4,
                        y: quad.y4,
                    },
                    downRight: {
                        x: quad.x2,
                        y: quad.y2,
                    },
                });
            }
            break;
        case 'boxAnnotation':
            const rect = annot.getRect();
            boundingBoxes.push({
                topLeft: {
                    x: rect.x1,
                    y: rect.y1,
                },
                downRight: {
                    x: rect.x2,
                    y: rect.y2,
                },
            });
            break;
    }

    return boundingBoxes;
};

const getStatement = async (webViewerInstance: WebViewerInstance, annot: Core.Annotations.Annotation, annotType: AnnotationAttributeTypeValues) => {
    let statement: string = '';
    switch (annotType) {
        case 'textAnnotation':
            statement = await getTextUnderAnnot(webViewerInstance, annot);
            statement = statement.trim();
            break;
        case 'boxAnnotation':
            throw new Error('Not implemented');
    }

    return statement;
};

export default useToolbars;
