import { Box } from '@mui/material';
import { Highlight } from '../../PdfViewerWithToolbar.Types';
import { HighlightLeftTopInformation } from '../HighlightLeftTopInformation.Component';
import HighlightLeftTopInformationNodeComponent from './HighlightLeftTopInformationNode/HighlightLeftTopInformationNode.Component';

type HighlightLeftTopInformationTooltipComponentProps<THighlightCustomData> = {
    siblings: Array<Highlight<THighlightCustomData>>;
    selectedHighlightIds: Array<string>;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    selectHighlight: (highlightId: string) => void;
};

function HighlightLeftTopInformationTooltipComponent<THighlightCustomData>(props: HighlightLeftTopInformationTooltipComponentProps<THighlightCustomData>) {
    const { siblings, selectedHighlightIds, leftTopInformation, selectHighlight } = props;

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 0.25, zIndex: 1500 }}>
            {
                // Reverse the highlights array to keep consistent with the left hand nav
                [...siblings].reverse().map((s) => (
                    <HighlightLeftTopInformationNodeComponent
                        key={`node-${s.id}`}
                        highlight={s}
                        selected={selectedHighlightIds.includes(s.id)}
                        leftTopInformation={leftTopInformation}
                        selectHighlight={selectHighlight}
                    />
                ))
            }
        </Box>
    );
}

export default HighlightLeftTopInformationTooltipComponent;
