import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import ProjectsApiClient from '../../../ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ProjectWorkspaceComponent from './ProjectWorkspace.Component';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';
import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';
import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import {
    useAnnotationsScope,
    useAuditLog,
    useMembers,
    useProjectWorkspaceNotifications,
    useTags,
    useUsers,
    useDocumentsScope,
    useSupportingInformationScope,
} from './Hooks/Module';
import { ErrorHandlerRegistration } from 'ApiClients/Sterling.ApiClient';
import useErrorHandler from './Hooks/Module/useErrorHandler';
import useProjectWorkspaceModals from './Hooks/Module/useProjectWorkspaceModals';
import useProjectSearchParams from './Hooks/Module/useProjectSearchParams';
import { useRef } from 'react';
import AutoAnnotationsApiClient from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient';
import { ProjectModulesProps, ViewersScrollContainerRefs } from '../ProjectModulesWrapper/ProjectModules.Component';
import { NavBarContents } from 'App/NavBar/NavBar.Component';

export type ProjectWorkspaceModuleProps = {
    navBarContents: NavBarContents;
    projectModulesProps: ProjectModulesProps;
    projectsApiClient: ProjectsApiClient;
    projectDocumentsApiClient: ProjectDocumentsApiClient;
    annotationsApiClient: AnnotationsApiClient;
    autoAnnotationsApiClient: AutoAnnotationsApiClient;
    supportingInformationApiClient: SupportingInformationApiClient;
    notificationsApiClient: NotificationsApiClient;
    usersApiClient: UsersApiClient;
    auditLogsApiClient: AuditLogsApiClient;
    notificationsConnector: NotificationsConnector;
    errorHandlerRegistration: ErrorHandlerRegistration;
    viewersScrollContainerRefs: ViewersScrollContainerRefs;
};

function ProjectWorkspaceModule(props: ProjectWorkspaceModuleProps) {
    const {
        navBarContents,
        projectModulesProps,
        projectsApiClient,
        projectDocumentsApiClient,
        annotationsApiClient,
        autoAnnotationsApiClient,
        supportingInformationApiClient,
        notificationsApiClient,
        usersApiClient,
        auditLogsApiClient,
        notificationsConnector,
        errorHandlerRegistration,
        viewersScrollContainerRefs,
    } = props;

    const { project } = projectModulesProps;

    const { createAuditLog } = useAuditLog(project.id, auditLogsApiClient);

    const modals = useProjectWorkspaceModals();

    const usersProps = useUsers(usersApiClient);

    const membersProps = useMembers(projectsApiClient);

    const setSelectedSupportingHighlightIdRef = useRef<(id: string | null, options: { dispatchEvent: boolean }) => void>(() => {});
    const closeSupportingInformationPropositionsRef = useRef<() => void>(() => {});

    const documentsScope = useDocumentsScope(
        project.id,
        project.workingVersion.id,
        projectDocumentsApiClient,
        setSelectedSupportingHighlightIdRef,
        closeSupportingInformationPropositionsRef
    );
    const {
        verificationDocumentInfo,
        verificationDocumentExtendedMethods,
        supportingDocumentsProps,
        supportingDocumentsExtendedMethods,
        webSearchDocumentsProps,
    } = documentsScope;

    const refreshAnnotationRef = useRef<(annotationId: string) => void>((_: string) => {});

    const supportingInformationScope = useSupportingInformationScope(
        project.id,
        project.workingVersion.id,
        supportingInformationApiClient,
        supportingDocumentsProps.switchSupportingDocument,
        supportingDocumentsProps.supportingDocumentInfo,
        webSearchDocumentsProps.switchWebSearchDocument,
        refreshAnnotationRef,
        webSearchDocumentsProps.uploadWebSearchDocument
    );

    setSelectedSupportingHighlightIdRef.current = supportingInformationScope.supportingInformationSelectingProps.setSelectedSupportingHighlightId;
    closeSupportingInformationPropositionsRef.current = supportingInformationScope.supportingInformationPropositionsListProps.close;

    const annotationsScope = useAnnotationsScope(
        project,
        annotationsApiClient,
        autoAnnotationsApiClient,
        notificationsApiClient,
        verificationDocumentInfo,
        createAuditLog,
        verificationDocumentExtendedMethods.setAutoAnnotationsProcessIsErrorReceived,
        supportingInformationScope.supportingInformationSelectingProps.setSelectedSupportingHighlightId,
        supportingInformationScope.supportingInformationPropositionsProps.getSupportingInformationPropositions,
        supportingInformationScope.supportingInformationPropositionsListProps.close,
        supportingInformationScope.supportingInformationPropositionsListProps.open,
        modals
    );

    const { annotationsProps, autoAnnotationsProps, annotationsScopeExtendedMethods } = annotationsScope;
    refreshAnnotationRef.current = annotationsProps.refreshAnnotation;

    const tagsProps = useTags(project.id, project.workingVersion.id, projectsApiClient, annotationsProps.annotationsDs.refetch);

    useProjectWorkspaceNotifications(
        notificationsConnector,
        project,
        verificationDocumentInfo,
        supportingDocumentsProps.documents.supportingDocumentsById,
        verificationDocumentExtendedMethods.setVerificationDocumentProcessingStatus,
        supportingDocumentsExtendedMethods.setSupportingDocumentProcessingStatus,
        verificationDocumentExtendedMethods.refetchVerificationDocument,
        supportingDocumentsExtendedMethods.refetchSupportingDocuments,
        autoAnnotationsProps.autoAnnotationsDs.refetch,
        annotationsProps.annotationsDs.refetch,
        verificationDocumentExtendedMethods.setAutoAnnotationsProcessProgress,
        verificationDocumentExtendedMethods.handleAutoAnnotationGenerated,
        verificationDocumentExtendedMethods.handleAutoAnnotationsSectionProcessStatusChanged,
        annotationsScopeExtendedMethods.handleAnnotationChanged,
        annotationsScopeExtendedMethods.handleAutoAnnotationChanged,
        supportingDocumentsExtendedMethods.handleSupportingDocumentUploaded
    );

    const readOnlyModalProps = useErrorHandler(errorHandlerRegistration, project.workingVersion);

    useProjectSearchParams(
        modals.projectMembersModal.openModal,
        supportingInformationScope.supportingInformationSelectingProps.switchSuppDocAndSelectHighlight,
        supportingDocumentsProps.switchSupportingDocument,
        annotationsProps.annotationsDs.data
    );

    return (
        <ProjectWorkspaceComponent
            navBarContents={navBarContents}
            project={project}
            membersProps={membersProps}
            usersProps={usersProps}
            verificationDocumentInfo={verificationDocumentInfo}
            supportingDocumentsProps={supportingDocumentsProps}
            webSearchDocumentsProps={webSearchDocumentsProps}
            annotationsProps={annotationsScope.annotationsProps}
            autoAnnotationsProps={annotationsScope.autoAnnotationsProps}
            supportingInformationProps={supportingInformationScope.supportingInformationProps}
            supportingInformationPropositionsProps={supportingInformationScope.supportingInformationPropositionsProps}
            supportingInformationWebSearchPropositionsProps={supportingInformationScope.supportingInformationWebSearchPropositionsProps}
            supportingInformationPropositionsListProps={supportingInformationScope.supportingInformationPropositionsListProps}
            supportingInformationSelectingProps={supportingInformationScope.supportingInformationSelectingProps}
            assertionsProps={annotationsScope.assertionProps}
            commentsProps={annotationsScope.commentsProps}
            tagsProps={tagsProps}
            modalsProps={modals}
            createAuditLog={createAuditLog}
            readOnlyModalProps={readOnlyModalProps}
            viewersScrollContainerRefs={viewersScrollContainerRefs}
        />
    );
}

export default ProjectWorkspaceModule;
