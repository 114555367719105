import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, VerifiSpinner } from 'UI';
import { Box } from '@mui/material';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import {
    ProjectDocumentType,
    ProjectDocumentValidationResult,
    ProjectDocumentValidationStatus,
    ValidationErrorCode,
} from 'ApiClients/SterlingApiClients/Types';

export type ValidationInProgressModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    file: File;
    validateProjectDocument: (file: File, documentType: ProjectDocumentType, flowMethods: FlowMethods<ProjectDocumentValidationResult>) => void;
    addProject: () => void;
    isAddingProject: boolean;
    documentType: ProjectDocumentType;
};

function ValidationInProgressModalComponent(props: ValidationInProgressModalComponentProps) {
    const initalTimerValue = 5;
    const { isOpen, closeModal, file, addProject, isAddingProject, documentType } = props;
    const [result, setResult] = useState<ProjectDocumentValidationResult | null>(null);
    const [timerValue, setTimerValue] = useState<number>(initalTimerValue);

    const { wrappedMethod: _validateProject, fetching: isInProgress } = usePromiseWithFlowMethods<{ file: File; documentType: ProjectDocumentType }, {}>({
        method: (input, flowMethods) =>
            props.validateProjectDocument(input.file, input.documentType, {
                ...flowMethods,
                onSuccess: (data: ProjectDocumentValidationResult) => {
                    setResult(data);
                    if (data.status === ProjectDocumentValidationStatus.Success) {
                        setTimerValue(initalTimerValue);
                    }
                },
            }),
        initialData: {},
    });

    const interval = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!result || result.status !== ProjectDocumentValidationStatus.Success) return;

        interval.current = setInterval(() => {
            setTimerValue((prev) => prev - 1);
        }, 1000);
    }, [result]);

    useEffect(() => {
        if (timerValue <= 0 && interval.current && result) {
            clearInterval(interval.current!);
            addProject();
        }
        // eslint-disable-next-line
    }, [timerValue]);

    useEffect(() => {
        if (isOpen && file) {
            setResult(null);
            _validateProject({ file, documentType });
        }
    }, [isOpen, file, _validateProject, documentType]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        addProject();
    };

    return (
        <Dialog open={isOpen} maxWidth='xs'>
            <Dialog.Title>Validating document</Dialog.Title>
            <Dialog.Content>
                {isInProgress && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <VerifiSpinner />
                    </Box>
                )}
                {result && result.status === ProjectDocumentValidationStatus.Error && (
                    <form onSubmit={handleSubmit}>
                        Following issues have been found during document validation:
                        <ul>
                            {result.errors.map((error) => {
                                return (
                                    <li key={error.code}>
                                        {error.code === ValidationErrorCode.MissingToc && 'Table of contents is missing'}
                                        {error.code === ValidationErrorCode.MissingSections && (
                                            <div>
                                                Following sections are missing:
                                                {error.description}
                                            </div>
                                        )}
                                        {error.code === ValidationErrorCode.Watermark && 'Document contains watermark'}
                                        {error.code === ValidationErrorCode.Background && 'Document does not have blank background'}
                                        {error.code === ValidationErrorCode.ReviewMode && 'Document is saved in review mode'}
                                        {error.code === ValidationErrorCode.TocOrdinality &&
                                            'Sections in document are in different order than in table of contents'}
                                    </li>
                                );
                            })}
                        </ul>
                        <Box
                            sx={{
                                marginBottom: '15px',
                            }}
                        >
                            You can cancel project creation and fix the document or you can create project anyway.
                        </Box>
                        <Dialog.Actions>
                            <Button
                                color='secondary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='button'
                                onClick={closeModal}
                                data-cy='close-validation'
                            >
                                Close
                            </Button>
                            <Button
                                data-cy='create-project'
                                disabled={isAddingProject}
                                isLoading={isAddingProject}
                                color='primary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='submit'
                            >
                                Create project
                            </Button>
                        </Dialog.Actions>
                    </form>
                )}
                {result && result.status === ProjectDocumentValidationStatus.Success && (
                    <div>Document validation finished, no errors detected. Project will be created in {timerValue} seconds</div>
                )}
            </Dialog.Content>
        </Dialog>
    );
}

export default ValidationInProgressModalComponent;
