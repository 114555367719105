import { useEffect, useState } from 'react';
import { FormMode } from '../../NewVersionForm.Module';
import { ProjectVersionForm } from '../../NewVersionForm.Component';

export type DocumentUploadState = {
    mode: FormMode;
    documentInfo: DocumentInfo | null;
    setDcumentInfo: React.Dispatch<React.SetStateAction<DocumentInfo | null>>;
    onNext: () => void;
    onCancel: () => void;
};

export type DocumentInfo = {
    id: string;
    name: string;
    fileId: string;
    file: File;
};

const useDocumentUploadState = (
    mode: FormMode,
    draftVersion: ProjectVersionForm | null,
    nextStep: () => void,
    navigateToDocumentVersionList: () => void
): DocumentUploadState => {
    const [documentInfo, setDcumentInfo] = useState<DocumentInfo | null>(null);

    useEffect(() => {
        if (mode === 'edit' && draftVersion && documentInfo === null) {
            setDcumentInfo(draftVersion.document);
        }
    }, [mode, draftVersion, documentInfo]);

    return {
        mode,
        documentInfo,
        setDcumentInfo,
        onNext: nextStep,
        onCancel: navigateToDocumentVersionList,
    };
};

export default useDocumentUploadState;
