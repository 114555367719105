import { Box } from '@mui/material';

export type ItemNumberComponentProps = {
    number: number;
};

function ItemNumberComponent(props: ItemNumberComponentProps) {
    return (
        <Box
            sx={{
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '1.5rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                backgroundColor: (theme) => theme.palette.body.main,
                borderStyle: 'solid',
                borderWidth: '2px',
                borderRadius: '0.25rem',
                borderColor: (theme) => theme.palette.stroke.main,
            }}
        >
            {props.number}
        </Box>
    );
}

export default ItemNumberComponent;
