import { FullscreenModeContextProviderMethods, FullscreenModeContextProviderValue } from 'Contexts/FullscreenMode.Context';
import { useCallback, useState } from 'react';

const useFullscreenMode = (): FullscreenModeContextProviderValue & FullscreenModeContextProviderMethods => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = useCallback(() => {
        setIsFullscreen((prevIsFullscreen) => {
            if (prevIsFullscreen) {
                document.exitFullscreen();
            } else {
                document.documentElement.requestFullscreen();
            }

            return !prevIsFullscreen;
        });
    }, [setIsFullscreen]);

    return { isFullscreen, toggleFullscreen };
};

export default useFullscreenMode;
