import { useContext, useState } from 'react';
import { AppBar, Box, Menu as MuiMenu, Toolbar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Contexts/Auth.Context';
import { AppRoutes } from 'App/RoutesPaths';
import { Button, Icon, Tooltip } from 'UI';
import Menu from 'UI/Menu';

type NavBarComponentProps = {
    navBarContents: NavBarContents;
    logout: () => void;
};

export type NavBarContents = {
    left: React.MutableRefObject<HTMLDivElement | null>;
    center: React.MutableRefObject<HTMLDivElement | null>;
    right: React.MutableRefObject<HTMLDivElement | null>;
};

export const NavBarHeight = '4.75rem';

function NavBarComponent(props: NavBarComponentProps) {
    const { navBarContents, logout } = props;
    const authContext = useContext(AuthContext);
    const { isAuthenticated, user } = authContext;
    const { firstName, lastName } = user;
    const userInitials = (firstName.length > 0 && lastName.length > 0 && `${firstName[0]}${lastName[0]}`.toUpperCase()) || '';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <AppBar sx={{ backgroundColor: (theme) => theme.palette.white.main, color: (theme) => theme.palette.text.main }}>
                <Toolbar sx={{ height: NavBarHeight, margin: '0 2rem', padding: '0 !important' }}>
                    <Box ref={navBarContents.left} sx={{ width: '30%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginRight: '2rem' }}>
                            <Tooltip title={`Version ${process.env.REACT_APP_VERSION || 'Unknown'}`}>
                                <Link style={{ display: 'flex' }} to={AppRoutes.homePage.specificPath({})}>
                                    <Icon.VerifiLogo height={24} width='100%' />
                                </Link>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box ref={navBarContents.center} sx={{ width: '40%' }}></Box>
                    <Box sx={{ width: '30%', height: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                        <div ref={navBarContents.right} />
                        {isAuthenticated && (
                            <Box sx={{ height: '100%', marginLeft: '2rem', display: 'flex', alignItems: 'center' }}>
                                <Button
                                    onClick={handleClick}
                                    shape='circle'
                                    sx={{
                                        height: '2.5rem',
                                        width: '2.5rem',
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: (theme) => theme.palette.text.main,
                                        backgroundColor: (theme) => theme.palette.primary.lightest,
                                        border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                                    }}
                                >
                                    {userInitials}
                                </Button>
                                <MuiMenu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    sx={{ marginTop: '0.5rem' }}
                                >
                                    <Menu.Item onClick={logout} sx={{ gap: 1, alignItems: 'center', padding: '0 1rem' }}>
                                        <LogoutIcon sx={{ fontSize: 14 }} />
                                        Logout
                                    </Menu.Item>
                                </MuiMenu>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavBarComponent;
