import { Core, WebViewerInstance } from '@pdftron/webviewer';
import Log from '../../Logger';
import {
    AnnotationAttributeMultiSelectEnable,
    AnnotationAttributeType,
    getHighlightId,
    getParentHighlightId,
    isFirstAnnotationOfHighlight,
    isLastAnnotationOfHighlight,
    isNewHighlight,
    isStandardAnnotation,
} from './Drawing/ViewerAnnotations/Attributes';
import { useCallback, useEffect, useState } from 'react';
import { SelectedHighlightChangedEvent } from '../../PdfViewerWithToolbar.Types';

export type AnnotationSelectedEvent = {
    action: AnnotationSelectedEventAction;
    annotations: Array<Core.Annotations.Annotation>;
    highlights: Array<SupportStateHighlight>;
    source: AnnotationSelectedEventSource;
};

type AnnotationSelectedEventAction = 'selected' | 'deselected';

type AnnotationSelectedEventSource = 'app' | 'viewer' | 'creating';

type SupportState = {
    highlights: Array<SupportStateHighlight>;
    update: number;
};

export type SupportStateHighlight = {
    id: string;
    isNewHighlight: boolean;
    annotations: Array<Core.Annotations.Annotation>;
};

const useAnnotationsSelecting = (
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    selectedHighlightIds: Array<string>,
    setSelectedHighlightIds: (ids: Array<string>) => void,
    selectedHighlightChangedEventName: string,
    clearSelectedHighlight: () => void,
    newHighlightId: string | null,
    setNewHighlightId: (id: string | null) => void,
    multiSelectNext: boolean,
    setMultiSelectNext: (next: boolean) => void,
    splittedHighlightId: string | null,
    setSplittedHighlightId: (id: string | null) => void,
    highlightMultiSelectEnabled?: boolean
) => {
    const [viewerAnnotationsUpdated, _setViewerAnnotationsUpdated] = useState<number>(0);
    const setViewerAnnotationsUpdated = useCallback(() => _setViewerAnnotationsUpdated((prev) => prev + 1), []);

    const [events, _setEvents] = useState<Array<AnnotationSelectedEvent>>([]);
    const addEvent = useCallback((event: AnnotationSelectedEvent) => _setEvents((prev) => [...prev, event]), [_setEvents]);
    const completeEvent = useCallback(() => _setEvents((prev) => [...prev.slice(1)]), [_setEvents]);
    const selectAnnotations = useCallback(
        (highlightsInput: Array<SupportStateHighlight>) => {
            const highlights = highlightsInput.filter((h) => h.annotations.length > 0);
            addEvent({ action: 'selected', annotations: [], highlights, source: 'app' });
        },
        [addEvent]
    );

    const [supportState, setSupportState] = useState<SupportState>({ highlights: [], update: 0 });
    const setSupportHighlights = useCallback(
        (highlights: Array<SupportStateHighlight>) => setSupportState((prev) => ({ highlights, update: prev.update + 1 })),
        [setSupportState]
    );
    const ignoreInit = useCallback((update: number) => update === 0, []);

    const [fakeAnnotation, setFakeAnnotation] = useState<Core.Annotations.Annotation | null>(null);
    useEffect(() => {
        if (webViewerInstance && !documentLoading) {
            const { Annotations, annotationManager } = webViewerInstance.Core;
            const aa = new Annotations.HTMLAnnotation();
            aa.PageNumber = 1;
            aa.disableRotationControl();
            aa.NoRotate = true;
            aa.NoMove = true;
            aa.NoResize = true;
            aa.PageNumber = 1;
            aa.X = 0;
            aa.Y = 0;
            aa.Width = 0;
            aa.Height = 0;
            aa.setCustomData('fakeAnnotation', 'true');
            annotationManager.addAnnotation(aa);
            setFakeAnnotation(aa);
        }
    }, [webViewerInstance, documentLoading, setFakeAnnotation]);

    const deselectAllAnnotations = useCallback(() => {
        Log(webViewerInstance, 'ACTION', ['useAnnotationsSelecting', 'deselectAllAnnotations'], []);
        if (webViewerInstance && fakeAnnotation) {
            const annotationManager = webViewerInstance.Core.annotationManager;
            const selectedAnnotations = annotationManager.getSelectedAnnotations();
            if (selectedAnnotations.length > 0) {
                Log(
                    webViewerInstance,
                    'ACTION',
                    ['useAnnotationsSelecting', 'deselectAllAnnotations', 'deselectAnnotations'],
                    [selectedAnnotations, fakeAnnotation]
                );
                annotationManager.selectAnnotation(fakeAnnotation);
                annotationManager.deselectAnnotations([fakeAnnotation, ...selectedAnnotations]);
            }
        }
    }, [webViewerInstance, fakeAnnotation]);

    const _selectAnnotations = useCallback(
        (highlightsInput: Array<SupportStateHighlight>) => {
            if (webViewerInstance && fakeAnnotation) {
                const highlights = highlightsInput.filter((h) => h.annotations.length > 0);

                const annotationManager = webViewerInstance.Core.annotationManager;
                const selectedAnnotations = annotationManager.getSelectedAnnotations();
                const highlightsAnnots = highlights
                    .map((h) => h.annotations)
                    .flat()
                    .reverse();
                Log(webViewerInstance, 'ACTION', ['useAnnotationsSelecting', 'Custom SelectAnnotation'], [selectedAnnotations, highlights, highlightsAnnots]);

                if (areAnnotationsListsEqual(selectedAnnotations, highlightsAnnots)) {
                    Log(
                        webViewerInstance,
                        'ACTION',
                        ['useAnnotationsSelecting', 'Custom SelectAnnotation', 'annotationsListsAreEqual'],
                        [selectedAnnotations, highlightsAnnots]
                    );
                    setSupportHighlights(highlights);
                    completeEvent();
                    return;
                } else {
                    let annotationsToDeselect: Array<Core.Annotations.Annotation> = [];
                    selectedAnnotations.forEach((a) => {
                        if (!highlightsAnnots.includes(a)) {
                            annotationsToDeselect.push(a);
                        }
                    });

                    annotationManager.selectAnnotations([...highlightsAnnots, fakeAnnotation]);

                    if (selectedAnnotations.length > 0) {
                        Log(
                            webViewerInstance,
                            'ACTION',
                            ['useAnnotationsSelecting', 'Custom SelectAnnotation', 'deselectAnnotations'],
                            [selectedAnnotations, annotationsToDeselect]
                        );
                        annotationManager.selectAnnotation(fakeAnnotation);
                        annotationManager.deselectAnnotations([...annotationsToDeselect, fakeAnnotation]);
                    }

                    setTimeout(() => {
                        // For Highlight Toolbar -> in another way it will be display under first annotation before deselect
                        Log(
                            webViewerInstance,
                            'ACTION',
                            ['useAnnotationsSelecting', 'Custom SelectAnnotation', 'setTimeout'],
                            [highlightsAnnots, fakeAnnotation]
                        );
                        annotationManager.selectAnnotations([...highlightsAnnots, fakeAnnotation]);
                        setSupportHighlights(highlights);
                        completeEvent();
                    }, 10);
                }
            }
        },
        [webViewerInstance, completeEvent, fakeAnnotation, setSupportHighlights]
    );

    useEffect(() => {
        if (webViewerInstance && events.length > 0) {
            const event = events[0];
            Log(webViewerInstance, 'HOOK', ['useAnnotationsSelecting', 'EventHandler'], [event]);

            if (event.action === 'selected') {
                if (event.source === 'app') {
                    _selectAnnotations(event.highlights);
                    return;
                }

                if (event.source === 'viewer') {
                    const annots = event.annotations.filter(
                        (a) => isStandardAnnotation(a) || AnnotationAttributeType.get(a) === 'highlightLeftTopInformationBackground' || isNewHighlight(a)
                    );
                    let highlightIdsTmp = annots.map((a) => ({
                        id: getHighlightId(a).length > 0 ? getHighlightId(a) : getParentHighlightId(a),
                        multiSelectEnable: AnnotationAttributeMultiSelectEnable.get(a) === 'true',
                    }));

                    let highlightIds = [...new Set(highlightIdsTmp.map((a) => a.id))];
                    if (highlightMultiSelectEnabled) {
                        if (highlightIds.length > 1) {
                            highlightIds = highlightIds.filter((id) => highlightIdsTmp.find((a) => a.id === id && a.multiSelectEnable));
                        }
                    } else {
                        highlightIds = highlightIds.reverse().slice(0, 1);
                    }

                    if (highlightIds.length > 0) {
                        let highlights: Array<SupportStateHighlight> = [];
                        highlightIds.forEach((id) => {
                            const highlightAnnotations = GetAnnotationsByHighlightId(webViewerInstance, id);
                            if (highlightAnnotations.firstAnnotation) {
                                highlights.push(highlightAnnotations);
                            }
                        });

                        if (highlights.length > 1) {
                            highlights = highlights.filter((h) => !h.isNewHighlight);
                        }

                        _selectAnnotations(highlights);
                        return;
                    } else {
                        deselectAllAnnotations();
                        setSupportHighlights([]);
                    }
                }
            }

            if (event.action === 'deselected') {
                if (event.source === 'app') {
                    deselectAllAnnotations();
                    setSupportHighlights([]);
                }

                if (event.source === 'viewer') {
                    const annots = event.annotations.filter(
                        (a) => isStandardAnnotation(a) || AnnotationAttributeType.get(a) === 'highlightLeftTopInformationBackground' || isNewHighlight(a)
                    );
                    const _highlightIds = annots.map((a) => getHighlightId(a));
                    const highlightIds = [...new Set(_highlightIds)];

                    if (highlightIds.length === 0) {
                        setSupportHighlights([]);
                    }
                }
            }

            completeEvent();
        }
        // eslint-disable-next-line
    }, [webViewerInstance, events, deselectAllAnnotations, _selectAnnotations, setSupportHighlights, completeEvent]);

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useAnnotationsSelecting', 'NewHighlight'], [supportState]);
        // Remove annotation prototype if no annotations are selected
        if (ignoreInit(supportState.update)) return;

        const highlights = supportState.highlights;

        if (highlights.length === 0 && newHighlightId !== null && !multiSelectNext) {
            setNewHighlightId(null);
        } else if (highlights.length === 1) {
            const annot = highlights[0].annotations[0];
            if (!isNewHighlight(annot)) {
                setNewHighlightId(null);
            }
            if (multiSelectNext) {
                setMultiSelectNext(false);
            }
        } else if (highlights.length > 1) {
            if (multiSelectNext) {
                setMultiSelectNext(false);
            }
            if (newHighlightId) {
                setNewHighlightId(null);
            }
        }
        // eslint-disable-next-line
    }, [supportState]);

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useAnnotationsSelecting', 'Split'], [supportState]);
        // Disable Splitting
        if (ignoreInit(supportState.update)) return;

        const highlights = supportState.highlights;

        if (highlights.length === 0 && splittedHighlightId !== null) {
            setSplittedHighlightId(null);
        }
        // eslint-disable-next-line
    }, [supportState]);

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useAnnotationsSelecting', 'MultiSelectNext'], [multiSelectNext]);

        if (webViewerInstance) {
            const selectedAnnotations = webViewerInstance.Core.annotationManager.getSelectedAnnotations();
            if (multiSelectNext && selectedAnnotations.length > 0) {
                deselectAllAnnotations();
            }
        }
        // eslint-disable-next-line
    }, [multiSelectNext]);

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useAnnotationsSelecting', 'AppState'], [supportState]);
        // Change selected highlight id in the app state
        if (ignoreInit(supportState.update)) return;

        const highlights = supportState.highlights;

        if (highlights.length > 0) {
            if (highlights.length === 1 && highlights[0].isNewHighlight) {
                return;
            }

            let highlightIds: Array<string> = highlights.map((h) => h.id);

            if (highlightIds.length !== selectedHighlightIds.length || !highlightIds.every((id) => selectedHighlightIds.includes(id))) {
                setSelectedHighlightIds(highlightIds);
            }
        } else {
            if (selectedHighlightIds.length > 0) {
                clearSelectedHighlight();
            }
        }
        // eslint-disable-next-line
    }, [supportState]);

    useEffect(() => {
        if (ignoreInit(supportState.update)) return;

        setViewerAnnotationsUpdated();
        // eslint-disable-next-line
    }, [supportState]);

    const registerAnnotationSelectedViewerListener = useCallback(
        (instance: WebViewerInstance) => RegisterAnnotationSelectedViewerListener(instance, addEvent),
        [addEvent]
    );
    const registerAnnotationSelectedAppListener = useCallback(
        (instance: WebViewerInstance) => RegisterAnnotationSelectedAppListener(instance, selectedHighlightChangedEventName, addEvent),
        // eslint-disable-next-line
        [addEvent]
    );

    return {
        registerAnnotationSelectedViewerListener,
        registerAnnotationSelectedAppListener,
        viewerAnnotationsUpdated,
        selectAnnotations,
        deselectAllAnnotations,
    };
};

const RegisterAnnotationSelectedViewerListener = (webViewerInstance: WebViewerInstance, addEvent: (event: AnnotationSelectedEvent) => void) => {
    const annotationManager = webViewerInstance.Core.annotationManager;
    annotationManager.addEventListener('annotationSelected', (annotations: Array<Core.Annotations.Annotation>, action: AnnotationSelectedEventAction) => {
        Log(webViewerInstance, 'EVENT', ['RegisterAnnotationSelectedViewerListener', 'annotationSelected'], [action, annotations]);

        if (ignoreEvent(annotationManager, annotations, action)) return;

        const selectedAnnotations = annotationManager.getSelectedAnnotations();
        Log(
            webViewerInstance,
            'EVENT',
            ['RegisterAnnotationSelectedViewerListener', 'annotationSelected', 'addEvent'],
            [action, annotations, selectedAnnotations]
        );
        addEvent({ action, annotations: selectedAnnotations, highlights: [], source: 'viewer' });
    });
};

const ignoreEvent = (annotationManager: Core.AnnotationManager, annotations: Array<Core.Annotations.Annotation>, action: AnnotationSelectedEventAction) => {
    const fakeAnnotation = annotations.find((a) => a.getCustomData('fakeAnnotation') === 'true');
    if (fakeAnnotation) {
        if (action === 'selected' && annotations.length > 1) {
            annotationManager.deselectAnnotation(fakeAnnotation);
        }
        return true;
    } else {
        return false;
    }
};

const RegisterAnnotationSelectedAppListener = (
    webViewerInstance: WebViewerInstance,
    selectedHighlightChangedEventName: string,
    addEvent: (event: AnnotationSelectedEvent) => void
) => {
    const eventHandler = ((e: CustomEvent<SelectedHighlightChangedEvent>) => {
        Log(webViewerInstance, 'EVENT', ['RegisterAnnotationSelectedAppListener', selectedHighlightChangedEventName], [e.detail]);
        const ids = e.detail.ids;

        if (ids.length > 0) {
            const annotationManager = webViewerInstance.Core.annotationManager;

            if (ids.length === 1) {
                const highlightAnnotations = GetAnnotationsByHighlightId(webViewerInstance, ids[0]);
                const firstAnnot = highlightAnnotations.firstAnnotation;
                if (firstAnnot) {
                    addEvent({ action: 'selected', annotations: [], highlights: [highlightAnnotations], source: 'app' });
                    annotationManager.jumpToAnnotation(firstAnnot);
                }
            } else {
                let highlights: Array<SupportStateHighlight> = [];
                ids.forEach((id) => {
                    const highlightAnnotations = GetAnnotationsByHighlightId(webViewerInstance, id);
                    const firstAnnot = highlightAnnotations.firstAnnotation;
                    if (firstAnnot) {
                        highlights.push(highlightAnnotations);
                    }
                });
                if (highlights.length > 0) {
                    addEvent({ action: 'selected', annotations: [], highlights, source: 'app' });
                }
            }
        } else {
            addEvent({ action: 'deselected', annotations: [], highlights: [], source: 'app' });
        }
    }) as EventListener;

    document.addEventListener(selectedHighlightChangedEventName, eventHandler);

    return () => document.removeEventListener(selectedHighlightChangedEventName, eventHandler);
};

const areAnnotationsListsEqual = (a: Array<Core.Annotations.Annotation>, b: Array<Core.Annotations.Annotation>) => {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (!a.includes(b[i])) return false;
    }

    return true;
};

export const GetAnnotationsByHighlightId = (
    webViewerInstance: WebViewerInstance,
    highlightId: string
): SupportStateHighlight & { firstAnnotation: Core.Annotations.Annotation | null; lastAnnotation: Core.Annotations.Annotation | null } => {
    const annotationManager = webViewerInstance.Core.annotationManager;
    const annotations = annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === highlightId && isStandardAnnotation(a));
    const firstAnnotation = annotations.find((a) => isFirstAnnotationOfHighlight(a));
    const lastAnnotation = annotations.find((a) => isLastAnnotationOfHighlight(a));
    const _isNewHighlight: boolean = annotations.find((a) => isNewHighlight(a)) !== undefined;

    if (firstAnnotation && lastAnnotation) {
        return {
            id: highlightId,
            isNewHighlight: _isNewHighlight,
            firstAnnotation,
            lastAnnotation,
            annotations,
        };
    }

    return {
        id: highlightId,
        isNewHighlight: false,
        firstAnnotation: null,
        lastAnnotation: null,
        annotations,
    };
};

export default useAnnotationsSelecting;
