import { Annotation } from 'ApiClients/SterlingApiClients/Types';
import { SearchRoutes, UrlParams } from 'App/RoutesPaths';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const useProjectSearchParams = (
    openProjectMembersModal: () => void,
    goToSupportingInformation: (id: string, documentId: string) => void,
    goToSupportingDocument: (documentId: string) => void,
    annotations: Array<Annotation>
) => {
    const { hash } = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (hash === SearchRoutes.goToEditProjectMembers.hash) {
            window.location.hash = '';
            openProjectMembersModal();
        }
        // eslint-disable-next-line
    }, [hash]);
    useEffect(() => {
        if (hash === SearchRoutes.goToLinkedInformation.hash) {
            const annotationId = searchParams.get(UrlParams.annotationId.paramName);
            const documentId = searchParams.get(UrlParams.documentId.paramName);
            const supportingInformationId = searchParams.get(UrlParams.supportingInformationId.paramName);

            if (annotationId && documentId && supportingInformationId) {
                goToSupportingInformation(supportingInformationId!, documentId!);
            }
        }
    }, [searchParams, annotations, goToSupportingInformation, hash]);
    useEffect(() => {
        if (hash === SearchRoutes.goToSupportingDocument.hash) {
            const documentId = searchParams.get(UrlParams.documentId.paramName);
            if (documentId) {
                goToSupportingDocument(documentId!);
            }
        }
    }, [searchParams, goToSupportingDocument, hash]);
};

export default useProjectSearchParams;
