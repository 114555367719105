import { Icon } from 'UI';
import { useMemo } from 'react';
import { Navigation } from '../../Hooks/useNavigation';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';
import { Highlight, HighlightToolbarActionsState, HighlightViewer } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { Annotation, AnnotationStatus, BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { SupportingHighlightCustomData } from '../../SupportingDocument.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { Box } from '@mui/material';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { AnnotationVerifiedDisabledTooltip } from 'App/Consts';

type PropositionPanelComponentProps = HighlightToolbarActionsState<PropositionPanelActionsState> & {
    selectedHighlight: Highlight<SupportingHighlightCustomData>;
    selectedAnnotation: Annotation;
    navigation: Navigation;
    isRejected: boolean;
    rejectProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkModifiedPropositionToAnnotation: (
        annotationId: string,
        propositionId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    restoreProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    addPart: () => void;
    getHighlightViewer: () => Promise<HighlightViewer>;
};

export type PropositionPanelActionsState = {
    restorePropositionFetching: boolean;
    rejectPropositionFetching: boolean;
    linkPropositionFetching: boolean;
};

function PropositionPanelComponent(props: PropositionPanelComponentProps) {
    const {
        selectedAnnotation,
        selectedHighlight,
        navigation,
        isRejected,
        rejectProposition,
        linkPropositionToAnnotation,
        linkModifiedPropositionToAnnotation,
        restoreProposition,
        actionsState,
        setHighlightActionsState,
        addPart,
        getHighlightViewer,
    } = props;

    const { restorePropositionFetching, rejectPropositionFetching, linkPropositionFetching } = useMemo(
        () =>
            actionsState || {
                restorePropositionFetching: false,
                rejectPropositionFetching: false,
                linkPropositionFetching: false,
            },
        [actionsState]
    );
    const fetching = useMemo(
        () => restorePropositionFetching || rejectPropositionFetching || linkPropositionFetching,
        [restorePropositionFetching, rejectPropositionFetching, linkPropositionFetching]
    );
    const annotationVerified = useMemo(() => selectedAnnotation.status === AnnotationStatus.Verified, [selectedAnnotation]);

    const { action: rejectPropositionWrapped } = useApiModify<{ annotationId: string; propositionId: string }>({
        method: (input, flowMethods) =>
            rejectProposition(input.annotationId, input.propositionId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.propositionId, 'rejectPropositionFetching', value),
            }),
    });

    const { action: restorePropositionWrapped } = useApiModify<{ annotationId: string; propositionId: string }>({
        method: (input, flowMethods) =>
            restoreProposition(input.annotationId, input.propositionId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.propositionId, 'restorePropositionFetching', value),
            }),
    });

    const { action: linkPropositionToAnnotationWrapped } = useApiModify<{ annotationId: string; propositionId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            if (highlightViewer.isModified) {
                linkModifiedPropositionToAnnotation(
                    input.annotationId,
                    input.propositionId,
                    highlightViewer.selectionType,
                    highlightViewer.boundingBoxSections,
                    highlightViewer.statement,
                    {
                        ...flowMethods,
                        setFetching: (value) => setHighlightActionsState(input.propositionId, 'linkPropositionFetching', value),
                    }
                );
            } else {
                linkPropositionToAnnotation(input.annotationId, input.propositionId, {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.propositionId, 'linkPropositionFetching', value),
                });
            }
        },
    });

    const utils: Array<Util> = [
        {
            icon: Icon.ArrowLeftCircle,
            onClick: () => navigation.previous(),
            tooltip: 'Previous Proposition',
            disabled: !navigation.available || fetching,
        },
        {
            icon: Icon.ArrowRightCircle,
            onClick: () => navigation.next(),
            tooltip: 'Next Proposition',
            disabled: !navigation.available || fetching,
        },
    ];

    if (isRejected) {
        utils.push({
            icon: Icon.ArrowCounterClockWise,
            onClick: () => restorePropositionWrapped({ annotationId: selectedAnnotation.id, propositionId: selectedHighlight.id }),
            tooltip: annotationVerified ? AnnotationVerifiedDisabledTooltip : 'Restore Proposition',
            description: 'Restore',
            disabled: fetching || annotationVerified,
            loading: restorePropositionFetching,
        });
    } else {
        utils.push({
            icon: Icon.CloseCircle,
            onClick: () => rejectPropositionWrapped({ annotationId: selectedAnnotation.id, propositionId: selectedHighlight.id }),
            tooltip: annotationVerified ? AnnotationVerifiedDisabledTooltip : 'Reject Proposition',
            description: 'Reject',
            disabled: fetching || annotationVerified,
            loading: rejectPropositionFetching,
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
        });
        utils.push({
            icon: Icon.Approve,
            onClick: () => linkPropositionToAnnotationWrapped({ annotationId: selectedAnnotation.id, propositionId: selectedHighlight.id }),
            tooltip: annotationVerified ? AnnotationVerifiedDisabledTooltip : 'Link to Annotation',
            description: 'Link',
            disabled: fetching || annotationVerified,
            loading: linkPropositionFetching,
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        });
    }

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent utils={utils} />
            <Box sx={{ width: '0.25rem' }} />
            <PartsToolbarComponent addPart={addPart} disabled={fetching || annotationVerified} />
        </div>
    );
}

export default PropositionPanelComponent;
