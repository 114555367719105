import Box from '@mui/material/Box';
import Button, { ButtonProps } from '../Button';
import * as Icon from '../Icon';
import { palette } from 'UI/Provider/VerifiTheme';
import { Typography } from '@mui/material';

function BackButton({
    children,
    text,
    sx,
    ...rest
}: ButtonProps & {
    text?: string;
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}) {
    return (
        <Button
            size='small'
            variant='text'
            sx={{
                ...sx,
                textTransform: 'capitalize',
                minWidth: 0,
                padding: '4px 4px 4px 0',
            }}
            {...rest}
        >
            <Box display='inline-flex' alignItems='center' gap={1}>
                <Icon.ArrowLeftCircle color={palette.link.main} />
                {text && (
                    <Typography
                        sx={{
                            fontSize: '14px !important',
                            fontWeight: 600,
                            color: (theme) => theme.palette.link.main,
                        }}
                    >
                        {text}
                    </Typography>
                )}
                {children}
            </Box>
        </Button>
    );
}
export default BackButton;
