import MuiToggleButton, { ToggleButtonProps as MuiToggleButtonProps } from '@mui/material/ToggleButton';
import MuiToggleButtonGroup, { ToggleButtonGroupProps as MuiToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';

function ToggleButton(props: MuiToggleButtonGroupProps) {
    return <MuiToggleButtonGroup {...props} color='primary' />;
}

function Button(props: MuiToggleButtonProps) {
    return <MuiToggleButton {...props} color='primary' />;
}

ToggleButton.Button = Button;

export default ToggleButton;
