import { Box, Collapse } from '@mui/material';
import { Icon, Tooltip, Typography } from 'UI';
import { useState } from 'react';
import ActionButton from './ActionButton/ActionButton.Component';

export type InformationDataToolbarProps = {
    savedInRepository: boolean;
    saveToRepository: () => void;
    savingToRepository: boolean;
    backToWebSearch: () => void;
    openInNewTab: () => void;
};

function InformationDataToolbar(props: InformationDataToolbarProps) {
    const { saveToRepository, savedInRepository, savingToRepository, backToWebSearch, openInNewTab } = props;

    const [open, setOpen] = useState<boolean>(true);

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '3rem',
                zIndex: 100,
                display: 'flex',
                width: 'fit-content',
            }}
        >
            <Box
                onClick={() => setOpen(!open)}
                sx={{
                    height: '7rem',
                    width: '2rem',
                    backgroundColor: (theme) => theme.palette.blue.dark,
                    color: (theme) => theme.palette.white.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                {open ? <Icon.ArrowSimpleLeft /> : <Icon.ArrowSimpleRight />}
            </Box>
            <Collapse in={open} orientation='horizontal'>
                <Box
                    sx={{
                        width: '20rem',
                        height: '100%',
                        backgroundColor: (theme) => theme.palette.white.main,
                        border: (theme) => `0.125rem solid ${theme.palette.stroke.main}`,
                        borderRadius: '0rem 0.25rem 0.25rem 0rem',
                        paddingLeft: '1rem',
                        paddingTop: '0.5rem',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '2rem',
                        }}
                    >
                        {!savedInRepository && (
                            <>
                                <ActionButton icon={Icon.CloudDownload} text='Save to Repository' onClick={saveToRepository} loading={savingToRepository} />
                                <Tooltip
                                    title={
                                        'If the quality of the document is not satisfactory, please open it in a new tab. Print to PDF manually and upload it.'
                                    }
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.attention.medium, marginLeft: '0.5rem' }}>
                                        <Icon.ExclamationCircle width={16} height={16} />
                                    </Box>
                                </Tooltip>
                            </>
                        )}
                        {savedInRepository && (
                            <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.success.main }}>
                                <Icon.Approve width={16} height={16} />
                                <Typography sx={{ marginLeft: '0.5rem', fontWeight: 600 }}>Document saved in repository</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '2rem',
                        }}
                    >
                        <ActionButton icon={Icon.Subtract} text='Open In New Tab' onClick={openInNewTab} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '2rem',
                        }}
                    >
                        <ActionButton icon={Icon.ArrowLeftCircle} text='Back to Web Search' onClick={backToWebSearch} />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

export default InformationDataToolbar;
