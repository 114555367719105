import React from 'react';

function Unchecked(props: React.SVGProps<SVGElement>, ref: React.Ref<SVGSVGElement>) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16' fill='none' {...props} ref={ref}>
            <g clipPath='url(#unchecked-a)'>
                <mask id='b' fill='#fff'>
                    <path
                        fillRule='evenodd'
                        d='M.586.586A2 2 0 0 1 2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2A2 2 0 0 1 .586.586Z'
                        clipRule='evenodd'
                    />
                </mask>
                <path
                    fill='#fff'
                    fillRule='evenodd'
                    d='M.586.586A2 2 0 0 1 2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2A2 2 0 0 1 .586.586Z'
                    clipRule='evenodd'
                />
                <path
                    fill='currentColor'
                    d='m.586.586.707.707L.586.586Zm14.828 0-.707.707.707-.707ZM.586 15.414l.707-.707-.707.707ZM2-1a3 3 0 0 0-2.121.879l1.414 1.414A1 1 0 0 1 2 1v-2Zm12 0H2v2h12v-2Zm2.121.879A3 3 0 0 0 14-1v2a1 1 0 0 1 .707.293l1.414-1.414ZM17 2a3 3 0 0 0-.879-2.121l-1.414 1.414A1 1 0 0 1 15 2h2Zm0 12V2h-2v12h2Zm-.879 2.121A3 3 0 0 0 17 14h-2a1 1 0 0 1-.293.707l1.414 1.414ZM14 17a3 3 0 0 0 2.121-.879l-1.414-1.414A1 1 0 0 1 14 15v2ZM2 17h12v-2H2v2Zm-2.121-.879A3 3 0 0 0 2 17v-2a1 1 0 0 1-.707-.293l-1.414 1.414ZM-1 14a3 3 0 0 0 .879 2.121l1.414-1.414A1 1 0 0 1 1 14h-2Zm0-12v12h2V2h-2Zm.879-2.121A3 3 0 0 0-1 2h2a1 1 0 0 1 .293-.707L-.121-.121Z'
                    mask='url(#b)'
                />
            </g>
            <defs>
                <clipPath id='unchecked-a'>
                    <path fill='#fff' d='M0 0h16v16H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.forwardRef<SVGSVGElement, React.SVGProps<SVGElement>>(Unchecked);
