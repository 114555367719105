import { useCallback, useMemo } from 'react';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { hexToRGBA } from 'UI/functions';
import { PdfViewerOtherProps } from '../../Hooks/usePdfViewer';
import { AnnotationTransferData, StatusConfig } from '../useAnnotationsTransferState';
import ActiveAnnotationPanelWrapperComponent from '../PdfViewerComponents/ActiveAnnotationPanelWrapper/ActiveAnnotationPanelWrapper.Component';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';

export type ActiveAnnotationHighlightCustomData = {
    status: AnnotationTransferPropositionStatus;
};

export const ActiveAnnotationSelectedEventName = 'activeAnnotationSelectedEventName';

const usePdfViewerActive = (
    selectedAnnotationId: string | null,
    setSelectedAnnotationId: (annotationId: string | null) => void,
    data: Array<AnnotationTransferData>,
    setAnnotationsListRedrawing: (value: boolean) => void
): PdfViewerOtherProps<ActiveAnnotationHighlightCustomData> => {
    const highlights = useMemo(
        () => [
            ...data.map((el): Highlight<ActiveAnnotationHighlightCustomData> => {
                const annot = el.annotationActive;
                return {
                    id: annot.id,
                    orderNumber: annot.orderNumber,
                    selectionType: annot.content.selectionType,
                    boundingBoxSections: annot.content.boundingBoxSections,
                    siblingsId: annot.id,
                    status: el.status,
                };
            }),
        ],
        [data]
    );
    const highlightColor = useCallback((highlight: Highlight<ActiveAnnotationHighlightCustomData>, selectedHighlightIds: Array<string>) => {
        return selectedHighlightIds.includes(highlight.id)
            ? hexToRGBA(StatusConfig[highlight.status].selectedColor)
            : hexToRGBA(StatusConfig[highlight.status].baseColor);
    }, []);

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [highlights, selectedAnnotationId];
    }, [highlights, selectedAnnotationId]);

    const selectedHighlightIds = useMemo(() => (selectedAnnotationId ? [selectedAnnotationId] : []), [selectedAnnotationId]);
    const setSelectedHighlightIds = useCallback((ids: Array<string>) => setSelectedAnnotationId(ids[0]), [setSelectedAnnotationId]);
    const clearSelectedHighlight = useCallback(() => setSelectedAnnotationId(null), [setSelectedAnnotationId]);
    const onAnnotationsRedraw = useCallback(() => setAnnotationsListRedrawing(false), [setAnnotationsListRedrawing]);

    return {
        displayHighlightIcons: false,
        isCreatingHighlightEnabled: false,
        highlightsProps: {
            highlights,
            highlightColor,
            highlightToolbars: {
                highlightToolbar: {
                    render: (options) => {
                        const annotation = data.find((el) => el.annotationActive.id === selectedAnnotationId)?.annotationActive;
                        if (annotation) {
                            return (
                                <ActiveAnnotationPanelWrapperComponent
                                    highlightToolbarOptions={options}
                                    annotation={data.find((el) => el.annotationActive.id === selectedAnnotationId)?.annotationActive!}
                                />
                            );
                        } else {
                            return <div />;
                        }
                    },
                },
                newHighlightToolbar: {
                    render: () => <div />,
                },
                deps: highlightToolbarsDeps,
            },
            highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
            selectedHighlightIds,
            setSelectedHighlightIds,
            clearSelectedHighlight,
            selectedHighlightChangedEventName: ActiveAnnotationSelectedEventName,
            focusOnSelectedHighlightAfterDraw: true,
            keepSelectedHighlightInCentre: true,
            onAnnotationsRedraw,
        },
    };
};

export default usePdfViewerActive;
