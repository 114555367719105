import { RootRoutePath } from "App/RoutesPaths/RoutePath";
import Creator from "./Routes/Creator";


export default class Project extends RootRoutePath {
    public creator = new Creator(this.specificPath.bind(this));

    constructor() {
        super('/project');
    }
}

