import React, { ForwardedRef } from 'react';
import MuiButton, { ButtonProps as MuiProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export type ButtonProps = MuiProps & { shape?: 'circle'; isLoading?: boolean; to?: RouterLinkProps['to']; component?: React.ElementType };

function Button({ color, variant = 'contained', shape, isLoading, to, sx, ...rest }: ButtonProps, ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) {
    return (
        <MuiButton
            {...(to && { component: RouterLink, to })}
            color={color}
            variant={variant}
            ref={ref as React.RefObject<HTMLButtonElement>}
            sx={{
                height: '2rem',
                ...(color === 'secondary' && {
                    backgroundColor: (theme) => theme.palette.white.main,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.blue.light,
                    },
                }),
                ...sx,
            }}
            {...rest}
            {...(color === 'primary' && { color: 'primary', variant: 'contained' })}
            {...(color === 'secondary' && {
                color: 'primary',
                variant: 'outlined',
            })}
            {...(shape === 'circle' && {
                sx: {
                    borderRadius: '50%',
                    minWidth: 16,
                    padding: 0.5,
                    fontSize: '1.5rem',
                    borderWidth: 1,
                    ...(color === 'secondary' && {
                        backgroundColor: (theme) => theme.palette.white.main,
                    }),
                    '&:hover': {
                        borderWidth: 1,
                        ...(color === 'secondary' && {
                            backgroundColor: (theme) => theme.palette.blue.light,
                        }),
                    },
                    ...(rest.size === 'small' && { padding: '5px', fontSize: '1.125rem' }),
                    ...sx,
                },
            })}
            {...(isLoading && { variant: 'contained', disabled: true })}
        >
            {isLoading ? (
                <>
                    <CircularProgress
                        color='inherit'
                        sx={{ position: 'absolute', height: '75% !important', width: 'unset !important', aspectRatio: '1/1 !important' }}
                    />
                    <div style={{ visibility: 'hidden' }}>{rest.children}</div>
                </>
            ) : (
                rest.children
            )}
        </MuiButton>
    );
}

export default React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(Button);
