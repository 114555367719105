import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import { FileInfo } from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useEffect, useRef, useState } from 'react';

export type WebSearchDocumentsProps = {
    webSearchDocumentInfo: WebSearchDocumentInfo | null;
    webSearchDocumentFileInfo: FileInfo | null;
    switchWebSearchDocument: (url: string | null) => void;
    uploadWebSearchDocument: (webSearchDocumentInfo: WebSearchDocumentInfo, webSearchDocumentFileInfo: FileInfo, flowMethods: FlowMethods<string>) => void;
};

export type WebSearchDocumentInfo = {
    id: string;
    supportingDocumentId: string | null;
    name: string;
    fileId: string;
    url: string;
    savedInRepository: boolean;
    creationDate: Date | null;
};

const useWebSearchDocuments = (
    projectId: string,
    projectVersionId: string,
    docsApi: ProjectDocumentsApiClient,
    setSelectedSupportingHighlightIdRef: React.MutableRefObject<(id: string | null, options: { dispatchEvent: boolean }) => void>,
    closeSupportingInformationPropositionsRef: React.MutableRefObject<() => void>,
    switchSupportingDocument: (documentId: string | null) => void,
    refetchSupportingDocuments: () => void
): WebSearchDocumentsProps => {
    const [webSearchDocumentInfo, setWebSearchDocumentInfo] = useState<WebSearchDocumentInfo | null>(null);
    const [webSearchDocumentFileInfo, setWebSearchDocumentFileInfo] = useState<FileInfo | null>(null);
    const urlRef = useRef<string | null>(webSearchDocumentInfo?.url || null);

    const switchWebSearchDocument = useCallback(
        (url: string | null) => {
            setSelectedSupportingHighlightIdRef.current(null, { dispatchEvent: true });
            if (url) {
                closeSupportingInformationPropositionsRef.current();
                switchSupportingDocument(null);

                const tmpId = new Date().getTime().toString();

                setWebSearchDocumentInfo({
                    id: tmpId,
                    supportingDocumentId: null,
                    name: '',
                    fileId: tmpId,
                    url,
                    savedInRepository: false,
                    creationDate: null,
                });
            } else {
                setWebSearchDocumentInfo(null);
                setWebSearchDocumentFileInfo(null);
            }
        },
        // eslint-disable-next-line
        []
    );

    const getWebSearchDocument = useCallback(
        (webSearchDocumentInfo: WebSearchDocumentInfo) => {
            const { fileId, url } = webSearchDocumentInfo;
            docsApi.getWebSearchDocument(projectId, projectVersionId, url).then(
                (data) => {
                    if (urlRef.current === url) {
                        setWebSearchDocumentInfo({
                            ...webSearchDocumentInfo,
                            name: data.file.name,
                            supportingDocumentId: data.documentId,
                            savedInRepository: data.documentId !== null,
                            creationDate: data.documentCreationDate,
                        });
                        setWebSearchDocumentFileInfo({ id: fileId, file: data.file });
                    }
                },
                () => {
                    switchWebSearchDocument(null);
                }
            );
        },
        [projectId, projectVersionId, docsApi, switchWebSearchDocument]
    );
    useEffect(() => {
        urlRef.current = webSearchDocumentInfo?.url || null;
        if (webSearchDocumentInfo) {
            getWebSearchDocument(webSearchDocumentInfo);
        }
        // eslint-disable-next-line
    }, [getWebSearchDocument, switchWebSearchDocument, setWebSearchDocumentFileInfo, webSearchDocumentInfo?.url]);

    const uploadWebSearchDocument = (webSearchDocumentInfo: WebSearchDocumentInfo, webSearchDocumentFileInfo: FileInfo, flowMethods: FlowMethods<string>) =>
        docsApi.uploadWebSearchDocument(projectId!, projectVersionId, {
            body: {
                name: webSearchDocumentInfo.name,
                document: webSearchDocumentFileInfo.file,
                url: webSearchDocumentInfo.url,
                creationDate: webSearchDocumentInfo.creationDate!,
            },
            ...flowMethods,
            onSuccess: (documentId) => {
                refetchSupportingDocuments();
                setWebSearchDocumentInfo({
                    ...webSearchDocumentInfo,
                    supportingDocumentId: documentId,
                    savedInRepository: true,
                });
                flowMethods.onSuccess && flowMethods.onSuccess(documentId);
            },
        });

    return {
        webSearchDocumentInfo,
        webSearchDocumentFileInfo,
        switchWebSearchDocument,
        uploadWebSearchDocument,
    };
};

export default useWebSearchDocuments;
