import { useMemo, useState } from 'react';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { IconType } from 'UI/Icon';

export type BulkActionInput = {
    title: string;
    icon: IconType;
    action: (ids: Array<string>, flowMethods: FlowMethods) => void;
    themeColor: string;
};

export type BulkActionOutput = {
    title: string;
    icon: IconType;
    themeColor: string;
    action: (ids: Array<string>) => void;
    fetching: boolean;
};

const useAnnotationBulkActions = (bulkActionsInput?: Array<BulkActionInput>): Array<BulkActionOutput> => {
    const [fetching, setFetching] = useState<{ [key: number]: boolean }>({});

    const bulkActionsOutput: Array<BulkActionOutput> = useMemo(() => {
        return bulkActionsInput ? bulkActionsInput?.map((bulkAction, index) => {
                return {
                    title: bulkAction.title,
                    icon: bulkAction.icon,
                    action: (ids: Array<string>) => {
                        bulkAction.action(ids, { setFetching: (value) => setFetching({ ...fetching, [index]: value })});
                    },
                    fetching: fetching[index] ?? false,
                    themeColor: bulkAction.themeColor,
                };
            }): []
    }, [bulkActionsInput, fetching]);

    return bulkActionsOutput;
};

export default useAnnotationBulkActions;