import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationAttributeType, AnnotationAttributeTypeValues, getParentHighlightId } from './Drawing/ViewerAnnotations/Attributes';
import { PartNumberContainerClassName } from './Drawing/HtmlElements/HighlightPartNumber';

export const ActionDesc = {
    Show: 'Show number',
    Hide: 'Hide number',
};

const ElemenetsToShowHide: Array<AnnotationAttributeTypeValues> = [
    'additionalHtmlElement',
    'highlightLeftTopInformation',
    'highlightLeftTopInformationBackground',
];

const ShowHideAnnotationElements = (webViewerInstance: WebViewerInstance, show: boolean, id?: string) => {
    const htmlElemenetsToShowHide: Array<HTMLDivElement> = webViewerInstance.UI.iframeWindow.document.getElementsByClassName(
        PartNumberContainerClassName
    ) as unknown as Array<HTMLDivElement>;

    const annotationManager = webViewerInstance.Core.annotationManager;
    const allElements = annotationManager.getAnnotationsList().filter((a) => ElemenetsToShowHide.includes(AnnotationAttributeType.get(a)));
    let annotsToChange: Array<Core.Annotations.Annotation> = [];
    if (id) {
        annotsToChange = allElements.filter((a) => getParentHighlightId(a) === id);
    } else {
        annotsToChange = allElements;
    }

    if (show) {
        annotsToChange.forEach((a) => (a.Hidden = false));
        annotationManager.drawAnnotationsFromList(annotsToChange);
        for (const e of htmlElemenetsToShowHide) {
            e.style.display = 'unset';
        }
    } else {
        annotsToChange.forEach((a) => (a.Hidden = true));
        annotationManager.drawAnnotationsFromList(annotsToChange);
        for (const e of htmlElemenetsToShowHide) {
            e.style.display = 'none';
        }
    }
};

export default ShowHideAnnotationElements;
