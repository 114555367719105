import { Box } from '@mui/material';
import { Icon } from 'UI';
import { ToolbarUtilComponent } from '../Toolbar.Component';
import { HighlightNavigationProps } from '../../PdfViewerWithToolbar.Types';

type HighlightNavigationUtilsComponentProps = HighlightNavigationProps;

function HighlightNavigationUtilsComponent(props: HighlightNavigationUtilsComponentProps) {
    const { disabled, previousHighlight, nextHighlight } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarUtilComponent title='Previous annotation' icon={Icon.ArrowShortUp} active={false} disabled={disabled} onClick={previousHighlight} />
            <ToolbarUtilComponent title='Next annotation' icon={Icon.ArrowShortDown} active={false} disabled={disabled} onClick={nextHighlight} />
        </Box>
    );
}

export default HighlightNavigationUtilsComponent;
