import { Box, Divider } from '@mui/material';
import { AppRoutes } from 'App/RoutesPaths';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import { ProjectTabRouteDashboard } from 'App/RoutesPaths/Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance';
import { Icon, Menu, Tooltip, Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';
import { Link } from 'react-router-dom';

export type ActionsCellComponentProps = {
    isProjectLeader: boolean;
    projectId: string;
    editProjectMembers: () => void;
    archiveProject: () => void;
};

function ActionsCellComponent(props: ActionsCellComponentProps) {
    const { isProjectLeader, projectId, editProjectMembers, archiveProject } = props;
    let actions = [
        {
            icon: Icon.People,
            text: 'Manage team',
            onClick: editProjectMembers,
        },
    ];

    if (isProjectLeader) {
        actions.push({
            icon: Icon.Archive,
            text: 'Archive project',
            onClick: archiveProject,
        });
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', paddingRight: '1rem' }}>
            <Link
                to={AppRoutes.projectInstance.projectVersionInstance.specificPath({
                    projectId,
                    versionId: ActiveProjectVersionId,
                    tab: ProjectTabRouteDashboard,
                })}
                style={{ textDecoration: 'none' }}
                onClick={(e) => e.stopPropagation()}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', color: (theme) => theme.palette.link.main }}>
                    <Icon.Activity />
                    <Typography
                        sx={{
                            marginLeft: '0.25rem',
                            fontSize: '0.75rem',
                            ':hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        Dashboard
                    </Typography>
                </Box>
            </Link>
            <Box sx={{ marginLeft: '1.5rem' }}>
                <Menu>
                    <Menu.Trigger
                        color='secondary'
                        sx={{
                            border: 'none',
                            color: palette.text.main,
                            padding: 0,
                            minWidth: 0,
                            '&:hover': {
                                border: 'none',
                            },
                        }}
                    >
                        <Icon.ThreeDotsVertical />
                    </Menu.Trigger>
                    <Menu.List hasContainerWidth={false} onClick={(e) => e.stopPropagation()}>
                        {actions.map((item, index) => (
                            <Menu.Item key={`actions-item-${index}`} sx={{ textTransform: 'unset' }} onClick={item.onClick}>
                                <Box display='flex' gap={1} alignItems='center'>
                                    <item.icon height={16} width={16} />
                                    {item.text}
                                </Box>
                            </Menu.Item>
                        ))}
                        <Divider sx={{ marginBottom: '0 !important', marginTop: '0 !important' }} />
                        <Menu.Item disabled={true} sx={{ textTransform: 'unset', pointerEvents: 'auto !important' }}>
                            <Tooltip title='Coming soon'>
                                <Box display='flex' gap={1} alignItems='center' onClick={(e) => e.stopPropagation()}>
                                    <Icon.Copy height={16} width={16} />
                                    Duplicate project
                                </Box>
                            </Tooltip>
                        </Menu.Item>
                    </Menu.List>
                </Menu>
            </Box>
        </Box>
    );
}

export default ActionsCellComponent;
