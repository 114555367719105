import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationStatusDictionary } from 'Views/Common/Types';
import { useCallback, useMemo, useReducer } from 'react';

export type AnnotationFilters = {
    all: {
        filters: AnnotationFiltersState;
        filtersList: FiltersList;
        update: (filters: AnnotationFiltersState) => void;
        clear: () => void;
    };
    status: AnnotationFilter<StatusFilterState>;
    tag: AnnotationFilter<TagFilterState>;
    assignment: AnnotationFilter<AssignmentFilterState>;
    assertion: AnnotationFilter<AssertionFilterState>;
    linkedInformation: AnnotationFilter<LinkedInformationFilterState>;
};

export type AnnotationFiltersState = {
    status: StatusFilterState;
    tag: TagFilterState;
    assignment: AssignmentFilterState;
    assertion: AssertionFilterState;
    linkedInformation: LinkedInformationFilterState;
};

export type AnnotationFilterState<TValue> = Array<TValue>;

export type StatusFilterState = AnnotationFilterState<AnnotationStatus>;
export type TagFilterState = AnnotationFilterState<{ id: string; name: string }>;
export type AssignmentFilterState = AnnotationFilterState<{ id: string; fullName: string }>;
export type AssertionFilterState = AnnotationFilterState<{ value: string; label: string }>;
export enum AdditionalAssertionFilter {
    NoAssertion = 'No assertion',
    Other = 'Other',
}
export const AdditionalAssertionFiltersAvailable: Array<string> = [AdditionalAssertionFilter.NoAssertion, AdditionalAssertionFilter.Other];
export type LinkedInformationFilterState = AnnotationFilterState<LinkedInformationFilter>;
export type LinkedInformationFilter = {
    value: LinkedInformationFilterType;
    label: string;
};
export type LinkedInformationFilterType = 'any' | 'no';
export const LinkedInformationFiltersAvailable: Array<LinkedInformationFilter> = [
    { label: 'Supporting Documents Linked', value: 'any' },
    { label: 'No Supporting Documents Linked', value: 'no' },
];

type AnnotationFilter<TValue> = {
    value: TValue;
    add: (value: TValue) => void;
    remove: (value: TValue) => void;
    replace: (value: TValue) => void;
    clear: () => void;
};

type FiltersList = Array<FiltersListItem>;

type FiltersListItem = {
    label: string;
    remove: () => void;
};

type ChangeFilterAction = {
    type: ChangeFilterActionType;
    payload: Partial<AnnotationFiltersState>;
};

enum ChangeFilterActionType {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    REPLACE = 'REPLACE',
    CLEAR_ALL = 'CLEAR_ALL',
}

type ChangeFilterActionFunctions = {
    add: <TValue>(state: Array<TValue>, value: Array<TValue>) => Array<TValue>;
    remove: <TValue>(state: Array<TValue>, value: Array<TValue>) => Array<TValue>;
};

const useAnnotationsFilters = (initialFilters?: AnnotationFiltersState): AnnotationFilters => {
    const annotationFilterDefaultValue = useMemo(() => [], []);
    const defaultValue: AnnotationFiltersState = useMemo(
        () => ({
            status: annotationFilterDefaultValue,
            tag: annotationFilterDefaultValue,
            assignment: annotationFilterDefaultValue,
            assertion: annotationFilterDefaultValue,
            linkedInformation: annotationFilterDefaultValue,
        }),
        [annotationFilterDefaultValue]
    );

    const filtersActionsFunctions: ChangeFilterActionFunctions = useMemo(
        () => ({
            add: (state, value) => [...new Set([...state, ...value])],
            remove: (state, value) => state.filter((el) => !value.includes(el)),
        }),
        []
    );

    const reducer = useCallback(
        (state: AnnotationFiltersState, action: ChangeFilterAction) => {
            const { type, payload } = action;
            let newState = { ...state };
            switch (type) {
                case ChangeFilterActionType.ADD:
                    if (payload.status) newState = { ...newState, status: filtersActionsFunctions.add(newState.status, payload.status) };
                    if (payload.tag) newState = { ...newState, tag: filtersActionsFunctions.add(newState.tag, payload.tag) };
                    if (payload.assignment) newState = { ...newState, assignment: filtersActionsFunctions.add(newState.assignment, payload.assignment) };
                    if (payload.assertion) newState = { ...newState, assertion: filtersActionsFunctions.add(newState.assertion, payload.assertion) };
                    if (payload.linkedInformation)
                        newState = { ...newState, linkedInformation: filtersActionsFunctions.add(newState.linkedInformation, payload.linkedInformation) };
                    return newState;
                case ChangeFilterActionType.REMOVE:
                    if (payload.status) newState = { ...newState, status: filtersActionsFunctions.remove(newState.status, payload.status) };
                    if (payload.tag) newState = { ...newState, tag: filtersActionsFunctions.remove(newState.tag, payload.tag) };
                    if (payload.assignment) newState = { ...newState, assignment: filtersActionsFunctions.remove(newState.assignment, payload.assignment) };
                    if (payload.assertion) newState = { ...newState, assertion: filtersActionsFunctions.remove(newState.assertion, payload.assertion) };
                    if (payload.linkedInformation)
                        newState = { ...newState, linkedInformation: filtersActionsFunctions.remove(newState.linkedInformation, payload.linkedInformation) };
                    return newState;
                case ChangeFilterActionType.REPLACE:
                    if (payload.status) newState = { ...newState, status: payload.status };
                    if (payload.tag) newState = { ...newState, tag: payload.tag };
                    if (payload.assignment) newState = { ...newState, assignment: payload.assignment };
                    if (payload.assertion) newState = { ...newState, assertion: payload.assertion };
                    if (payload.linkedInformation) newState = { ...newState, linkedInformation: payload.linkedInformation };
                    return newState;
                case ChangeFilterActionType.CLEAR_ALL:
                    return defaultValue;
                default:
                    return state;
            }
        },
        [filtersActionsFunctions, defaultValue]
    );

    const [filters, setFilters] = useReducer<(state: AnnotationFiltersState, action: ChangeFilterAction) => AnnotationFiltersState>(
        reducer,
        initialFilters || defaultValue
    );
    const filtersList: FiltersList = useMemo(() => {
        let list: FiltersList = [];
        filters.status.forEach((f, idx) =>
            list.push({
                label: AnnotationStatusDictionary[f].name,
                remove: () => setFilters({ type: ChangeFilterActionType.REMOVE, payload: { status: [filters.status[idx]] } }),
            })
        );
        filters.tag.forEach((f, idx) =>
            list.push({
                label: f.name,
                remove: () => setFilters({ type: ChangeFilterActionType.REMOVE, payload: { tag: [filters.tag[idx]] } }),
            })
        );
        filters.assignment.forEach((f, idx) =>
            list.push({
                label: f.fullName,
                remove: () => setFilters({ type: ChangeFilterActionType.REMOVE, payload: { assignment: [filters.assignment[idx]] } }),
            })
        );
        filters.assertion.forEach((f, idx) =>
            list.push({
                label: f.label,
                remove: () => setFilters({ type: ChangeFilterActionType.REMOVE, payload: { assertion: [filters.assertion[idx]] } }),
            })
        );
        filters.linkedInformation.forEach((f, idx) =>
            list.push({
                label: f.label,
                remove: () => setFilters({ type: ChangeFilterActionType.REMOVE, payload: { linkedInformation: [filters.linkedInformation[idx]] } }),
            })
        );
        return list;
    }, [filters]);

    const result = useMemo(() => {
        const _result = {
            all: {
                filters,
                filtersList,
                update: (filters: AnnotationFiltersState) => setFilters({ type: ChangeFilterActionType.REPLACE, payload: filters }),
                clear: () => setFilters({ type: ChangeFilterActionType.CLEAR_ALL, payload: {} }),
            },
            status: getFilter<StatusFilterState>(filters.status, annotationFilterDefaultValue, (action, value) =>
                setFilters({ type: action, payload: { status: value } })
            ),
            tag: getFilter<TagFilterState>(filters.tag, annotationFilterDefaultValue, (action, value) => setFilters({ type: action, payload: { tag: value } })),
            assignment: getFilter<AssignmentFilterState>(filters.assignment, annotationFilterDefaultValue, (action, value) =>
                setFilters({ type: action, payload: { assignment: value } })
            ),
            assertion: getFilter<AssertionFilterState>(filters.assertion, annotationFilterDefaultValue, (action, value) =>
                setFilters({ type: action, payload: { assertion: value } })
            ),
            linkedInformation: getFilter<LinkedInformationFilterState>(filters.linkedInformation, annotationFilterDefaultValue, (action, value) =>
                setFilters({ type: action, payload: { linkedInformation: value } })
            ),
        };

        return _result;
    }, [filters, filtersList, annotationFilterDefaultValue]);

    return result;
};

const getFilter = <TValue,>(
    value: TValue,
    defaultValue: TValue,
    setFilter: (action: ChangeFilterActionType, value: TValue) => void
): AnnotationFilter<TValue> => {
    return {
        value,
        add: (value: TValue) => setFilter(ChangeFilterActionType.ADD, value),
        remove: (value: TValue) => setFilter(ChangeFilterActionType.REMOVE, value),
        replace: (value: TValue) => setFilter(ChangeFilterActionType.REPLACE, value),
        clear: () => setFilter(ChangeFilterActionType.REPLACE, defaultValue),
    };
};

export default useAnnotationsFilters;
