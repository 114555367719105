import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import {
    Annotation,
    AnnotationTransferProposition,
    AnnotationTransferPropositionStatus,
    AnnotationsTransferProposition,
    CreateAnnotationFromModifiedTransferPropositionBody,
    ProjectSimple,
} from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectVersionForm } from '../../../NewVersionForm.Component';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'App/RoutesPaths';
import useSelectingAnnotation from './Hooks/useSelectingAnnotation';
import { PdfViewerOtherProps } from '../Hooks/usePdfViewer';
import usePdfViewerActive, { ActiveAnnotationHighlightCustomData } from './Hooks/usePdfViewerActive';
import usePdfViewerDraft, { DraftAnnotationHighlightCustomData } from './Hooks/usePdfViewerDraft';

export type AnnotationsTransferState = {
    pdfViewerOtherPropsActive: PdfViewerOtherProps<ActiveAnnotationHighlightCustomData>;
    pdfViewerOtherPropsDraft: PdfViewerOtherProps<DraftAnnotationHighlightCustomData>;
    annotationsTransferData: Array<AnnotationTransferData> | null;
    activeStatus: AnnotationTransferPropositionStatus | null;
    setActiveStatus: (status: AnnotationTransferPropositionStatus) => void;
    acceptDraftProjectVersionProps: AcceptDraftProjectVersionProps;
    refetchAnnotationsDraft: () => void;
    navigateToDocumentVersionList: () => void;
    showDiffs: boolean;
    setShowDiffs: (show: boolean) => void;
    annotationsListRedrawing: boolean;
};

export type AnnotationTransferData = {
    annotationActive: Annotation;
    annotationDraft: Annotation | null;
    proposition: AnnotationTransferProposition;
    status: AnnotationTransferPropositionStatus;
};

export type AcceptDraftProjectVersionProps = {
    acceptingDraft: boolean;
    acceptDraftProjectVersion: () => void;
};

const useAnnotationsTransferState = (
    project: ProjectSimple,
    activeVersion: ProjectVersionForm,
    draftVersion: ProjectVersionForm | null,
    annotationsTransfer: AnnotationsTransferProposition | null,
    getAnnotationsTransfer: (draftId: string, flowMethods?: FlowMethods<AnnotationsTransferProposition | null>) => void,
    getAnnotations: (versionId: string, flowMethods?: FlowMethods<Array<Annotation>>) => void,
    acceptDraftProjectVersionInput: (draftId: string, flowMethods?: FlowMethods) => void,
    createAnnotationFromTransferPropositionInput: (draftId: string, annotationId: string, flowMethods: FlowMethods) => void,
    createAnnotationFromModifiedTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods: FlowMethods
    ) => void,
    rejectAnnotationTransferPropositionInput: (draftId: string, annotationId: string, displayToastSuccessMessages: boolean, flowMethods: FlowMethods) => void,
    deleteAnnotationCreatedFromTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        displayToastSuccessMessages: boolean,
        flowMethods: FlowMethods
    ) => void,
    navigateToDocumentVersionList: () => void
): AnnotationsTransferState => {
    const navigate = useNavigate();
    const filterData = useCallback(
        (data: Array<AnnotationTransferData>, status: AnnotationTransferPropositionStatus) => data.filter((p) => p.status === status),
        []
    );

    const [activeStatus, _setActiveStatus] = useState<AnnotationTransferPropositionStatus | null>(null);

    const propositionByAnnotationId = useMemo(() => {
        if (annotationsTransfer && annotationsTransfer.totalAnnotationsToTransfer === annotationsTransfer.propositions.length) {
            const result: { [key: string]: AnnotationTransferProposition } = {};
            annotationsTransfer?.propositions.forEach((p) => (result[p.annotationId] = p));
            return result;
        } else return null;
    }, [annotationsTransfer]);
    const refetchAnnotationsTransfer = useCallback(() => draftVersion && getAnnotationsTransfer(draftVersion.id), [draftVersion, getAnnotationsTransfer]);

    // annotationsActive
    const { data: annotationsActive } = usePromiseWithFlowMethods<{ versionId: string }, Array<Annotation> | null>({
        method: (input, flowMethods) => getAnnotations(input.versionId, flowMethods),
        initialData: null,
        initFetch: { input: { versionId: activeVersion.id } },
        enabled: annotationsTransfer !== null,
    });
    const annotationsActiveById = useMemo(() => {
        if (!annotationsActive) return null;
        const result: { [key: string]: Annotation } = {};
        annotationsActive.forEach((a) => (result[a.id] = a));
        return result;
    }, [annotationsActive]);

    // annotationsDraft
    const { data: annotationsDraft, refetch: refetchAnnotationsDraft } = usePromiseWithFlowMethods<{ versionId: string }, Array<Annotation> | null>({
        method: (input, flowMethods) => getAnnotations(input.versionId, flowMethods),
        initialData: null,
        enabled: annotationsTransfer !== null && draftVersion !== null,
        initFetch: { input: { versionId: draftVersion?.id || '' } },
    });

    const annotationsDraftByActiveAnnotationId = useMemo(() => {
        if (!annotationsDraft) return null;
        const result: { [key: string]: Annotation } = {};
        annotationsDraft.forEach((a) => (result[a.sourceId] = a));
        return result;
    }, [annotationsDraft]);

    const annotationsTransferData: Array<AnnotationTransferData> | null = useMemo(() => {
        if (annotationsActive && annotationsActiveById && annotationsDraftByActiveAnnotationId && propositionByAnnotationId) {
            const result: Array<AnnotationTransferData> = [];
            annotationsActive.forEach((a) => {
                const proposition = propositionByAnnotationId[a.id];
                const annotationDraft = annotationsDraftByActiveAnnotationId[a.id] || null;
                let status = proposition.status;
                if (annotationDraft) status = AnnotationTransferPropositionStatus.Found;
                if (!annotationDraft && proposition.isRejected) status = AnnotationTransferPropositionStatus.Removed;

                result.push({
                    annotationActive: a,
                    annotationDraft,
                    proposition,
                    status,
                });
            });
            return result;
        } else return null;
    }, [annotationsActive, annotationsActiveById, annotationsDraftByActiveAnnotationId, propositionByAnnotationId]);

    const filteredData = useMemo(() => {
        if (activeStatus === null) return annotationsTransferData || [];
        else return (annotationsTransferData && filterData(annotationsTransferData, activeStatus)) || [];
    }, [filterData, annotationsTransferData, activeStatus]);

    const {
        selectedAnnotationIdActiveVersion,
        selectedAnnotationIdDraftVersion,
        setSelectedAnnotationIdActiveVersionViewer,
        setSelectedAnnotationIdDraftVersionViewer,
        setSelectedAnnotationIdBothVersions,
    } = useSelectingAnnotation(annotationsTransferData, filteredData);

    const [_annotationsListRedrawing, setAnnotationsListRedrawing] = useState<{
        active: boolean;
        draft: boolean;
        status: AnnotationTransferPropositionStatus | null;
        update: number;
    }>({ active: true, draft: true, status: null, update: 0 });
    const setAnnotationsListRedrawingActive = useCallback(
        (value: boolean) => setAnnotationsListRedrawing((prev) => ({ ...prev, active: value })),
        [setAnnotationsListRedrawing]
    );
    const setAnnotationsListRedrawingDraft = useCallback(
        (value: boolean) => setAnnotationsListRedrawing((prev) => ({ ...prev, draft: value })),
        [setAnnotationsListRedrawing]
    );
    const annotationsListRedrawing = useMemo(() => _annotationsListRedrawing.active || _annotationsListRedrawing.draft, [_annotationsListRedrawing]);

    const setActiveStatus = useCallback(
        (status: AnnotationTransferPropositionStatus) => {
            const newStatus = activeStatus === status ? null : status;
            setAnnotationsListRedrawing((prev) => ({ active: true, draft: true, status: newStatus, update: prev.update + 1 }));
        },
        [setAnnotationsListRedrawing, activeStatus]
    );

    useEffect(() => {
        if (_annotationsListRedrawing.update > 0) {
            if (_annotationsListRedrawing.active && _annotationsListRedrawing.draft) {
                _setActiveStatus(_annotationsListRedrawing.status);
            }

            if (!_annotationsListRedrawing.active && !_annotationsListRedrawing.draft) {
                if (annotationsTransferData) {
                    if (selectedAnnotationIdActiveVersion) {
                        const record = filteredData.find((el) => el.annotationActive.id === selectedAnnotationIdActiveVersion);
                        if (!record) {
                            const highlight = filteredData.length > 0 ? filteredData[0] : null;
                            if (highlight) {
                                setSelectedAnnotationIdBothVersions(highlight.annotationActive.id);
                            } else {
                                setSelectedAnnotationIdBothVersions(null);
                            }
                        }
                    } else {
                        const highlight = filteredData.length > 0 ? filteredData[0] : null;
                        if (highlight) {
                            setSelectedAnnotationIdBothVersions(highlight.annotationActive.id);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [_annotationsListRedrawing]);

    // Accept Draft Version
    const navigateToVersionList = useCallback(
        () => navigate(AppRoutes.projectInstance.projectVersion.list.specificPath({ projectId: project.id })),
        [navigate, project]
    );
    const { wrappedMethod: _acceptDraftProjectVersion, fetching: acceptingDraft } = usePromiseWithFlowMethods<{ draftId: string }, {}>({
        method: (input, flowMethods) =>
            acceptDraftProjectVersionInput(input.draftId, {
                ...flowMethods,
                onSuccess(data) {
                    flowMethods.onSuccess?.(data);
                    navigateToVersionList();
                },
            }),
        initialData: {},
    });
    const acceptDraftProjectVersion = useCallback(
        () => draftVersion && _acceptDraftProjectVersion({ draftId: draftVersion.id }),
        [draftVersion, _acceptDraftProjectVersion]
    );

    // Proposition Methods
    const refetchData = useCallback(() => {
        refetchAnnotationsTransfer();
        refetchAnnotationsDraft();
    }, [refetchAnnotationsTransfer, refetchAnnotationsDraft]);
    const createAnnotationFromTransferProposition = useCallback(
        (annotationId: string, flowMethods: FlowMethods) => {
            if (draftVersion)
                createAnnotationFromTransferPropositionInput(draftVersion.id, annotationId, {
                    ...flowMethods,
                    onSuccess(data) {
                        flowMethods.onSuccess?.(data);
                        refetchData();
                    },
                });
        },
        [draftVersion, createAnnotationFromTransferPropositionInput, refetchData]
    );
    const createAnnotationFromModifiedTransferProposition = useCallback(
        (annotationId: string, body: CreateAnnotationFromModifiedTransferPropositionBody, flowMethods: FlowMethods) => {
            if (draftVersion && selectedAnnotationIdActiveVersion)
                createAnnotationFromModifiedTransferPropositionInput(draftVersion.id, annotationId, body, {
                    ...flowMethods,
                    onSuccess(data) {
                        flowMethods.onSuccess?.(data);
                        refetchData();
                    },
                });
        },
        [draftVersion, selectedAnnotationIdActiveVersion, createAnnotationFromModifiedTransferPropositionInput, refetchData]
    );

    const reject = useCallback(
        (annotationId: string, flowMethods: FlowMethods) => {
            if (draftVersion && annotationsDraftByActiveAnnotationId && propositionByAnnotationId) {
                const draftAnnotation = annotationsDraftByActiveAnnotationId[annotationId];
                const proposition = propositionByAnnotationId[annotationId];
                if (draftAnnotation) {
                    const deleteAnnotation = () =>
                        deleteAnnotationCreatedFromTransferPropositionInput(draftVersion.id, annotationId, true, {
                            ...flowMethods,
                            onSuccess(data) {
                                flowMethods.onSuccess?.(data);
                                refetchData();
                            },
                        });
                    if (proposition) {
                        if (proposition.isRejected) deleteAnnotation();
                        else
                            rejectAnnotationTransferPropositionInput(draftVersion.id, annotationId, false, {
                                ...flowMethods,
                                onSuccess(data) {
                                    flowMethods.onSuccess?.(data);
                                    deleteAnnotation();
                                },
                            });
                    }
                } else {
                    if (proposition && !proposition.isRejected) {
                        rejectAnnotationTransferPropositionInput(draftVersion.id, annotationId, true, {
                            ...flowMethods,
                            onSuccess(data) {
                                flowMethods.onSuccess?.(data);
                                refetchData();
                            },
                        });
                    }
                }
            }
        },
        [
            draftVersion,
            annotationsDraftByActiveAnnotationId,
            propositionByAnnotationId,
            deleteAnnotationCreatedFromTransferPropositionInput,
            rejectAnnotationTransferPropositionInput,
            refetchData,
        ]
    );

    const onlyReject: boolean = useMemo(() => {
        if (annotationsDraftByActiveAnnotationId && selectedAnnotationIdActiveVersion) {
            const draftAnnot = annotationsDraftByActiveAnnotationId[selectedAnnotationIdActiveVersion];
            if (draftAnnot) return true;
        }

        return false;
    }, [annotationsDraftByActiveAnnotationId, selectedAnnotationIdActiveVersion]);

    // viewers
    const pdfViewerOtherPropsActive = usePdfViewerActive(
        selectedAnnotationIdActiveVersion,
        setSelectedAnnotationIdActiveVersionViewer,
        filteredData,
        setAnnotationsListRedrawingActive
    );
    const pdfViewerOtherPropsDraft = usePdfViewerDraft(
        selectedAnnotationIdActiveVersion,
        selectedAnnotationIdDraftVersion,
        setSelectedAnnotationIdDraftVersionViewer,
        filteredData,
        onlyReject,
        createAnnotationFromTransferProposition,
        createAnnotationFromModifiedTransferProposition,
        reject,
        setAnnotationsListRedrawingDraft
    );

    const [showDiffs, setShowDiffs] = useState(false);

    return {
        pdfViewerOtherPropsActive,
        pdfViewerOtherPropsDraft,
        annotationsTransferData,
        activeStatus,
        setActiveStatus,
        acceptDraftProjectVersionProps: {
            acceptingDraft,
            acceptDraftProjectVersion,
        },
        refetchAnnotationsDraft,
        navigateToDocumentVersionList,
        showDiffs,
        setShowDiffs,
        annotationsListRedrawing,
    };
};

type StatusConfigType = {
    baseColor: string;
    selectedColor: string;
};

export const StatusConfig: { [key in AnnotationTransferPropositionStatus]: StatusConfigType } = {
    [AnnotationTransferPropositionStatus.Found]: {
        baseColor: '#00ff0033', // TODO: Fix when palette is ready
        selectedColor: '#00ff0066', // TODO: Fix when palette is ready
    },
    [AnnotationTransferPropositionStatus.FoundWithModifications]: {
        baseColor: '#ffff0033', // TODO: Fix when palette is ready
        selectedColor: '#ffff0066', // TODO: Fix when palette is ready
    },
    [AnnotationTransferPropositionStatus.Removed]: {
        baseColor: '#dc354533', // TODO: Fix when palette is ready
        selectedColor: '#dc354566', // TODO: Fix when palette is ready
    },
};

export default useAnnotationsTransferState;
