import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions } from '../../Sterling.ApiClient';
import {
    AddAnnotationBody,
    AddAnnotationKeywordBody,
    AddAnnotationTagBody,
    Annotation,
    Assertion,
    Keyword,
    SetNeedsReviewBody,
    AcceptAnnotationStageBody,
    Tag,
    AddCommentBody,
    AnnotationStatus,
    AnnotationComment,
} from '../Types';

export type RootRouteParams = {
    projectId: string;
    projectVersionId: string;
};

export type AnnotationsRouteParams = RootRouteParams & {
    annotationId: string;
};

export type AnnotationsRouteParamsWithoutProjectVersion = Omit<AnnotationsRouteParams, 'projectVersionId'>;

export default class AnnotationsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRouteAnnotations = (params: RootRouteParams) => `projects/${params.projectId}/versions/${params.projectVersionId}/annotations`;
    _rootRouteTags = (params: AnnotationsRouteParams) => `${this._rootRouteAnnotations(params)}/${params.annotationId}/tags`;

    _rootRouteAnnotationWithoutVersion = (params: AnnotationsRouteParamsWithoutProjectVersion) =>
        `projects/${params.projectId}/annotations/${params.annotationId}`;
    _rootRouteAssertions = (params: AnnotationsRouteParamsWithoutProjectVersion) => `${this._rootRouteAnnotationWithoutVersion(params)}/assertions`;
    _rootRouteAssignments = (params: AnnotationsRouteParamsWithoutProjectVersion) => `${this._rootRouteAnnotationWithoutVersion(params)}/assignments`;
    _rootRouteComments = (params: AnnotationsRouteParamsWithoutProjectVersion) => `${this._rootRouteAnnotationWithoutVersion(params)}/comments`;
    _rootRouteKeywords = (params: AnnotationsRouteParamsWithoutProjectVersion) => `${this._rootRouteAnnotationWithoutVersion(params)}/keywords`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    // _rootRouteAnnotations
    getAnnotations = (params: RootRouteParams, getRequestOptions?: GetRequestOptions<Array<Annotation>>) =>
        this._sterlingApiClient.getAsync<Array<Annotation>>(`${this._rootRouteAnnotations(params)}`, getRequestOptions);

    getAnnotation = (params: AnnotationsRouteParams, getRequestOptions?: GetRequestOptions<Annotation | null>) =>
        this._sterlingApiClient.getAsync<Annotation | null>(`${this._rootRouteAnnotations(params)}/${params.annotationId}`, getRequestOptions);

    addAnnotation = (params: RootRouteParams, postRequestOptions: PostRequestOptions<AddAnnotationBody, string>) =>
        this._sterlingApiClient.postAsync<AddAnnotationBody, string>(`${this._rootRouteAnnotations(params)}`, postRequestOptions);

    deleteAnnotation = (params: AnnotationsRouteParams, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteAnnotations(params)}/${params.annotationId}`, deleteRequestOptions);

    acceptAnnotationStage = (params: AnnotationsRouteParams, postRequestOptions: PostRequestOptions<AcceptAnnotationStageBody>) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAnnotations(params)}/${params.annotationId}/acceptStage`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotation state updated.'],
        });

    setNeedsReview = (params: AnnotationsRouteParams, postRequestOptions?: PostRequestOptions<SetNeedsReviewBody>) =>
        this._sterlingApiClient.postAsync<SetNeedsReviewBody>(`${this._rootRouteAnnotations(params)}/${params.annotationId}/needsReview`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Needs review tag updated.'],
        });

    getAvailablePreviousStatuses = (params: AnnotationsRouteParams, getRequestOptions?: GetRequestOptions<Array<AnnotationStatus>>) =>
        this._sterlingApiClient.getAsync<Array<AnnotationStatus>>(
            `${this._rootRouteAnnotations(params)}/${params.annotationId}/availablePreviousStatuses`,
            getRequestOptions
        );

    reorderAnnotationsNumbers = (params: RootRouteParams, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAnnotations(params)}/reorder`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotation numbers reordered.'],
        });

    // _rootRouteAssignments
    assignMember = (params: AnnotationsRouteParamsWithoutProjectVersion & { memberId: string }, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAssignments(params)}/members/${params.memberId}`, postRequestOptions);

    // _rootRouteAssertions
    getAnnotationAssertion = (params: AnnotationsRouteParamsWithoutProjectVersion, getRequestOptions: GetRequestOptions<Assertion>) =>
        this._sterlingApiClient.getAsync<Assertion>(`${this._rootRouteAssertions(params)}`, getRequestOptions);

    addAnnotationAssertion = (params: AnnotationsRouteParamsWithoutProjectVersion, postRequestOptions: PostRequestOptions<Assertion>) =>
        this._sterlingApiClient.postAsync<Assertion>(`${this._rootRouteAssertions(params)}`, postRequestOptions);

    // _rootRouteTags
    getAssignedTags = (params: AnnotationsRouteParams, requestOptions?: GetRequestOptions<Array<Tag>>) =>
        this._sterlingApiClient.getAsync<Array<Tag>>(`${this._rootRouteTags(params)}`, requestOptions);

    addTag = (params: AnnotationsRouteParams, postRequestOptions?: PostRequestOptions<AddAnnotationTagBody>) =>
        this._sterlingApiClient.postAsync<AddAnnotationTagBody>(`${this._rootRouteTags(params)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Tag assigned to annotations.'],
        });

    removeTag = (params: AnnotationsRouteParams & { tagId: string }, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteTags(params)}/${params.tagId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Tag unpinned from annotation.'],
        });

    // _rootRouteKeywords
    getKeywords = (params: AnnotationsRouteParamsWithoutProjectVersion, requestOptions?: GetRequestOptions<Array<Keyword>>) =>
        this._sterlingApiClient.getAsync<Array<Keyword>>(`${this._rootRouteKeywords(params)}`, requestOptions);

    addKeyword = (params: AnnotationsRouteParamsWithoutProjectVersion, postRequestOptions?: PostRequestOptions<AddAnnotationKeywordBody>) =>
        this._sterlingApiClient.postAsync<AddAnnotationKeywordBody>(`${this._rootRouteKeywords(params)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Keyword added to annotation.'],
        });

    removeKeyword = (params: AnnotationsRouteParamsWithoutProjectVersion & { keywordId: string }, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteKeywords(params)}/${params.keywordId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Keyword removed from annotation.'],
        });

    removeAllKeywords = (params: AnnotationsRouteParamsWithoutProjectVersion, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteKeywords(params)}`, {
            ...requestOptions,
            toastSuccessMessages: ['Keywords removed from annotation.'],
        });

    // _rootRouteComments
    getComments = (params: AnnotationsRouteParamsWithoutProjectVersion, requestOptions?: GetRequestOptions<Array<AnnotationComment>>) =>
        this._sterlingApiClient.getAsync<Array<AnnotationComment>>(`${this._rootRouteComments(params)}`, requestOptions);

    addComment = (params: AnnotationsRouteParamsWithoutProjectVersion, postRequestOptions: PostRequestOptions<AddCommentBody>) =>
        this._sterlingApiClient.postAsync<AddCommentBody>(`${this._rootRouteComments(params)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Comment added.'],
        });

    resolveComment = (params: AnnotationsRouteParamsWithoutProjectVersion & { commentId: string }, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteComments(params)}/${params.commentId}/resolve`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Comment resolved.'],
        });

    deleteComment = (params: AnnotationsRouteParamsWithoutProjectVersion & { commentId: string }, deleteRequestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteComments(params)}/${params.commentId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Comment deleted.'],
        });
}
