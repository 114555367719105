import * as signalR from '@microsoft/signalr';
import AuthApiClient from 'ApiClients/Auth.ApiClient';
const NotificationsHubUrl = process.env.REACT_APP_INTERNAL_NOTIFICATIONS_URL!;
const RetryTimes = [0, 3000, 10000, 20000, 30000];
const MaxNumberOfRetries = 5;

export default class NotificationsConnector {
    _authApiClient: AuthApiClient;
    public connection: signalR.HubConnection | null;
    public onReconnect: () => void;

    constructor(authApiClient: AuthApiClient, isAuthenticated: boolean, onReconnectionError: () => void) {
        this._authApiClient = authApiClient;
        this.connection = null;
        this.onReconnect = () => { };

        if (isAuthenticated) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(NotificationsHubUrl, {
                    accessTokenFactory: async () => {
                        return authApiClient.getToken();
                    },
                    withCredentials: true,
                })
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        if (retryContext.previousRetryCount === MaxNumberOfRetries) {
                            if (onReconnectionError) onReconnectionError();
                            return null;
                        }

                        const index = retryContext.previousRetryCount < RetryTimes.length ? retryContext.previousRetryCount : RetryTimes.length - 1;
                        return RetryTimes[index];
                    },
                })
                .build();

            this.connection.start();

            this.connection.onreconnected((_) => {
                if (this.onReconnect) this.onReconnect();
            });
        }
    }

    public isConnected = (): boolean => {
        return this.connection?.state === signalR.HubConnectionState.Connected;
    };
}
