import { Box, Button, CircularProgress } from '@mui/material';
import { Icon, Typography } from 'UI';
import { BulkActionOutput } from '../Hooks/Annotations/useAnnotationBulkActions';
import { IconType } from 'UI/Icon';
import { useMemo } from 'react';

export type MultiHighlightsToolbarComponentProps = {
    selectedHighlightIds: Array<string>;
    mergeHighlightsProps: { merge: (input: { ids: Array<string> }) => void; fetching: boolean };
    bulkActionsProps?: Array<BulkActionOutput>;
};

function MultiHighlightsToolbarComponent(props: MultiHighlightsToolbarComponentProps) {
    const { selectedHighlightIds, mergeHighlightsProps, bulkActionsProps } = props;
    const renderIcon = (icon: IconType) => {
        const IconComponent = icon;

        return <IconComponent width='16' height='16' />;
    };

    const disabled = useMemo(
        () => bulkActionsProps?.some((item) => item.fetching) || mergeHighlightsProps.fetching,
        [bulkActionsProps, mergeHighlightsProps.fetching]
    );

    return (
        <Box sx={{ position: 'absolute', bottom: '1.5rem', left: 'calc(50% - 3.25rem)' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2rem',
                    border: (theme) => `2px solid ${theme.palette.blue.dark}`,
                    background: (theme) => theme.palette.white.main,
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                }}
            >
                <Button
                    disabled={mergeHighlightsProps.fetching}
                    sx={{ width: '2.5rem', minWidth: '2.5rem', padding: '0rem' }}
                    onClick={() => mergeHighlightsProps.merge({ ids: selectedHighlightIds })}
                >
                    {mergeHighlightsProps.fetching ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Box>
                            <Box sx={{ color: (theme) => theme.palette.blue.dark, marginBottom: '0.25rem' }}>
                                <Icon.Wrapper width="16" height="16" />
                            </Box>
                            <Typography sx={{ color: (theme) => theme.palette.text.main, textTransform: 'capitalize', fontSize: '0.625rem', fontWeight: 700 }}>
                                Join
                            </Typography>
                        </Box>
                    )}
                </Button>
                {bulkActionsProps &&
                    bulkActionsProps.map((actionData, index) => {
                        return (
                            <Button
                                key={`bulk-action-${index}`}
                                disabled={disabled}
                                sx={{ width: '2.5rem', minWidth: '2.5rem', padding: '0rem' }}
                                onClick={() => actionData.action(selectedHighlightIds)}
                            >
                                {actionData.fetching ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <Box>
                                        <Box sx={{ color: () => actionData.themeColor, marginBottom: '0.25rem' }}>{renderIcon(actionData.icon)}</Box>
                                        <Typography
                                            sx={{
                                                color: (theme) => theme.palette.text.main,
                                                textTransform: 'capitalize',
                                                fontSize: '0.625rem',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {actionData.title}
                                        </Typography>
                                    </Box>
                                )}
                            </Button>
                        );
                    })}
            </Box>
        </Box>
    );
}

export default MultiHighlightsToolbarComponent;
