import { Box } from '@mui/material';
import { Icon, Tooltip, Typography } from 'UI';
import { Tabs } from '../../Hooks/Module';

export type ToolbarComponentProps = {
    selectedTab: Tabs;
    setSelectedTab: React.Dispatch<React.SetStateAction<Tabs>>;
    closeSidebar: () => void;
};

export const ToolbarHeight = '3.5rem';

function ToolbarComponent(props: ToolbarComponentProps) {
    const { selectedTab, setSelectedTab, closeSidebar } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                height: ToolbarHeight,
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'flex', height: '2.5rem', marginTop: '1rem' }}>
                {Object.keys(Tabs).map((_tab) => {
                    const tab = Tabs[_tab as keyof typeof Tabs];
                    const selected = selectedTab === tab;

                    return (
                        <Box
                            key={`tabs-tab-${tab}`}
                            onClick={() => setSelectedTab(tab)}
                            sx={{
                                paddingLeft: '2.5rem',
                                paddingRight: '2.5rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                cursor: 'pointer',
                                borderBottom: (theme) => `2px solid ${selected ? theme.palette.blue.dark : 'transparent'}`,
                                ...(!selected && { backgroundColor: (theme) => theme.palette.blue.light }),
                            }}
                        >
                            <Typography
                                variant='subtitle2'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    ...(!selected && { color: (theme) => theme.palette.blue.medium }),
                                    ...(selected && { fontWeight: 600 }),
                                }}
                            >
                                {tab}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{ display: 'flex', height: '2.5rem', marginTop: '1rem', borderBottom: (theme) => `1px solid ${theme.palette.stroke.main}`, width: '100%' }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '0rem', marginLeft: 'auto' }}>
                    <Tooltip title={'Close'} placement='bottom'>
                        <Box
                            onClick={closeSidebar}
                            sx={{
                                height: '1.5rem',
                                width: '1.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '0.75rem',
                                cursor: 'pointer',
                                borderRadius: '0.25rem',
                            }}
                        >
                            <Icon.CloseCircle height={16} width={16} />
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default ToolbarComponent;
