import { Core, WebViewerInstance } from '@pdftron/webviewer';

export type Position = {
    x: number;
    y: number;
};

export type Dimensions = {
    width: number;
    height: number;
};

export const getElementPositionBasedOn = (
    instance: WebViewerInstance,
    annotation: Core.Annotations.Annotation,
    elementDimensions: Dimensions,
    calculatePosition: (topLeft: Position, bottomRight: Position, elementDimensions: Dimensions) => { left: number; top: number }
) => {
    const annotationPosition = getAnnotationWindowPosition(instance, annotation);
    const elementPosition = calculatePosition(annotationPosition.topLeft, annotationPosition.bottomRight, elementDimensions);
    const { left, top } = _calculatePosition(instance, elementPosition.left, elementPosition.top, elementDimensions);

    return { left, top };
};

export const getAnnotationWindowPosition = (instance: WebViewerInstance, annotation: Core.Annotations.Annotation) => {
    const { left, top, right, bottom } = getAnnotationPageCoordinates(annotation);

    const pageNumber = annotation.getPageNumber();
    const topLeft: Position = convertPageCoordinatesToWindowCoordinates(instance, { x: left, y: top }, pageNumber);
    const bottomRight: Position = convertPageCoordinatesToWindowCoordinates(instance, { x: right, y: bottom }, pageNumber);

    return { topLeft, bottomRight };
};

const convertPageCoordinatesToWindowCoordinates = (instance: WebViewerInstance, position: Position, pageNumber: number): Position => {
    const displayMode = instance.Core.documentViewer.getDisplayModeManager().getDisplayMode();
    const result: Position = displayMode.pageToWindow(position, pageNumber);

    return result;
};

const getAnnotationPageCoordinates = (annotation: Core.Annotations.Annotation) => {
    const rect = annotation.getRect();
    let { x1: left, y1: top, x2: right, y2: bottom } = rect;

    if ((annotation as Core.Annotations.TextHighlightAnnotation).Quads && (annotation as Core.Annotations.TextHighlightAnnotation).Quads.length > 0) {
        const quads = (annotation as Core.Annotations.TextHighlightAnnotation).Quads;
        const firstQuad = quads[0];
        const lastQuad = quads[quads.length - 1];

        left = firstQuad.x4;
        top = firstQuad.y4;
        right = lastQuad.x2;
        bottom = lastQuad.y2;
    }

    return { left, top, right, bottom };
};

const _calculatePosition = (
    instance: WebViewerInstance,
    leftInput: number,
    topInput: number,
    _elementDimensions: Dimensions
): { left: number; top: number } => {
    const scrollContainer = instance.Core.documentViewer.getScrollViewElement();

    return { left: Math.round(leftInput - scrollContainer.scrollLeft), top: Math.round(topInput - scrollContainer.scrollTop) };
};
