import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as Icon from 'UI/Icon';
import Box from '@mui/material/Box';
import Typography from 'UI/Typography/Typography.Component';

type Optional<T, K extends keyof T> = Omit<T, K> & { [P in keyof T]?: T[P] | undefined };

function Autocomplete<T, S extends boolean | undefined = false, E extends boolean | undefined = false, W extends boolean | undefined = false>({
    label,
    placeholder,
    required,
    ...props
}: Optional<MuiAutocompleteProps<T, S, E, W> & { label: string; required?: boolean }, 'renderInput'>) {
    return (
        <Box sx={{ minWidth: 120, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='inputLabel'>{label}</Typography>
            <MuiAutocomplete
                disablePortal
                {...props}
                fullWidth
                popupIcon={<Icon.ChevronDown />}
                noOptionsText='No option'
                blurOnSelect
                renderInput={(params) => (
                    <TextField
                        variant='standard'
                        {...params}
                        required={required}
                        placeholder={placeholder}
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default Autocomplete;
