import React, { useState, useRef, useLayoutEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { typography } from 'UI/Provider/VerifiTheme';

type TagProps = React.PropsWithChildren<
    {
        textColor?: string;
    } & BoxProps
>;

function Tag({ children, height = '16px', color, textColor, sx, ...rest }: TagProps, ref: React.ForwardedRef<SVGSVGElement>) {
    const [svgWidth, setSvgWidth] = useState(60);
    const textRef = useRef<SVGTextElement | null>(null);
    const gutter =
        (typeof typography.tooltip.fontSize === 'number'
            ? typography.tooltip.fontSize
            : parseInt((typography.tooltip.fontSize as string).replace('px', ''), 10)) + 1;

    useLayoutEffect(() => {
        if (textRef.current) {
            const bbox = textRef.current.getBBox();
            setSvgWidth(bbox.width + 32);
        }
    }, [children]);

    return (
        <Box
            {...(rest as any)}
            ref={ref}
            component='svg'
            sx={{
                ...sx,
                color,
                width: `${svgWidth}px`,
                minHeight: 16,
                height,
            }}
            viewBox={`0 0 ${svgWidth} 16`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.33922 0.546525L0.683157 6.67138C0.235571 7.02945 0 7.50059 0 8.00942C0 8.51826 0.259128 8.9894 0.683157 9.34747L8.31567 15.4535C8.76325 15.8115 9.35218 16 9.98822 16H21H33.3098H182C184.209 16 186 14.2091 186 12V4C186 1.79086 184.209 0 182 0H33.3098H21H9.98822C9.35218 0 8.76325 0.207303 8.31567 0.546525H8.33922ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
                fill='currentColor'
            />
            <text
                ref={textRef}
                x='16'
                y={gutter}
                fontFamily='Roboto, Helvetica, Arial,sans-serif'
                style={{
                    ...typography.tooltip,
                }}
                fill={textColor}
            >
                {children}
            </text>
        </Box>
    );
}

export default React.forwardRef<SVGSVGElement, TagProps>(Tag);
