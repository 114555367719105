import { useCallback, useState } from 'react';

const useHighlightsActionsState = <TState,>() => {
    const [highlightsActionsState, setHighlightsActionsState] = useState<{ [key: string]: TState }>({});
    const setHighlightActionsState = useCallback(
        (highlightId: string, field: keyof TState, value: boolean) =>
            setHighlightsActionsState((prev) => {
                return {
                    ...prev,
                    [highlightId]: {
                        ...prev[highlightId],
                        [field]: value,
                    },
                };
            }),
        []
    );

    return { highlightsActionsState, setHighlightActionsState };
};

export default useHighlightsActionsState;
