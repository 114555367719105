import { useEffect, useMemo, useRef } from 'react';
import { Annotation, AnnotationStatus, BoundingBoxSection, FileInfo, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { HighlightToolbarActionsState, NewHighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { Box } from '@mui/material';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { WebSearchDocumentInfo } from 'Views/Project/ProjectWorkspace/Hooks/Module/Documents/useWebSearchDocuments';
import { AnnotationVerifiedDisabledTooltip } from 'App/Consts';

export type NewSupportingInformationPanelWrapperComponentProps = HighlightToolbarActionsState<NewSupportingInformationPanelActionsState> & {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    webSearchDocumentInfo: WebSearchDocumentInfo;
    webSearchDocumentFileInfo: FileInfo;
    selectedAnnotationRef: React.MutableRefObject<Annotation | null>;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    uploadDocAndAddSupportingDocument: (
        webSearchDocumentInfo: WebSearchDocumentInfo,
        webSearchDocumentFileInfo: FileInfo,
        annotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        addFlowMethods: FlowMethods
    ) => void;
};

export type NewSupportingInformationPanelActionsState = {
    addSupportingInformationFetching: boolean;
};

export const NewSupportingInformationId = 'new-supporting-information';

function NewSupportingInformationPanelWrapperComponent(props: NewSupportingInformationPanelWrapperComponentProps) {
    const {
        newHighlightToolbarOptions,
        selectedAnnotationRef,
        addSupportingInformation,
        webSearchDocumentInfo,
        webSearchDocumentFileInfo,
        actionsState,
        setHighlightActionsState,
        uploadDocAndAddSupportingDocument,
    } = props;

    const { setHighlightToolbarRef, addPart } = newHighlightToolbarOptions;
    const annot = selectedAnnotationRef.current;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const { action: addSupportingInformationWrapped } = useApiModify<{
        annotationId: string;
        supportingDocumentId: string;
        selectionType: SelectionType;
        boundingBoxSections: BoundingBoxSection[];
        statement: string;
    }>({
        method: (input, flowMethods) =>
            addSupportingInformation(input.annotationId, input.supportingDocumentId, input.selectionType, input.boundingBoxSections, input.statement, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(NewSupportingInformationId, 'addSupportingInformationFetching', value),
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    newHighlightToolbarOptions.clearSelection();
                },
            }),
    });

    const disabled = useMemo(() => actionsState?.addSupportingInformationFetching || annot?.status === AnnotationStatus.Verified, [actionsState, annot]);

    let panel: JSX.Element = <div />;

    if (annot) {
        panel = (
            <div style={{ display: 'flex' }}>
                <HighlightButtonComponent
                    rootRef={ref}
                    content={webSearchDocumentInfo.savedInRepository ? 'LINK TO ANNOTATION' : 'UPLOAD AND LINK TO ANNOTATION'}
                    onClick={async () => {
                        const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                        if (annot) {
                            if (webSearchDocumentInfo.savedInRepository) {
                                addSupportingInformationWrapped({
                                    annotationId: annot.id,
                                    supportingDocumentId: webSearchDocumentInfo.supportingDocumentId!,
                                    selectionType: newHighlight.selectionType,
                                    boundingBoxSections: newHighlight.boundingBoxSections,
                                    statement: newHighlight.statement,
                                });
                            } else {
                                uploadDocAndAddSupportingDocument(
                                    webSearchDocumentInfo,
                                    webSearchDocumentFileInfo,
                                    annot.id,
                                    newHighlight.selectionType,
                                    newHighlight.boundingBoxSections,
                                    newHighlight.statement,
                                    {
                                        onSuccess: () => {
                                            newHighlightToolbarOptions.clearSelection();
                                        },
                                    }
                                );
                            }
                        }
                    }}
                    disabled={disabled}
                    loading={actionsState?.addSupportingInformationFetching}
                    tooltip={annot?.status === AnnotationStatus.Verified ? AnnotationVerifiedDisabledTooltip : undefined}
                />
                <Box sx={{ width: '0.25rem' }} />
                <PartsToolbarComponent addPart={addPart} disabled={disabled} />
            </div>
        );
    }

    return <div ref={ref}>{panel}</div>;
}

export default NewSupportingInformationPanelWrapperComponent;
