import { Box } from '@mui/material';
import { AppRoutes } from 'App/RoutesPaths';
import {
    ProjectTabRoute,
    ProjectTabRouteDashboard,
    ProjectTabRouteWorkspace,
} from 'App/RoutesPaths/Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'UI';

export type TabsComponentProps = {
    tab: ProjectTabRoute;
};

function TabsComponent(props: TabsComponentProps) {
    const { tab } = props;
    const { projectId, versionId } = useParams();

    const getTabUrl = useCallback(
        (tab: ProjectTabRoute) => AppRoutes.projectInstance.projectVersionInstance.specificPath({ projectId: projectId!, versionId: versionId!, tab }),
        [projectId, versionId]
    );
    const workspaceLink = useMemo(() => getTabUrl(ProjectTabRouteWorkspace), [getTabUrl]);
    const dashboardLink = useMemo(() => getTabUrl(ProjectTabRouteDashboard), [getTabUrl]);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 1.5 }}>
            <ButtonComponent title='PROJECT WORKSPACE' link={workspaceLink} active={tab === ProjectTabRouteWorkspace} />
            <ButtonComponent title='PROJECT DASHBOARD' link={dashboardLink} active={tab === ProjectTabRouteDashboard} />
        </Box>
    );
}

function ButtonComponent(props: { title: string; link: string; active: boolean }) {
    const { title, link, active } = props;

    return (
        <Button
            to={link}
            variant='text'
            sx={{
                height: '2.5rem',
                padding: '0.75rem 1rem',
                color: (theme) => theme.palette.primary.dark,
                fontWeight: 600,
                ...(active && { backgroundColor: (theme) => theme.palette.primary.lightest }),
                '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.lightest,
                },
            }}
        >
            {title}
        </Button>
    );
}

export default TabsComponent;
