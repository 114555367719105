import React from 'react';
import useAnnotationFilters, {
    AdditionalAssertionFiltersAvailable,
    AnnotationFilters,
    LinkedInformationFiltersAvailable,
} from 'Views/Project/ProjectWorkspace/Hooks/Module/Annotations/Annotations/useAnnotationsFilters';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { DefaultAssertions } from '../AssertionModal/AssertionModal.Component';
import { AnnotationStatusDictionary } from 'Views/Common/Types';
import { AnnotationStatus, ProjectMember, Tag } from 'ApiClients/SterlingApiClients/Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import useToasts, { ToastEnum } from 'Hooks/useToasts';
import Dialog from 'UI/Dialog';
import { Button, Chip, Multiselect, Typography, Checkbox } from 'UI';

export type AnnotationFiltersModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    annotationFilters: AnnotationFilters;
    getMembers: (flowMethods: FlowMethods<ProjectMember[]>) => void;
    getTags: (flowMethods: FlowMethods<Tag[]>) => void;
};

function AnnotationFiltersModalComponent(props: AnnotationFiltersModalComponentProps) {
    const { isOpen, closeModal, annotationFilters } = props;
    const { addToast } = useToasts();

    const _filters = useAnnotationFilters(annotationFilters.all.filters);

    const statusFiltersOptions = Object.keys(AnnotationStatusDictionary).map((s, idx) => ({
        value: s.toString(),
        label: AnnotationStatusDictionary[s as any as AnnotationStatus].name,
        idx,
    }));

    const { data: members } = usePromiseWithFlowMethods<{}, ProjectMember[]>({
        method: (_input, flowMethods) => props.getMembers(flowMethods),
        initialData: [],
        initFetch: { input: {} },
    });

    const { data: tags } = usePromiseWithFlowMethods<{}, Tag[]>({
        method: (_input, flowMethods) => props.getTags(flowMethods),
        initialData: [],
        initFetch: { input: {} },
    });

    const tagsFiltersOptions = tags.map((t, idx) => ({ value: t.id, label: t.name, idx }));

    const assignmentFiltersOptions = [
        ...members.map((m, idx) => ({ value: m.id, label: m.fullName, idx })),
        ...[{ value: 'NoAssignment', label: 'No Assignment', idx: members.length }],
    ];

    const assertionFiltersOptions = [...DefaultAssertions, ...AdditionalAssertionFiltersAvailable].map((a, idx) => ({ value: idx.toString(), label: a, idx }));

    const handleStatusChange = (value: string[]) => {
        if (!value) {
            return;
        }

        const statuses = value.map((s) => parseInt(s) as AnnotationStatus);
        _filters.status.replace(statuses);
    };

    const handleAssignedToChange = (value: string[]) => {
        const values = assignmentFiltersOptions.filter((x) => value.includes(x.value)).map((c) => ({ id: c.value, fullName: c.label }));

        _filters.assignment.replace(values as unknown as typeof _filters.assignment.value);
    };

    const handleTagsChange = (value: string[]) => {
        const values = tagsFiltersOptions.filter((x) => value.includes(x.value)).map((c) => ({ id: c.value, name: c.label }));

        _filters.tag.replace(values as unknown as typeof _filters.tag.value);
    };

    const handleAssertionChange = (value: string[]) => {
        const values = assertionFiltersOptions.filter((x) => value.includes(x.value));

        _filters.assertion.replace(values as unknown as typeof _filters.assertion.value);
    };

    const handleLinkedInformationChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
        const name = event.target.name;
        const v = LinkedInformationFiltersAvailable.find((p) => p.value === name);

        if (checked && v) {
            _filters.linkedInformation.add([v]);
            return;
        }
        if (v) {
            _filters.linkedInformation.remove([v]);
        }
    };

    const handleClearFilters =
        (
            filter:
                | AnnotationFilters['status']
                | AnnotationFilters['tag']
                | AnnotationFilters['assertion']
                | AnnotationFilters['linkedInformation']
                | AnnotationFilters['assignment']
        ) =>
        () => {
            filter.replace([]);
        };

    const handleSubmitFilters = (event: React.FormEvent) => {
        event.preventDefault();
        annotationFilters.all.update(_filters.all.filters);
        addToast({ type: ToastEnum.SUCCESS, content: ['Filters set.'] });
        closeModal();
    };

    const assignments = _filters.assignment?.value
        .filter((c) => c)
        .map((f) =>
            assignmentFiltersOptions.find((a) => {
                return a?.value === f.id.toString();
            })
        )
        ?.map?.((x) => x?.value) as string[];

    const assertions = _filters.assertion.value
        .map((f) => assertionFiltersOptions.find((a) => a.value === f.value.toString()))
        ?.map((x) => x?.value) as string[];
    const selectedTags = _filters.tag.value.map((f) => tagsFiltersOptions.find((a) => a.value === f.id.toString()))?.map((x) => x?.value) as string[];
    const statuses = _filters.status.value.map((f) => statusFiltersOptions.find((a) => a.value === f.toString()))?.map((x) => x?.value) as string[];

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Filters</Dialog.Title>
            <Dialog.Content>
                <Box component='form' onSubmit={handleSubmitFilters} data-cy='annotation-filters-form'>
                    <Box display='flex' flexDirection='column' gap={4}>
                        <Multiselect<string>
                            data-cy='annotation-status-dropdown'
                            label='Status'
                            placeholder='Annotation status'
                            renderValue={function (v) {
                                const names = statusFiltersOptions.filter((c) => v.includes(c.value));
                                return names.map((p) => p.label).join(', ') as React.ReactNode;
                            }}
                            onClear={handleClearFilters(_filters.status)}
                            value={statuses}
                            onChange={handleStatusChange}
                        >
                            {statusFiltersOptions.map((item, ind) => (
                                <MenuItem key={item.value} value={item.value} data-cy={`select-option-${ind}`}>
                                    <Checkbox checked={_filters.status.value?.some((p) => p.toString() === item.value)} />
                                    <Typography variant='inputLabel'>{item.label}</Typography>
                                </MenuItem>
                            ))}
                        </Multiselect>

                        <Multiselect<string>
                            data-cy='team-members-dropdown'
                            label='Assigned To'
                            placeholder='Needs review'
                            onClear={handleClearFilters(_filters.assignment)}
                            renderValue={function (v) {
                                const members = assignmentFiltersOptions.filter((c) => v.includes(c.value));
                                return members.map((p) => p.label).join(', ') as React.ReactNode;
                            }}
                            value={assignments}
                            onChange={handleAssignedToChange}
                        >
                            {assignmentFiltersOptions.map((item, ind) => (
                                <MenuItem key={item.value} value={item.value} data-cy={`select-option-${ind}`}>
                                    <Checkbox checked={_filters.assignment?.value?.some((p) => p.id === item.value)} />
                                    <Typography variant='inputLabel'>{item.label}</Typography>
                                </MenuItem>
                            ))}
                        </Multiselect>

                        <Multiselect<string>
                            data-cy='tags-dropdown'
                            label='Tag'
                            placeholder='Needs review'
                            onClear={handleClearFilters(_filters.tag)}
                            renderValue={function (v) {
                                const tags = tagsFiltersOptions.filter((c) => v.includes(c.value));
                                return tags.map((p) => (
                                    <Chip
                                        key={p.value}
                                        sx={{
                                            marginRight: 1,
                                        }}
                                        color={p.label?.toLowerCase?.() === 'needs review' ? 'warning' : 'info'}
                                        label={p.label}
                                    />
                                ));
                            }}
                            value={selectedTags}
                            onChange={handleTagsChange}
                        >
                            {tagsFiltersOptions?.length === 0 && (
                                <MenuItem
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    key='no-options'
                                    data-cy={`select-option-${-1}`}
                                >
                                    No tags
                                </MenuItem>
                            )}
                            {tagsFiltersOptions.map((item, ind) => (
                                <MenuItem key={item.value} value={item.value} data-cy={`select-option-${ind}`}>
                                    <Checkbox checked={_filters.tag.value?.some((p) => p.id === item.value)} />
                                    <Typography variant='inputLabel'>{item.label}</Typography>
                                </MenuItem>
                            ))}
                        </Multiselect>

                        <Multiselect<string>
                            data-cy='assertions-dropdown'
                            label='Assertion'
                            placeholder='Self Evident'
                            onClear={handleClearFilters(_filters.assertion)}
                            renderValue={function (v) {
                                const assertions = assertionFiltersOptions.filter((c) => v.includes(c.value));
                                return assertions.map((p) => p.label).join(', ') as React.ReactNode;
                            }}
                            value={assertions}
                            onChange={handleAssertionChange}
                        >
                            {assertionFiltersOptions.map((item, ind) => (
                                <MenuItem key={item.value} value={item.value} data-cy={`select-option-${ind}`}>
                                    <Checkbox checked={_filters.assertion.value?.some((p) => p.value === item.value)} />
                                    <Typography variant='inputLabel'>{item.label}</Typography>
                                </MenuItem>
                            ))}
                        </Multiselect>
                        <Box display='flex' justifyContent='center' data-cy='linked-information'>
                            {LinkedInformationFiltersAvailable.map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    componentsProps={{
                                        typography: { variant: 'inputLabel' },
                                    }}
                                    control={
                                        <Checkbox
                                            data-cy={`linked-information-${item.value}`}
                                            name={item.value}
                                            checked={_filters.linkedInformation.value.some((p) => p.value === item.value)}
                                            onChange={handleLinkedInformationChange}
                                        />
                                    }
                                    label={item.label}
                                />
                            ))}
                        </Box>

                        <Dialog.Actions>
                            <Button
                                color='secondary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='button'
                                onClick={closeModal}
                                data-cy='close-filters'
                            >
                                Close
                            </Button>
                            <Button
                                data-cy='save-filters'
                                color='primary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='submit'
                            >
                                Filter
                            </Button>
                        </Dialog.Actions>
                    </Box>
                </Box>
            </Dialog.Content>
        </Dialog>
    );
}

export default AnnotationFiltersModalComponent;
