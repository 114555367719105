import { Box, Divider } from '@mui/material';
import { Annotation, SupportingInformationWebSearchProposition } from 'ApiClients/SterlingApiClients/Types';
import ListWrapperComponent from '../Common/ListWrapper.Component';
import { Icon, Tooltip, Typography } from 'UI';
import { useCallback } from 'react';
import FaviconComponent from './Favicon/Favicon.Component';

export type WebSearchComponentProps = {
    fetching: boolean;
    selectedAnnotation: Annotation;
    suppInfoWebSearchProp: Array<SupportingInformationWebSearchProposition>;
    countWebSearchPropositionsToDisplay: number;
    increaseCountWebSearchPropositionsToDisplay: (annotationId: string) => void;
    openUrl: (url: string) => void;
};

function WebSearchComponent(props: WebSearchComponentProps) {
    const { fetching, selectedAnnotation, suppInfoWebSearchProp, countWebSearchPropositionsToDisplay, increaseCountWebSearchPropositionsToDisplay, openUrl } =
        props;

    const increaseResultsLengthToDisplay = useCallback(
        () => increaseCountWebSearchPropositionsToDisplay(selectedAnnotation.id),
        [selectedAnnotation, increaseCountWebSearchPropositionsToDisplay]
    );

    return (
        <ListWrapperComponent
            fetching={fetching}
            resultsLength={suppInfoWebSearchProp.length}
            resultsLengthToDisplay={countWebSearchPropositionsToDisplay}
            increaseResultsLengthToDisplay={increaseResultsLengthToDisplay}
        >
            {[...suppInfoWebSearchProp].slice(0, countWebSearchPropositionsToDisplay).map((result) => {
                const hostName = new URL(result.url).hostname;

                return (
                    <Box key={`web-search-propositions-${result.rank}`} sx={{ paddingTop: '0.5rem' }}>
                        <Box sx={{ display: 'flex', height: '3rem', marginBottom: '0.5rem' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
                                <Typography sx={{ width: '2rem', marginRight: '1.5rem', fontSize: '1rem', fontWeight: 600 }}>#{result.rank}</Typography>
                                <FaviconComponent url={result.url} />
                                <Typography sx={{ marginLeft: '0.5rem', marginRight: '1rem', fontSize: '0.875rem', fontWeight: 600 }}>
                                    {result.siteName && result.siteName.length > 0 ? result.siteName : hostName}
                                </Typography>
                                <Tooltip title={result.url} placement='top'>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            color: (theme) => theme.palette.link.main,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => openUrl(result.url)}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontWeight: 600,
                                                color: (theme) => theme.palette.link.main,
                                            }}
                                        >
                                            {result.url.length > 150 ? `${result.url.substring(0, 150)}...` : result.url}
                                        </Typography>
                                        <Icon.ArrowUpRightSquare width={10} height={10} />
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            <Typography sx={{ fontSize: '1rem', fontWeight: 400 }}>{result.snippet}</Typography>
                        </Box>
                        <Divider />
                    </Box>
                );
            })}
        </ListWrapperComponent>
    );
}

export default WebSearchComponent;
