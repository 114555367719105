import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { Button, Dialog, Typography } from 'UI';

export type ArchiveModalComponentProps = {
    isOpen: boolean;
    archiveProject: (flowMethods: FlowMethods) => void;
    closeModal: () => void;
};

function ArchiveModalComponent(props: ArchiveModalComponentProps) {
    const { isOpen, closeModal } = props;

    const { wrappedMethod: archiveProject, fetching: archivingProject } = usePromiseWithFlowMethods<{}, {}>({
        method: (_input, flowMethods) => props.archiveProject(flowMethods),
        initialData: {},
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        archiveProject({});
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Archive Project</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Typography fontSize={14} fontWeight={600} marginBottom={4}>
                        Are you sure you want to move this project to the archive?
                    </Typography>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-archiving-project'
                        >
                            Cancel
                        </Button>
                        <Button
                            data-cy='archive-project'
                            isLoading={archivingProject}
                            color='error'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Archive
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default ArchiveModalComponent;
