import SterlingApiClient from '../../Sterling.ApiClient';

export default class NotificationsApiClient {
    _sterlingApiClient: SterlingApiClient;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    markNotificationAsRead = (annotationId: string, onSuccess: () => void) =>
        this._sterlingApiClient.deleteAsync(`notifications/${annotationId}`, { onSuccess });
}
