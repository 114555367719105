import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import { BoundingBoxSection, SelectionType, SupportingInformationProposition } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import useToasts, { ToastEnum } from 'Hooks/useToasts';
import { useCallback, useMemo } from 'react';

export type SupportingInformationPropositionsProps = {
    supportingInformationPropositionByAnnotationId: SupportingInformationPropositionByAnnotationId;
    increaseCountPropositionsToDisplay: (annotationId: string) => void;
    getSupportingInformationPropositions: GetSupportingInformationPropositionsMethodType;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkModifiedPropositionToAnnotation: (
        annotationId: string,
        propositionId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    rejectProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    restoreProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
};

export type SupportingInformationPropositionByAnnotationId = {
    [key: string]: SupportingInformationPropositionContext;
};

export type SupportingInformationPropositionContext = {
    isFetching: boolean;
    supportingInformationPropositions: Array<SupportingInformationProposition> | null;
    countToDisplay: number;
};

export type GetSupportingInformationPropositionsMethodType = (
    annotationId: string,
    options?: { keepPrevData?: boolean; onInit?: () => void; onSuccess?: () => void }
) => void;

const useSupportingInformationPropositions = (
    projectId: string,
    projectVersionId: string,
    suppInfoApiClient: SupportingInformationApiClient,
    getSupportingInformation: (annotationId: string) => void,
    refreshAnnotationRef: React.MutableRefObject<(annotationId: string) => void>
) => {
    const rootRouteParams = useMemo(() => ({ projectId, projectVersionId }), [projectId, projectVersionId]);
    const { addToast } = useToasts();
    const [supportingInformationPropositionByAnnotationId, setSupportingInformationPropositionByAnnotationId] =
        useStateById<SupportingInformationPropositionContext>();
    const getSupportingInformationPropositions = (annotationId: string, options?: { keepPrevData?: boolean; onInit?: () => void; onSuccess?: () => void }) => {
        const _setState = (data: Array<SupportingInformationProposition>, isFetching: boolean) =>
            setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => ({
                isFetching,
                supportingInformationPropositions: isFetching && options?.keepPrevData ? prev.supportingInformationPropositions : data,
                countToDisplay: prev?.countToDisplay ? prev.countToDisplay : 5,
            }));

        suppInfoApiClient.getSupportingInformationPropositions(
            { ...rootRouteParams, annotationId },
            {
                onInit: () => {
                    _setState([], true);
                    options?.onInit && options.onInit();
                },
                onSuccess: (data) => {
                    _setState(data, false);
                    options?.onSuccess && options.onSuccess();
                },
                onError: () => _setState([], false),
            }
        );
    };

    const increaseCountPropositionsToDisplay = useCallback(
        (annotationId: string) => {
            setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => ({
                ...prev,
                countToDisplay: prev.countToDisplay + 5,
            }));
        },
        [setSupportingInformationPropositionByAnnotationId]
    );

    const linkPropositionToAnnotation = (annotationId: string, propositionId: string, flowMethods: FlowMethods) => {
        suppInfoApiClient.linkPropositionToAnnotation(
            { ...rootRouteParams, annotationId, propositionId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    getSupportingInformationPropositions(annotationId, {
                        keepPrevData: true,
                        onSuccess: () => getSupportingInformation(annotationId),
                    });
                    refreshAnnotationRef.current(annotationId);
                },
            }
        );
    };

    const linkModifiedPropositionToAnnotation = (
        annotationId: string,
        propositionId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => {
        suppInfoApiClient.linkModifiedPropositionToAnnotation(
            { ...rootRouteParams, annotationId, propositionId },
            {
                ...flowMethods,
                body: { selectionType, boundingBoxSections, statement },
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    getSupportingInformationPropositions(annotationId, {
                        keepPrevData: true,
                        onSuccess: () => getSupportingInformation(annotationId),
                    });
                    refreshAnnotationRef.current(annotationId);
                },
            }
        );
    };

    const rejectProposition = (annotationId: string, propositionId: string, flowMethods: FlowMethods) => {
        suppInfoApiClient.rejectProposition(
            { ...rootRouteParams, annotationId, propositionId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => {
                        const newState = { ...prev };
                        const item = newState.supportingInformationPropositions?.find((s) => s.id === propositionId);
                        if (item) item.isRejected = true;

                        return newState;
                    });

                    addToast({ type: ToastEnum.SUCCESS, content: ['Proposal rejected.'] });
                },
            }
        );
    };

    const restoreProposition = (annotationId: string, propositionId: string, flowMethods: FlowMethods) => {
        suppInfoApiClient.restoreProposition(
            { ...rootRouteParams, annotationId, propositionId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => {
                        const newState = { ...prev };
                        const item = newState.supportingInformationPropositions?.find((s) => s.id === propositionId);
                        if (item) item.isRejected = false;

                        return newState;
                    });
                    addToast({ type: ToastEnum.SUCCESS, content: ['Proposal restored.'] });
                },
            }
        );
    };

    return {
        supportingInformationPropositionByAnnotationId,
        increaseCountPropositionsToDisplay,
        getSupportingInformationPropositions,
        linkPropositionToAnnotation,
        linkModifiedPropositionToAnnotation,
        rejectProposition,
        restoreProposition,
    };
};

export default useSupportingInformationPropositions;
