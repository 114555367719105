import { Box } from '@mui/material';
import { Dialog, ProgressBar, Typography, VerifiSpinner } from 'UI';
import { createPortal } from 'react-dom';

export type ProcessingLayoutProps = {
    isOpen: boolean;
    title: string | null;
    progress: number | null;
};

function ProcessingLayoutComponent(props: ProcessingLayoutProps) {
    const { isOpen, title, progress } = props;

    return createPortal(
        <Dialog open={isOpen} PaperComponent={Box}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: (theme) => theme.palette.white.main }}>
                <Box sx={{ width: '15rem', textAlign: 'center' }}>
                    <VerifiSpinner />
                    <Typography sx={{ marginTop: '0.5rem', marginBottom: '0.5rem', fontSize: '1.25rem', fontWeight: 700 }}>Processing</Typography>
                    <Typography sx={{ marginBottom: '1rem', fontSize: '1rem', fontWeight: 700 }}>{title}</Typography>
                    {progress !== null && (
                        <ProgressBar currentValue={progress} linearProgressSx={{ borderRadius: '0.25rem' }} typographySx={{ fontSize: '0.75rem' }} />
                    )}
                </Box>
            </Box>
        </Dialog>,
        document.body
    );
}

export default ProcessingLayoutComponent;
