import { useRef } from 'react';

type NavigationProps = {
    selectedHighlightId: string | null;
    highlights: Array<string> | null;
    switchHighlight: (index: number) => void;
};

export type Navigation = {
    available: boolean;
    previous: () => void;
    next: () => void;
};

const useNavigation = (props: NavigationProps): React.RefObject<Navigation> => {
    const { selectedHighlightId, highlights, switchHighlight } = props;

    let prevHighlightIndex: number = 0;
    let nextHighlightIndex: number = 0;
    let highlightNavigationAvailable: boolean = false;

    if (selectedHighlightId && highlights) {
        const selectedHighlightIndex = highlights.indexOf(selectedHighlightId);
        prevHighlightIndex = selectedHighlightIndex - 1 < 0 ? highlights.length - 1 : selectedHighlightIndex - 1;
        nextHighlightIndex = selectedHighlightIndex + 1 > highlights.length - 1 ? 0 : selectedHighlightIndex + 1;
        highlightNavigationAvailable = highlights.length > 1;
    }

    const highlightNav: Navigation = {
        available: highlightNavigationAvailable,
        previous: () => highlights && switchHighlight(prevHighlightIndex),
        next: () => highlights && switchHighlight(nextHighlightIndex),
    };

    const highlightNavRef = useRef(highlightNav);
    highlightNavRef.current = highlightNav;

    return highlightNavRef;
};

export default useNavigation;
