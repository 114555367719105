import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { Heading } from '../PdfViewerWithToolbar.Types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnnotationAttributeHighlightId, AnnotationAttributeType, getHighlightId } from './Annotations/Drawing/ViewerAnnotations/Attributes';

const useHeadings = (webViewerInstance: WebViewerInstance | null, documentLoading: boolean, headings?: Array<Heading>) => {
    const [currentHeading, setCurrentHeading] = useState<Heading | null>(headings ? headings[0] : null);

    const headingsByPage: { [key: number]: Array<Heading> } = useMemo(() => {
        let headingsByPage: { [key: number]: Array<Heading> } = {};
        if (headings) {
            headings.forEach((h) => {
                const pageNumber = getHeadingPageNumber(h);
                if (!headingsByPage[pageNumber]) headingsByPage[pageNumber] = [];
                headingsByPage[pageNumber].push(h);
            });
        }
        return headingsByPage;
    }, [headings]);
    const headingsByPageRef = useRef<{ [key: number]: Array<Heading> }>(headingsByPage);
    useEffect(() => {
        headingsByPageRef.current = headingsByPage;
    }, [headingsByPage]);

    useEffect(() => {
        if (webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && !documentLoading && headings) {
            removeHeadingsFromDocument(webViewerInstance);
            addHeadingsToDocument(webViewerInstance, headings);
        }
    }, [webViewerInstance, documentLoading, headings]);

    const headingsRef = useRef<Array<Heading> | undefined>(headings);
    useEffect(() => {
        headingsRef.current = headings;
    }, [headings]);

    const currentHeadingRef = useRef<Heading | null>(currentHeading);
    useEffect(() => {
        currentHeadingRef.current = currentHeading;
    }, [currentHeading]);

    useEffect(() => {
        if (webViewerInstance) {
            webViewerInstance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0].addEventListener('scroll', (e) => {
                if (headingsRef.current) {
                    const headings = headingsRef.current;
                    const heading = headings.findLast((h) => getHeadingPageNumber(h) <= webViewerInstance.Core.documentViewer.getCurrentPage());

                    if (heading) {
                        const headingsPage = headingsByPageRef.current[getHeadingPageNumber(heading)];
                        if (headingsPage && headingsPage.length > 1) {
                            const scrollTop = (e.target as HTMLElement).scrollTop;
                            const annots = webViewerInstance.Core.annotationManager.getAnnotationsList();
                            const headingsWithWindowPosition = headingsPage.map((h) => {
                                const annot = annots.find((a) => AnnotationAttributeType.get(a) === 'heading' && getHighlightId(a) === h.id);
                                const position = getAnnotationWindowPosition(webViewerInstance, annot!);
                                return { heading: h, position };
                            });

                            const headingFinal =
                                headingsWithWindowPosition.findLast((h) => h.position.topLeft.y <= scrollTop + 200) || headingsWithWindowPosition[0];

                            if (headingFinal.heading.id !== currentHeadingRef.current?.id) {
                                setCurrentHeading(headingFinal.heading);
                            }
                        } else {
                            if (heading.id !== currentHeadingRef.current?.id) {
                                setCurrentHeading(heading);
                            }
                        }
                    }
                }
            });
        }
    }, [webViewerInstance, headings]);

    const jumpToHeading = useCallback(
        (heading: Heading) => {
            if (webViewerInstance) {
                const { annotationManager } = webViewerInstance.Core;
                const annot = annotationManager
                    .getAnnotationsList()
                    .find((a) => AnnotationAttributeType.get(a) === 'heading' && getHighlightId(a) === heading.id);
                if (annot) {
                    annotationManager.jumpToAnnotation(annot, { verticalOffset: '10%', isSmoothScroll: false });
                }
            }
        },
        [webViewerInstance]
    );

    return {
        currentHeading,
        jumpToHeading,
    };
};

const removeHeadingsFromDocument = (webViewerInstance: WebViewerInstance) => {
    const { annotationManager } = webViewerInstance.Core;
    const annotations: Array<Core.Annotations.Annotation> = annotationManager.getAnnotationsList();

    const toRemove = annotations.filter((a) => AnnotationAttributeType.get(a) === 'heading');

    annotationManager.deleteAnnotations(toRemove);
};

const addHeadingsToDocument = (webViewerInstance: WebViewerInstance, headings: Array<Heading>) => {
    const { Annotations, annotationManager } = webViewerInstance.Core;

    let annotationsHeadings: Array<Core.Annotations.HTMLAnnotation> = [];
    headings.forEach((h) => {
        const annot = new Annotations.HTMLAnnotation();
        annot.NoMove = true;
        annot.NoResize = true;
        annot.PageNumber = getHeadingPageNumber(h);
        annot.X = h.boundingBoxSections[0].boundingBoxes[0].topLeft.x;
        annot.Y = h.boundingBoxSections[0].boundingBoxes[0].topLeft.y;
        annot.Height = 0;
        annot.Width = 0;
        annot.createInnerElement = () => {
            const newDiv = document.createElement('div');
            return newDiv;
        };
        AnnotationAttributeType.set(annot, 'heading');
        AnnotationAttributeHighlightId.set(annot, h.id);
        annotationsHeadings.push(annot);
    });

    annotationManager.addAnnotations(annotationsHeadings);
    annotationManager.drawAnnotationsFromList(annotationsHeadings);
};

const getHeadingPageNumber = (heading: Heading) => heading.boundingBoxSections[0].pageNumber;

type Position = {
    x: number;
    y: number;
};

const getAnnotationWindowPosition = (instance: WebViewerInstance, annotation: Core.Annotations.Annotation) => {
    const { left, top, right, bottom } = getAnnotationPageCoordinates(annotation);

    const pageNumber = annotation.getPageNumber();
    const topLeft: Position = convertPageCoordinatesToWindowCoordinates(instance, { x: left, y: top }, pageNumber);
    const bottomRight: Position = convertPageCoordinatesToWindowCoordinates(instance, { x: right, y: bottom }, pageNumber);

    return { topLeft, bottomRight };
};

const getAnnotationPageCoordinates = (annotation: Core.Annotations.Annotation) => {
    const rect = annotation.getRect();
    let { x1: left, y1: top, x2: right, y2: bottom } = rect;

    return { left, top, right, bottom };
};

const convertPageCoordinatesToWindowCoordinates = (instance: WebViewerInstance, position: Position, pageNumber: number): Position => {
    const displayMode = instance.Core.documentViewer.getDisplayModeManager().getDisplayMode();
    const result: Position = displayMode.pageToWindow(position, pageNumber);

    return result;
};

export default useHeadings;
