import { Icon } from 'UI';
import HighlightToolbarComponent, { Util } from '../HighlightToolbar/HighlightToolbar.Component';

export type PartsToolbarComponentProps = {
    addPart: () => void;
    disabled?: boolean;
};

function PartsToolbarComponent(props: PartsToolbarComponentProps) {
    const { addPart, disabled } = props;

    let utils: Array<Util> = [
        {
            icon: Icon.PlusCircleFill,
            onClick: () => addPart(),
            description: 'Add Part',
            tooltip: 'Add Part',
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
            disabled,
        },
    ];

    return <HighlightToolbarComponent utils={utils} />;
}

export default PartsToolbarComponent;
