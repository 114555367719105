import SterlingApiClient, { GetRequestOptions } from '../../Sterling.ApiClient';
import { User } from '../Types';

export default class UsersApiClient {
    _sterlingApiClient: SterlingApiClient;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getMyApplicationUserInstance = (requestOptions?: GetRequestOptions<User>) => this._sterlingApiClient.getAsync<User>(`users/myInstance`, requestOptions);

    getUsers = (requestOptions?: GetRequestOptions<Array<User>>) => this._sterlingApiClient.getAsync<Array<User>>(`users`, requestOptions);
}
