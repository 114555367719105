import { Box } from '@mui/material';
import { Icon, Tooltip } from 'UI';

export type ExclamationCircleWrapperComponentProps = {
    display: boolean;
    title: string;
    color: string;
};

function ExclamationCircleWrapperComponent(props: ExclamationCircleWrapperComponentProps) {
    const { display, title, color } = props;

    if (display) {
        return (
            <Box sx={{ color, marginLeft: '0.5rem', display: 'flex' }}>
                <Tooltip title={title} tooltipsx={{ color }}>
                    <Icon.ExclamationCircle />
                </Tooltip>
            </Box>
        );
    } else {
        return null;
    }
}

export default ExclamationCircleWrapperComponent;
