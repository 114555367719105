import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import * as Icon from 'UI/Icon';
import TextField from '@mui/material/TextField';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from 'UI/Typography/Typography.Component';
import { omit } from 'UI/omit';
import { typography } from 'UI/Provider/VerifiTheme';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export type SelectProps<T> = {
    onChange: MuiSelectProps<T>['onChange'];
    renderValue?: (value: T) => React.ReactNode;
    value?: T;
    maxWidth?: string | number;
    width?: string | number;
    label?: string;
    hasEmpty?: boolean;
    size?: 'small';
    onClear?: () => void;
    multiple?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & { ['data-cy']?: string };

function Select<T>({
    size,
    onClear,
    hasEmpty,
    placeholder,
    value,
    onChange,
    label,
    children,
    renderValue,
    multiple,
    maxWidth,
    width,
    ...rest
}: SelectProps<T>) {
    const [isVisible, setIsVisible] = React.useState(false);
    const handleClose = () => {
        setIsVisible(false);
    };

    const handleOpen = () => {
        setIsVisible(true);
    };

    const selectProps: MuiSelectProps<T> = {
        IconComponent: Icon.ChevronDown,
        displayEmpty: true,
        MenuProps: {
            disablePortal: true,
        },
        onChange,
        onClose: handleClose,
        onOpen: handleOpen,
        multiple,
        inputProps: {
            ...(size === 'small' && {
                sx: {
                    minHeight: 'auto !important',
                    ...typography.inputLabel,
                },
            }),
        },
        renderValue,
    };
    const hasClear = typeof onClear !== 'undefined' && !isVisible && ((Boolean(value) && !multiple) || (multiple && (value as [])?.length > 0));
    const props = omit(['data-cy', 'hasClear'], rest);
    return (
        <Box sx={{ minWidth: 100, display: 'flex', flexDirection: 'column', width, maxWidth }} {...props}>
            {size !== 'small' && label && (
                <Typography variant='inputLabel' id={`label-select-for-${label}`}>
                    {label}
                </Typography>
            )}
            <TextField
                id={`select-for-${label}`}
                value={value}
                select
                InputProps={{
                    ...(size === 'small' && {
                        InputProps: {
                            disableUnderline: true,
                            size,
                        },
                    }),
                    ...(hasClear && {
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={onClear}
                                    sx={{
                                        color: (th) => th.palette.text.primary,
                                        width: 16,
                                        height: 16,
                                        padding: 0,
                                        right: 32,
                                    }}
                                    size='small'
                                >
                                    <Icon.CloseCircle />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }),
                }}
                sx={{
                    maxWidth,
                    width,
                    ...(size === 'small' && {
                        minWidth: '0 !important',
                    }),
                }}
                variant='standard'
                SelectProps={selectProps as MuiSelectProps<unknown>}
                data-cy={rest['data-cy']}
            >
                {hasEmpty && <MenuItem value=''>Select value</MenuItem>}
                {children}
            </TextField>
        </Box>
    );
}

export default Select;
