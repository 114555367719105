import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Keyword } from 'ApiClients/SterlingApiClients/Types';

export type AnnotationKeywordsProps = {
    getKeywords: (annotationId: string, flowMethods: FlowMethods<Array<Keyword>>) => void;
    addKeyword: (annotationId: string, keyword: string, flowMethods: FlowMethods) => void;
    removeKeyword: (annotationId: string, keywordId: string, flowMethods: FlowMethods) => void;
    removeAllKeywords: (annotationId: string, flowMethods: FlowMethods) => void;
};

const useAnnotationsKeywords = (projectId: string, annotationApi: AnnotationsApiClient): AnnotationKeywordsProps => {
    const getKeywords = (annotationId: string, flowMethods?: FlowMethods<Array<Keyword>>) =>
        annotationApi.getKeywords({ projectId, annotationId }, flowMethods);

    const addKeyword = (annotationId: string, keyword: string, flowMethods?: FlowMethods) =>
        annotationApi.addKeyword(
            { projectId, annotationId },
            {
                ...flowMethods,
                body: { keyword },
            }
        );

    const removeKeyword = (annotationId: string, keywordId: string, flowMethods?: FlowMethods) =>
        annotationApi.removeKeyword({ projectId, annotationId, keywordId }, flowMethods);

    const removeAllKeywords = (annotationId: string, flowMethods?: FlowMethods) => annotationApi.removeAllKeywords({ projectId, annotationId }, flowMethods);

    return {
        getKeywords,
        addKeyword,
        removeKeyword,
        removeAllKeywords,
    };
};

export default useAnnotationsKeywords;
