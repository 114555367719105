import { AnnotationStatus, DocumentHeading, DocumentInfo, FileInfo, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useContext, useMemo, useRef } from 'react';
import AnnotationPanelWrapperComponent, { AnnotationPanelActionsState } from './AnnotationPanelWrapper/AnnotationPanelWrapper.Component';
import NewAnnotationPanelWrapperComponent, {
    NewAnnotationId,
    NewAnnotationPanelActionsState,
} from './NewAnnotationPanelWrapper/NewAnnotationPanelWrapper.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchRoutes, UrlParams } from 'App/RoutesPaths';
import AutoAnnotationLabelComponent from './AutoAnnotationLabel/AutoAnnotationLabel.Component';
import { Button, Icon, Typography } from 'UI';
import { Box } from '@mui/material';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';
import PdfViewerWithToolbarComponent from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import { HighlightColor, Highlight, HighlightColors, Heading } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { AnnotationSelectedEventName, AnnotationsProps, AutoAnnotationsProps, SelectedAnnotation, SelectedAnnotationType } from '../../Hooks/Module';
import useHighlightsActionsState from 'Views/Common/PdfViewerWithToolbar/Hooks/useHighlightsActionsState';
import { palette } from 'UI/Provider/VerifiTheme';
import useToasts from 'Hooks/useToasts';

export type VerificationDocumentComponentProps = {
    documentInfo: DocumentInfo;
    headings: Array<DocumentHeading>;
    getDocument: (docId: string, docName: string) => Promise<FileInfo | null>;
    onDocumentLoad: () => void;
    supportingInformationPropositionsOpen: boolean;
    annotationsProps: AnnotationsProps;
    autoAnnotationsProps: AutoAnnotationsProps;
    goToProjectVersionsList: () => void;
    onAnnotationsLoaded: () => void;
    scrollContainerRef: React.MutableRefObject<Element | null>;
};

export type AnnotationHighlightCustomData = {
    type: SelectedAnnotationType;
    annotationScope?: AnnotationScope;
    autoAnnotationScope?: AutoAnnotationScope;
};

type AnnotationScope = {
    status: AnnotationStatus;
};

type AutoAnnotationScope = {
    statementType: string;
    description: string;
};

type HighlightActionsState = NewAnnotationPanelActionsState & AnnotationPanelActionsState;

const AnnotateColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const VerifyColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const ApproveColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const VerifiedColor: HighlightColor = { R: 0, G: 255, B: 0, A: 0.2 };

const AnnotationStatusColors: { [key in AnnotationStatus]: HighlightColor } = {
    0: AnnotateColor,
    1: VerifyColor,
    2: ApproveColor,
    3: VerifiedColor,
};

const AutoAnnotationColors: { default: HighlightColor; selected: HighlightColor } = {
    default: { R: 207, G: 207, B: 207, A: 0.2 },
    selected: { R: 79, G: 117, B: 255, A: 0.4 },
};

const DisplayHighlightedAutoAnnotationInfo = process.env.REACT_APP_DISPLAY_HIGHLIGHTED_AUTOANNOTATION_INFO === 'true';

function VerificationDocumentComponent(props: VerificationDocumentComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { hash } = useLocation();
    const { addToast } = useToasts();

    const {
        supportingInformationPropositionsOpen,
        onDocumentLoad,
        goToProjectVersionsList,
        annotationsProps,
        autoAnnotationsProps,
        onAnnotationsLoaded,
        scrollContainerRef,
    } = props;
    const {
        annotationsDs,
        selectedAnnotation,
        setSelectedAnnotationId,
        addAnnotation,
        modals: annotationModals,
        verifyAnnotation,
        acceptAnnotationStage,
        isAnnotationModificationAllowed,
        selectedAnnotationIds,
        setSelectedAnnotationIds: _setSelectedAnnotationIds,
    } = annotationsProps;
    const { data: annotations } = annotationsDs;

    const initAnnotId: string | null = urlSearchParams.get(UrlParams.annotationId.paramName);
    if (initAnnotId && (hash === SearchRoutes.goToAnnotation.hash || hash === SearchRoutes.goToLinkedInformation.hash)) {
        const clearInitAnnotId = () => {
            urlSearchParams.delete(UrlParams.annotationId.paramName);
            setUrlSearchParams(urlSearchParams);
        };
        setSelectedAnnotationId(initAnnotId, { dispatchEvent: true, callback: clearInitAnnotId });
    }

    const {
        autoAnnotationsDs,
        selectedAutoAnnotationId,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        rejectAutoAnnotation,
        splitAutoAnnotation,
        mergeAutoAnnotations,
        approveAutoAnnotations,
        rejectAutoAnnotations,
    } = autoAnnotationsProps;
    const { data: autoAnnotations } = autoAnnotationsDs;

    const highlights = useMemo(
        () => [
            ...annotations.map((el): Highlight<AnnotationHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.orderNumber,
                    selectionType: el.content.selectionType,
                    boundingBoxSections: el.content.boundingBoxSections,
                    type: 'annotation',
                    annotationScope: {
                        status: el.status,
                    },
                };
            }),
            ...autoAnnotations.map((el): Highlight<AnnotationHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.orderNumber,
                    selectionType: el.content.selectionType,
                    boundingBoxSections: el.content.boundingBoxSections,
                    type: 'autoAnnotation',
                    autoAnnotationScope: {
                        statementType: el.statementType,
                        description: el.description,
                    },
                    isEditable: true,
                    multiSelectEnabled: true,
                };
            }),
        ],
        [annotations, autoAnnotations]
    );

    const highlightColor = useCallback((highlight: Highlight<AnnotationHighlightCustomData>, selectedHighlightIds: Array<string>) => {
        if (highlight.type === 'annotation') {
            const status = highlight.annotationScope?.status;
            if (status) {
                return selectedHighlightIds.includes(highlight.id) ? HighlightColors.selected : AnnotationStatusColors[status];
            }
        }
        if (highlight.type === 'autoAnnotation') {
            return selectedHighlightIds.includes(highlight.id) ? AutoAnnotationColors.selected : AutoAnnotationColors.default;
        }
    }, []);

    const { highlightsActionsState, setHighlightActionsState } = useHighlightsActionsState<HighlightActionsState>();

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [
            isEditor,
            supportingInformationPropositionsOpen,
            highlights,
            selectedAutoAnnotationId,
            selectedAnnotation,
            props.documentInfo?.processingStatus,
            highlightsActionsState,
        ];
    }, [
        isEditor,
        supportingInformationPropositionsOpen,
        highlights,
        selectedAutoAnnotationId,
        selectedAnnotation,
        props.documentInfo?.processingStatus,
        highlightsActionsState,
    ]);

    const highlightsRef = useRef(highlights);
    highlightsRef.current = highlights;

    const headings: Array<Heading> = useMemo(
        () => props.headings.map((h) => ({ id: h.id, title: h.statement, boundingBoxSections: h.content.boundingBoxSections })),
        [props.headings]
    );

    const title: JSX.Element = useMemo(() => {
        return (
            <Box sx={{ marginLeft: '1rem' }}>
                <Button
                    size='small'
                    variant='text'
                    sx={{
                        textTransform: 'none',
                        minWidth: 0,
                    }}
                    onClick={() => goToProjectVersionsList()}
                >
                    <Box display='inline-flex' alignItems='center' gap={1} sx={{ color: (theme) => theme.palette.link.main }}>
                        <Icon.FileEarmarkText />
                        <Typography
                            sx={{
                                fontSize: '14px !important',
                                fontWeight: 600,
                                color: (theme) => theme.palette.link.main,
                            }}
                        >
                            Manage document versions
                        </Typography>
                    </Box>
                </Button>
            </Box>
        );
    }, [goToProjectVersionsList]);

    const selectedHighlightIds = useMemo(() => selectedAnnotationIds.map((annot) => annot.id), [selectedAnnotationIds]);
    const setSelectedHighlightIds = useCallback(
        (ids: Array<string>) => {
            const selectedAnnotationIds: Array<SelectedAnnotation> = ids.map((id) => {
                const h = highlightsRef.current.find((h) => h.id === id);
                return { id, type: h?.type === 'annotation' ? 'annotation' : 'autoAnnotation' };
            });

            _setSelectedAnnotationIds(selectedAnnotationIds, { dispatchEvent: false });
        },
        [_setSelectedAnnotationIds]
    );
    const clearSelectedHighlight = useCallback(() => setSelectedAnnotationId(null, { dispatchEvent: false }), [setSelectedAnnotationId]);

    const bulkHighlightsActions = useMemo(() => {
        return [
            {
                title: 'Accept',
                icon: Icon.Approve,
                action: approveAutoAnnotations,
                themeColor: palette.success.main,
            },
            {
                title: 'Reject',
                icon: Icon.CloseCircle,
                action: rejectAutoAnnotations,
                themeColor: palette.attention.high,
            },
        ];
    }, [approveAutoAnnotations, rejectAutoAnnotations]);

    const { webViewerInstance, viewer } = useWebViewerInstance();
    return (
        <PdfViewerWithToolbarComponent<AnnotationHighlightCustomData>
            webViewerInstance={webViewerInstance}
            viewerRef={viewer}
            title={title}
            documentInfo={props.documentInfo}
            headings={headings}
            fileSource={'getDocument'}
            getDocument={props.getDocument}
            onDocumentLoad={onDocumentLoad}
            displayHighlightIcons={true}
            isCreatingHighlightEnabled={isAnnotationModificationAllowed}
            highlightsProps={{
                highlights,
                highlightColor,
                highlightToolbars: {
                    newHighlightToolbar: {
                        render: (options) => (
                            <NewAnnotationPanelWrapperComponent
                                newHighlightToolbarOptions={options}
                                addAnnotation={addAnnotation}
                                actionsState={highlightsActionsState[NewAnnotationId]}
                                setHighlightActionsState={setHighlightActionsState}
                            />
                        ),
                    },
                    highlightToolbar: {
                        render: (options) => {
                            const highlight = highlights.find((h) => h.id === (selectedAnnotation?.id || selectedAutoAnnotationId));
                            if (!highlight) return <div />;

                            return (
                                <AnnotationPanelWrapperComponent
                                    isEditor={isEditor}
                                    highlightToolbarOptions={options}
                                    highlight={highlight}
                                    setRef={options.setHighlightToolbarRef}
                                    supportingInformationPropositionsOpen={supportingInformationPropositionsOpen}
                                    openAssertionModal={annotationModals.openAssertionModal}
                                    openAssignmentModal={annotationModals.openAssignmentModal}
                                    openDeleteModal={annotationModals.openDeleteModal}
                                    openNeedsReviewModal={annotationModals.openNeedsReviewModal}
                                    verifyAnnotation={verifyAnnotation}
                                    openTagAssignmentModal={annotationModals.openTagAssignmentModal}
                                    isVerificationDocumentProcessed={props.documentInfo?.processingStatus === ProjectDocumentProcessingStatus.Processed}
                                    autoAnnotationProps={{
                                        createAnnotationFromAutoAnnotation,
                                        createAnnotationFromModifiedAutoAnnotation,
                                        rejectAnnotation: rejectAutoAnnotation,
                                        addPart: options.addPart,
                                        splitAutoAnnotation,
                                    }}
                                    isAnnotateStatus={selectedAnnotation?.status === AnnotationStatus.Annotate}
                                    openSuppInfoSummaryModal={annotationModals.openSuppInfoSummaryModal}
                                    acceptAnnotationStage={acceptAnnotationStage}
                                    nextAnnotationStatus={selectedAnnotation?.nextStatus || null}
                                    annotationStatus={selectedAnnotation ? selectedAnnotation.status : null}
                                    needsReview={selectedAnnotation?.needsReview || false}
                                    actionsState={highlightsActionsState[highlight.id]}
                                    setHighlightActionsState={setHighlightActionsState}
                                    addToast={addToast}
                                />
                            );
                        },
                    },
                    deps: highlightToolbarsDeps,
                },
                highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
                additionalHtmlElements: [
                    {
                        display: (options) =>
                            DisplayHighlightedAutoAnnotationInfo &&
                            options.highlight.type === 'autoAnnotation' &&
                            options.selectedHighlightIds.includes(options.highlight.id),
                        component: (options) =>
                            (options.highlight.autoAnnotationScope && <AutoAnnotationLabelComponent {...options.highlight.autoAnnotationScope} />) || <div />,
                        info: (options) => {
                            const section = options.highlight.boundingBoxSections[0];
                            return {
                                pageNumber: section.pageNumber,
                                height: 20,
                                width: 50,
                                x: section.boundingBoxes[0].topLeft.x + 5,
                                y: section.boundingBoxes[0].topLeft.y - 20,
                                position: 'bottom left',
                            };
                        },
                    },
                ],
                selectedHighlightIds,
                setSelectedHighlightIds,
                selectedHighlightChangedEventName: AnnotationSelectedEventName,
                clearSelectedHighlight,
                highlightMultiSelectEnabled: true,
                mergeHighlights: mergeAutoAnnotations,
                bulkHighlightsActions: bulkHighlightsActions,
                onAnnotationsLoaded,
            }}
            scrollContainerRef={scrollContainerRef}
        />
    );
}

export default VerificationDocumentComponent;
