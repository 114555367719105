import { AuthUser } from 'ApiClients/Auth.ApiClient';
import { UserRole } from 'ApiClients/SterlingApiClients/Types';
import React, { ReactNode } from 'react';

export const AuthContext = React.createContext<AuthContextProviderValue & AuthContextProviderMethods>({
    isAuthenticated: false,
    user: {
        id: '',
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
    },
    checkRole: () => false,
    setAuth: () => {},
});

type AuthContextProviderProps = {
    authContextProviderValue: AuthContextProviderValue & AuthContextProviderMethods;
    children?: ReactNode;
};

export type AuthContextProviderValue = {
    isAuthenticated: boolean;
    user: AuthUser & {
        firstName: string;
        lastName: string;
    };
};

export type AuthContextProviderMethods = {
    checkRole: (role: UserRole) => boolean;
    setAuth: (user: AuthUser, isAuthenticated: boolean) => void;
};

export function AuthContextProvider(props: AuthContextProviderProps) {
    return <AuthContext.Provider value={props.authContextProviderValue}>{props.children}</AuthContext.Provider>;
}
