import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { highlightColorToAnnotationsColor } from '../../useDrawing';
import {
    isStandardAnnotation,
    isNewHighlight,
    getHighlightId,
    getHighlightPartIndex,
    AnnotationAttributeTypeValues,
    AnnotationAttributeHighlightId,
    AnnotationAttributeType,
    AnnotationAttributeIsFirst,
    AnnotationAttributeIsLast,
    AnnotationAttributeHighlightPartIndex,
    AnnotationAttributeSiblingsId,
    AnnotationAttributeHighlightOriginalPartsNumber,
    AnnotationAttributeMultiSelectEnable,
} from './Attributes';
import { Highlight, HighlightColor, HighlightColors } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export const getHighlightPartAnnotationsToRemove = (instance: WebViewerInstance, highlight: Highlight) => {
    const { annotationManager } = instance.Core;
    const allAnnotations = annotationManager.getAnnotationsList();
    const highlightAnnotations = allAnnotations.filter((a) => isStandardAnnotation(a) && !isNewHighlight(a) && getHighlightId(a) === highlight.id);
    let annotationsToRemove: Array<Core.Annotations.Annotation> = [];
    highlightAnnotations.forEach((a) => {
        const highlightPartIndex = getHighlightPartIndex(a);
        if (highlightPartIndex + 1 > highlight.boundingBoxSections.length) annotationsToRemove.push(a);
    });

    return annotationsToRemove;
};

export const createOrRefreshAnnotations = <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlight: Highlight<THighlightCustomData>,
    selectedHighlightIds: Array<string>,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined
) => {
    const { Annotations, annotationManager } = instance.Core;
    const allAnnotations = annotationManager.getAnnotationsList();

    let newTextAnnotations: Array<Core.Annotations.Annotation> = [];
    let allTextAnnotations: Array<Core.Annotations.Annotation> = [];
    const highlightAnnots = allAnnotations.filter((a) => getHighlightId(a) === highlight.id && isStandardAnnotation(a)) as Array<Core.Annotations.Annotation>;
    highlight.boundingBoxSections.forEach((section, idx) => {
        let textAnnot = highlightAnnots.filter((a) => getHighlightPartIndex(a) === idx)[0] as Core.Annotations.TextHighlightAnnotation;
        if (!textAnnot) {
            textAnnot = new Annotations.TextHighlightAnnotation();
            newTextAnnotations.push(textAnnot);
        }

        let quads: Array<Core.Math.Quad> = [];
        section.boundingBoxes.forEach((b) => {
            const tL = b.topLeft;
            const dR = b.downRight;

            quads.push(new instance.Core.Math.Quad(tL.x, dR.y, dR.x, dR.y, dR.x, tL.y, tL.x, tL.y));
        });
        textAnnot.Quads = quads;
        setupAnnotation(instance, selectedHighlightIds, textAnnot, highlight, 'textAnnotation', idx, section.pageNumber, highlightColor);
        allTextAnnotations.push(textAnnot);
    });

    return { newTextAnnotations, allTextAnnotations };
};

const setupAnnotation = <THighlightCustomData,>(
    instance: WebViewerInstance,
    selectedHighlightIds: Array<string>,
    annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation,
    highlight: Highlight<THighlightCustomData>,
    type: AnnotationAttributeTypeValues,
    idx: number,
    pageNumber: number,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined
) => {
    AnnotationAttributeHighlightId.set(annot, highlight.id);
    AnnotationAttributeType.set(annot, type);
    if (idx === 0) AnnotationAttributeIsFirst.set(annot, 'true');
    if (idx === highlight.boundingBoxSections.length - 1) AnnotationAttributeIsLast.set(annot, 'true');
    AnnotationAttributeHighlightPartIndex.set(annot, idx);
    if (highlight.siblingsId) AnnotationAttributeSiblingsId.set(annot, highlight.siblingsId);
    AnnotationAttributeHighlightOriginalPartsNumber.set(annot, highlight.boundingBoxSections.length);
    AnnotationAttributeMultiSelectEnable.set(annot, highlight.multiSelectEnabled ? 'true' : 'false');
    annot.PageNumber = pageNumber;
    setupColor(instance, selectedHighlightIds, annot, highlight, highlightColor);
    annot.NoResize = highlight.isEditable && selectedHighlightIds.length <= 1 ? false : true;
};

const setupColor = <THighlightCustomData,>(
    instance: WebViewerInstance,
    selectedHighlightIds: Array<string>,
    annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation,
    highlight: Highlight<THighlightCustomData>,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined
) => {
    const color = getColor(instance, highlight, selectedHighlightIds, highlightColor);
    annot.StrokeColor = color;
    annot.FillColor = color;
};

const getColor = <THighlightCustomData,>(
    instance: WebViewerInstance,
    h: Highlight<THighlightCustomData>,
    selectedHighlightIds: Array<string>,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined
) => {
    const { Annotations } = instance.Core;
    const color = highlightColor && highlightColor(h, selectedHighlightIds);

    if (color) return highlightColorToAnnotationsColor(Annotations, color);
    else {
        const selectedColor = highlightColorToAnnotationsColor(Annotations, HighlightColors.selected);
        const defaultColor = highlightColorToAnnotationsColor(Annotations, HighlightColors.default);

        if (selectedHighlightIds.includes(h.id)) return selectedColor;
        else return defaultColor;
    }
};
