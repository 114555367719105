import { useEffect, useMemo, useRef } from 'react';
import { CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { HighlightToolbarActionsState, NewHighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { Box } from '@mui/material';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';

export type CustomTransferPanelWrapperComponentProps = HighlightToolbarActionsState<CustomTransferPanelActionsState> & {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    selectedAnnotationId: string;
    transferAnnotation: (annotationId: string, body: CreateAnnotationFromModifiedTransferPropositionBody, flowMethods: FlowMethods) => void;
};

export type CustomTransferPanelActionsState = {
    transferAnnotationFetching: boolean;
};

export const NewAnnotationId = 'new-annotation';

function CustomTransferPanelWrapperComponent(props: CustomTransferPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, selectedAnnotationId, transferAnnotation, actionsState, setHighlightActionsState } = props;
    const { transferAnnotationFetching } = useMemo(() => actionsState || { transferAnnotationFetching: false }, [actionsState]);

    const { setHighlightToolbarRef, addPart } = newHighlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const { action: transferAnnotationWrapped } = useApiModify<{
        annotationId: string;
        body: CreateAnnotationFromModifiedTransferPropositionBody;
    }>({
        method: (input, flowMethods) =>
            transferAnnotation(input.annotationId, input.body, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(NewAnnotationId, 'transferAnnotationFetching', value),
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    newHighlightToolbarOptions.clearSelection();
                },
            }),
    });

    let panel: JSX.Element = (
        <div style={{ display: 'flex' }}>
            <HighlightButtonComponent
                rootRef={ref}
                content='TRANSFER ANNOTATION'
                loading={transferAnnotationFetching}
                disabled={transferAnnotationFetching}
                onClick={async () => {
                    const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                    transferAnnotationWrapped({ annotationId: selectedAnnotationId, body: newHighlight });
                }}
            />
            <Box sx={{ width: '0.25rem' }} />
            <PartsToolbarComponent addPart={addPart} />
        </div>
    );
    return <div ref={ref}>{panel}</div>;
}

export default CustomTransferPanelWrapperComponent;
