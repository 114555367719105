import { Button, Dialog, Typography } from 'UI';

export type DeleteTagModalComponentProps = {
    isOpen: boolean;
    removeTag: () => void;
    removingTag: boolean;
    closeModal: () => void;
    annotationsNumber: number;
};

function DeleteTagModalComponent(props: DeleteTagModalComponentProps) {
    const { isOpen, removeTag, removingTag, closeModal, annotationsNumber } = props;

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        removeTag();
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Delete Tag</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Typography fontSize={14} fontWeight={600} marginBottom={4}>
                        Are you sure you want to remove the tag? Tag is assigned to {annotationsNumber} annotation(s)
                    </Typography>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-tag-removing'
                        >
                            Cancel
                        </Button>
                        <Button
                            data-cy='remove-tag'
                            isLoading={removingTag}
                            color='error'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Delete
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default DeleteTagModalComponent;
