import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { Typography } from 'UI';
import { Box, Step, StepConnector, StepLabel, stepConnectorClasses, Stepper as MuiStepper } from '@mui/material';
import StepLabelIconComponent, { IconSize, StepState } from './StepLabelIcon/StepLabelIcon.Component';

type StepperComponentProps = {
    steps: Array<StepType>;
    activeStep: number;
};

type StepType = {
    title: string;
    subtitle?: string;
};

function StepperComponent({ steps, activeStep }: StepperComponentProps) {
    return (
        <MuiStepper
            alternativeLabel
            activeStep={activeStep}
            connector={
                <StepConnector
                    sx={{
                        width: `calc(100% - ${IconSize})`,
                        left: `calc(-50% + ${IconSize}/2)`,
                        right: `calc(50% + ${IconSize}/2)`,
                        [`&.${stepConnectorClasses.alternativeLabel}`]: { top: 11 },
                        [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: { backgroundColor: (theme: Theme) => theme.palette.blue.dark },
                        [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
                            backgroundColor: (theme: Theme) => theme.palette.blue.dark,
                        },
                        [`&.${stepConnectorClasses.disabled} .${stepConnectorClasses.line}`]: { backgroundColor: (theme) => theme.palette.text.disabled },
                        [`& .${stepConnectorClasses.line}`]: { height: 2, border: 0, borderRadius: 1 },
                    }}
                />
            }
            sx={{ pb: '1rem' }}
            data-testid='stepper'
        >
            {steps.map((step, index) => {
                let state: StepState;
                switch (true) {
                    case index < activeStep:
                        state = 'prev';
                        break;
                    case index === activeStep:
                        state = 'current';
                        break;
                    case index > activeStep:
                        state = 'next';
                        break;
                    default:
                        break;
                }

                return (
                    <Step
                        key={`step-${index}`}
                        index={index}
                        active={activeStep === index}
                        sx={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            '& .MuiStepLabel-iconContainer': {
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                        }}
                    >
                        <StepLabel StepIconComponent={() => StepLabelIconComponent({ state })} data-testid={`steplabel-${index}`}>
                            <Box
                                sx={{ color: (theme) => (state !== 'next' ? theme.palette.blue.dark : theme.palette.text.main) }}
                                data-testid={`step-description-${index}`}
                            >
                                <Typography variant='subtitle1'>{step.title}</Typography>
                                {step.subtitle && <Typography variant='body2'>{step.subtitle}</Typography>}
                            </Box>
                        </StepLabel>
                    </Step>
                );
            })}
        </MuiStepper>
    );
}

export default React.memo(StepperComponent);
