import { useEffect, useRef } from 'react';
import { HighlightToolbarActionsState, HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import AnnotationTransferPanelComponent, { AnnotationTransferPanelActionsState } from './AnnotationTransferPanel/AnnotationTransferPanel.Component';
import { CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type PropositionTransferPanelWrapperComponentProps = HighlightToolbarActionsState<PropositionTransferPanelActionsState> & {
    highlightToolbarOptions: HighlightToolbarOptions;
    selectedAnnotationId: string;
    createAnnotationFromTransferProposition: (annotationId: string, flowMethods: FlowMethods) => void;
    createAnnotationFromModifiedTransferProposition: (
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods: FlowMethods
    ) => void;
    reject: (annotationId: string, flowMethods: FlowMethods) => void;
    onlyReject: boolean;
};

export type PropositionTransferPanelActionsState = AnnotationTransferPanelActionsState;

function PropositionTransferPanelWrapperComponent(props: PropositionTransferPanelWrapperComponentProps) {
    const {
        highlightToolbarOptions,
        selectedAnnotationId,
        createAnnotationFromTransferProposition,
        createAnnotationFromModifiedTransferProposition,
        reject,
        onlyReject,
        actionsState,
        setHighlightActionsState,
    } = props;

    const { setHighlightToolbarRef, getHighlightViewer, addPart } = highlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    return (
        <div ref={ref}>
            <AnnotationTransferPanelComponent
                selectedAnnotationId={selectedAnnotationId}
                createAnnotationFromTransferProposition={createAnnotationFromTransferProposition}
                createAnnotationFromModifiedTransferProposition={createAnnotationFromModifiedTransferProposition}
                reject={reject}
                onlyReject={onlyReject}
                addPart={addPart}
                actionsState={actionsState}
                setHighlightActionsState={setHighlightActionsState}
                getHighlightViewer={getHighlightViewer}
            />
        </div>
    );
}

export default PropositionTransferPanelWrapperComponent;
