import { Icon } from 'UI';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';
import { Highlight, HighlightToolbarActionsState, HighlightViewer } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { Annotation, AnnotationStatus, BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { SupportingHighlightCustomData } from '../../WebSearchViewer.Component';
import { AnnotationVerifiedDisabledTooltip } from 'App/Consts';

type SupportingInformationPanelComponentProps = HighlightToolbarActionsState<SupportingInformationPanelActionsState> & {
    selectedHighlight: Highlight<SupportingHighlightCustomData>;
    selectedAnnotation: Annotation;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
    addPart: () => void;
    getHighlightViewer: () => Promise<HighlightViewer>;
};

export type SupportingInformationPanelActionsState = {
    modifySupportingInformationFetching: boolean;
    deleteSupportingInformationFetching: boolean;
};

function SupportingInformationPanelComponent(props: SupportingInformationPanelComponentProps) {
    const {
        selectedAnnotation,
        selectedHighlight,
        modifySupportingInformation,
        deleteSupportingInformation,
        actionsState,
        setHighlightActionsState,
        addPart,
        getHighlightViewer,
    } = props;
    const { modifySupportingInformationFetching, deleteSupportingInformationFetching } = useMemo(
        () => actionsState || { deleteSupportingInformationFetching: false, modifySupportingInformationFetching: false },
        [actionsState]
    );
    const fetching = useMemo(
        () => modifySupportingInformationFetching || deleteSupportingInformationFetching,
        [modifySupportingInformationFetching, deleteSupportingInformationFetching]
    );
    const annotationVerified = useMemo(() => selectedAnnotation.status === AnnotationStatus.Verified, [selectedAnnotation]);

    const { action: modifySupportingInformationWrapped } = useApiModify<{ annotationId: string; supportingInformationId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            modifySupportingInformation(
                input.annotationId,
                input.supportingInformationId,
                highlightViewer.selectionType,
                highlightViewer.boundingBoxSections,
                highlightViewer.statement,
                {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.supportingInformationId, 'modifySupportingInformationFetching', value),
                }
            );
        },
    });

    const { action: deleteSupportingInformationWrapped } = useApiModify<{ annotationId: string; supportingInformationId: string }>({
        method: (input, flowMethods) =>
            deleteSupportingInformation(input.annotationId, input.supportingInformationId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.supportingInformationId, 'deleteSupportingInformationFetching', value),
            }),
    });

    const utils: Array<Util> = [
        {
            icon: Icon.TrashBin,
            onClick: () => deleteSupportingInformationWrapped({ annotationId: selectedAnnotation.id, supportingInformationId: selectedHighlight.id }),
            tooltip: annotationVerified ? AnnotationVerifiedDisabledTooltip : 'Delete',
            disabled: fetching || annotationVerified,
            loading: deleteSupportingInformationFetching,
        },
        {
            icon: Icon.Approve,
            onClick: () => modifySupportingInformationWrapped({ annotationId: selectedAnnotation.id, supportingInformationId: selectedHighlight.id }),
            tooltip: annotationVerified ? AnnotationVerifiedDisabledTooltip : 'Save changes',
            description: 'Save changes',
            disabled: fetching || annotationVerified,
            loading: modifySupportingInformationFetching,
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent utils={utils} />
            <Box sx={{ width: '0.25rem' }} />
            <PartsToolbarComponent addPart={addPart} disabled={fetching || annotationVerified} />
        </div>
    );
}

export default SupportingInformationPanelComponent;
