import { Box } from '@mui/material';
import { useState } from 'react';
import { Icon } from 'UI';

export type FaviconComponentProps = {
    url: string;
};

function FaviconComponent(props: FaviconComponentProps) {
    const { url } = props;

    const faviconUrl = new URL(url).origin + '/favicon.ico';

    const [iconValid, setIconValid] = useState(true);

    return (
        <Box
            sx={{
                width: '3rem',
                height: '3rem',
                color: (theme) => theme.palette.text.disabled,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                borderRadius: '50%',
            }}
        >
            {iconValid ? <img src={faviconUrl} alt='' height={32} width={32} onError={() => setIconValid(false)} /> : <Icon.Subtract height={32} width={32} />}
        </Box>
    );
}

export default FaviconComponent;
