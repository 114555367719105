import { useRef } from 'react';
import { NavBarContents } from './NavBar.Component';

const useNavBarContents = (): NavBarContents => {
    const navBarContents: NavBarContents = {
        left: useRef<HTMLDivElement>(null),
        center: useRef<HTMLDivElement>(null),
        right: useRef<HTMLDivElement>(null),
    };

    return navBarContents;
};

export default useNavBarContents;
