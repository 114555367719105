import { useEffect, useMemo, useState } from 'react';
import { Annotation, DocumentInfo } from 'ApiClients/SterlingApiClients/Types';
import { Box, Divider } from '@mui/material';
import ToolbarComponent from './Toolbar/Toolbar.Component';
import { AnnotationKeywordsProps, SupportingInformationPropositionContext, Tabs } from '../Hooks/Module';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import HeadComponent from './Head/Head.Component';
import { SupportingInformationWebSearchPropositionContext } from '../Hooks/Module/SupportingInformation/useSupportingInformationWebSearchPropositions';
import DocumentRepositoryComponent from './Tabs/DocumentRepository/DocumentRepository.Component';
import WebSearchComponent from './Tabs/WebSearch/WebSearch.Component';

type SupportingInformationPropositionsComponentProps = {
    selectedAnnotation: Annotation;
    selectPreviousAnnotation: () => void;
    selectNextAnnotation: () => void;
    supportingInformationPropositionsProps: SupportingInformationPropositionsProps;
    supportingInformationWebSearchPropositionsProps: SupportingInformationWebSearchPropositionsProps;
    documentsById: { [key: string]: DocumentInfo };
    defaultTab: Tabs | null;
    clearDefaultTab: () => void;
    closeSidebar: () => void;
    annotationKeywordsProps: AnnotationKeywordsProps;
};

type SupportingInformationPropositionsProps = {
    supportingInformationPropositionContext: SupportingInformationPropositionContext;
    increaseCountPropositionsToDisplay: (annotationId: string) => void;
    getSupportingInformationPropositions: (annotationId: string) => void;
    showSupportingInformationPropositionOnDocument: (id: string, documentId: string) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    unlinkPropositionFromAnnotation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
    rejectProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    restoreProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
};

type SupportingInformationWebSearchPropositionsProps = {
    supportingInformationWebSearchPropositionContext: SupportingInformationWebSearchPropositionContext;
    increaseCountWebSearchPropositionsToDisplay: (annotationId: string) => void;
    getSupportingInformationWebSearchPropositions: (annotationId: string) => void;
    openWebSearchDocument: (url: string) => void;
};

type ListContext = {
    fetching: boolean;
    resultsLength: number;
    resultsLengthToDisplay: number;
};

function SupportingInformationPropositionsComponent(props: SupportingInformationPropositionsComponentProps) {
    const {
        selectedAnnotation,
        selectPreviousAnnotation,
        selectNextAnnotation,
        supportingInformationPropositionsProps,
        supportingInformationWebSearchPropositionsProps,
        documentsById,
        defaultTab,
        clearDefaultTab,
        closeSidebar,
        annotationKeywordsProps,
    } = props;
    const {
        supportingInformationPropositionContext: suppInfoPropContext,
        increaseCountPropositionsToDisplay,
        getSupportingInformationPropositions,
        showSupportingInformationPropositionOnDocument,
        linkPropositionToAnnotation,
        unlinkPropositionFromAnnotation,
        rejectProposition,
        restoreProposition,
    } = supportingInformationPropositionsProps;
    const {
        supportingInformationWebSearchPropositionContext: suppInfoWebSearchPropContext,
        getSupportingInformationWebSearchPropositions,
        increaseCountWebSearchPropositionsToDisplay,
        openWebSearchDocument,
    } = supportingInformationWebSearchPropositionsProps;

    const [selectedTab, setSelectedTab] = useState<Tabs>(defaultTab || Tabs.DocumentRepository);

    useEffect(() => {
        setSelectedTab(Tabs.DocumentRepository);
    }, [selectedAnnotation]);

    useEffect(() => {
        if (defaultTab) {
            setSelectedTab(defaultTab);
            clearDefaultTab();
        }
    }, [defaultTab, setSelectedTab, clearDefaultTab]);

    useEffect(() => {
        if (!suppInfoPropContext) {
            getSupportingInformationPropositions(selectedAnnotation.id);
        }
    }, [selectedAnnotation, suppInfoPropContext, getSupportingInformationPropositions]);

    useEffect(() => {
        if (!suppInfoWebSearchPropContext && selectedTab === Tabs.WebSearch) {
            getSupportingInformationWebSearchPropositions(selectedAnnotation.id);
        }
        // eslint-disable-next-line
    }, [selectedTab, suppInfoWebSearchPropContext, getSupportingInformationWebSearchPropositions]);

    const suppInfoProp = useMemo(() => suppInfoPropContext?.supportingInformationPropositions || [], [suppInfoPropContext]);
    const suppInfoWebSearchProp = useMemo(() => suppInfoWebSearchPropContext?.supportingInformationWebSearchPropositions || [], [suppInfoWebSearchPropContext]);

    const propositionsListContext: ListContext = useMemo(
        () => ({
            fetching: suppInfoPropContext?.isFetching || false,
            resultsLength: suppInfoProp.length,
            resultsLengthToDisplay: suppInfoPropContext?.countToDisplay || 0,
        }),
        [suppInfoPropContext, suppInfoProp]
    );

    const webSearchPropositionsListContext: ListContext = useMemo(
        () => ({
            fetching: suppInfoWebSearchPropContext?.isFetching || false,
            resultsLength: suppInfoWebSearchProp.length,
            resultsLengthToDisplay: suppInfoWebSearchPropContext?.countToDisplay || 0,
        }),
        [suppInfoWebSearchPropContext, suppInfoWebSearchProp]
    );

    const listContext: ListContext = useMemo(() => {
        switch (selectedTab) {
            case Tabs.DocumentRepository:
                return propositionsListContext;
            case Tabs.WebSearch:
                return webSearchPropositionsListContext;
            default:
                return {
                    fetching: false,
                    resultsLength: 0,
                    resultsLengthToDisplay: 0,
                };
        }
    }, [selectedTab, propositionsListContext, webSearchPropositionsListContext]);

    return (
        <Box
            sx={{
                height: '100%',
                border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                borderRadius: '0.25rem',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
            }}
        >
            <ToolbarComponent selectedTab={selectedTab} setSelectedTab={setSelectedTab} closeSidebar={closeSidebar} />
            <HeadComponent
                selectedTab={selectedTab}
                selectNextAnnotation={selectNextAnnotation}
                selectPreviousAnnotation={selectPreviousAnnotation}
                selectedAnnotation={selectedAnnotation}
                resultsFetching={listContext.fetching}
                resultsLength={listContext.resultsLength}
                resultsLengthToDisplay={listContext.resultsLengthToDisplay}
                annotationKeywordsProps={annotationKeywordsProps}
                getSupportingInformationPropositions={getSupportingInformationPropositions}
            />
            <Divider />
            {selectedTab === Tabs.DocumentRepository && (
                <DocumentRepositoryComponent
                    fetching={propositionsListContext.fetching}
                    selectedAnnotation={selectedAnnotation}
                    documentsById={documentsById}
                    suppInfoProp={suppInfoProp}
                    showSupportingInformationPropositionOnDocument={showSupportingInformationPropositionOnDocument}
                    linkPropositionToAnnotation={linkPropositionToAnnotation}
                    unlinkPropositionFromAnnotation={unlinkPropositionFromAnnotation}
                    rejectProposition={rejectProposition}
                    restoreProposition={restoreProposition}
                    countPropositionsToDisplay={propositionsListContext.resultsLengthToDisplay}
                    increaseCountPropositionsToDisplay={increaseCountPropositionsToDisplay}
                />
            )}
            {selectedTab === Tabs.WebSearch && (
                <WebSearchComponent
                    fetching={webSearchPropositionsListContext.fetching}
                    selectedAnnotation={selectedAnnotation}
                    suppInfoWebSearchProp={suppInfoWebSearchProp}
                    countWebSearchPropositionsToDisplay={webSearchPropositionsListContext.resultsLengthToDisplay}
                    increaseCountWebSearchPropositionsToDisplay={increaseCountWebSearchPropositionsToDisplay}
                    openUrl={openWebSearchDocument}
                />
            )}
        </Box>
    );
}

export default SupportingInformationPropositionsComponent;
