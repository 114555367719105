import { Box, LinearProgress } from '@mui/material';
import { CompareState } from '../../Hooks/useCompareState';
import { Switch, Typography } from 'UI';

export type ComparingInfoComponentProps = CompareState & {
    showDiffs?: boolean;
    showHideDiffs?: (value: boolean) => void;
};

function ComparingInfoComponent(props: ComparingInfoComponentProps) {
    const { comparing, docsCompareResult, showDiffs, showHideDiffs } = props;

    return (
        <>
            {comparing && (
                <Box sx={{ width: '100%', paddingLeft: '10rem', paddingRight: '10rem' }}>
                    <LinearProgress />
                    <Typography sx={{ width: '100%', textAlign: 'center', marginTop: '0.5rem', fontWeight: 600, color: (theme) => theme.palette.blue.dark }}>
                        Comparing documents...
                    </Typography>
                </Box>
            )}
            {!comparing && docsCompareResult && (
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '1.25rem',
                            fontWeight: 600,
                            color: (theme) => theme.palette.blue.dark,
                        }}
                    >
                        Editorial changes: {docsCompareResult.diffCount}
                    </Typography>
                    {showHideDiffs && (
                        <Box sx={{ marginLeft: '1rem', display: 'flex', alignItems: 'center', width: '6rem' }}>
                            <Switch checked={showDiffs} onChange={(_e, checked) => showHideDiffs(checked)} size='small' />
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    marginLeft: '0.5rem',
                                    color: (theme) => (showDiffs ? theme.palette.blue.dark : theme.palette.text.secondary),
                                }}
                            >
                                {showDiffs ? 'Visible' : 'Hidden'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}

export default ComparingInfoComponent;
