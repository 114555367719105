type InputType = {
    page: number,
    coordinateY: number,
    coordinateX: number,
}

export const OrderByHighlightPosition = (a: InputType, b: InputType) => {
    const multiplier = 10000;

    if (a.page !== b.page) {
        return a.page - b.page;
    }

    // If the difference in Y is greater than 3, we can assume that the objects are on different lines
    if (Math.abs(a.coordinateY - b.coordinateY) > 3) {
        const aY = a.coordinateY * multiplier;
        const bY = b.coordinateY * multiplier;
        return aY - bY;
    }

    const aX = a.coordinateX * multiplier;
    const bX = b.coordinateX * multiplier;
    return aX - bX;
}