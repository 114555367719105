import { useCallback, useState } from 'react';

export type SupportingInformationPropositionsListProps = {
    isOpen: boolean;
    open: (defaultTab?: Tabs) => void;
    close: () => void;
    defaultTab: Tabs | null;
    clearDefaultTab: () => void;
};

export enum Tabs {
    DocumentRepository = 'Document Repository',
    WebSearch = 'Web Search',
}

const useSupportingInformationPropositionsList = (
    switchSupportingDocument: (documentId: string | null) => void,
    switchWebSearchDocument: (url: string | null) => void
): SupportingInformationPropositionsListProps => {
    const [isOpen, _setIsOpen] = useState<boolean>(false);

    const open = useCallback(
        (defaultTab?: Tabs) => {
            switchSupportingDocument(null);
            switchWebSearchDocument(null);
            if (defaultTab) _defaultTab(defaultTab);
            _setIsOpen(true);
        },
        [switchSupportingDocument, switchWebSearchDocument, _setIsOpen]
    );
    const close = useCallback(() => _setIsOpen(false), [_setIsOpen]);

    const [defaultTab, _defaultTab] = useState<Tabs | null>(null);
    const clearDefaultTab = useCallback(() => _defaultTab(null), [_defaultTab]);

    const supportingInformationPropositionsListProps: SupportingInformationPropositionsListProps = {
        isOpen,
        open,
        close,
        defaultTab,
        clearDefaultTab,
    };

    return supportingInformationPropositionsListProps;
};

export default useSupportingInformationPropositionsList;
