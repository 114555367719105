import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { MutableRefObject, useCallback, useState } from 'react';
import {
    AnnotationAttributeHighlightPartIndex,
    AnnotationAttributeIsFirst,
    AnnotationAttributeIsLast,
    getHighlightId,
    getHighlightPartIndex,
    isStandardAnnotation,
} from './Drawing/ViewerAnnotations/Attributes';
import { AvailableToolMode } from '../../PdfViewerWithToolbar.Component';
import Log from '../../Logger';
import { drawHighlightPartNumber, eraseHighlightPartNumber } from './Drawing/HtmlElements/HighlightPartNumber';
import { EmotionCache } from '@emotion/react';

const useAnnotationParts = (
    webViewerInstance: WebViewerInstance | null,
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void,
    deselectAllAnnotationsRef: MutableRefObject<() => void>
) => {
    const [multiSelectNext, setMultiSelectNext] = useState<boolean>(false);

    const addPart = useCallback(() => {
        if (webViewerInstance) {
            Log(webViewerInstance, 'ACTION', ['useAnnotationParts', 'addPart'], []);
            deselectAllAnnotationsRef.current();
            setToolMode(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT, 'AnnotationCreateTextHighlight');
            setMultiSelectNext(true);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, setMultiSelectNext, setToolMode]);

    return { multiSelectNext, setMultiSelectNext, addPart };
};

export const removePart = (
    webViewerInstance: WebViewerInstance,
    highlightId: string,
    partIndex: number,
    emotionCache: EmotionCache,
    annotationElementsEnabled: boolean
) => {
    if (webViewerInstance) {
        Log(webViewerInstance, 'ACTION', ['useAnnotationParts', 'removePart'], []);
        const annotationManager = webViewerInstance.Core.annotationManager;
        const annotations = annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === highlightId && isStandardAnnotation(a));

        const annotationToRemove = annotations.find((a) => getHighlightPartIndex(a) === partIndex);

        const annotationsToReorder = annotations.filter((a) => getHighlightPartIndex(a) !== partIndex);

        if (annotationToRemove) {
            const highlightId = getHighlightId(annotationToRemove);
            annotationsToReorder.forEach((a, idx) => {
                if (idx === 0) AnnotationAttributeIsFirst.set(a, 'true');
                if (idx === annotationsToReorder.length - 1) AnnotationAttributeIsLast.set(a, 'true');

                AnnotationAttributeHighlightPartIndex.set(a, idx);
            });
            annotationManager.deleteAnnotation(annotationToRemove);

            eraseHighlightPartNumber(webViewerInstance);
            drawHighlightPartNumber(webViewerInstance, highlightId, true, emotionCache, annotationElementsEnabled);

            setTimeout(() => {
                annotationManager.selectAnnotations(annotationsToReorder.reverse());
            }, 10);
        }
    }
};

export default useAnnotationParts;
