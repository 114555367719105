import { Box } from '@mui/material';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import { Icon } from 'UI';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { HighlightToolbarActionsState, HighlightViewer } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { useMemo } from 'react';

export type AnnotationTransferPanelComponentProps = HighlightToolbarActionsState<AnnotationTransferPanelActionsState> & {
    selectedAnnotationId: string;
    createAnnotationFromTransferProposition: (annotationId: string, flowMethods: FlowMethods) => void;
    createAnnotationFromModifiedTransferProposition: (
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods: FlowMethods
    ) => void;
    reject: (annotationId: string, flowMethods: FlowMethods) => void;
    onlyReject: boolean;
    addPart: () => void;
    getHighlightViewer: () => Promise<HighlightViewer>;
};

export type AnnotationTransferPanelActionsState = {
    createFetching: boolean;
    rejectFetching: boolean;
};

function AnnotationTransferPanelComponent(props: AnnotationTransferPanelComponentProps) {
    const {
        selectedAnnotationId,
        createAnnotationFromTransferProposition,
        createAnnotationFromModifiedTransferProposition,
        reject,
        onlyReject,
        addPart,
        getHighlightViewer,
        actionsState,
        setHighlightActionsState,
    } = props;
    const { createFetching, rejectFetching } = useMemo(() => actionsState || { createFetching: false, rejectFetching: false }, [actionsState]);
    const fetching = useMemo(() => createFetching || rejectFetching, [createFetching, rejectFetching]);

    const { action: createWrapped } = useApiModify<{ annotationId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            if (highlightViewer.isModified) {
                createAnnotationFromModifiedTransferProposition(input.annotationId, highlightViewer, {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.annotationId, 'createFetching', value),
                });
            } else {
                createAnnotationFromTransferProposition(input.annotationId, {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.annotationId, 'createFetching', value),
                });
            }
        },
    });

    const { action: rejectWrapped } = useApiModify<{ annotationId: string }>({
        method: (input, flowMethods) =>
            reject(input.annotationId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.annotationId, 'rejectFetching', value),
            }),
    });

    let utils: Array<Util> = [
        {
            icon: Icon.CloseCircle,
            onClick: () => rejectWrapped({ annotationId: selectedAnnotationId }),
            description: 'Reject',
            tooltip: 'Reject',
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
            disabled: fetching,
            loading: rejectFetching,
        },
    ];

    if (!onlyReject) {
        utils.push({
            icon: Icon.Approve,
            onClick: () => createWrapped({ annotationId: selectedAnnotationId }),
            description: 'Accept',
            tooltip: 'Accept',
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
            disabled: fetching,
            loading: createFetching,
        });
    }

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent utils={utils} />
            {!onlyReject && (
                <>
                    <Box sx={{ width: '0.25rem' }} />
                    <PartsToolbarComponent addPart={addPart} disabled={fetching} />
                </>
            )}
        </div>
    );
}

export default AnnotationTransferPanelComponent;
