import { SxProps } from '@mui/material';
import MuiTooltip, { TooltipProps as MuiProps } from '@mui/material/Tooltip';

export type TooltipProps = MuiProps & { tooltipref?: React.Ref<HTMLDivElement>; marginBottom?: number; tooltipsx?: SxProps };

function Tooltip({ sx, ...rest }: TooltipProps) {
    const { tooltipref, marginBottom, tooltipsx } = rest;

    return (
        <MuiTooltip
            arrow
            placement='top'
            componentsProps={{
                tooltip: {
                    sx: {
                        mb: `${marginBottom || 10}px !important`,
                        maxWidth: 'none',
                        paddingLeft: '0.75rem',
                        paddingRight: '0.75rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        borderRadius: '0.5rem',
                        fontWeight: 600,
                        lineHeight: '1.25rem',
                        ...tooltipsx,
                    },
                },
                popper: {
                    ref: tooltipref,
                },
            }}
            disableFocusListener
            sx={sx}
            {...rest}
        />
    );
}

export default Tooltip;
