import React, { useEffect, memo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Toast, ToastEnum, ToastTypes, ToastVariant, ToastVariantEnum, ToastWithId } from 'Hooks/useToasts';
import { CheckCircle, Close, InfoCircle, QuestionCircle } from 'UI/Icon';
import { Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';

export type ToastComponentProps = ToastWithId & {
    removeToast: () => void;
};

const TOAST_CONFIG = {
    success: {
        color: palette.text.main,
        bgColor: palette.white.main,
        borderColor: palette.success.main,
        icon: CheckCircle,
        buttonColor: palette.white.main,
        buttonTextColorSecondary: palette.white.main,
    },
    info: {
        color: palette.white.main,
        bgColor: palette.accent.dark,
        borderColor: palette.accent.extraDark,
        icon: InfoCircle,
        buttonColor: palette.accent.medium,
        buttonTextColorSecondary: palette.white.main,
    },
    warning: {
        color: palette.warningToast.extraDark,
        bgColor: palette.warningToast.extraLight,
        borderColor: palette.warningToast.light,
        icon: InfoCircle,
        buttonColor: palette.warningToast.medium,
        buttonTextColorSecondary: palette.text.main,
    },
    error: {
        color: palette.danger.dark,
        bgColor: palette.danger.extraLight,
        borderColor: palette.danger.light,
        icon: InfoCircle,
        buttonColor: palette.danger.dark,
        buttonTextColorSecondary: palette.white.main,
    },
    primary: {
        color: palette.accent.dark,
        bgColor: palette.accent.extraLight,
        borderColor: palette.accent.medium,
        icon: InfoCircle,
        buttonColor: palette.accent.medium,
        buttonTextColorSecondary: palette.white.main,
    },
    secondary: {
        color: palette.text.main,
        bgColor: palette.neutrals.medium,
        borderColor: palette.neutrals.extraDark,
        icon: InfoCircle,
        buttonColor: palette.accent.medium,
        buttonTextColorSecondary: palette.white.main,
    },
    notificationPrimary: {
        color: palette.white.main,
        bgColor: palette.attention.high,
        borderColor: palette.danger.light,
        icon: InfoCircle,
        buttonColor: palette.white.main,
        buttonTextColorSecondary: palette.text.main,
    },
    notificationSecondary: {
        color: palette.text.main,
        bgColor: palette.white.main,
        borderColor: palette.attention.high,
        icon: InfoCircle,
        buttonColor: palette.blue.dark,
        buttonTextColorSecondary: palette.white.main,
    },
};

const TOAST_WIDTH = '24rem';

const renderIcon = (mode: ToastTypes, variant?: ToastVariant) => {
    const config = TOAST_CONFIG[mode];
    if (!config) return null;

    const IconComponent = config.icon;

    let fillColor = config.color;

    if (mode === ToastEnum.SUCCESS) {
        fillColor = palette.success.main;

        if (variant === ToastVariantEnum.SECONDARY) {
            fillColor = palette.white.main;
        }
    } else if (mode === ToastEnum.NOTIFICATION_SECONDARY) {
        fillColor = palette.attention.high;
    }

    return <IconComponent color={fillColor} />;
};

const ToastHeader = memo(function ToastHeader({
    children,
    type,
    variant,
    mainColor,
    progress,
    handleCloseIcon,
    handleOpenErrorInfo,
}: Omit<Toast, 'content'> & { children?: React.ReactNode; mainColor: string; handleCloseIcon: () => void; handleOpenErrorInfo: () => void; progress: number }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                // ...(!notificationEnabled && { alignItems: 'center' }),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    // ...(!notificationEnabled && { justifyContent: 'center' }),
                    width: '17.625rem',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'inline-flex',
                        paddingRight: '1rem',
                        //  ...(!notificationEnabled && { alignItems: 'center' })
                    }}
                >
                    {renderIcon(type, variant)}
                </Box>
                <Box sx={{ display: 'flex' }}>{children}</Box>
            </Box>
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                {type === ToastEnum.ERROR && (
                    <QuestionCircle style={{ cursor: 'pointer' }} width={16} height={16} color={mainColor} onClick={handleOpenErrorInfo} />
                )}
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '0.25rem',
                    }}
                >
                    <CircularProgress
                        size={16}
                        sx={{
                            color: mainColor,
                            zIndex: 1,
                            cursor: 'pointer',
                        }}
                        variant='determinate'
                        value={progress}
                    />
                    <Close
                        width={9}
                        height={9}
                        style={{
                            position: 'absolute',
                            color: mainColor,
                            cursor: 'pointer',
                            top: '50%',
                            left: '60%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }}
                        onClick={handleCloseIcon}
                    />
                </Box>
            </Box>
        </Box>
    );
});

function ToastComponent(props: ToastComponentProps) {
    const { type, content, variant, removeToast, autoClose = true, errorId } = props;

    const [extended, setExtended] = useState<boolean>(false);
    const config = TOAST_CONFIG[type];
    const [progress, setProgress] = useState<number>(0);

    // const handleButtonClick = () => {
    //     if (onActionButtonClick) {
    //         onActionButtonClick();
    //         return;
    //     }
    // };

    useEffect(() => {
        if (progress === 100) setTimeout(() => removeToast(), 500);
        // eslint-disable-next-line
    }, [progress]);

    useEffect(() => {
        if (autoClose) {
            const interval: number = 50;
            const toastExpirationMs: number = 4000;
            const step: number = (interval / toastExpirationMs) * 100;
            const iterationsCount: number = toastExpirationMs / interval;
            const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
            const runTimer = async () => {
                for (let i = 0; i < iterationsCount; i++) await delay(interval).then(() => setProgress((prev) => prev + step));
            };
            runTimer();
        }
        // eslint-disable-next-line
    }, []);

    const isToastSuccessSecondary = variant === ToastVariantEnum.SECONDARY && type === ToastEnum.SUCCESS;

    return (
        <Box sx={{ width: TOAST_WIDTH }}>
            <Box
                sx={{
                    padding: '0.875rem 1rem',
                    borderRadius: '0.25rem',
                    border: () => `1px solid ${variant === ToastVariantEnum.SECONDARY ? palette.danger.light : config.borderColor}`,
                    backgroundColor: isToastSuccessSecondary ? palette.success.main : config.bgColor,
                    boxShadow: `0px 2px 10px 0px rgba(63, 68, 68, 0.40)`,
                    zIndex: 90,
                    position: 'relative',
                }}
            >
                <ToastHeader
                    {...props}
                    mainColor={isToastSuccessSecondary ? palette.white.main : config.color}
                    handleCloseIcon={() => removeToast()}
                    handleOpenErrorInfo={() => {
                        setExtended((prev) => !prev);
                    }}
                    progress={progress}
                >
                    <Box>
                        {/* {title && (
                            <Typography
                                variant='subtitle1'
                                sx={{
                                    paddingBottom: '0.5rem',
                                    color: config.color,
                                }}
                            >
                                {title}
                            </Typography>
                        )} */}
                        <Box
                            sx={{
                                display: 'flex',
                                // ...(!notificationEnabled && { justifyContent: 'center', alignItems: 'center' }),
                            }}
                        >
                            <Typography
                                variant='body2'
                                sx={{
                                    color: isToastSuccessSecondary ? palette.white.main : config.color,
                                    fontSize: 13,
                                    fontWeight: 500,
                                    whiteSpace: 'pre-line',
                                    maxWidth: '10rem',
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {content.join('\n')}
                            </Typography>
                            {/* {type !== ToastEnum.SUCCESS && enableActionButton && (
                                <Box>
                                    <Button
                                        onClick={handleButtonClick}
                                        color={variant === ToastVariantEnum.SECONDARY ? 'primary' : 'secondary'}
                                        sx={{
                                            backgroundColor: `${palette.danger.extraLight} !important`,
                                            border: `1px solid ${palette.neutrals.dark}`,
                                            color: palette.text.main,
                                            '&:hover': {
                                                border: `1px solid ${palette.neutrals.dark}`,
                                            },
                                            marginLeft: '0.5rem',
                                        }}
                                        size='small'
                                    >
                                        {type === ToastEnum.INFO ? 'start' : 'action'}
                                    </Button>

                                    <Button
                                        onClick={handleButtonClick}
                                        color={variant === ToastVariantEnum.SECONDARY ? 'primary' : 'secondary'}
                                        sx={{
                                            backgroundColor: `${config.buttonColor} !important`,
                                            border: 'none',
                                            borderColor: `${palette.white.main} !important`,
                                            color: config.buttonTextColorSecondary,
                                            '&:hover': {
                                                border: 'none',
                                                backgroundColor: `${config.buttonColor} !important`,
                                            },
                                            marginLeft: '0.5rem',
                                        }}
                                        size='small'
                                    >
                                        {type === ToastEnum.INFO ? 'start' : 'action'}
                                    </Button>
                                </Box>
                            )} */}
                        </Box>
                        {/* {notificationEnabled && (
                            <Box
                                sx={{
                                    paddingTop: '1rem',
                                }}
                            >
                                <Button
                                    onClick={handleButtonClick}
                                    color={variant === ToastVariantEnum.SECONDARY ? 'primary' : 'secondary'}
                                    sx={{
                                        backgroundColor: `${config.buttonColor} !important`,
                                        borderColor: `${palette.white.main} !important`,
                                        color: config.buttonTextColorSecondary,
                                        '&:hover': {
                                            backgroundColor: `${config.buttonColor} !important`,
                                        },
                                    }}
                                    size='small'
                                >
                                    {type === ToastEnum.INFO ? 'start' : 'action'}
                                </Button>
                            </Box>
                        )} */}
                    </Box>
                </ToastHeader>
            </Box>
            {extended && type === ToastEnum.ERROR && (
                <Box
                    sx={{
                        borderRadius: '0.25rem',
                        display: 'flex',
                        flexDirection: 'row',
                        bgcolor: palette.danger.dark,
                        padding: '0.5rem 1rem',
                        alignContent: 'center',
                        width: TOAST_WIDTH,
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='title' sx={{ color: palette.white.main, fontSize: 13, fontWeight: 400, whiteSpace: 'nowrap' }}>
                        Error code
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: palette.white.main,
                            marginLeft: '1rem',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            borderRadius: '0.125rem',
                            fontSize: '0.6875rem',
                        }}
                    >
                        <Typography variant='caption' sx={{ color: palette.text.main }}>
                            {errorId}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default memo(ToastComponent);
