import { ProjectMember, DocumentInfo, Content, BoundingBoxSection, SelectionType } from '../Types';

export type ProjectVersionSimple = {
    id: string;
    status: ProjectVersionStatus;
    isLocked: boolean;
    createdBy: ProjectMember;
};

export type ProjectVersionExtended = {
    id: string;
    status: ProjectVersionStatus;
    isLocked: boolean;
    createdBy: ProjectMember;
    verificationDocument: DocumentInfo;
};

export type ProjectVersionListRecord = {
    id: string;
    status: ProjectVersionStatus;
    verificationDocumentName: string;
    verificationDocumentOriginalName: string | null;
    annotationsCount: number;
    lastModifiedDate: Date;
    creationDate: Date;
    createdBy: ProjectMember;
};

export enum ProjectVersionStatus {
    Archived = 0,
    Active = 1,
    Draft = 2,
}

export type CreateDraftProjectVersion = {
    verificationDocument: File;
};

export type AnnotationsTransferProposition = {
    activeProjectVersionId: string;
    draftProjectVersionId: string;
    totalAnnotationsToTransfer: number;
    propositions: Array<AnnotationTransferProposition>;
};

export interface AnnotationTransferProposition {
    annotationId: string;
    content: Content | null;
    statement: string;
    status: AnnotationTransferPropositionStatus;
    isRejected: boolean;
}

export enum AnnotationTransferPropositionStatus {
    Found = 1,
    FoundWithModifications = 2,
    Removed = 3,
}

export type CreateAnnotationFromModifiedTransferPropositionBody = {
    selectionType: SelectionType;
    boundingBoxSections: Array<BoundingBoxSection>;
    statement: string;
};
