import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useCallback, useEffect, useMemo, useState } from 'react';

// !!! OBSOLETE !!! Use useApiGet/useApiModify instead
const usePromiseWithFlowMethods = <TInput, TData = undefined>(options: {
    method: (input: TInput, flowMethods: FlowMethods<TData>) => void;
    initialData: TData;
    initFetch?: {
        input: TInput;
        flowMethods?: FlowMethods<TData> | undefined;
    };
    enabled?: boolean;
}) => {
    const { method, initialData, initFetch, enabled } = options;
    const _enabled = useMemo(() => {
        if (enabled === undefined) return true;
        else return enabled;
    }, [enabled]);

    const [data, setData] = useState<TData>(initialData);
    const [fetching, setFetching] = useState<boolean>(false);
    const wrappedMethod = useCallback(
        (input: TInput, flowMethods?: FlowMethods<TData>) => {
            if (_enabled) {
                method(input, {
                    setFetching: (fetching) => {
                        setFetching(fetching);
                        flowMethods?.setFetching && flowMethods.setFetching(fetching);
                    },
                    onInit: () => flowMethods?.onInit && flowMethods.onInit(),
                    onSuccess: (data) => {
                        setData(data);
                        flowMethods?.onSuccess && flowMethods.onSuccess(data);
                    },
                    onError: () => flowMethods?.onError && flowMethods.onError(),
                });
            }
        },
        // eslint-disable-next-line
        [_enabled]
    );
    const refetch = useCallback(
        () => {
            if (initFetch && _enabled) wrappedMethod(initFetch.input, initFetch.flowMethods);
        },
        // eslint-disable-next-line
        [_enabled]
    );

    useEffect(() => {
        if (initFetch && _enabled) {
            wrappedMethod(initFetch.input, initFetch.flowMethods);
        }
        // eslint-disable-next-line
    }, [_enabled]);

    return {
        data,
        fetching,
        wrappedMethod,
        setData,
        refetch,
    };
};

export default usePromiseWithFlowMethods;
