import { Box } from '@mui/material';
import PdfViewerWithToolbarComponent from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';
import { SupportingHighlightSelectedEventName, SupportingInformationContext, Tabs } from '../../Hooks/Module';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { WebSearchDocumentInfo } from '../../Hooks/Module/Documents/useWebSearchDocuments';
import { Annotation, AnnotationStatus, BoundingBoxSection, FileInfo, SelectionType, SupportingInformationSource } from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Typography, VerifiSpinner } from 'UI';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import InformationDataToolbar from './InformationDataToolbar/InformationDataToolbar';
import useHighlightsActionsState from 'Views/Common/PdfViewerWithToolbar/Hooks/useHighlightsActionsState';
import NewSupportingInformationPanelWrapperComponent, {
    NewSupportingInformationId,
    NewSupportingInformationPanelActionsState,
} from './NewSupportingInformationPanelWrapper/NewSupportingInformationPanelWrapper.Component';
import SupportingInformationPanelWrapperComponent, {
    SupportingInformationPanelWrapperActionsState,
} from './SupportingInformationPanelWrapper/SupportingInformationPanelWrapper.Component';

type WebSearchViewerComponentProps = {
    onDocumentLoad: () => void;
    selectedAnnotation: Annotation | null;
    webSearchProps: WebSearchProps;
    supportingInformationProps: SupportingInformationProps;
    highlightProps: HighlightProps;
    openSupportingInformationPropositions: (defaultTab?: Tabs) => void;
    closeDocument: () => void;
    scrollContainerRef: React.MutableRefObject<Element | null>;
};

type WebSearchProps = {
    webSearchDocumentInfo: WebSearchDocumentInfo;
    webSearchDocumentFileInfo: FileInfo | null;
    uploadWebSearchDocument: (webSearchDocumentInfo: WebSearchDocumentInfo, webSearchDocumentFileInfo: FileInfo, flowMethods: FlowMethods<string>) => void;
    uploadDocAndAddSupportingDocument: (
        webSearchDocumentInfo: WebSearchDocumentInfo,
        webSearchDocumentFileInfo: FileInfo,
        annotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        addFlowMethods: FlowMethods
    ) => void;
};

type SupportingInformationProps = {
    supportingInformationContext: SupportingInformationContext;
    getSupportingInformation: (annotationId: string) => void;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
};

type HighlightProps = {
    selectedSupportingHighlightId: string | null;
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void;
};

export type SupportingHighlightCustomData = {
    source: SupportingInformationSource | null;
};

type HighlightActionsState = NewSupportingInformationPanelActionsState & SupportingInformationPanelWrapperActionsState;

function WebSearchViewerComponent(props: WebSearchViewerComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const {
        onDocumentLoad,
        selectedAnnotation,
        webSearchProps,
        supportingInformationProps,
        highlightProps,
        openSupportingInformationPropositions,
        closeDocument,
        scrollContainerRef,
    } = props;
    const { webSearchDocumentInfo, webSearchDocumentFileInfo, uploadWebSearchDocument, uploadDocAndAddSupportingDocument } = webSearchProps;
    const { supportingInformationContext, getSupportingInformation, addSupportingInformation, modifySupportingInformation, deleteSupportingInformation } =
        supportingInformationProps;
    const { selectedSupportingHighlightId, setSelectedSupportingHighlightId } = highlightProps;

    useEffect(() => {
        if (!supportingInformationContext && selectedAnnotation && webSearchDocumentInfo.supportingDocumentId) {
            getSupportingInformation(selectedAnnotation.id);
        }
    }, [supportingInformationContext, getSupportingInformation, selectedAnnotation, webSearchDocumentInfo.supportingDocumentId]);

    const suppInfoHighlights = useMemo(
        () =>
            supportingInformationContext && supportingInformationContext.supportingInformation
                ? supportingInformationContext.supportingInformation
                      .filter((el) => el.documentId === webSearchDocumentInfo.supportingDocumentId)
                      .map((el): Highlight<SupportingHighlightCustomData> => {
                          return {
                              id: el.id,
                              orderNumber: el.orderNumber,
                              boundingBoxSections: el.content.boundingBoxSections,
                              selectionType: el.content.selectionType,
                              source: el.source,
                              isEditable: selectedAnnotation ? selectedAnnotation.status !== AnnotationStatus.Verified : false,
                          };
                      })
                : ([] as Array<Highlight<SupportingHighlightCustomData>>),
        [supportingInformationContext, webSearchDocumentInfo, selectedAnnotation]
    );

    const { highlightsActionsState, setHighlightActionsState } = useHighlightsActionsState<HighlightActionsState>();

    const { webViewerInstance, viewer } = useWebViewerInstance();

    const selectedHighlightIds = useMemo(() => (selectedSupportingHighlightId ? [selectedSupportingHighlightId] : []), [selectedSupportingHighlightId]);
    const setSelectedHighlightIds = useCallback(
        (ids: Array<string>) => setSelectedSupportingHighlightId(ids[0], { dispatchEvent: false }),
        [setSelectedSupportingHighlightId]
    );

    const selectedAnnotationRef = useRef(selectedAnnotation || null);
    selectedAnnotationRef.current = selectedAnnotation;

    const selectedSupportingHighlight = suppInfoHighlights.find((h) => h.id === selectedSupportingHighlightId) || null;
    const selectedSupportingHighlightRef = useRef(selectedSupportingHighlight);
    selectedSupportingHighlightRef.current = selectedSupportingHighlight;

    const [uploadingAndAddingSupportingDocument, setUploadingAndAddingSupportingDocument] = useState<boolean>(false);
    const [uploadingSupportingDocument, setUploadingSupportingDocument] = useState<boolean>(false);
    const uploadDocAndAddSupportingDocumentWrapped = useCallback(
        (
            webSearchDocumentInfo: WebSearchDocumentInfo,
            webSearchDocumentFileInfo: FileInfo,
            annotationId: string,
            selectionType: SelectionType,
            boundingBoxSections: Array<BoundingBoxSection>,
            statement: string,
            addFlowMethods: FlowMethods
        ) => {
            setUploadingAndAddingSupportingDocument(true);
            uploadDocAndAddSupportingDocument(webSearchDocumentInfo, webSearchDocumentFileInfo, annotationId, selectionType, boundingBoxSections, statement, {
                ...addFlowMethods,
                onSuccess: (data) => {
                    addFlowMethods.onSuccess?.(data);
                    setUploadingAndAddingSupportingDocument(false);
                },
            });
        },
        // eslint-disable-next-line
        []
    );

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        const anySelectedAnnotation = selectedAnnotation ? true : false;
        return [selectedSupportingHighlightId, selectedAnnotation, anySelectedAnnotation, suppInfoHighlights, highlightsActionsState, webSearchDocumentInfo];
    }, [selectedSupportingHighlightId, selectedAnnotation, suppInfoHighlights, highlightsActionsState, webSearchDocumentInfo]);

    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            {(uploadingAndAddingSupportingDocument || uploadingSupportingDocument) && (
                <Box
                    sx={{
                        zIndex: 1075,
                        height: '100%',
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ zIndex: 1, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <VerifiSpinner />
                        <Typography sx={{ mt: '2rem', fontSize: '1.5rem', fontWeight: 600, color: (theme) => theme.palette.primary.dark }}>
                            Saving to document repository
                        </Typography>
                        {uploadingAndAddingSupportingDocument && (
                            <Typography sx={{ mt: '0.5rem', fontSize: '1rem', fontWeight: 600, color: (theme) => theme.palette.primary.dark }}>
                                ...and linking annotation
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: (theme) => theme.palette.white.main,
                            opacity: 0.8,
                        }}
                    />
                </Box>
            )}
            {webSearchDocumentInfo && webSearchDocumentFileInfo && (
                <InformationDataToolbar
                    savedInRepository={webSearchDocumentInfo.savedInRepository}
                    saveToRepository={() =>
                        webSearchDocumentFileInfo &&
                        uploadWebSearchDocument(webSearchDocumentInfo, webSearchDocumentFileInfo, { setFetching: setUploadingSupportingDocument })
                    }
                    savingToRepository={uploadingSupportingDocument || uploadingAndAddingSupportingDocument}
                    backToWebSearch={() => openSupportingInformationPropositions(Tabs.WebSearch)}
                    openInNewTab={() => window.open(webSearchDocumentInfo.url, '_blank')}
                />
            )}
            <PdfViewerWithToolbarComponent
                webViewerInstance={webViewerInstance}
                fileSource={'docFile'}
                documentInfo={webSearchDocumentInfo}
                docFileInfo={webSearchDocumentFileInfo}
                viewerRef={viewer}
                title={webSearchDocumentInfo.name}
                onDocumentLoad={onDocumentLoad}
                closeIconOnClick={closeDocument}
                displayHighlightIcons={true}
                isCreatingHighlightEnabled={true}
                highlightsProps={{
                    highlights: suppInfoHighlights,
                    highlightToolbars: {
                        newHighlightToolbar: {
                            render: (options) =>
                                webSearchDocumentFileInfo ? (
                                    <NewSupportingInformationPanelWrapperComponent
                                        newHighlightToolbarOptions={options}
                                        webSearchDocumentInfo={webSearchDocumentInfo}
                                        webSearchDocumentFileInfo={webSearchDocumentFileInfo}
                                        selectedAnnotationRef={selectedAnnotationRef}
                                        addSupportingInformation={addSupportingInformation}
                                        uploadDocAndAddSupportingDocument={uploadDocAndAddSupportingDocumentWrapped}
                                        actionsState={highlightsActionsState[NewSupportingInformationId]}
                                        setHighlightActionsState={setHighlightActionsState}
                                    />
                                ) : (
                                    <div />
                                ),
                        },
                        highlightToolbar: {
                            render: (options) => (
                                <SupportingInformationPanelWrapperComponent
                                    highlightToolbarOptions={options}
                                    selectedHighlightRef={selectedSupportingHighlightRef}
                                    selectedAnnotationRef={selectedAnnotationRef}
                                    modifySupportingInformation={modifySupportingInformation}
                                    deleteSupportingInformation={deleteSupportingInformation}
                                    actionsState={highlightsActionsState[selectedSupportingHighlightRef.current?.id || '']}
                                    setHighlightActionsState={setHighlightActionsState}
                                />
                            ),
                        },
                        deps: highlightToolbarsDeps,
                    },
                    highlightLeftTopInformation: (highlight) => {
                        let leftTopInformation = [{ iconContent: Icon.Link45deg }, { textContent: highlight.orderNumber.toString() }];
                        if (highlight.source && highlight.source === 1 && isEditor) {
                            leftTopInformation.push({ iconContent: Icon.Flash });
                        }

                        return leftTopInformation;
                    },
                    selectedHighlightIds,
                    setSelectedHighlightIds,
                    selectedHighlightChangedEventName: SupportingHighlightSelectedEventName,
                    clearSelectedHighlight: () => setSelectedSupportingHighlightId(null, { dispatchEvent: false }),
                }}
                scrollContainerRef={scrollContainerRef}
            />
        </Box>
    );
}

export default WebSearchViewerComponent;
