import React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import * as Icon from '../Icon';

type ChipsProps = Omit<MuiChipProps, 'color'> & { color: MuiChipProps['color'] | 'verified' };

function Chip({ color, ...rest }: ChipsProps, ref: React.ForwardedRef<HTMLDivElement>) {
    const hasColorFromPalette = color !== 'secondary' && color !== 'verified';
    return (
        <MuiChip
            {...rest}
            {...(hasColorFromPalette && {
                color: color as MuiChipProps['color'],
            })}
            {...(color === 'secondary' && {
                color: 'primary',
                variant: 'outlined',
            })}
            {...(color === 'verified' && {
                color: 'primary',
                icon: <Icon.VerifiV />,
            })}
            ref={ref}
        />
    );
}

export default React.forwardRef<HTMLDivElement, ChipsProps>(Chip);
