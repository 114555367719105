import DocumentsListComponent, { DocumentsListComponentProps } from './DocumentsList/DocumentsList.Component';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { AuthContext } from 'Contexts/Auth.Context';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Box, Input, useTheme } from '@mui/material';
import { Button, Icon, Tooltip } from 'UI';
import { ErrorsDescription } from 'App/Consts';

export type DocumentsComponentProps = DocumentsListComponentProps & {
    uploadSupportingDocument: (name: string, file: File, flowMethods: FlowMethods) => void;
    anySupportingDocumentsProcessingErrors: boolean;
};

function DocumentsComponent(props: DocumentsComponentProps) {
    const theme = useTheme();

    const { uploadSupportingDocument, anySupportingDocumentsProcessingErrors } = props;
    const authContext = useContext(AuthContext);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);

    const isDocumentUploader = authContext.checkRole('document_uploader');
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const acceptedFiles = Array.from(files || []).filter((file) => {
            const sizeInMB: number = file.size / (1024 * 1024);
            return isAcceptedFileType(file.type) && sizeInMB < 200;
        });

        let handlers = [];

        if (acceptedFiles) {
            for (let i = acceptedFiles.length - 1; i >= 0; i--) {
                const file = acceptedFiles[i];
                const onSuccess = handlers.length > 0 ? handlers[handlers.length - 1] : undefined;

                if (file) {
                    handlers.push((): void => upload(file, onSuccess));
                }
            }
        }

        handlers.reverse();
        if (handlers) handlers[0]();
    };

    const upload = (file: File, onSuccessCallback: (() => void) | undefined) => {
        uploadSupportingDocument(file.name, file, { setFetching: setUploading, onSuccess: onSuccessCallback });
    };

    const isAcceptedFileType = (fileType: string): boolean => {
        var allowedTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/pdf',
            'application/zip',
            'application/x-zip-compressed',
        ];

        return allowedTypes.includes(fileType);
    };

    return (
        <Box
            sx={{
                height: 'calc(100% - 2.5rem)',
            }}
        >
            <Box
                sx={{
                    height: '5rem',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '1.5rem',
                }}
            >
                <Tooltip title={isDocumentUploader ? 'Upload document' : 'Coming soon'}>
                    <span>
                        <Button variant='outlined' onClick={() => inputRef.current?.click()} isLoading={uploading} disabled={!isDocumentUploader}>
                            Add document
                        </Button>
                    </span>
                </Tooltip>
                {isDocumentUploader && (
                    <Input
                        type='file'
                        inputProps={{
                            accept: '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip',
                            ref: inputRef,
                            style: { display: 'none' },
                            multiple: true,
                        }}
                        onChange={handleFileChange}
                    />
                )}
                {anySupportingDocumentsProcessingErrors && (
                    <Box sx={{ color: (theme) => theme.palette.attention.medium, marginLeft: '0.5rem', display: 'flex', alignItems: 'center' }}>
                        <Tooltip tooltipsx={{ color: theme.palette.attention.medium }} title={ErrorsDescription.anySupportingDocumentsProcessingErrors}>
                            <Icon.ExclamationCircle height={24} width={24} />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <DocumentsListComponent {...props} />
        </Box>
    );
}

export default DocumentsComponent;
