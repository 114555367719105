import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { Box, Divider, SxProps, Theme } from '@mui/material';
import { Icon, Tooltip, Typography } from 'UI';
import { IconType } from 'UI/Icon';
import DocumentNavigationUtilsComponent from './DocumentNavigationUtils/DocumentNavigationUtils.Component';
import { AvailableToolMode, SyncingProps } from '../PdfViewerWithToolbar.Component';
import HighlightUtilsComponent from './HighlightUtils/HighlightUtils.Component';
import HighlightNavigationUtilsComponent from './HighlightNavigationUtils/HighlightNavigationUtils.Component';
import { HighlightNavigationProps } from '../PdfViewerWithToolbar.Types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ShowHideAnnotationElements, { ActionDesc } from '../Hooks/Annotations/ShowHideAnnotationElements';

export type ToolbarComponentProps = {
    webViewerInstance: WebViewerInstance | null;
    documentLoading: boolean;
    title: string | JSX.Element;
    displayHighlightIcons: boolean;
    displayHighlightNavigationIcons: boolean;
    highlightNavigationUtilsProps: HighlightNavigationProps;
    currentToolMode: AvailableToolMode;
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void;
    openSearchPopup: () => void;
    openComments?: () => void;
    closeIconOnClick?: () => void;
    isCreatingHighlightEnabled: boolean;
    syncingProps?: SyncingProps;
    annotationElementsEnabled: boolean;
    setAnnotationElementsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ToolbarHeight = '2.5rem';

function ToolbarComponent(props: ToolbarComponentProps) {
    const {
        webViewerInstance,
        documentLoading,
        title,
        displayHighlightIcons,
        displayHighlightNavigationIcons,
        highlightNavigationUtilsProps,
        currentToolMode,
        setToolMode,
        openSearchPopup,
        openComments,
        closeIconOnClick,
        isCreatingHighlightEnabled,
        syncingProps,
        annotationElementsEnabled,
        setAnnotationElementsEnabled,
    } = props;

    const documentViewer = webViewerInstance?.Core.documentViewer;

    const toolbarRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const utilsRef = useRef<HTMLDivElement>(null);
    const [utilsWidth, setUtilsWidth] = useState<number | null>(null);
    const [titleOriginalWidth, setTitleOriginalWidth] = useState<number | null>(null);

    const observerSetUtilsWidth = () => {
        setUtilsWidth(utilsRef.current?.clientWidth || 0);
    };

    const observerSetTitleOriginalWidth = (_entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        if (titleRef.current && titleRef.current.clientWidth > 0) {
            setTitleOriginalWidth(titleRef.current.clientWidth);
            observer.disconnect();
        }
    };

    useEffect(() => {
        if (typeof title === 'string') {
            if (toolbarRef.current) new ResizeObserver(observerSetUtilsWidth).observe(toolbarRef.current);
            if (utilsRef.current) new ResizeObserver(observerSetUtilsWidth).observe(utilsRef.current);
            if (titleRef.current) new ResizeObserver(observerSetTitleOriginalWidth).observe(titleRef.current);
        }
        // eslint-disable-next-line
    }, []);

    const titleIsShrinked: boolean = (titleOriginalWidth || 0) > (titleRef.current?.clientWidth || 0);

    const showHideAnnotationElements = useCallback(
        (show: boolean) => {
            if (webViewerInstance) {
                ShowHideAnnotationElements(webViewerInstance, show);
            }
        },
        [webViewerInstance]
    );

    useEffect(() => {
        showHideAnnotationElements(annotationElementsEnabled);
    }, [showHideAnnotationElements, annotationElementsEnabled]);

    return (
        <Box
            ref={toolbarRef}
            sx={{
                display: 'flex',
                height: ToolbarHeight,
                borderRadius: '0.25rem 0.25rem 0rem 0rem',
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.blue.light,
            }}
        >
            <Box sx={{ width: `calc(100% - ${utilsWidth}px)` }}>
                {typeof title === 'string' ? (
                    <Tooltip title={title}>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '1rem',
                                width: 'fit-content',
                            }}
                        >
                            <Typography
                                ref={titleRef}
                                variant='subtitle1'
                                sx={{
                                    marginLeft: '1rem',
                                    wordBreak: 'break-all',
                                    overflow: 'hidden',
                                    ...(titleIsShrinked && { width: 'calc(100% - 2rem)' }),
                                }}
                            >
                                {title}
                            </Typography>
                            {titleIsShrinked && (
                                <Typography variant='subtitle1' sx={{ width: '1rem' }}>
                                    ...
                                </Typography>
                            )}
                        </Box>
                    </Tooltip>
                ) : (
                    title
                )}
            </Box>
            <Box ref={utilsRef} sx={{ display: 'flex', alignItems: 'center', marginRight: '1.5rem', marginLeft: 'auto' }}>
                {(titleOriginalWidth || typeof title !== 'string') && webViewerInstance && documentViewer && documentViewer.getDocument() && (
                    <>
                        {syncingProps && (
                            <>
                                <ToolbarUtilComponent
                                    title={syncingProps.syncing ? 'Stop syncing' : 'Sync viewers'}
                                    icon={Icon.ArrowLeftRight}
                                    active={syncingProps.syncing !== null}
                                    disabled={false}
                                    onClick={() => syncingProps.setSyncing()}
                                />
                                <ToolbarDividerComponent />
                            </>
                        )}
                        {webViewerInstance && (
                            <>
                                <ToolbarUtilComponent
                                    title={annotationElementsEnabled ? `${ActionDesc.Hide}s` : `${ActionDesc.Show}s`}
                                    icon={Icon.FileEarmarkBinary}
                                    active={!annotationElementsEnabled}
                                    disabled={false}
                                    onClick={() => setAnnotationElementsEnabled((prev) => !prev)}
                                />
                                <ToolbarDividerComponent />
                            </>
                        )}
                        {displayHighlightNavigationIcons && (
                            <>
                                <HighlightNavigationUtilsComponent {...highlightNavigationUtilsProps} />
                                <ToolbarDividerComponent />
                            </>
                        )}
                        {displayHighlightIcons && (
                            <>
                                <HighlightUtilsComponent
                                    webViewerInstance={webViewerInstance}
                                    currentToolMode={currentToolMode}
                                    setToolMode={setToolMode}
                                    isCreatingHighlightEnabled={isCreatingHighlightEnabled}
                                />
                                <ToolbarDividerComponent />
                            </>
                        )}
                        <DocumentNavigationUtilsComponent
                            webViewerInstance={webViewerInstance}
                            documentLoading={documentLoading}
                            openSearchPopup={openSearchPopup}
                            openComments={openComments}
                            closeIconOnClick={closeIconOnClick}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}

type ToolbarUtilComponentProps = {
    onClick: () => void;
    title: string;
    icon: IconType;
    active: boolean;
    disabled: boolean;
    iconBoxSx?: SxProps<Theme>;
};

export function ToolbarUtilComponent(props: ToolbarUtilComponentProps) {
    const { onClick, title, icon: Icon, active, disabled, iconBoxSx } = props;

    return (
        <Box>
            <Tooltip title={title} placement='bottom'>
                <Box
                    onClick={onClick}
                    sx={{
                        height: '1.5rem',
                        width: '1.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '0.75rem',
                        cursor: 'pointer',
                        borderRadius: '0.25rem',
                        ...(active && { backgroundColor: 'white !important' }),
                        ...(disabled && { opacity: 0.5, cursor: 'default !important' }),
                        ...iconBoxSx,
                    }}
                >
                    <Icon height={16} width={16} />
                </Box>
            </Tooltip>
        </Box>
    );
}

export function ToolbarDividerComponent() {
    return <Divider orientation='vertical' flexItem sx={{ marginRight: '0.75rem' }} />;
}

export default ToolbarComponent;
