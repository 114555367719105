import { useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Icon } from 'UI';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { AnnotationHighlightCustomData } from '../../VerificationDocument.Component';
import { Highlight, HighlightToolbarActionsState } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { ActionDesc } from 'Views/Common/PdfViewerWithToolbar/Hooks/Annotations/ShowHideAnnotationElements';

export type EditPanelComponentProps = HighlightToolbarActionsState<EditPanelActionsState> & {
    highlight: Highlight<AnnotationHighlightCustomData>;
    openDeleteModal: (id: string) => void;
    openAssignmentModal: (id: string) => void;
    openTagAssignmentModal: (id: string) => void;
    openNeedsReviewModal: (id: string) => void;
    supportingInformationPropositionsOpen: boolean;
    verifyAnnotation: (id: string) => void;
    openAssertionModal: (id: string) => void;
    isEditor: boolean;
    isVerificationDocumentProcessed: boolean;
    showHideAnnotationElements: (id: string, show: boolean) => void;
    isAnnotateStatus: boolean;
    openSuppInfoSummaryModal: (id: string) => void;
    acceptAnnotationStage: (id: string, flowMethods: FlowMethods) => void;
    nextAnnotationStatus: AnnotationStatus | null;
    needsReview: boolean;
};

export type EditPanelActionsState = {
    acceptAnnotationStageFetching: boolean;
};

function EditPanelComponent(props: EditPanelComponentProps) {
    const {
        highlight,
        openDeleteModal,
        openAssignmentModal,
        openTagAssignmentModal,
        openAssertionModal,
        openNeedsReviewModal,
        supportingInformationPropositionsOpen,
        verifyAnnotation,
        isEditor,
        isVerificationDocumentProcessed,
        showHideAnnotationElements,
        isAnnotateStatus,
        openSuppInfoSummaryModal,
        acceptAnnotationStage,
        nextAnnotationStatus,
        needsReview,
        actionsState,
        setHighlightActionsState,
    } = props;
    const { acceptAnnotationStageFetching } = useMemo(() => actionsState || { acceptAnnotationStageFetching: false }, [actionsState]);
    const fetching = useMemo(() => acceptAnnotationStageFetching, [acceptAnnotationStageFetching]);

    const [expanded, setExpanded] = useState<boolean>(true);
    const showHideAnnotationElementsHandle = () => {
        showHideAnnotationElements(highlight.id, !expanded);
        setExpanded(!expanded);
    };

    const { action: acceptAnnotationStageWrapped } = useApiModify<{ annotationId: string }>({
        method: (input, flowMethods) =>
            acceptAnnotationStage(input.annotationId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.annotationId, 'acceptAnnotationStageFetching', value),
            }),
    });

    let utils: Array<Util> = [];

    if (isEditor || !isAnnotateStatus) {
        utils.push({
            icon: Icon.FileEarmarkBinary,
            onClick: showHideAnnotationElementsHandle,
            tooltip: expanded ? ActionDesc.Hide : ActionDesc.Show,
            disabled: fetching,
        });

        if (isEditor) {
            utils.push({ icon: Icon.PersonsAdd, onClick: () => openAssignmentModal(highlight.id), tooltip: 'Assign Member', disabled: fetching });
        }

        utils.push({ icon: Icon.Quote, onClick: () => openAssertionModal(highlight.id), tooltip: 'Add Assertion', disabled: fetching });

        if (isEditor) {
            utils.push({ icon: Icon.Tag, onClick: () => openTagAssignmentModal(highlight.id), tooltip: 'Add Tag', disabled: fetching });
        }

        utils.push({ icon: Icon.Link45deg, onClick: () => openSuppInfoSummaryModal(highlight.id), tooltip: 'Linked Information', disabled: fetching });

        if (isEditor) {
            utils.push({
                icon: Icon.FlagFill,
                onClick: () => openNeedsReviewModal(highlight.id),
                tooltip: 'Needs Review',
                utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
                disabled: needsReview || fetching,
            });
            utils.push({ icon: Icon.TrashBin, onClick: () => openDeleteModal(highlight.id), tooltip: 'Delete Annotation', disabled: fetching });
        }
    }

    let nextStatusTooltip: string = '';
    if (nextAnnotationStatus) {
        switch (nextAnnotationStatus) {
            case AnnotationStatus.Verify:
                nextStatusTooltip = 'Approve annotation';
                break;
            case AnnotationStatus.Approve:
                nextStatusTooltip = 'Submit for approval';
                break;
            case AnnotationStatus.Verified:
                nextStatusTooltip = 'Approve verification';
                break;
            default:
                break;
        }
    }

    const rootRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={rootRef} style={{ display: 'flex' }}>
            {!supportingInformationPropositionsOpen && !isAnnotateStatus && (
                <HighlightButtonComponent
                    rootRef={rootRef}
                    content='FIND SUPPORTING INFORMATION'
                    {...(!isVerificationDocumentProcessed && { tooltip: 'Indexing is currently been done' })}
                    disabled={!isVerificationDocumentProcessed || fetching}
                    onClick={() => verifyAnnotation(highlight.id)}
                />
            )}
            <Box sx={{ width: '0.25rem' }} />
            {utils.length > 0 && <HighlightToolbarComponent utils={utils} />}
            {nextAnnotationStatus && (
                <>
                    <Box sx={{ width: '0.25rem' }} />
                    <HighlightToolbarComponent
                        utils={[
                            {
                                icon: Icon.Approve,
                                onClick: () => acceptAnnotationStageWrapped({ annotationId: highlight.id }),
                                tooltip: nextStatusTooltip,
                                utilIconBoxSx: { color: (theme) => theme.palette.success.main },
                                disabled: needsReview || fetching,
                                loading: acceptAnnotationStageFetching,
                            },
                        ]}
                    />
                </>
            )}
        </div>
    );
}

export default EditPanelComponent;
