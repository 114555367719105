import { useState } from 'react';

type ObjectsById<TObject> = {
    [key: string]: TObject;
};

const useStateById = <TObject,>(): [
    ObjectsById<TObject>,
    {
        Value: (id: string, object: TObject) => void;
        Function: (id: string, object: (object: TObject) => TObject) => void;
        PartialObject: (id: string, object: Partial<TObject>) => void;
    },
    React.Dispatch<React.SetStateAction<ObjectsById<TObject>>>
] => {
    const [objectsById, setObjectsById] = useState<ObjectsById<TObject>>({});

    const Value = (id: string, value: TObject) => {
        setObjectsById((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const Function = (id: string, object: (object: TObject) => TObject) => {
        setObjectsById((prevState) => ({
            ...prevState,
            [id]: object(prevState[id]),
        }));
    };

    const PartialObject = (id: string, object: Partial<TObject>) => {
        if (typeof object !== 'object') throw new Error('PartialObject -> only objects are available');
        else {
            setObjectsById((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    ...object,
                },
            }));
        }
    };

    return [objectsById, { Value, Function, PartialObject }, setObjectsById];
};

export default useStateById;
