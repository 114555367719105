import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { AnnotationComment } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import { CommentDrawerState } from 'Views/Project/ProjectWorkspace/Drawers/CommentDrawer/CommentDrawer.Component';
import { useCallback, useState } from 'react';

export type AnnotationCommentsContext = {
    isFetching: boolean;
    comments: Array<AnnotationComment> | null;
};

export type AnnotationCommentsByAnnotationId = {
    [key: string]: AnnotationCommentsContext;
};

export type CommentsProps = {
    commentsByAnnotationId: AnnotationCommentsByAnnotationId;
    getComments: (annotationId: string) => void;
    addComment: (annotationId: string, comment: string) => void;
    resolveComment: (annotationId: string, commentId: string) => void;
    deleteComment: (annotationId: string, commentId: string) => void;
    commentDrawerState: CommentDrawerState;
    openCommentDrawer: (annotationId: string) => void;
    closeCommentDrawer: () => void;
};

const useAnnotationsComments = (projectId: string, annotationApi: AnnotationsApiClient, refreshAnnotation: (annotationId: string) => void): CommentsProps => {
    const [commentsByAnnotationId, setCommentsByAnnotationId] = useStateById<AnnotationCommentsContext>();

    const getComments = (annotationId: string) => {
        setCommentsByAnnotationId.Function(annotationId, (prev) => ({
            isFetching: true,
            comments: prev?.comments ? prev.comments : [],
        }));

        annotationApi.getComments(
            { projectId, annotationId },
            {
                onSuccess: (data) => {
                    setCommentsByAnnotationId.Value(annotationId, {
                        isFetching: false,
                        comments: data,
                    });
                },
            }
        );
    };

    const addComment = (annotationId: string, comment: string) => {
        annotationApi.addComment(
            { projectId, annotationId },
            {
                body: { comment },
                onSuccess: () => {
                    refreshAnnotation(annotationId);
                    getComments(annotationId);
                },
            }
        );
    };

    const resolveComment = (annotationId: string, commentId: string) => {
        annotationApi.resolveComment(
            { projectId, annotationId, commentId },
            {
                onSuccess: () => {
                    refreshAnnotation(annotationId);
                    getComments(annotationId);
                },
            }
        );
    };

    const deleteComment = (annotationId: string, commentId: string) => {
        annotationApi.deleteComment(
            { projectId, annotationId, commentId },
            {
                onSuccess: () => {
                    refreshAnnotation(annotationId);
                    getComments(annotationId);
                },
            }
        );
    };

    const [commentDrawerState, setCommentDrawerState] = useState<CommentDrawerState>({ isOpen: false, annotationId: null });
    const openCommentDrawer = useCallback((annotationId: string) => {
        setCommentDrawerState({ isOpen: true, annotationId });
    }, []);
    const closeCommentDrawer = useCallback(() => {
        setCommentDrawerState((prev: CommentDrawerState) => ({ ...prev, isOpen: false }));
    }, []);

    return {
        commentsByAnnotationId,
        getComments,
        addComment,
        resolveComment,
        deleteComment,
        commentDrawerState,
        openCommentDrawer,
        closeCommentDrawer,
    };
};

export default useAnnotationsComments;
