import { Box, CircularProgress } from '@mui/material';
import { AcceptDraftProjectVersionProps, AnnotationTransferData, AnnotationsTransferState } from './useAnnotationsTransferState';
import { Button, Icon, Typography } from 'UI';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';
import { CompareState } from '../Hooks/useCompareState';
import StatusWithCountLabelComponent, { StatusConfig } from '../Common/StatusWithCountLabel/StatusWithCountLabel.Component';
import ComparingInfoComponent from '../Common/ComparingInfo/ComparingInfo.Component';
import { useContext } from 'react';
import { FullscreenModeContext } from 'Contexts/FullscreenMode.Context';
import { FullscreenButton } from 'Views/Project/NewVersionForm/NewVersionForm.Component';

export type AnnotationsTransferComponentProps = AnnotationsTransferState & {
    compareState: CompareState;
};

export const AnnotationsTransferHeadHeight = '6rem';
export const AnnotationsTransferHeadHeightFullscreen = '4.5rem';
export const AnnotationsTransferMarginBottom = '1rem';
const Title = 'Annotation transfer review';

function AnnotationsTransferComponent(props: AnnotationsTransferComponentProps) {
    const {
        activeStatus,
        setActiveStatus,
        acceptDraftProjectVersionProps,
        annotationsTransferData,
        navigateToDocumentVersionList,
        compareState,
        showDiffs,
        setShowDiffs,
        annotationsListRedrawing,
    } = props;

    const { isFullscreen, toggleFullscreen } = useContext(FullscreenModeContext);

    if (isFullscreen) {
        return (
            <Box sx={{ height: AnnotationsTransferHeadHeightFullscreen, marginBottom: AnnotationsTransferMarginBottom }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '20%' }}>
                        <Typography sx={{ height: '100%', display: 'flex', alignItems: 'center', fontSize: '24px', fontWeight: 700, whiteSpace: 'nowrap' }}>
                            {Title}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '60%', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', marginBottom: '0.5rem' }}>
                            {Object.keys(StatusConfig).map((s) => {
                                const status = parseInt(s) as AnnotationTransferPropositionStatus;
                                return (
                                    <StatusWithCountLabelComponent
                                        key={status}
                                        status={status}
                                        active={activeStatus === status}
                                        number={annotationsTransferData?.filter((p) => p.status === status).length || 0}
                                        onClick={setActiveStatus}
                                        disabled={annotationsListRedrawing}
                                    />
                                );
                            })}
                            <Box sx={{ width: '1.5rem' }}>{annotationsListRedrawing && <CircularProgress size={24} />}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', height: '2rem', alignItems: 'end', width: '100%' }}>
                            <ComparingInfoComponent {...compareState} showDiffs={showDiffs} showHideDiffs={setShowDiffs} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '20%',
                            marginLeft: 'auto',
                            marginRight: '0rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <CompleteButton annotationsTransferData={annotationsTransferData} acceptDraftProjectVersionProps={acceptDraftProjectVersionProps} />
                        <FullscreenButton isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
                    </Box>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box sx={{ height: AnnotationsTransferHeadHeight, marginBottom: AnnotationsTransferMarginBottom }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '20%' }}>
                        <Typography sx={{ height: '2rem', marginBottom: '2rem', fontSize: '24px', fontWeight: 700, whiteSpace: 'nowrap' }}>{Title}</Typography>
                        <Button color='secondary' sx={{ gap: 1 }} onClick={() => navigateToDocumentVersionList()}>
                            <Icon.ArrowSimpleLeft />
                            Exit
                        </Button>
                    </Box>
                    <Box sx={{ width: '60%', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        >
                            {Object.keys(StatusConfig).map((s) => {
                                const status = parseInt(s) as AnnotationTransferPropositionStatus;
                                return (
                                    <StatusWithCountLabelComponent
                                        key={status}
                                        status={status}
                                        active={activeStatus === status}
                                        number={annotationsTransferData?.filter((p) => p.status === status).length || 0}
                                        onClick={setActiveStatus}
                                        disabled={annotationsListRedrawing}
                                    />
                                );
                            })}
                            <Box sx={{ width: '1.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {annotationsListRedrawing && <CircularProgress size={24} />}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', height: '3rem', alignItems: 'end', width: '100%' }}>
                            <ComparingInfoComponent {...compareState} showDiffs={showDiffs} showHideDiffs={setShowDiffs} />
                        </Box>
                    </Box>
                    <Box sx={{ width: '20%', marginLeft: 'auto', marginRight: '0rem', marginBottom: '0rem', marginTop: 'auto', display: 'flex' }}>
                        <CompleteButton annotationsTransferData={annotationsTransferData} acceptDraftProjectVersionProps={acceptDraftProjectVersionProps} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

type CompleteButtonProps = {
    annotationsTransferData: Array<AnnotationTransferData> | null;
    acceptDraftProjectVersionProps: AcceptDraftProjectVersionProps;
};

function CompleteButton(props: CompleteButtonProps) {
    const { annotationsTransferData, acceptDraftProjectVersionProps } = props;

    return (
        <Button
            color='primary'
            sx={{ gap: 1, marginLeft: 'auto', marginRight: '0rem', height: '2rem' }}
            disabled={
                !annotationsTransferData ||
                annotationsTransferData.filter((p) => p.status === AnnotationTransferPropositionStatus.FoundWithModifications).length > 0
            }
            isLoading={acceptDraftProjectVersionProps.acceptingDraft}
            onClick={() => acceptDraftProjectVersionProps.acceptDraftProjectVersion()}
        >
            Complete Document Update
            <Icon.ArrowSimpleRight />
        </Button>
    );
}

export default AnnotationsTransferComponent;
