import { Avatar } from '@mui/material';
import { Typography } from 'UI';
import React from 'react';

type InitialsAvatarProps = {
    name: string;
};

function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function InitialsAvatar({ name }: InitialsAvatarProps) {
    const color = stringToColor(name);
    const initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

    return (
        <Avatar sx={{ bgcolor: color, width: '2rem', height: '2rem' }} data-testid={`stats-avatar-${name}`}>
            <Typography variant='subtitle1'>{initials}</Typography>
        </Avatar>
    );
}

export default React.memo(InitialsAvatar);
