import AssertionModalComponent, { AssertionModalComponentProps } from './AssertionModal/AssertionModal.Component';
import AssignmentModalComponent, { AssignmentModalComponentProps } from './AssignmentModal/AssignmentModal.Component';
import DeleteModalComponent, { DeleteModalComponentProps } from './DeleteModal/DeleteModal.Component';
import ProjectMembersModal, { ProjectMembersModalProps } from 'Views/Common/ProjectMembersModal/ProjectMembersModal.Component';
import SuppInfoSummaryModalComponent, { SuppInfoSummaryModalComponentProps } from './SuppInfoSummaryModal/SuppInfoSummaryModal.Component';
import TagManagementModalComponent, { TagManagementModalComponentProps } from './TagManagementModal/TagManagementModal.Component';
import TagAssignmentModalComponent, { TagAssignmentModalComponentProps } from './TagAssignmentModal/TagAssignmentModal.Component';
import AnnotationFiltersModalComponent, { AnnotationFiltersModalComponentProps } from './AnnotationFiltersModal/AnnotationFiltersModal.Component';
import NeedsReviewModalComponent, { NeedsReviewModalComponentProps } from './NeedsReviewModal/NeedsReviewModal.Component';
import ReadOnlyModalComponent, { ReadOnlyModalProps } from './ReadOnlyModal/ReadOnlyModal.Component';

type ProjectWorkspaceModalsComponentProps = {
    suppInfoSummaryModal: SuppInfoSummaryModalComponentProps;
    assertionModal: AssertionModalComponentProps;
    assignmentModal: AssignmentModalComponentProps;
    deleteModal: DeleteModalComponentProps;
    projectMembersModal: ProjectMembersModalProps;
    tagManagementModal: TagManagementModalComponentProps;
    tagAssignmentModal: TagAssignmentModalComponentProps;
    annotationFiltersModal: AnnotationFiltersModalComponentProps;
    needsReviewModal: NeedsReviewModalComponentProps;
    readOnlyModalProps: ReadOnlyModalProps;
};

function ProjectWorkspaceModalsComponent(props: ProjectWorkspaceModalsComponentProps) {
    const {
        suppInfoSummaryModal,
        assertionModal,
        assignmentModal,
        deleteModal,
        projectMembersModal,
        tagManagementModal,
        tagAssignmentModal,
        annotationFiltersModal,
        needsReviewModal,
        readOnlyModalProps,
    } = props;

    return (
        <div>
            {suppInfoSummaryModal.isOpen && <SuppInfoSummaryModalComponent {...suppInfoSummaryModal} />}
            {assertionModal.isOpen && <AssertionModalComponent {...assertionModal} />}
            {assignmentModal.isOpen && <AssignmentModalComponent {...assignmentModal} />}
            {deleteModal.isOpen && <DeleteModalComponent {...deleteModal} />}
            {projectMembersModal.isOpen && <ProjectMembersModal {...projectMembersModal} />}
            {tagManagementModal.isOpen && <TagManagementModalComponent {...tagManagementModal} />}
            {tagAssignmentModal.isOpen && <TagAssignmentModalComponent {...tagAssignmentModal} />}
            {annotationFiltersModal.isOpen && <AnnotationFiltersModalComponent {...annotationFiltersModal} />}
            {needsReviewModal.isOpen && <NeedsReviewModalComponent {...needsReviewModal} />}
            {readOnlyModalProps.isOpen && <ReadOnlyModalComponent {...readOnlyModalProps} />}
        </div>
    );
}

export default ProjectWorkspaceModalsComponent;
