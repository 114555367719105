import { ProjectMember } from 'ApiClients/SterlingApiClients/Types';
import React, { ReactNode } from 'react';

export const ProjectAuthContext = React.createContext<ProjectAuthContextProviderValue>({
    projectId: undefined,
    projectMember: null,
    isEditor: false,
    isLoading: true,
});

type ProjectAuthContextProviderProps = {
    projectAuthContextProviderValue: ProjectAuthContextProviderValue;
    children?: ReactNode;
};

export type ProjectAuthContextProviderValue = {
    projectId: string | undefined;
    projectMember: ProjectMember | null;
    isEditor: boolean;
    isLoading: boolean;
};

export function ProjectAuthContextProvider(props: ProjectAuthContextProviderProps) {
    return <ProjectAuthContext.Provider value={props.projectAuthContextProviderValue}>{props.children}</ProjectAuthContext.Provider>;
}
