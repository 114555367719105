import './PdfViewerWithToolbar.Styles.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import createCache from '@emotion/cache';
import useDocument from './Hooks/useDocument';
import useAnnotations from './Hooks/useAnnotations';
import useToolbars from './Hooks/useToolbars';
import { DocumentProps, HighlightsProps } from './PdfViewerWithToolbar.Types';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { Box } from '@mui/material';
import useHeadings from './Hooks/useHeadings';
import ToolbarComponent, { ToolbarHeight } from './Toolbar/Toolbar.Component';
import SearchPopupComponent from './SearchPopup/SearchPopup.Component';
import HeadingsListComponent from './HeadingsList/HeadingsList.Component';
import HighlightNavigationComponent from './HighlightNavigation/HighlightNavigation.Component';
import MultiHighlightsToolbarComponent from './MultiHighlightsToolbar/MultiHighlightsToolbar.Component';

export type PdfViewerWithToolbarComponentProps<THighlightCustomData = {}> = DocumentProps & {
    viewerRef: React.RefObject<HTMLDivElement>;
    webViewerInstance: WebViewerInstance | null;
    title: string | JSX.Element;
    closeIconOnClick?: () => void;
    highlightsProps: HighlightsProps<THighlightCustomData>;
    displayHighlightIcons: boolean;
    displayHighlightNavigationIcons?: boolean;
    isCreatingHighlightEnabled: boolean;
    syncingProps?: SyncingProps;
    scrollContainerRef?: React.MutableRefObject<Element | null>;
};

export type SyncingProps = {
    syncing: string | null;
    setSyncing: () => void;
};

export type AvailableToolMode = 'AnnotationCreateTextHighlight' | 'TextSelect' /*| 'AnnotationCreateRectangle'*/;

function PdfViewerWithToolbarComponent<THighlightCustomData = {}>(props: PdfViewerWithToolbarComponentProps<THighlightCustomData>) {
    const {
        viewerRef,
        webViewerInstance,
        documentInfo,
        headings,
        getDocument,
        onDocumentLoad,
        fileSource,
        docFileInfo,
        highlightsProps,
        syncingProps,
        isCreatingHighlightEnabled,
        displayHighlightNavigationIcons: displayHighlightNavigationIconsProps,
        onFinishedRendering,
        scrollContainerRef,
    } = props;
    const {
        highlights,
        selectedHighlightIds,
        setSelectedHighlightIds,
        selectedHighlightChangedEventName,
        highlightLeftTopInformation,
        highlightToolbars,
        clearSelectedHighlight,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw,
        keepSelectedHighlightInCentre,
        highlightColor,
        highlightMultiSelectEnabled,
        mergeHighlights,
        bulkHighlightsActions,
        onAnnotationsLoaded,
        onAnnotationsRedraw,
    } = highlightsProps;

    const emotionCache = createCache({
        key: 'pdfviewer',
        container: webViewerInstance?.UI.iframeWindow.document.head,
        prepend: true,
    });

    const [searchPopupOpen, _setSearchPopupOpen] = useState<boolean>(false);
    const setSearchPopupOpen = useCallback(() => _setSearchPopupOpen((prev) => !prev), []);

    const [currentToolMode, setCurrentToolMode] = useState<AvailableToolMode>('TextSelect');
    const setToolMode = useCallback(
        (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => {
            if (webViewerInstance) {
                webViewerInstance.UI.setToolMode(viewerMode);
                setCurrentToolMode(mode);
            }
        },
        [webViewerInstance]
    );
    useEffect(() => {
        if (webViewerInstance && currentToolMode !== 'TextSelect' && !isCreatingHighlightEnabled)
            setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
    }, [webViewerInstance, currentToolMode, setToolMode, isCreatingHighlightEnabled]);

    const [annotationElementsEnabled, setAnnotationElementsEnabled] = useState<boolean>(true);

    const { documentLoading } = useDocument(webViewerInstance, fileSource, documentInfo, getDocument, docFileInfo, onDocumentLoad, onFinishedRendering);
    const {
        viewerAnnotationsUpdated,
        addPart,
        splittedHighlightId,
        splitAnnotation,
        cancelSplitAnnotation,
        highlightNavigationUtilsProps,
        mergingProps,
        bulkActionsProps,
    } = useAnnotations<THighlightCustomData>(
        webViewerInstance,
        documentLoading,
        highlights,
        selectedHighlightIds,
        setSelectedHighlightIds,
        highlightLeftTopInformation,
        clearSelectedHighlight,
        setToolMode,
        emotionCache,
        highlightColor,
        selectedHighlightChangedEventName,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw,
        keepSelectedHighlightInCentre,
        highlightMultiSelectEnabled,
        mergeHighlights,
        bulkHighlightsActions,
        onAnnotationsLoaded,
        onAnnotationsRedraw,
        annotationElementsEnabled
    );

    useToolbars(
        webViewerInstance,
        highlightToolbars,
        selectedHighlightIds,
        emotionCache,
        viewerAnnotationsUpdated,
        addPart,
        splittedHighlightId,
        splitAnnotation,
        cancelSplitAnnotation
    );

    const { currentHeading, jumpToHeading } = useHeadings(webViewerInstance, documentLoading, headings);

    const displayHighlightNavigationIcons = useMemo(
        () => (displayHighlightNavigationIconsProps === undefined ? true : displayHighlightNavigationIconsProps),
        [displayHighlightNavigationIconsProps]
    );

    useEffect(() => {
        if (webViewerInstance && scrollContainerRef) {
            scrollContainerRef.current = webViewerInstance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0];
        }
        // eslint-disable-next-line
    }, [webViewerInstance]);

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
                border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                borderRadius: '0.25rem',
            }}
        >
            {displayHighlightNavigationIcons && <HighlightNavigationComponent {...highlightNavigationUtilsProps} />}
            {headings && headings.length > 1 && currentHeading && (
                <HeadingsListComponent headings={headings} currentHeading={currentHeading} jumpToHeading={jumpToHeading} />
            )}
            {selectedHighlightIds.length > 1 && (
                <MultiHighlightsToolbarComponent
                    selectedHighlightIds={selectedHighlightIds}
                    mergeHighlightsProps={mergingProps}
                    bulkActionsProps={bulkActionsProps}
                />
            )}
            <ToolbarComponent
                webViewerInstance={webViewerInstance}
                documentLoading={documentLoading}
                title={props.title}
                currentToolMode={currentToolMode}
                setToolMode={setToolMode}
                openSearchPopup={() => setSearchPopupOpen()}
                closeIconOnClick={props.closeIconOnClick}
                displayHighlightIcons={props.displayHighlightIcons}
                isCreatingHighlightEnabled={isCreatingHighlightEnabled}
                syncingProps={syncingProps}
                displayHighlightNavigationIcons={displayHighlightNavigationIcons}
                highlightNavigationUtilsProps={highlightNavigationUtilsProps}
                annotationElementsEnabled={annotationElementsEnabled}
                setAnnotationElementsEnabled={setAnnotationElementsEnabled}
            />
            {searchPopupOpen && webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && (
                <SearchPopupComponent webViewerInstance={webViewerInstance} closePopup={() => setSearchPopupOpen()} />
            )}
            <Box
                sx={{
                    height: `calc(100% - ${ToolbarHeight})`,
                }}
            >
                <div style={{ height: '100%' }} ref={viewerRef}></div>
            </Box>
        </Box>
    );
}

export default PdfViewerWithToolbarComponent;
