type UrlParamType = {
    paramName: string;
    specificParam: (param: string) => string;
};

type UrlParamsType = {
    annotationId: UrlParamType;
    documentId: UrlParamType;
    supportingInformationId: UrlParamType;
};

const UrlParams: UrlParamsType = {
    annotationId: {
        paramName: 'annotationId',
        specificParam: (annotationId: string) => `annotationId=${annotationId}`,
    },
    documentId: {
        paramName: 'documentId',
        specificParam: (documentId: string) => `documentId=${documentId}`,
    },
    supportingInformationId: {
        paramName: 'supportingInformationId',
        specificParam: (supportingInformationId: string) => `supportingInformationId=${supportingInformationId}`,
    },
};

export default UrlParams