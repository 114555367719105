import { AuthUser } from 'ApiClients/Auth.ApiClient';
import { UserRole } from 'ApiClients/SterlingApiClients/Types';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';
import { AuthContextProviderMethods, AuthContextProviderValue } from 'Contexts/Auth.Context';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useAuth = (usersApiClientRef: React.MutableRefObject<UsersApiClient | null>): AuthContextProviderValue & AuthContextProviderMethods => {
    // TODO: Review data in AuthUser vs ApplicationUser
    const [authContextProviderValue, setAuthContextProviderValue] = useState<AuthContextProviderValue>({
        isAuthenticated: false,
        user: {
            id: '',
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            roles: [],
        },
    });

    const setAuth = useCallback(
        (user: AuthUser, isAuthenticated: boolean) => setAuthContextProviderValue((prev) => ({ user: { ...prev.user, ...user }, isAuthenticated })),
        [setAuthContextProviderValue]
    );

    useEffect(() => {
        const usersApiClient = usersApiClientRef.current;
        if (usersApiClient && authContextProviderValue.isAuthenticated) {
            usersApiClient.getMyApplicationUserInstance().then((user) => {
                setAuthContextProviderValue((prev) => ({ ...prev, user: { ...prev.user, firstName: user.firstName, lastName: user.lastName } }));
            });
        }
    }, [usersApiClientRef, authContextProviderValue.isAuthenticated]);

    const emailDomain: string = useMemo(() => 'verifi.legal', []);
    const userEmail: string = useMemo(() => {
        const userName: string = authContextProviderValue.user.name;
        return userName.length > 0 ? `${userName.split(' ')[0][0].toLowerCase()}${userName.split(' ')[1].toLowerCase()}@${emailDomain}` : '';
    }, [authContextProviderValue.user.name, emailDomain]);

    return {
        ...authContextProviderValue,
        user: {
            ...authContextProviderValue.user,
            email: userEmail,
        },
        checkRole: (role: UserRole) => authContextProviderValue.user.roles.includes(role),
        setAuth,
    };
};

export default useAuth;
