export type Content = {
    boundingBoxSections: Array<BoundingBoxSection>;
    selectionType: SelectionType;
};

export enum SelectionType {
    Text = 0,
    Shape = 1,
}

export type BoundingBoxSection = {
    pageNumber: number;
    pageSize: PageSize;
    boundingBoxes: Array<BoundingBox>;
};

type PageSize = {
    width: number;
    height: number;
};

export type BoundingBox = {
    topLeft: Coordinates;
    downRight: Coordinates;
};

type Coordinates = {
    x: number;
    y: number;
};

export type FileInfo = {
    id: string;
    file: File;
};
