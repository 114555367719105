import HomePage from './Routes/HomePage';
import LoginRedirect from './Routes/LoginRedirect';
import Project from './Routes/Project/Project';
import ProjectInstance from './Routes/ProjectInstance/ProjectInstance';

class AppRoutes {
    public homePage = new HomePage();
    public project = new Project();
    public projectInstance = new ProjectInstance();
    public loginRedirect = new LoginRedirect();
}

const _AppRoutes = new AppRoutes();

export default _AppRoutes;

export { ActiveProjectVersionId } from './Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance'