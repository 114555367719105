import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { Keyword } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import { Button, ChipsBox, Dialog, Typography } from 'UI';
import { ChipBoxElement } from 'UI/ChipsBox/ChipBox.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { AnnotationKeywordsProps } from 'Views/Project/ProjectWorkspace/Hooks/Module';

export type KeywordsModalComponentProps = AnnotationKeywordsProps & {
    isOpen: boolean;
    closeModal: () => void;
    keywords: Array<Keyword>;
    refetchKeywords: (flowMethods?: FlowMethods) => void;
    fetching: boolean;
    annotationId: string;
    annotationStatement: string;
    getSupportingInformationPropositions: (annotationId: string) => void;
};

function KeywordsModalComponent(props: KeywordsModalComponentProps) {
    const { isOpen, closeModal, keywords, refetchKeywords, fetching, annotationId, annotationStatement, getSupportingInformationPropositions } = props;

    const options = useMemo(() => [...new Set(annotationStatement.split(' ').filter((option) => option.length > 2))], [annotationStatement]);

    const [keyword, setKeyword] = useState<string>('');
    const { wrappedMethod: addKeyword, fetching: adding } = usePromiseWithFlowMethods<{ annotationId: string; keyword: string }, {}>({
        method: (input, flowMethods) =>
            props.addKeyword(input.annotationId, input.keyword, {
                ...flowMethods,
                onSuccess: () => {
                    refetchKeywords();
                    setKeyword('');
                },
            }),
        initialData: {},
    });

    const [removingKeywords, setRemovingKeywords] = useStateById<boolean>();
    const { wrappedMethod: removeKeyword } = usePromiseWithFlowMethods<{ annotationId: string; keywordId: string }, {}>({
        method: (input, flowMethods) =>
            props.removeKeyword(input.annotationId, input.keywordId, {
                ...flowMethods,
                onInit: () => setRemovingKeywords.Value(input.keywordId, true),
                onSuccess: () =>
                    refetchKeywords({
                        onSuccess: () => setRemovingKeywords.Value(input.keywordId, false),
                    }),
            }),
        initialData: {},
    });

    const { wrappedMethod: removeAllKeywords, fetching: removingAll } = usePromiseWithFlowMethods<{ annotationId: string }, {}>({
        method: (input, flowMethods) =>
            props.removeAllKeywords(input.annotationId, {
                ...flowMethods,
                onSuccess: refetchKeywords,
            }),
        initialData: {},
    });

    const handleSubmitKeywords = (event: React.FormEvent) => {
        event.preventDefault();
        getSupportingInformationPropositions(annotationId);
        closeModal();
    };

    const chips: Array<ChipBoxElement<{ id: string }>> = keywords.map((t) => ({
        color: removingKeywords[t.id] ? 'default' : 'info',
        hasDelete: removingKeywords[t.id] ? false : true,
        label: t.content,
        id: t.id,
    }));

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            sx={{
                '& .MuiPaper-root': {
                    width: '31.25rem',
                },
            }}
        >
            <Dialog.Title>Keyword search</Dialog.Title>
            <Dialog.Content>
                <Box component='form' onSubmit={handleSubmitKeywords} data-cy='annotation-keywords-form' sx={{ minHeight: '15rem' }}>
                    <Box>
                        <Typography sx={{ marginBottom: '0.5rem' }}>Assigned to</Typography>
                        <Box sx={{ display: 'flex', marginBottom: '2rem' }}>
                            <Autocomplete
                                inputValue={keyword}
                                onInputChange={(_e, value) => setKeyword(value)}
                                freeSolo
                                options={options}
                                renderInput={(params) => <TextField {...params} placeholder='Keyword' />}
                                sx={{
                                    width: '15rem',
                                    '& .MuiAutocomplete-input': {
                                        height: '0.5rem',
                                        fontSize: '0.75rem',
                                        fontWeight: 700,
                                        '::placeholder': {
                                            fontWeight: 400,
                                            fontSize: '0.9375rem',
                                            lineHeight: '1.25rem',
                                        },
                                    },
                                }}
                            />
                            <Button
                                sx={{
                                    height: '2.5rem',
                                    width: '10rem',
                                    paddingLeft: '0.75rem',
                                    paddingRight: '0.75rem',
                                    marginRight: '0rem',
                                    marginLeft: 'auto',
                                }}
                                onClick={() => addKeyword({ annotationId, keyword: keyword! })}
                                disabled={!(keyword && keyword.length > 0) || fetching}
                                isLoading={adding}
                                data-cy='add-keyword-button'
                            >
                                ADD KEYWORD
                            </Button>
                        </Box>
                        <Box sx={{ minHeight: '7rem', marginBottom: '2rem' }}>
                            <Box sx={{ marginBottom: '0.25rem' }}>
                                <ChipsBox<{ id: string }>
                                    chips={chips}
                                    label={
                                        <Box sx={{ marginBottom: '0.5rem', alignItems: 'center', display: 'flex', width: '100%' }}>
                                            <Typography variant='subtitle2' data-cy='keywords-container-title'>
                                                Keywords assigned to search
                                            </Typography>
                                            {fetching && keywords.length > 0 && <CircularProgress size={16} sx={{ marginLeft: '0.5rem' }} />}
                                            {keywords.length > 0 && (
                                                <>
                                                    {removingAll ? (
                                                        <CircularProgress size={16} sx={{ marginLeft: 'auto', marginRight: '0.5rem' }} />
                                                    ) : (
                                                        <Typography
                                                            variant='link14'
                                                            sx={{
                                                                marginLeft: 'auto',
                                                                marginRight: '0.5rem',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                if (!removingAll) removeAllKeywords({ annotationId });
                                                            }}
                                                            data-cy='clear-all-keywords'
                                                        >
                                                            Clear all keywords
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    }
                                    emptyLabel='No keywords assigned to search'
                                    onChipDelete={(chip) => !removingAll && removeKeyword({ annotationId, keywordId: chip.id })}
                                    chipProps={{
                                        sx: {
                                            minWidth: 'initial',
                                        },
                                    }}
                                    loading={fetching && keywords.length === 0}
                                />
                            </Box>
                        </Box>
                        <Dialog.Actions>
                            <Button
                                color='secondary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='button'
                                onClick={closeModal}
                                data-cy='close-keywords-modal'
                            >
                                Close
                            </Button>
                            <Button
                                data-cy='save-keywords'
                                color='primary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='submit'
                            >
                                Search
                            </Button>
                        </Dialog.Actions>
                    </Box>
                </Box>
            </Dialog.Content>
        </Dialog>
    );
}

export default KeywordsModalComponent;
