import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Drawer, IconButton } from '@mui/material';
import { orderBy } from 'lodash';
import { ChatRight, Close } from 'UI/Icon';
import { CommentReason } from 'ApiClients/SterlingApiClients/Types';
import Comment from './Comment/Comment.Component';
import Respond from './Respond/Respond.Component.';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';
import { AnnotationCommentsContext } from '../../Hooks/Module';
import CommentTextButton from './Common/CommentTextButton/CommentTextButton.Component';

export type CommentDrawerState = {
    isOpen: boolean;
    annotationId: string | null;
};

type CommentDrawerProps = {
    closeCommentDrawer: () => void;
    commentSelected: boolean;
    commentDrawerState: CommentDrawerState;
    annotationCommentsContext: AnnotationCommentsContext;
    getComments: () => void;
    addComment: (annotationId: string, comment: string) => void;
    resolveComment: (annotationId: string, commentId: string) => void;
    deleteComment: (annotationId: string, commentId: string) => void;
};

function CommentDrawer(props: CommentDrawerProps) {
    const { closeCommentDrawer, getComments, addComment, resolveComment, deleteComment, commentSelected, annotationCommentsContext } = props;
    const { isOpen, annotationId } = props.commentDrawerState;
    const commentsRef = useRef<HTMLDivElement | null>(null);

    const [isReplyClicked, setIsReplyClicked] = useState(false);
    const { projectMember, isEditor } = useContext(ProjectAuthContext);

    const scrollToBottom = () => {
        const obj = commentsRef.current;
        if (obj) {
            obj.scroll({ top: obj.scrollHeight, behavior: 'smooth' });
        }
    };

    const handleReplyClick = () => {
        if (isReplyClicked) scrollToBottom();
        setIsReplyClicked(true);
    };

    const handleCancelClick = () => {
        setIsReplyClicked(false);
    };

    const handleDrawerClose = () => {
        closeCommentDrawer();
        handleCancelClick();
    };

    const handleSubmitClick = useCallback(
        (comment: string) => {
            addComment(annotationId ?? '', comment);
            handleCancelClick();
        },
        [annotationId, addComment]
    );

    const sortedComments = useMemo(() => {
        return orderBy(annotationCommentsContext?.comments, ['creationDate'], ['asc']);
    }, [annotationCommentsContext?.comments]);

    useEffect(() => {
        handleCancelClick();
        if (annotationId) {
            getComments();
        }

        //eslint-disable-next-line
    }, [annotationId]);

    return (
        <Drawer
            id='comment-drawer-x'
            open={isOpen}
            variant='persistent'
            anchor='right'
            onClose={handleDrawerClose}
            hideBackdrop
            ModalProps={{ keepMounted: true }}
            PaperProps={{
                sx: {
                    marginTop: '5rem',
                    height: 'auto',
                    maxHeight: '70%',
                    width: 'auto',
                    border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                    borderTopLeftRadius: '2rem',
                    borderBottomLeftRadius: '2rem',
                    boxShadow: '-10px 10px 10px 0px #3f444466',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.white.main,
                    padding: '0.5rem 1rem',
                    borderBottom: (theme) => `1px solid ${theme.palette.stroke.main}`,
                    zIndex: 10,
                }}
            >
                {annotationCommentsContext?.isFetching && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '1rem',
                        }}
                    >
                        <CircularProgress size={20} />
                    </Box>
                )}
                <IconButton
                    sx={{ marginLeft: 'auto', marginRight: '0rem' }}
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        closeCommentDrawer();
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            {sortedComments.length > 0 && annotationId ? (
                <Box
                    ref={commentsRef}
                    sx={{
                        overflowY: 'scroll',
                        padding: '1rem 1.5rem',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {sortedComments.map((comment, index) => {
                            const isLoggedUser = projectMember?.id === comment.author.id;
                            const enableTrash = isLoggedUser && sortedComments.length - 1 === index;
                            const flaggedComment = comment.reason === CommentReason.SettingNeedsReview && !comment.isResolved;

                            return (
                                <Box key={comment.id}>
                                    <Comment
                                        username={comment.author.fullName}
                                        creationDate={comment.creationDate}
                                        onDelete={() => deleteComment(annotationId, comment.id)}
                                        reason={comment.reason}
                                        enableTrash={enableTrash}
                                        enableFlag={flaggedComment}
                                        commentSelected={commentSelected && flaggedComment}
                                    >
                                        {comment.content}
                                    </Comment>
                                    {flaggedComment && (
                                        <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='0.5rem'>
                                            {isEditor ? (
                                                <CommentTextButton
                                                    onClick={() => {
                                                        resolveComment(annotationId, comment.id);
                                                        handleDrawerClose();
                                                    }}
                                                    sx={{ color: (theme) => theme.palette.secondary.dark }}
                                                >
                                                    Mark as resolved
                                                </CommentTextButton>
                                            ) : (
                                                <Box />
                                            )}
                                            <CommentTextButton onClick={handleReplyClick} sx={{ color: (theme) => theme.palette.icons.primary }}>
                                                Reply
                                            </CommentTextButton>
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    {isReplyClicked && (
                        <Respond
                            handleCancelClick={handleCancelClick}
                            handleSubmitClick={handleSubmitClick}
                            author={projectMember?.fullName || ''}
                            onMount={scrollToBottom}
                        />
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 3,
                        width: '28.5rem',
                        padding: '1rem',
                    }}
                >
                    <ChatRight color={palette.stroke.main} width={50} height={50} />
                    <Typography variant='body1'>No Comments</Typography>
                </Box>
            )}
        </Drawer>
    );
}

export default CommentDrawer;
