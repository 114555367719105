import { configureStore } from '@reduxjs/toolkit';
import { reducer as toastsReducer } from './Slices/ToastsSlice';

const store = configureStore({
    reducer: {
        toasts: toastsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
