import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { useState } from 'react';
import { ProjectNameMaxLength } from '../Types';
import { Button, Dialog, TextField } from 'UI';
import { Box } from '@mui/material';

export type ProjectNameModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    projectId: string;
    projectName: string;
    changeProjectName: (projectId: string, projectName: string, flowMethods: FlowMethods) => void;
};

function ProjectNameModalComponent(props: ProjectNameModalComponentProps) {
    const { isOpen, closeModal, projectId, projectName } = props;

    const { wrappedMethod: changeProjectName, fetching: changing } = usePromiseWithFlowMethods<{ projectId: string; projectName: string }, {}>({
        method: (input, flowMethods) =>
            props.changeProjectName(input.projectId, input.projectName, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    closeModal();
                },
            }),
        initialData: {},
    });
    const [newProjectName, setNewProjectName] = useState<string>(projectName);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (newProjectName.trim().length !== 0) changeProjectName({ projectId, projectName: newProjectName });
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Project Name</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                    >
                        <TextField
                            label='Project Name'
                            placeholder='Enter Project Name'
                            value={newProjectName}
                            onChange={(e) => {
                                if (e.target.value.length <= ProjectNameMaxLength) setNewProjectName(e.target.value);
                            }}
                        />
                    </Box>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-project-name'
                        >
                            Close
                        </Button>
                        <Button
                            data-cy='change-project-name'
                            isLoading={changing}
                            disabled={newProjectName.trim().length === 0}
                            color='primary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Save
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default ProjectNameModalComponent;
