import React, { ReactNode } from 'react';

export const FullscreenModeContext = React.createContext<FullscreenModeContextProviderValue & FullscreenModeContextProviderMethods>({
    isFullscreen: false,
    toggleFullscreen: () => {},
});

type FullscreenModeContextProviderProps = {
    fullscreenModeContextProviderValue: FullscreenModeContextProviderValue & FullscreenModeContextProviderMethods;
    children?: ReactNode;
};

export type FullscreenModeContextProviderValue = {
    isFullscreen: boolean;
};

export type FullscreenModeContextProviderMethods = {
    toggleFullscreen: () => void;
};

export function FullscreenModeContextProvider(props: FullscreenModeContextProviderProps) {
    return <FullscreenModeContext.Provider value={props.fullscreenModeContextProviderValue}>{props.children}</FullscreenModeContext.Provider>;
}
