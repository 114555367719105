import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast, ToastWithId } from 'Hooks/useToasts';
import { RootState } from 'Redux/Store';
import { v4 as UuidNew } from 'uuid';

type ToastsState = Array<ToastWithId>;

const toastsSlice = createSlice<
    ToastsState,
    { addToast: CaseReducer<ToastsState, PayloadAction<Toast>>; removeToast: CaseReducer<ToastsState, PayloadAction<string>> },
    string
>({
    name: 'toasts',
    initialState: [],
    reducers: {
        addToast: (state: ToastsState, action: PayloadAction<Toast>) => [{ id: UuidNew(), ...action.payload }, ...state],
        removeToast: (state: ToastsState, action: PayloadAction<string>) => [...state.filter((t) => t.id !== action.payload)],
    },
});

export const actions = toastsSlice.actions;

export const stateSelector = (state: RootState) => state.toasts;

export const reducer = toastsSlice.reducer;
