import { useNavigate } from 'react-router-dom';
import ProjectsApiClient from '../../ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import HomePageComponent from './HomePage.Component';
import { AppRoutes } from 'App/RoutesPaths';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { User, ProjectMemberRole, ProjectMemberExtended } from 'ApiClients/SterlingApiClients/Types';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import {
    ProjectTabRouteDashboard,
    ProjectTabRouteWorkspace,
} from 'App/RoutesPaths/Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance';

type HomePageModuleProps = {
    navBarContents: NavBarContents;
    projectsApiClient: ProjectsApiClient;
    usersApiClient: UsersApiClient;
};

function HomePageModule(props: HomePageModuleProps) {
    const { projectsApiClient: projectsApi, usersApiClient: usersApi, navBarContents } = props;

    const { data: projects, refetch: refetchProjects, isFetching } = projectsApi.getProjectsUseQuery();

    const deleteProject = (id: string, flowMethods: FlowMethods) =>
        projectsApi.deleteProject(id, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess?.(data);
                refetchProjects();
            },
        });

    const navigate = useNavigate();
    const goToProjectWorkspace = (projectId: string, projectVersionId: string) =>
        navigate(AppRoutes.projectInstance.projectVersionInstance.specificPath({ projectId, versionId: projectVersionId, tab: ProjectTabRouteWorkspace }));
    const goToProjectDashboard = (projectId: string, projectVersionId: string) =>
        navigate(AppRoutes.projectInstance.projectVersionInstance.specificPath({ projectId, versionId: projectVersionId, tab: ProjectTabRouteDashboard }));
    const goToProjectCreator = () => navigate(AppRoutes.project.creator.specificPath({}));

    const getUsers = (flowMethods: FlowMethods<Array<User>>) => usersApi.getUsers(flowMethods);

    const getProjectMembers = (projectId: string, flowMethods?: FlowMethods<Array<ProjectMemberExtended>>) => projectsApi.getMembers(projectId, flowMethods);
    const addMemberToProject = (projectId: string, userId: string, role: ProjectMemberRole, flowMethods?: FlowMethods) =>
        projectsApi.addMember(projectId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess && flowMethods.onSuccess(data);
                refetchProjects();
            },
            body: {
                userId,
                role,
            },
        });
    const changeMemberProjectRole = (projectId: string, memberId: string, role: ProjectMemberRole, flowMethods?: FlowMethods) =>
        projectsApi.changeMemberRole(projectId, memberId, { ...flowMethods, body: { role } });
    const removeMemberFromProject = (projectId: string, memberId: string, flowMethods?: FlowMethods) =>
        projectsApi.removeMember(projectId, memberId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess && flowMethods.onSuccess(data);
                refetchProjects();
            },
        });

    const changeProjectName = (projectId: string, projectName: string, flowMethods?: FlowMethods) =>
        projectsApi.changeProjectName(projectId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess && flowMethods.onSuccess(data);
                refetchProjects();
            },
            body: { name: projectName },
        });

    return (
        <HomePageComponent
            projects={projects || []}
            isFetching={isFetching}
            navBarContents={navBarContents}
            deleteProject={deleteProject}
            goToProjectWorkspace={goToProjectWorkspace}
            goToProjectDashboard={goToProjectDashboard}
            goToProjectCreator={goToProjectCreator}
            usersProps={{
                getUsers,
            }}
            membersProps={{
                getProjectMembers,
                addMemberToProject,
                changeMemberProjectRole,
                removeMemberFromProject,
            }}
            changeProjectName={changeProjectName}
        />
    );
}

export default HomePageModule;
