import { Core, WebViewerInstance } from '@pdftron/webviewer';
import useInit from './Annotations/useInit';
import { AdditionalHtmlElement, Highlight, HighlightColor } from '../PdfViewerWithToolbar.Types';
import { HighlightLeftTopInformation } from '../HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { AvailableToolMode } from '../PdfViewerWithToolbar.Component';
import { EmotionCache } from '@emotion/react';
import useDrawing from './Annotations/useDrawing';
import useAnnotationsSelecting, { SupportStateHighlight } from './Annotations/useAnnotationsSelecting';
import useAnnotationCreating from './Annotations/useAnnotationCreating';
import { useRef } from 'react';
import useAnnotationParts from './Annotations/useAnnotationParts';
import useAnnotationSpliting from './Annotations/useAnnotationSpliting';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import useHighlightNavigation from './Annotations/useHighlightNavigation';
import useAnnotationBulkActions, { BulkActionInput } from './Annotations/useAnnotationBulkActions';
import useAnnotationMerging from './Annotations/useAnnotationMerging';

const useAnnotations = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightIds: Array<string>,
    setSelectedHighlightIds: (ids: Array<string>) => void,
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    clearSelectedHighlight: () => void,
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void,
    emotionCache: EmotionCache,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined,
    selectedHighlightChangedEventName: string,
    additionalHtmlElements: Array<AdditionalHtmlElement<THighlightCustomData>> | undefined,
    focusOnSelectedHighlightAfterDraw: boolean | undefined,
    keepSelectedHighlightInCentre: boolean | undefined,
    highlightMultiSelectEnabled: boolean | undefined,
    mergeHighlights: ((ids: Array<string>, flowMethods: FlowMethods) => void) | undefined,
    bulkHighlightsActions: Array<BulkActionInput> | undefined,
    onAnnotationsLoaded: (() => void) | undefined,
    onAnnotationsRedraw: (() => void) | undefined,
    annotationElementsEnabled: boolean
) => {
    const selectAnnotationsRef = useRef<(highlights: Array<SupportStateHighlight>) => void>(() => {});
    const deselectAllAnnotationsRef = useRef<() => void>(() => {});
    const redrawHighlightRef = useRef<(id: string) => void>((_id: string) => {});

    const { multiSelectNext, setMultiSelectNext, addPart } = useAnnotationParts(webViewerInstance, setToolMode, deselectAllAnnotationsRef);

    const { newHighlightId, setNewHighlightId, registerAnnotationCreatingListener } = useAnnotationCreating(
        webViewerInstance,
        selectAnnotationsRef,
        selectedHighlightIds,
        multiSelectNext,
        emotionCache,
        annotationElementsEnabled
    );

    const { splittedHighlightId, setSplittedHighlightId, splitAnnotation, cancelSplitAnnotation } = useAnnotationSpliting(
        webViewerInstance,
        redrawHighlightRef,
        selectAnnotationsRef
    );

    const {
        registerAnnotationSelectedViewerListener,
        registerAnnotationSelectedAppListener,
        viewerAnnotationsUpdated,
        selectAnnotations,
        deselectAllAnnotations,
    } = useAnnotationsSelecting(
        webViewerInstance,
        documentLoading,
        selectedHighlightIds,
        setSelectedHighlightIds,
        selectedHighlightChangedEventName,
        clearSelectedHighlight,
        newHighlightId,
        setNewHighlightId,
        multiSelectNext,
        setMultiSelectNext,
        splittedHighlightId,
        setSplittedHighlightId,
        highlightMultiSelectEnabled
    );
    selectAnnotationsRef.current = selectAnnotations;
    deselectAllAnnotationsRef.current = deselectAllAnnotations;

    useInit(
        webViewerInstance,
        registerAnnotationSelectedViewerListener,
        registerAnnotationSelectedAppListener,
        registerAnnotationCreatingListener,
        keepSelectedHighlightInCentre,
        onAnnotationsLoaded
    );
    const { redrawHighlight } = useDrawing(
        webViewerInstance,
        documentLoading,
        highlights,
        selectedHighlightIds,
        highlightLeftTopInformation,
        emotionCache,
        highlightColor,
        selectAnnotations,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw,
        onAnnotationsRedraw,
        annotationElementsEnabled
    );

    redrawHighlightRef.current = redrawHighlight;

    const mergingProps = useAnnotationMerging(mergeHighlights);
    const bulkActionsProps = useAnnotationBulkActions(bulkHighlightsActions);

    const highlightNavigationUtilsProps = useHighlightNavigation(webViewerInstance, highlights, selectAnnotations);

    return {
        viewerAnnotationsUpdated,
        addPart,
        splittedHighlightId,
        splitAnnotation,
        cancelSplitAnnotation,
        mergingProps,
        highlightNavigationUtilsProps,
        bulkActionsProps,
    };
};

export default useAnnotations;
