import SterlingApiClient, { PostRequestOptions } from '../../Sterling.ApiClient';
import { ConvertDocxToPdfBody } from './Files.ApiClient.Types';

export default class FilesApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = () => `files`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    convertDocxToPdf = (fileName: string, requestOptions: PostRequestOptions<ConvertDocxToPdfBody, File>) =>
        this._sterlingApiClient.getFileAsyncWithPost<ConvertDocxToPdfBody>(
            `${this._rootRoute()}/convertDocxToPdf`,
            ChangeFileExtension(fileName, 'docx', 'pdf'),
            requestOptions
        );
}

const ChangeFileExtension = (fileName: string, extension: string, newExtension: string) => {
    const indexOfExtension = fileName.lastIndexOf(`.${extension}`);
    const _fileName = fileName.substring(0, indexOfExtension);
    return `${_fileName}.${newExtension}`;
};
