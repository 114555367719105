import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import ProjectVersionsApiClient from 'ApiClients/SterlingApiClients/ProjectVersionsApiClient/ProjectVersions.ApiClient';
import { ProjectVersionForm } from '../NewVersionForm.Component';
import { useCallback, useEffect, useState } from 'react';
import { ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';

const useProjectVersion = (
    projectDocumentsApiClient: ProjectDocumentsApiClient,
    projectVersionsApiClient: ProjectVersionsApiClient,
    projectId: string,
    versionId: string,
    disableFetch: boolean
): { version: ProjectVersionForm | null; setDocumentIsProcessed: () => void } => {
    const [version, setVersion] = useState<ProjectVersionForm | null>(null);

    const setDocumentIsProcessed = useCallback(() => {
        setVersion((v) => {
            if (v) {
                return {
                    ...v,
                    documentIsProcessed: true,
                };
            }
            return v;
        });
    }, [setVersion]);

    useEffect(() => {
        if (!disableFetch) {
            projectVersionsApiClient.getVersion(projectId, versionId).then((v) => {
                projectDocumentsApiClient.getDocument(projectId, versionId, v.verificationDocument.fileId, v.verificationDocument.name).then((d) => {
                    if (d) {
                        setVersion({
                            id: versionId,
                            document: {
                                id: d.id,
                                name: v.verificationDocument.originalName || v.verificationDocument.name,
                                fileId: v.verificationDocument.fileId,
                                file: d.file,
                            },
                            documentIsProcessed: v.verificationDocument.processingStatus === ProjectDocumentProcessingStatus.Processed,
                        });
                    }
                });
            });
        }
    }, [projectDocumentsApiClient, projectVersionsApiClient, projectId, versionId, disableFetch]);

    return {
        version,
        setDocumentIsProcessed,
    };
};

export default useProjectVersion;
