import { FormControl, Grid } from '@mui/material';
import { ProjectDocumentType, TransactionType } from 'ApiClients/SterlingApiClients/Types';
import { Select, TextField, Typography } from 'UI';
import Menu from 'UI/Menu';
import DocumentUploaderComponent from 'Views/Project/Common/DocumentUploader/DocumentUploader.Component';
import { useMemo } from 'react';

type ProjectCreatorComponentProps = {
    projectName: string;
    setProjectName: React.Dispatch<React.SetStateAction<string>>;
    documentType: ProjectDocumentType;
    setDocumentType: (value: ProjectDocumentType) => void;
    file: File | null;
    setFile: React.Dispatch<React.SetStateAction<File | null>>;
    projectType: TransactionType;
    setProjectType: (value: TransactionType) => void;
};

type TransactionTypeOptionsType = { [key in TransactionType]: TransactionTypeOption };

type TransactionTypeOption = {
    label: string;
    documentTypes: Array<ProjectDocumentType>;
    defaultDocType: ProjectDocumentType;
};

type ProjectDocumentTypeDictionaryType = { [key in ProjectDocumentType]: ProjectDocumentTypeInfo };

type ProjectDocumentTypeInfo = {
    label: string;
};

export const ProjectDocumentTypeDictionary: ProjectDocumentTypeDictionaryType = {
    [ProjectDocumentType.RnsAnnouncement]: { label: 'RNS announcement' },
    [ProjectDocumentType.OfferDocument]: { label: 'Offer document' },
    [ProjectDocumentType.SchemeDocument]: { label: 'Scheme document' },
    [ProjectDocumentType.ResponseDocument]: { label: 'Response document' },
    [ProjectDocumentType.Circular]: { label: 'Circular' },
    [ProjectDocumentType.Presentation]: { label: 'Presentation' },
    [ProjectDocumentType.PressRelease]: { label: 'Press Release' },
    [ProjectDocumentType.Prospectus]: { label: 'Prospectus' },
    [ProjectDocumentType.OfferingMemorandum]: { label: 'Offering Memorandum' },
    [ProjectDocumentType.Other]: { label: 'Other' },
};

export const TransactionTypeOptions: TransactionTypeOptionsType = {
    [TransactionType.TakeOver]: {
        label: 'Takeover',
        documentTypes: [
            ProjectDocumentType.RnsAnnouncement,
            ProjectDocumentType.OfferDocument,
            ProjectDocumentType.SchemeDocument,
            ProjectDocumentType.Circular,
        ],
        defaultDocType: ProjectDocumentType.RnsAnnouncement,
    },
    [TransactionType.Other]: {
        label: 'Other',
        documentTypes: [ProjectDocumentType.Other],
        defaultDocType: ProjectDocumentType.Other,
    },
    [TransactionType.PublicOffering]: {
        label: 'Public Offering',
        documentTypes: [ProjectDocumentType.Prospectus, ProjectDocumentType.Other],
        defaultDocType: ProjectDocumentType.Prospectus,
    },
    [TransactionType.PrivateOffering]: {
        label: 'Private Offering',
        documentTypes: [ProjectDocumentType.OfferingMemorandum, ProjectDocumentType.Other],
        defaultDocType: ProjectDocumentType.OfferingMemorandum,
    },
};

const commonStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
};

type InputFieldProps = {
    label: string;
    description: string;
    value: string;
    placeholder: string;
    onChange: (value: string) => void;
};

function InputField({ label, description, value, placeholder, onChange }: InputFieldProps) {
    return (
        <>
            <Grid item xs={4} pr='4rem' data-testid={`input-label-${label}`}>
                <Typography variant='subtitle1'>{label}</Typography>
                <Typography variant='body2'>{description}</Typography>
            </Grid>
            <Grid item xs={8} sx={commonStyles} data-testid={`input-container-${label}`}>
                <TextField fullWidth value={value} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
            </Grid>
        </>
    );
}

type DropdownFieldProps = {
    label: string;
    description: string;
    value: number;
    options: { label: string; value: number }[];
    onChange: (value: number) => void;
};

function DropdownField({ label, description, value, options, onChange }: DropdownFieldProps) {
    return (
        <Row
            label={label}
            description={description}
            component={
                <FormControl fullWidth variant='outlined'>
                    <Select value={value || 0} onChange={(event) => onChange(event.target.value as number)} data-testid={`dropdown-container-${label}`}>
                        {options.map((option, index) => (
                            <Menu.Item key={index} value={option.value || 0}>
                                {option.label}
                            </Menu.Item>
                        ))}
                    </Select>
                </FormControl>
            }
        />
    );
}

type UploaderProps = {
    label: string;
    description: string;
    file: File | null;
    setFile: React.Dispatch<React.SetStateAction<File | null>>;
};

function Uploader({ label, description, file, setFile }: UploaderProps) {
    return (
        <Row label={label} description={description} component={<DocumentUploaderComponent file={file} setFile={setFile} uploadLabel='Upload document' />} />
    );
}

type RowProps = {
    label: string;
    description: string;
    component: React.ReactElement;
};

function Row({ label, description, component }: RowProps) {
    return (
        <>
            <Grid item xs={4} pr='4rem' data-testid={`dropdown-label-${label}`}>
                <Typography variant='subtitle1'>{label}</Typography>
                <Typography variant='body2'>{description}</Typography>
            </Grid>
            <Grid item xs={8} sx={commonStyles}>
                {component}
            </Grid>
        </>
    );
}

function CreatorProjectDetails(props: ProjectCreatorComponentProps) {
    const { projectName, setProjectName, documentType, setDocumentType, file, setFile, setProjectType, projectType } = props;

    const transactionTypeOptions: Array<{ label: string; value: TransactionType }> = useMemo(() => {
        return Object.keys(TransactionTypeOptions)
            .map((key) => {
                const keyInt = parseInt(key);
                const value = TransactionTypeOptions[keyInt as TransactionType];
                return { label: value.label, value: keyInt };
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1));
    }, []);

    const documentTypeOptions: Array<{ label: string; value: ProjectDocumentType }> = useMemo(() => {
        const projectTypeInfo = TransactionTypeOptions[projectType];
        const documentTypes = projectTypeInfo.documentTypes;

        return documentTypes.map((value) => {
            return { label: ProjectDocumentTypeDictionary[value].label, value };
        });
    }, [projectType]);

    return (
        <form data-testid='creator-project-details-form'>
            <Grid
                container
                spacing={3}
                sx={{
                    width: '70%',
                    rowGap: '1.5rem',
                }}
            >
                <InputField
                    label='Project name'
                    description='Enter the name of your project'
                    value={projectName}
                    placeholder='ACME Widgets Acquisition'
                    onChange={setProjectName}
                />
                <DropdownField
                    label='Project type'
                    description='Choose the type of project'
                    value={projectType}
                    options={transactionTypeOptions}
                    onChange={setProjectType}
                />
                <DropdownField
                    label='Document type'
                    description='Choose the type of document you would like to verify'
                    value={documentType}
                    options={documentTypeOptions}
                    onChange={setDocumentType}
                />
                <Uploader label='Upload document to verify' description='Upload the single document that you want to verify' file={file} setFile={setFile} />
            </Grid>
        </form>
    );
}

export default CreatorProjectDetails;
