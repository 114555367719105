import { Box, LinearProgress, SxProps, Typography } from '@mui/material';

type ProgressBarComponentProps = {
    currentValue: number;
    linearProgressSx?: SxProps;
    typographySx?: SxProps;
};

function ProgressBarComponent({ currentValue, linearProgressSx, typographySx }: ProgressBarComponentProps) {
    return (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', margin: 1 }}>
                <LinearProgress
                    variant='determinate'
                    value={currentValue}
                    sx={{
                        height: '1rem',
                        backgroundColor: 'lightgray',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'blue',
                        },
                        ...linearProgressSx,
                    }}
                />
            </Box>
            <Box
                sx={{
                    minWidth: 35,
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Typography
                    variant='body2'
                    sx={{
                        color: (theme) => theme.palette.white.main,
                        ...typographySx,
                    }}
                >
                    {`${Math.floor(currentValue ?? 0)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default ProgressBarComponent;
