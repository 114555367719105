import { Box, LinearProgress, Theme, Typography } from '@mui/material';

const typographyCommonStyle = {
    fontWeight: 500,
    fontSize: '0.875rem',
    width: '50%',
};

type ProgressBarComponentProps = {
    leftValue: ProgressBarValue;
    rightValue: ProgressBarValue;
};

type ProgressBarValue = {
    label: string;
    value: number;
};

function ProgressBarComponent({ leftValue, rightValue }: ProgressBarComponentProps) {
    const sum = leftValue.value + rightValue.value;
    let leftPercantage: number | null = null;
    let rightPercantage: number | null = null;
    let isNotApplicable = false;

    if (sum === 0) {
        isNotApplicable = true;
    } else {
        leftPercantage = (leftValue.value / sum) * 100;
        leftPercantage = Math.round(leftPercantage);
        if (leftPercantage === 100 && rightValue.value > 0) leftPercantage = 99;
        if (leftPercantage === 0 && leftValue.value > 0) leftPercantage = 1;
        rightPercantage = 100 - leftPercantage;
    }

    return (
        <Box sx={{ position: 'relative', marginTop: 5, width: '100%', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ marginRight: '0.25rem', width: '2.5rem', minWidth: '2.5rem', textAlign: 'right' }}>
                    {!isNotApplicable && `${leftPercantage}%`}
                </Typography>
                <LinearProgress
                    variant='determinate'
                    value={leftPercantage === null ? 100 : leftPercantage}
                    sx={{
                        borderRadius: '1.5rem',
                        height: '1.5rem',
                        width: 'calc(100% - 5rem - 0.5rem)',
                        backgroundColor: (theme: Theme) => `${theme.palette.white.main}`,
                        border: (theme: Theme) => `1px solid ${theme.palette.stroke.main}`,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: (theme: Theme) => (isNotApplicable ? theme.palette.text.disabled : theme.palette.stroke.main),
                        },
                    }}
                />
                <Typography sx={{ marginLeft: '0.25rem', width: '2.5rem' }}>{!isNotApplicable && `${rightPercantage}%`}</Typography>
                {isNotApplicable && <Typography sx={{ position: 'absolute', fontWeight: 600 }}>N/A</Typography>}
            </Box>
            <Box sx={{ display: 'flex', marginTop: '0.5rem', paddingLeft: '2.75rem', paddingRight: '2.75rem' }}>
                <Typography sx={{ ...typographyCommonStyle }}>{leftValue.label}</Typography>
                <Typography sx={{ ...typographyCommonStyle, textAlign: 'right' }}>{rightValue.label}</Typography>
            </Box>
        </Box>
    );
}

export default ProgressBarComponent;
