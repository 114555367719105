import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Tag } from 'ApiClients/SterlingApiClients/Types';

export type AnnotationTagsProps = {
    addTagToAnnotation: (annotationId: string, tagId: string, flowMethods: FlowMethods) => void;
    deleteAssignedTagFromAnnotation: (annotationId: string, tagId: string, flowMethods: FlowMethods) => void;
    getAnnotationAssignedTags: (annotationId: string, flowMethods: FlowMethods<Array<Tag>>) => void;
};

const useAnnotationsTags = (
    projectId: string,
    projectVersionId: string,
    annotationApi: AnnotationsApiClient,
    refreshAnnotation: (annotationId: string) => void
): AnnotationTagsProps => {
    const getAnnotationAssignedTags = (annotationId: string, flowMethods?: FlowMethods<Array<Tag>>) =>
        annotationApi.getAssignedTags({ projectId, projectVersionId, annotationId }, flowMethods);

    const addTagToAnnotation = (annotationId: string, tagId: string, flowMethods?: FlowMethods) =>
        annotationApi.addTag(
            { projectId, projectVersionId, annotationId },
            {
                ...flowMethods,
                body: { tagId: tagId },
                onSuccess() {
                    if (flowMethods && flowMethods.onSuccess) {
                        flowMethods.onSuccess({});
                    }
                    refreshAnnotation(annotationId);
                },
            }
        );

    const deleteAssignedTagFromAnnotation = (annotationId: string, tagId: string, flowMethods?: FlowMethods) =>
        annotationApi.removeTag(
            { projectId, projectVersionId, annotationId, tagId },
            {
                ...flowMethods,
                onSuccess() {
                    if (flowMethods && flowMethods.onSuccess) {
                        flowMethods.onSuccess({});
                    }
                    refreshAnnotation(annotationId);
                },
            }
        );

    return {
        getAnnotationAssignedTags,
        addTagToAnnotation,
        deleteAssignedTagFromAnnotation,
    };
};

export default useAnnotationsTags;
