import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { DependencyList, useCallback, useMemo, useState } from 'react';

export type ApiModify<TInput> = {
    fetching: boolean;
    action: (input: TInput, flowMethods?: FlowMethods) => void;
};

export const useApiModify = <TInput,>(options: {
    method: (input: TInput, flowMethods: FlowMethods) => void;
    actionDependencyList?: DependencyList;
}): ApiModify<TInput> => {
    const { method, actionDependencyList } = options;
    const _actionDependencyList = useMemo(() => actionDependencyList || [], [actionDependencyList]);

    const [fetching, setFetching] = useState<boolean>(false);
    const action = useCallback(
        (input: TInput, flowMethods?: FlowMethods) => {
            method(input, {
                setFetching: (fetching) => {
                    setFetching(fetching);
                    flowMethods?.setFetching && flowMethods.setFetching(fetching);
                },
                onInit: () => flowMethods?.onInit && flowMethods.onInit(),
                onSuccess: (data) => {
                    flowMethods?.onSuccess && flowMethods.onSuccess(data);
                },
                onError: () => flowMethods?.onError && flowMethods.onError(),
            });
        },
        // eslint-disable-next-line
        [_actionDependencyList]
    );

    return {
        fetching,
        action,
    };
};
