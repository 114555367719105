import ProjectVersionsApiClient from 'ApiClients/SterlingApiClients/ProjectVersionsApiClient/ProjectVersions.ApiClient';
import VersionsListComponent from './VersionsList.Component';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ProjectSimple, ProjectVersionListRecord } from 'ApiClients/SterlingApiClients/Types';
import { useParams } from 'react-router-dom';
import LoadingLayoutComponent from 'App/LoadingLayout/LoadingLayout.Component';
import { useCallback } from 'react';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type VersionsListModuleProps = {
    projectsApiClient: ProjectsApiClient;
    projectVersionsApiClient: ProjectVersionsApiClient;
};

function VersionsListModule(props: VersionsListModuleProps) {
    const { projectsApiClient: projectsApi, projectVersionsApiClient: versionsApi } = props;
    const { projectId } = useParams();

    const { data: project } = usePromiseWithFlowMethods<{ projectId: string }, ProjectSimple | null>({
        method: (input, flowMethods) => projectsApi.getProject(input.projectId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId: projectId! } },
    });

    const {
        data: versions,
        fetching: isFetching,
        refetch: refetchVersions,
    } = usePromiseWithFlowMethods<{ projectId: string }, Array<ProjectVersionListRecord>>({
        method: (input, flowMethods) => versionsApi.getVersionsList(input.projectId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId: projectId! } },
    });

    const { wrappedMethod: _restoreVersion, fetching: isRestoreInProgress } = usePromiseWithFlowMethods<{ versionId: string }, {}>({
        method: (input, flowMethods) =>
            versionsApi.restoreVersion(projectId!, input.versionId, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    refetchVersions();
                },
            }),
        initialData: {},
    });
    const restoreVersion = useCallback((versionId: string, flowMethods: FlowMethods) => _restoreVersion({ versionId }, flowMethods), [_restoreVersion]);

    const { wrappedMethod: _deleteDraftVersion } = usePromiseWithFlowMethods<{ versionId: string }, {}>({
        method: (input, flowMethods) =>
            versionsApi.deleteDraftProjectVersion(projectId!, input.versionId, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    refetchVersions();
                },
            }),
        initialData: {},
    });
    const deleteDraftVersion = useCallback((versionId: string) => _deleteDraftVersion({ versionId }), [_deleteDraftVersion]);

    return project ? (
        <VersionsListComponent
            project={project}
            isFetching={isFetching}
            versions={versions}
            restoreVersion={restoreVersion}
            isRestoreInProgress={isRestoreInProgress}
            deleteDraftVersion={deleteDraftVersion}
        />
    ) : (
        <LoadingLayoutComponent />
    );
}

export default VersionsListModule;
