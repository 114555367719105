import { Grid, Box, Button, SvgIcon } from '@mui/material';
import { TextField, Typography } from 'UI';
import { Add, TrashBin } from 'UI/Icon';
import { memo, useCallback } from 'react';
import { palette } from 'UI/Provider/VerifiTheme';

type CreatorPartiesInvolvedProps = {
    targetCompanyName: string;
    setTargetCompanyName: React.Dispatch<React.SetStateAction<string>>;
    acquiringCompanyName: string;
    setAcquiringCompanyName: React.Dispatch<React.SetStateAction<string>>;
    ultimateParentNames: string[];
    setUltimateParentNames: React.Dispatch<React.SetStateAction<string[]>>;
    uploading: boolean;
};

type TextFieldGroupProps = {
    label: string;
    subtitle: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
};

const commonStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
};

const TextFieldGroup = memo(({ label, subtitle, value, onChange, placeholder }: TextFieldGroupProps) => {
    return (
        <>
            <Grid item xs={4} pr='4rem'>
                <Typography variant='subtitle1'>{label}</Typography>
                <Typography variant='body2'>{subtitle}</Typography>
            </Grid>
            <Grid item xs={8} sx={commonStyles}>
                <TextField fullWidth value={value} onChange={onChange} placeholder={placeholder} />
            </Grid>
        </>
    );
});

function CreatorPartiesInvolved({
    targetCompanyName,
    setTargetCompanyName,
    acquiringCompanyName,
    setAcquiringCompanyName,
    ultimateParentNames,
    setUltimateParentNames,
}: CreatorPartiesInvolvedProps) {
    const handleAddField = useCallback((): void => {
        setUltimateParentNames((prevNames) => [...prevNames, '']);
    }, [setUltimateParentNames]);

    const handleFieldChange = useCallback(
        (index: number) =>
            (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                setUltimateParentNames((prevNames) => {
                    const newFields: string[] = [...prevNames];
                    newFields[index] = event.target.value;
                    return newFields;
                });
            },
        [setUltimateParentNames]
    );

    const handleRemove = useCallback(
        (indexToRemove: number): void => {
            setUltimateParentNames((prevNames) => prevNames.filter((_, index) => index !== indexToRemove));
        },
        [setUltimateParentNames]
    );

    return (
        <form>
            <Grid
                item
                container
                spacing={3}
                sx={{
                    width: '70%',
                    rowGap: '1.5rem',
                }}
            >
                <TextFieldGroup
                    label='Acquisition of'
                    subtitle='Enter Target company name'
                    value={targetCompanyName}
                    onChange={(event) => setTargetCompanyName(event.target.value)}
                    placeholder='ACME Widgets plc.'
                />

                <TextFieldGroup
                    label='Acquiring company/Bidco'
                    subtitle='Legal name of acquiring party'
                    value={acquiringCompanyName}
                    onChange={(event) => setAcquiringCompanyName(event.target.value)}
                    placeholder='Bidco Holdings Limited'
                />

                <Grid item xs={4} pr='4rem'>
                    <Typography variant='subtitle1'>Ultimate parent of acquiring company</Typography>
                    <Typography variant='body2'>If different from Bidco (optional)</Typography>
                </Grid>
                <Grid item xs={8} sx={{ ...commonStyles, rowGap: '3.5rem' }}>
                    {ultimateParentNames &&
                        ultimateParentNames.map((fieldValue, index) => (
                            <Box key={index} sx={{ position: 'relative' }}>
                                <TextField
                                    fullWidth
                                    value={fieldValue || ''}
                                    onChange={(event) => handleFieldChange(index)(event)}
                                    placeholder='Bidco Partners plc'
                                />
                                {index !== 0 && (
                                    <SvgIcon
                                        sx={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            right: '-2rem',
                                            top: '50%',
                                            color: palette.blue.medium,
                                            '&:hover': {
                                                color: palette.attention.high,
                                            },
                                        }}
                                        component={TrashBin}
                                        onClick={() => {
                                            handleRemove(index);
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                    <Button
                        variant='outlined'
                        color='primary'
                        startIcon={<Add />}
                        sx={{
                            border: 'none',
                            textTransform: 'none',
                            fontSize: '14px',
                            width: '13.5rem',
                            padding: 0,
                            color: palette.attention.low,
                            '&:hover': {
                                border: 'none',
                            },
                            '&.Mui-disabled': {
                                border: 'none',
                            },
                        }}
                        onClick={handleAddField}
                        disabled={ultimateParentNames[ultimateParentNames.length - 1].trim() === ''}
                    >
                        Add another ultimate parent
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default CreatorPartiesInvolved;
