import { Box } from '@mui/material';
import { HighlightNavigationProps } from '../PdfViewerWithToolbar.Types';
import { IconType } from 'UI/Icon';
import { Icon, Tooltip } from 'UI';

export type HighlightNavigationComponentProps = HighlightNavigationProps;

function HighlightNavigationComponent(props: HighlightNavigationComponentProps) {
    const { disabled, nextHighlight, previousHighlight } = props;

    return (
        <Box sx={{ position: 'absolute', bottom: '3rem', right: '1.5rem', display: 'grid', rowGap: 1 }}>
            <BoxArrow disabled={disabled} tooltip='Previous annotation' Icon={Icon.ArrowShortUp} onClick={previousHighlight} />
            <BoxArrow disabled={disabled} tooltip='Next annotation' Icon={Icon.ArrowShortDown} onClick={nextHighlight} />
        </Box>
    );
}

function BoxArrow(props: { tooltip: string; Icon: IconType; disabled: boolean; onClick: () => void }) {
    const { tooltip, Icon, disabled, onClick } = props;

    return (
        <Tooltip title={tooltip}>
            <Box
                sx={{
                    height: '2rem',
                    width: '2rem',
                    border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                    borderRadius: '0.25rem',
                    boxShadow: '0px 2px 10px 0px #3f444466',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...(!disabled && {
                        cursor: 'pointer',
                    }),
                    ...(disabled && {
                        color: (theme) => theme.palette.text.disabled,
                    }),
                }}
                onClick={!disabled ? onClick : undefined}
            >
                <Icon />
            </Box>
        </Tooltip>
    );
}

export default HighlightNavigationComponent;
