import { CacheProvider, EmotionCache } from '@emotion/react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { getZoomRounded } from '../../useDrawing';
import { ThemeProvider } from 'UI';
import { AnnotationAttributeIsHtmlAnnotation, AnnotationAttributeParentHighlightId, AnnotationAttributeType } from './Attributes';
import { createRoot } from 'react-dom/client';
import { Highlight, AdditionalHtmlElement, AdditionalHtmlElementOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export const getAdditionalHtmlElements = <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlight: Highlight<THighlightCustomData>,
    selectedHighlightIds: Array<string>,
    additionalHtmlElements: Array<AdditionalHtmlElement<THighlightCustomData>>,
    emotionCache: EmotionCache
) => {
    const { Annotations, documentViewer } = instance.Core;

    let annotationsAdditionalHtmlElements: Array<Core.Annotations.HTMLAnnotation> = [];
    const zoomRounded = getZoomRounded(documentViewer.getZoomLevel());

    additionalHtmlElements.forEach((el) => {
        const options: AdditionalHtmlElementOptions<THighlightCustomData> = { highlight, selectedHighlightIds };
        if (el.display(options)) {
            const annot = new Annotations.HTMLAnnotation();
            annot.NoMove = true;
            annot.NoResize = true;
            const info = el.info(options);
            const htmlPosition = info.position || 'top left';
            annot.PageNumber = info.pageNumber;
            annot.X = info.x;
            annot.Y = info.y;
            annot.Height = info.height;
            annot.Width = info.width;
            annot.createInnerElement = () => {
                const newDiv = document.createElement('div');
                const root = createRoot(newDiv);
                root.render(
                    <div style={{ height: '100%', transform: `scale(${zoomRounded})`, transformOrigin: htmlPosition }}>
                        <ThemeProvider>
                            <CacheProvider value={emotionCache}>{el.component(options)}</CacheProvider>
                        </ThemeProvider>
                    </div>
                );
                return newDiv;
            };
            AnnotationAttributeType.set(annot, 'additionalHtmlElement');
            AnnotationAttributeIsHtmlAnnotation.set(annot, 'true');
            AnnotationAttributeParentHighlightId.set(annot, highlight.id);
            annotationsAdditionalHtmlElements.push(annot);
        }
    });

    return annotationsAdditionalHtmlElements;
};
