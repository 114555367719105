import SupportingInformationPanelComponent, { SupportingInformationPanelActionsState } from './SupportingInformationPanel/SupportingInformationPanel.Component';
import { Annotation, BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { useEffect, useRef } from 'react';
import { Highlight, HighlightToolbarActionsState, HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { SupportingHighlightCustomData } from '../WebSearchViewer.Component';

export type SupportingInformationPanelWrapperComponentProps = HighlightToolbarActionsState<SupportingInformationPanelWrapperActionsState> & {
    highlightToolbarOptions: HighlightToolbarOptions;
    selectedHighlightRef: React.RefObject<Highlight<SupportingHighlightCustomData> | null>;
    selectedAnnotationRef: React.RefObject<Annotation | null>;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
};

export type SupportingInformationPanelWrapperActionsState = SupportingInformationPanelActionsState;

function SupportingInformationPanelWrapperComponent(props: SupportingInformationPanelWrapperComponentProps) {
    const {
        highlightToolbarOptions,
        selectedHighlightRef,
        selectedAnnotationRef,
        modifySupportingInformation,
        deleteSupportingInformation,
        actionsState,
        setHighlightActionsState,
    } = props;
    const { setHighlightToolbarRef, addPart, getHighlightViewer } = highlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const selectedHighlight = selectedHighlightRef.current;
    const selectedAnnotation = selectedAnnotationRef.current;

    return (
        <div ref={ref}>
            {selectedAnnotation && selectedHighlight && (
                <SupportingInformationPanelComponent
                    selectedAnnotation={selectedAnnotation}
                    selectedHighlight={selectedHighlight}
                    modifySupportingInformation={modifySupportingInformation}
                    deleteSupportingInformation={deleteSupportingInformation}
                    actionsState={actionsState}
                    setHighlightActionsState={setHighlightActionsState}
                    addPart={addPart}
                    getHighlightViewer={getHighlightViewer}
                />
            )}
        </div>
    );
}

export default SupportingInformationPanelWrapperComponent;
