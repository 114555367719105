import { useCallback, useMemo } from 'react';
import { Box, Typography, Badge, Chip, useTheme } from '@mui/material';
import { AnnotationStatus, AnnotationsByStatusStatistics, ProjectStatistics } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationStatusDictionary } from 'Views/Common/Types';
import { FlagFill } from 'UI/Icon';
import { ItemsStats } from '../Types';
import StatsComponent from './Stats/Stats.Component';
import BoardTileComponent from './BoardTile/BoardTile.Component';

export type BoardComponentProps = {
    projectStatistics: ProjectStatistics | null;
    membersStatsById: ItemsStats;
    selectedStatus: AnnotationStatus | null;
    setSelectedStatus: (status: AnnotationStatus | null) => void;
    selectedMemberId: string | null;
    setSelectedMemberId: (id: string | null) => void;
};

function BoardComponent(props: BoardComponentProps) {
    const { projectStatistics, membersStatsById, selectedStatus, setSelectedStatus, selectedMemberId, setSelectedMemberId } = props;
    const statusOrder: Array<AnnotationStatus> = [0, 1, 2, 3];

    const theme = useTheme();

    const handleStatusClick = useCallback(
        (key: AnnotationStatus) => setSelectedStatus(selectedStatus === key ? null : key),
        [setSelectedStatus, selectedStatus]
    );

    const statusesStats: { [key in AnnotationStatus]: AnnotationsByStatusStatistics } | null = useMemo(() => {
        if (projectStatistics) {
            return {
                0: projectStatistics.annotateStatus,
                1: projectStatistics.verifyStatus,
                2: projectStatistics.approveStatus,
                3: projectStatistics.verifiedStatus,
            };
        }

        return null;
    }, [projectStatistics]);

    return (
        <Box data-testid='board-container'>
            <Box sx={{ display: 'flex', position: 'relative', flexDirection: 'row', gap: 2, marginTop: 3, marginBottom: 3 }}>
                {statusesStats &&
                    statusOrder.map((status, index) => {
                        return (
                            <Badge
                                badgeContent={
                                    statusesStats[status].needsReview ? (
                                        <Box position='relative' width={'3.5rem'} height={'3.5rem'}>
                                            <FlagFill
                                                color={theme.palette.attention.high}
                                                width={30}
                                                height={32}
                                                style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                            />
                                            <Typography
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '80%',
                                                    transform: 'translate(-50%, -50%)',
                                                    fontWeight: '500',
                                                    fontSize: '0.75rem',
                                                }}
                                                variant='subtitle2'
                                            >
                                                {statusesStats[status].needsReview > 99 ? '99+' : statusesStats[status].needsReview}
                                            </Typography>
                                        </Box>
                                    ) : null
                                }
                                color='primary'
                                sx={{
                                    flexGrow: 1,
                                    width: 'calc((100% - 3rem) / 4)',
                                    '.MuiBadge-badge': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                invisible={status === AnnotationStatus.Verified}
                                data-testid={`badge-${status}`}
                                key={status}
                            >
                                <BoardTileComponent
                                    status={status}
                                    orderNum={index}
                                    annotationsByStatusStatistics={statusesStats[status]}
                                    isSelected={selectedStatus === status}
                                    handleStatusClick={() => handleStatusClick(status)}
                                    estimatedTime={projectStatistics?.estimatedHoursSaved || 0}
                                />
                            </Badge>
                        );
                    })}
            </Box>
            <Box data-testid='annotation-section' mb='1rem'>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '2rem',
                        }}
                        data-testid='annotation-header'
                    >
                        <Typography variant='subtitle2'>Annotations assigned to:</Typography>
                        {selectedStatus !== null && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                                data-testid='filter-set'
                            >
                                <Typography variant='subtitle2'>Filter set</Typography>
                                <Chip label={AnnotationStatusDictionary[selectedStatus].name} color='primary' onDelete={() => setSelectedStatus(null)} />
                            </Box>
                        )}
                    </Box>
                </Box>
                <StatsComponent items={membersStatsById} selectedItemId={selectedMemberId} setSelectedItemId={setSelectedMemberId} />
            </Box>
        </Box>
    );
}

export default BoardComponent;
