import { useCallback, useMemo } from 'react';
import { Box, Theme } from '@mui/material';
import { InitialsAvatar, Typography } from 'UI';
import { ItemsStats, ItemStats } from 'Views/Project/ProjectDashboard/Types';

type StatsComponentProps = {
    items: ItemsStats;
    selectedItemId: string | null;
    setSelectedItemId: (id: string | null) => void;
};

type ItemsStatsWithId = ItemStats & { id: string };

function StatsComponent(props: StatsComponentProps) {
    const { items, selectedItemId, setSelectedItemId } = props;

    const sortedItems = useMemo(() => {
        return Object.entries(items)
            .map<ItemsStatsWithId>(([id, value]) => ({ id, ...value }))
            .sort((a, b) => b.count - a.count);
    }, [items]);

    const handleItemClick = useCallback(
        (itemId: string) => {
            setSelectedItemId(selectedItemId === itemId ? null : itemId);
        },
        [setSelectedItemId, selectedItemId]
    );

    return (
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }} data-testid='stats-list-container'>
            {sortedItems.map((item, idxI) => {
                return (
                    <Box
                        sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                        key={`stats-list-column-item-${idxI}`}
                        data-testid={`stats-list-item-${idxI}`}
                        onClick={() => handleItemClick(item.id)}
                    >
                        <InitialsAvatar name={item.name} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                            <Typography
                                variant='link14'
                                sx={{ color: (theme: Theme) => (selectedItemId === item.id ? theme.palette.attention.medium : theme.palette.link.main) }}
                            >
                                {item.name}
                            </Typography>
                            <Typography variant='link14'>: {item.count}</Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}
export default StatsComponent;
