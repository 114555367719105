import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationAttributeIsModifiedHighlight } from './Drawing/ViewerAnnotations/Attributes';
import { recalculateHighlightPartNumberPosition } from './Drawing/HtmlElements/HighlightPartNumber';

const RegisterAnnotationChangedEventListener = (webViewerInstance: WebViewerInstance) => {
    const annotationManager = webViewerInstance.Core.annotationManager;

    annotationManager.addEventListener('annotationChanged', (annotations: Array<Core.Annotations.Annotation>, action: any, info: any) => {
        if (action === 'modify' && info.source === 'resize') {
            annotations.forEach((annotation) => {
                AnnotationAttributeIsModifiedHighlight.set(annotation, 'true');
            });

            recalculateHighlightPartNumberPosition(webViewerInstance);
        }
    });
};

export default RegisterAnnotationChangedEventListener;
