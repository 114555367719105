import { Box, Input, InputAdornment } from '@mui/material';
import { Icon } from 'UI';
import { ToolbarDividerComponent, ToolbarUtilComponent } from '../Toolbar.Component';
import { useEffect, useMemo, useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';

export type DocumentNavigationUtilsComponentProps = {
    webViewerInstance: WebViewerInstance;
    documentLoading: boolean;
    openSearchPopup: () => void;
    openComments?: () => void;
    closeIconOnClick?: () => void;
};

const ZoomStep: number = 0.05;

function DocumentNavigationUtilsComponent(props: DocumentNavigationUtilsComponentProps) {
    const { webViewerInstance, documentLoading, openSearchPopup, openComments, closeIconOnClick } = props;
    const documentViewer = useMemo(() => webViewerInstance.Core.documentViewer, [webViewerInstance]);

    const [currentPageInput, setCurrentPageInput] = useState<string>('1');

    useEffect(() => {
        if (!documentLoading && documentViewer && documentViewer.getDocument()) {
            documentViewer.addEventListener('pageNumberUpdated', async (pageNumber: number) => {
                setCurrentPageInput(pageNumber.toString());
            });
        }
    }, [documentViewer, documentLoading]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarUtilComponent
                title='Zoom out'
                icon={Icon.ZoomOut}
                active={false}
                disabled={false}
                onClick={() => documentViewer.zoomTo(documentViewer.getZoomLevel() - ZoomStep)}
            />
            <ToolbarUtilComponent
                title='Zoom in'
                icon={Icon.ZoomIn}
                active={false}
                disabled={false}
                onClick={() => documentViewer.zoomTo(documentViewer.getZoomLevel() + ZoomStep)}
            />
            <ToolbarDividerComponent />
            <ToolbarUtilComponent
                title='Previous page'
                icon={Icon.ArrowLeftCircle}
                active={false}
                disabled={false}
                onClick={() => {
                    if (documentViewer.getCurrentPage() - 1 > 0) {
                        documentViewer.setCurrentPage(Math.max(documentViewer.getCurrentPage() - 1, 1), false);
                    }
                }}
            />
            <Input
                value={currentPageInput}
                onChange={(e) => {
                    let val = e.target.value;
                    if (!isNaN(parseInt(val)) || val === '') {
                        setCurrentPageInput(val);
                    }
                }}
                onBlur={() => setCurrentPageInput(documentViewer.getCurrentPage().toString() || '1')}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        let val = e.target.value;
                        if (/^\d+$/.test(val)) {
                            let valInt = parseInt(val);
                            if (valInt > 0 && valInt <= documentViewer.getPageCount()) {
                                documentViewer.setCurrentPage(valInt, false);
                            }
                        } else setCurrentPageInput(documentViewer.getCurrentPage().toString() || '1');
                    }
                }}
                sx={{ width: '2rem', textAlign: 'center', fontSize: '1rem', '& .MuiInputBase-input': { textAlign: 'center', padding: '0rem' } }}
            />
            <InputAdornment
                sx={{
                    '& .MuiTypography-root': {
                        fontFamily: 'inherit',
                        fontStyle: 'inherit',
                        fontWeight: 'inherit',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        color: 'inherit',
                    },
                    marginRight: '0.75rem',
                }}
                position='end'
            >
                /{documentViewer.getPageCount()}
            </InputAdornment>
            <ToolbarUtilComponent
                title='Next page'
                icon={Icon.ArrowRightCircle}
                active={false}
                disabled={false}
                onClick={() => {
                    if (documentViewer.getCurrentPage() + 1 <= documentViewer.getPageCount()) {
                        documentViewer.setCurrentPage(Math.min(documentViewer.getCurrentPage() + 1, documentViewer.getPageCount()), false);
                    }
                }}
            />
            <ToolbarDividerComponent />
            {openComments && <ToolbarUtilComponent title='Comments' icon={Icon.ChatRight} active={false} disabled={false} onClick={() => openComments()} />}
            <ToolbarUtilComponent title='Search in document' icon={Icon.Search} active={false} disabled={false} onClick={() => openSearchPopup()} />
            {closeIconOnClick && (
                <ToolbarUtilComponent title='Close' icon={Icon.CloseCircle} active={false} disabled={false} onClick={() => closeIconOnClick()} />
            )}
        </Box>
    );
}

export default DocumentNavigationUtilsComponent;
