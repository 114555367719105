import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
    AnnotationAttributeHighlightId,
    AnnotationAttributeHighlightPartIndex,
    AnnotationAttributeIsModifiedHighlight,
    AnnotationAttributeIsNewHighlight,
    AnnotationAttributeType,
    AnnotationAttributeTypeValues,
    getHighlightId,
    isNewHighlight,
    isStandardAnnotation,
} from './Drawing/ViewerAnnotations/Attributes';
import Log from '../../Logger';
import { drawHighlightPartNumber, eraseHighlightPartNumber } from './Drawing/HtmlElements/HighlightPartNumber';
import { EmotionCache } from '@emotion/react';
import { SupportStateHighlight } from './useAnnotationsSelecting';

const useAnnotationCreating = (
    webViewerInstance: WebViewerInstance | null,
    selectAnnotationsRef: MutableRefObject<(highlights: Array<SupportStateHighlight>) => void>,
    selectedHighlightIds: Array<string>,
    multiSelectNext: boolean,
    emotionCache: EmotionCache,
    annotationElementsEnabled: boolean
) => {
    const [newHighlightId, setNewHighlightId] = useState<string | null>(null);
    const newHighlightIdRef = useRef<string | null>(newHighlightId);
    newHighlightIdRef.current = newHighlightId;

    const selectedHighlightIdsRef = useRef<Array<string>>(selectedHighlightIds);
    selectedHighlightIdsRef.current = selectedHighlightIds;

    const multiSelectNextRef = useRef<boolean>(multiSelectNext);
    multiSelectNextRef.current = multiSelectNext;

    const emotionCacheRef = useRef<EmotionCache>(emotionCache);
    emotionCacheRef.current = emotionCache;

    const annotationElementsEnabledRef = useRef<boolean>(annotationElementsEnabled);
    annotationElementsEnabledRef.current = annotationElementsEnabled;

    useEffect(() => {
        if (webViewerInstance) {
            const annotationManager = webViewerInstance.Core.annotationManager;
            const annots = annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a) && getHighlightId(a) !== newHighlightId);

            if (annots.length > 0) {
                annotationManager.deleteAnnotations(annots);
            }
        }
    }, [webViewerInstance, newHighlightId]);

    useEffect(() => {
        if (webViewerInstance) {
            if (!newHighlightId) {
                eraseHighlightPartNumber(webViewerInstance);
            }
        }
    }, [webViewerInstance, newHighlightId]);

    const registerAnnotationCreatingListener = useCallback(
        (instance: WebViewerInstance) =>
            RegisterAnnotationCreatingListener(
                instance,
                setNewHighlightId,
                selectAnnotationsRef,
                selectedHighlightIdsRef,
                newHighlightIdRef,
                multiSelectNextRef,
                emotionCacheRef,
                annotationElementsEnabledRef
            ),
        // eslint-disable-next-line
        []
    );

    return { newHighlightId, setNewHighlightId, registerAnnotationCreatingListener };
};

const RegisterAnnotationCreatingListener = (
    webViewerInstance: WebViewerInstance,
    setNewHighlightId: (id: string) => void,
    selectAnnotationsRef: MutableRefObject<(highlights: Array<SupportStateHighlight>) => void>,
    selectedHighlightIdsRef: MutableRefObject<Array<string>>,
    newHighlightIdRef: MutableRefObject<string | null>,
    multiSelectNextRef: MutableRefObject<boolean>,
    emotionCacheRef: MutableRefObject<EmotionCache>,
    annotationElementsEnabledRef: MutableRefObject<boolean>
) => {
    const { documentViewer } = webViewerInstance.Core;

    const onAnnotationAdded = (annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation, type: AnnotationAttributeTypeValues) => {
        if (annot.getWidth() > 0 && annot.getHeight() > 0) {
            Log(
                webViewerInstance,
                'EVENT',
                ['RegisterAnnotationCreatingListener', 'annotationAdded'],
                [annot, type, multiSelectNextRef.current, newHighlightIdRef.current]
            );
            annot.disableRotationControl();
            AnnotationAttributeType.set(annot, type);
            const allAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList();

            let highlightId = '';
            if (multiSelectNextRef.current && newHighlightIdRef.current) {
                Log(
                    webViewerInstance,
                    'EVENT',
                    ['RegisterAnnotationCreatingListener', 'annotationAdded', 'NewHighlight - NextPart'],
                    [annot, newHighlightIdRef.current]
                );
                highlightId = newHighlightIdRef.current;
                AnnotationAttributeIsNewHighlight.set(annot, 'true');
                AnnotationAttributeHighlightId.set(annot, highlightId);
            } else if (multiSelectNextRef.current) {
                Log(
                    webViewerInstance,
                    'EVENT',
                    ['RegisterAnnotationCreatingListener', 'annotationAdded', 'Highlight - NextPart'],
                    [annot, selectedHighlightIdsRef.current]
                );
                if (selectedHighlightIdsRef.current.length === 1) {
                    highlightId = selectedHighlightIdsRef.current[0];
                    AnnotationAttributeHighlightId.set(annot, highlightId);
                    AnnotationAttributeIsModifiedHighlight.set(annot, 'true');
                    const _annot = allAnnotations.find(
                        (a) => getHighlightId(a) === highlightId && isStandardAnnotation(a)
                    ) as Core.Annotations.TextHighlightAnnotation;
                    annot.StrokeColor = _annot.StrokeColor;
                    annot.FillColor = _annot.FillColor;
                }
            } else {
                Log(webViewerInstance, 'EVENT', ['RegisterAnnotationCreatingListener', 'annotationAdded', 'NewHighlight'], [annot.Id]);
                highlightId = annot.Id;
                AnnotationAttributeIsNewHighlight.set(annot, 'true');
                AnnotationAttributeHighlightId.set(annot, highlightId);
                setNewHighlightId(highlightId);
            }

            const highlightAnnots = allAnnotations.filter((a) => getHighlightId(a) === highlightId && isStandardAnnotation(a));
            AnnotationAttributeHighlightPartIndex.set(annot, highlightAnnots.length - 1);
            const highlight: SupportStateHighlight = { id: highlightId, annotations: highlightAnnots, isNewHighlight: true };

            selectAnnotationsRef.current([highlight]);

            eraseHighlightPartNumber(webViewerInstance);
            drawHighlightPartNumber(webViewerInstance, highlightId, true, emotionCacheRef.current, annotationElementsEnabledRef.current);
        }
    };

    const textHightlightTool = documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT);
    textHightlightTool.addEventListener('annotationAdded', (annot: Core.Annotations.TextHighlightAnnotation) => onAnnotationAdded(annot, 'textAnnotation'));

    const boxHightlightTool = documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.RECTANGLE);
    boxHightlightTool.addEventListener('annotationAdded', (annot: Core.Annotations.RectangleAnnotation) => onAnnotationAdded(annot, 'boxAnnotation'));
};

export default useAnnotationCreating;
