import { Box, CircularProgress, Popover, Typography } from '@mui/material';
import { Icon, TableList } from 'UI';
import { useState } from 'react';

export type DataRowProps = {
    title: string;
    highlightsContext: HighlightsContext | null;
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void;
    action: Action;
    actionWhenEmpty?: Action;
};

type HighlightsContext = {
    isFetching: boolean;
    highlights: Array<HighlightRow>;
};

type HighlightRow = {
    id: string;
    number: number;
    page: number;
    active: boolean;
};

type Action = {
    title: string;
    func: () => void;
};

function InformationDataComponent(props: DataRowProps) {
    const { title, highlightsContext, setSelectedSupportingHighlightId, action, actionWhenEmpty } = props;
    const { highlights, isFetching } = highlightsContext || { highlights: [], isFetching: false };
    const count = highlights.length;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const handleExtendCollapse = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };
    const handleCollapse = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.75rem' }}>{title}:</Typography>
            {highlightsContext ? (
                isFetching ? (
                    <CircularProgress size={16} sx={{ marginLeft: '0.5rem' }} />
                ) : (
                    <>
                        <Typography sx={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{count}</Typography>
                        <Box
                            sx={{
                                marginLeft: '0.5rem',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: count > 0 ? 'pointer' : 'default',
                                color: (theme) => (count > 0 ? theme.palette.text.primary : theme.palette.text.disabled),
                            }}
                            onClick={(e) => {
                                count > 0 && handleExtendCollapse(e);
                            }}
                        >
                            <Icon.ArrowSimpleDown />
                        </Box>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCollapse}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Box
                                sx={{
                                    width: '15rem',
                                    maxHight: '20rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <TableList.Table maxHeight={384}>
                                    <TableList.Body data-cy='table-body'>
                                        {highlights.map((h, idx) => (
                                            <TableList.TR
                                                key={`table-row-${idx}`}
                                                data-cy='table-row'
                                                sx={{
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: (theme) => theme.palette.stroke.main,
                                                    height: '3rem',
                                                    '&:hover': {
                                                        backgroundColor: 'inherit',
                                                    },
                                                    position: 'relative',
                                                }}
                                            >
                                                {!h.active && (
                                                    <TableList.TD
                                                        sx={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#d9d9d999', zIndex: 1 }}
                                                    ></TableList.TD>
                                                )}
                                                <TableList.TD size={70} sx={{ display: 'flex' }}>
                                                    <Box
                                                        sx={{
                                                            marginLeft: '0.5rem',
                                                            minWidth: '1.5rem',
                                                            height: '1.5rem',
                                                            fontWeight: 700,
                                                            fontSize: '0.75rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '0.25rem',
                                                            paddingLeft: '0.25rem',
                                                            paddingRight: '0.25rem',
                                                            backgroundColor: (theme) => theme.palette.blue.light,
                                                            border: (theme) => `0.125rem solid ${theme.palette.blue.dark}`,
                                                        }}
                                                    >
                                                        {h.number}
                                                    </Box>
                                                </TableList.TD>
                                                <TableList.TD
                                                    size={100}
                                                    sx={{
                                                        display: 'flex',
                                                        fontSize: '0.75rem',
                                                        gap: 0.25,
                                                    }}
                                                >
                                                    <Typography>{`Page: ${h.page.toString()}`}</Typography>
                                                </TableList.TD>
                                                <TableList.TD sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                    <Box
                                                        onClick={() => setSelectedSupportingHighlightId(h.id, { dispatchEvent: true })}
                                                        sx={{ marginRight: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer', zIndex: 2 }}
                                                    >
                                                        <Icon.ArrowUpRightSquare />
                                                    </Box>
                                                </TableList.TD>
                                            </TableList.TR>
                                        ))}
                                    </TableList.Body>
                                </TableList.Table>
                            </Box>
                        </Popover>
                        <Box
                            sx={{
                                marginRight: '1rem',
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: (theme) => theme.palette.link.main,
                            }}
                            onClick={action.func}
                        >
                            <Typography sx={{ marginRight: '0.5rem', fontSize: '0.75rem' }}>{action.title}</Typography>
                            <Icon.ArrowUpRightSquare />
                        </Box>
                    </>
                )
            ) : (
                actionWhenEmpty && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: (theme) => theme.palette.link.main,
                            marginLeft: '1rem',
                        }}
                        onClick={actionWhenEmpty.func}
                    >
                        <Typography sx={{ marginRight: '0.5rem', fontSize: '0.75rem' }}>{actionWhenEmpty.title}</Typography>
                        <Icon.ArrowRightCircle />
                    </Box>
                )
            )}
        </Box>
    );
}

export default InformationDataComponent;
