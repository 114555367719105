import React from 'react';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Button, { ButtonProps } from 'UI/Button/Button.Component';
import { typography } from 'UI/Provider/VerifiTheme';

function noop() {}

type MenuContextType = {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    onClose: (event: React.MouseEvent<HTMLElement>) => void;
    isOpen?: boolean;
    anchorEl?: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    composeClick: (props: Record<string, unknown> & { onClick?: (props?: React.MouseEvent<HTMLElement>) => void }) => React.MouseEventHandler;
};

const MenuContext = React.createContext<MenuContextType>(
    Object.assign(
        {},
        {
            onClick: noop,
            onClose: noop,
            isOpen: false,
            setAnchorEl: noop,
            composeClick: function () {
                return noop;
            },
        }
    )
);

function useMenu() {
    const context = React.useContext(MenuContext);
    return context;
}

export type { MuiMenuProps as MenuProps };

function Menu({ children, open, onOpen, ...props }: React.PropsWithChildren<{ open?: boolean; onOpen?: (isOpen?: boolean) => void }>) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    React.useEffect(() => {
        if (open) {
            setAnchorEl(buttonRef.current);
            return;
        }
        setAnchorEl(null);
    }, [open]);

    React.useEffect(() => {
        onOpen?.(anchorEl ? true : false);
    }, [anchorEl, onOpen]);

    const contextValue = React.useMemo(() => {
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl((anchorEl) => (anchorEl ? null : buttonRef.current));
            event.stopPropagation();
        };
        const handleClose = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(null);
            event.stopPropagation();
        };
        const composeClick = (props: Record<string, unknown> & { onClick?: (props?: React.MouseEvent<HTMLElement>) => void }) => {
            return (event: React.MouseEvent<HTMLElement>) => {
                handleClick(event);
                props?.onClick?.(event);
            };
        };

        return {
            composeClick,
            onClick: handleClick,
            onClose: handleClose,
            isOpen: anchorEl ? true : false,
            buttonRef,
            anchorEl,
            setAnchorEl,
        };
    }, [anchorEl]);

    return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
}

function Trigger({ children, ...props }: React.PropsWithChildren<ButtonProps>) {
    const context = useMenu();
    return (
        <Button
            {...props}
            aria-controls={context.isOpen ? 'menu' : undefined}
            aria-haspopup='true'
            aria-expanded={context.isOpen ? 'true' : undefined}
            ref={context.buttonRef}
            onClick={context.composeClick(props as Record<string, unknown>)}
        >
            {children}
        </Button>
    );
}

function List({ children, hasContainerWidth = true, ...props }: React.PropsWithChildren<Omit<MuiMenuProps, 'open'> & { hasContainerWidth?: boolean }>) {
    const context = useMenu();
    const menuListWidth = context.anchorEl ? context.anchorEl.offsetWidth : 'auto';

    return (
        <MuiMenu
            anchorEl={context.anchorEl as HTMLElement}
            open={context.isOpen!}
            onClose={context.onClose}
            MenuListProps={{
                'aria-labelledby': 'menu-button',
                ...(hasContainerWidth && { sx: { width: menuListWidth } }),
            }}
            {...props}
        >
            {React.Children.map(
                children,
                (child) => React.isValidElement(child) && React.cloneElement(child, { ...child.props, onClick: context.composeClick(child.props) })
            )}
        </MuiMenu>
    );
}

function GroupItem({ sx, ...props }: React.PropsWithChildren<MenuItemProps>) {
    return (
        <MenuItem
            {...props}
            sx={{
                pointerEvents: 'none',
                color: (p) => p.palette.text.primary,
                ...sx,
                height: 40,
                background: (c) => c.palette.blue.light,
                ...typography.buttonSmall,
            }}
        />
    );
}

function Item({ sx, ...props }: React.PropsWithChildren<MenuItemProps>) {
    return (
        <MenuItem
            {...props}
            sx={{
                color: (p) => p.palette.text.primary,
                height: 40,
                ...typography.buttonSmall,
                ...sx,
            }}
        />
    );
}

Menu.Trigger = Trigger;
Menu.List = List;
Menu.Item = Item;
Menu.GroupItem = GroupItem;

export default Menu;
