import AnnotationsApiClient, { RootRouteParams } from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { AutoAnnotation, ProjectSimple, VerificationDocument } from 'ApiClients/SterlingApiClients/Types';
import useAnnotationsFilters from './Annotations/useAnnotationsFilters';
import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import useAnnotationsSelecting from './Annotations/useAnnotationsSelecting';
import useAnnotations, { AnnotationModals, AnnotationsProps } from './Annotations/useAnnotations';
import useAutoAnnotations, { AutoAnnotationsProps } from './Annotations/useAutoAnnotations';
import useAnnotationsStatus from './useAnnotationsStatus';
import useAnnotationsComments, { CommentsProps } from './useAnnotationsComments';
import useAnnotationsTags from './useAnnotationsTags';
import useAnnotationsNeedsReview from './useAnnotationsNeedsReview';
import useAnnotationsKeywords from './useAnnotationsKeywords';
import useAnnotationsAssertions, { AssertionsProps } from './useAnnotationsAssertions';
import { ProjectWorkspaceModals } from '../useProjectWorkspaceModals';
import { ObjectChangeType } from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';
import { useMemo, useRef, useState } from 'react';
import AutoAnnotationsApiClient from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

type AnnotationsScope = {
    annotationsProps: AnnotationsProps;
    autoAnnotationsProps: AutoAnnotationsProps;
    commentsProps: CommentsProps;
    assertionProps: AssertionsProps;
    annotationsScopeExtendedMethods: AnnotationsScopeExtendedMethods;
};

type AnnotationsScopeExtendedMethods = {
    handleAnnotationChanged: (annotationId: string, changeType: ObjectChangeType) => void;
    handleAutoAnnotationChanged: (autoAnnotationId: string, changeType: ObjectChangeType) => void;
};

const useAnnotationsScope = (
    project: ProjectSimple,
    annotationsApi: AnnotationsApiClient,
    autoAnnotationsApiClient: AutoAnnotationsApiClient,
    notifiApi: NotificationsApiClient,
    verificationDocumentInfo: VerificationDocument | null,
    createAuditLog: (actionType: string, content: string) => void,
    setAutoAnnotationsProcessIsErrorReceived: (isErrorReceived: boolean) => void,
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void,
    getSupportingInformationPropositions: (
        annotationId: string,
        options?:
            | {
                  keepPrevData?: boolean | undefined;
                  onInit?: (() => void) | undefined;
                  onSuccess?: (() => void) | undefined;
              }
            | undefined
    ) => void,
    closeSupportingInformationPropositions: () => void,
    openSupportingInformationPropositions: () => void,
    modals: ProjectWorkspaceModals
): AnnotationsScope => {
    const annotationFilters = useAnnotationsFilters();
    const annotationsProps = useAnnotations(
        project,
        annotationsApi,
        notifiApi,
        annotationFilters,
        verificationDocumentInfo,
        createAuditLog,
        getSupportingInformationPropositions,
        openSupportingInformationPropositions
    );
    const { annotationsDs, markAnnotationNotificationAsRead, refreshAnnotation } = annotationsProps;

    const autoAnnotationsRef = useRef<Array<AutoAnnotation>>([]);

    const annotationsSelecting = useAnnotationsSelecting(
        annotationsDs,
        autoAnnotationsRef,
        markAnnotationNotificationAsRead,
        closeSupportingInformationPropositions,
        setSelectedSupportingHighlightId
    );

    const autoAnnotationsProps = useAutoAnnotations(
        project,
        autoAnnotationsApiClient,
        verificationDocumentInfo,
        refreshAnnotation,
        annotationsDs.refetch,
        setAutoAnnotationsProcessIsErrorReceived,
        annotationsSelecting.setSelectedAnnotationIds
    );

    autoAnnotationsRef.current = autoAnnotationsProps.autoAnnotationsDs.data;

    const { acceptAnnotationStage } = useAnnotationsStatus(project.id, project.workingVersion.id, annotationsApi, refreshAnnotation);

    const commentsProps = useAnnotationsComments(project.id, annotationsApi, refreshAnnotation);

    const { setNeedsReview } = useAnnotationsNeedsReview(project.id, project.workingVersion.id, annotationsApi, refreshAnnotation, commentsProps.getComments);

    const annotationTagsProps = useAnnotationsTags(project.id, project.workingVersion.id, annotationsApi, refreshAnnotation);

    const annotationKeywordsProps = useAnnotationsKeywords(project.id, annotationsApi);

    const assertionProps = useAnnotationsAssertions(project.id, annotationsApi, refreshAnnotation);

    const annotationsModals: AnnotationModals = useMemo(() => {
        return {
            openAssertionModal: modals.assertionModal.openModal,
            openAssignmentModal: modals.assignmentModal.openModal,
            openDeleteModal: modals.deleteModal.openModal,
            openSuppInfoSummaryModal: modals.suppInfoSummaryModal.openModal,
            openTagAssignmentModal: modals.tagAssignmentModal.openModal,
            openAnnotationFiltersModal: modals.annotationFiltersModal.openModal,
            openNeedsReviewModal: modals.needsReviewModal.openModal,
        };
    }, [
        modals.assertionModal.openModal,
        modals.assignmentModal.openModal,
        modals.deleteModal.openModal,
        modals.suppInfoSummaryModal.openModal,
        modals.tagAssignmentModal.openModal,
        modals.annotationFiltersModal.openModal,
        modals.needsReviewModal.openModal,
    ]);

    const rootRouteParams: RootRouteParams = useMemo(() => ({ projectId: project.id, projectVersionId: project.workingVersion.id }), [project]);
    const reorderAnnotationsNumbers = (flowMethods: FlowMethods) =>
        annotationsApi.reorderAnnotationsNumbers(rootRouteParams, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                annotationsDs.refetch();
                autoAnnotationsProps.autoAnnotationsDs.refetch();
            },
        });

    const [annotationsListMounted, setAnnotationsListMounted] = useState<boolean>(false);

    return {
        annotationsProps: {
            ...annotationsProps,
            ...annotationsSelecting,
            annotationKeywordsProps,
            annotationTagsProps,
            acceptAnnotationStage,
            setNeedsReview,
            modals: annotationsModals,
            openCommentDrawer: commentsProps.openCommentDrawer,
            annotationsListMounted,
            setAnnotationsListMounted,
            reorderAnnotationsNumbers,
        },
        autoAnnotationsProps: { ...autoAnnotationsProps, ...annotationsSelecting },
        commentsProps,
        assertionProps,
        annotationsScopeExtendedMethods: {
            handleAnnotationChanged: annotationsProps.handleAnnotationChanged,
            handleAutoAnnotationChanged: autoAnnotationsProps.handleAutoAnnotationChanged,
        },
    };
};

export default useAnnotationsScope;
