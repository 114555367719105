import { useCallback, useState } from 'react';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ProjectTag as Tag } from 'ApiClients/SterlingApiClients/Types';
import { useToastModalClose } from 'Hooks/useToasts';
import { Button, ChipsBox, Dialog, TextField } from 'UI';
import { Box } from '@mui/material';
import { ChipBoxElement } from 'UI/ChipsBox/ChipBox.Component';
import DeleteTagModalComponent from './DeleteTagModal/DeleteTagModal.Component';

export type TagManagementModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    addTag: (tag: string, flowMethods: FlowMethods) => void;
    getTags: (flowMethods: FlowMethods<Array<Tag>>) => void;
    removeTag: (tagId: string, flowMethods: FlowMethods) => void;
};

function TagManagementModalComponent(props: TagManagementModalComponentProps) {
    const { isOpen } = props;
    const { closeModal, flowMethodsMiddleware: addToastMiddleware } = useToastModalClose(props.closeModal);
    const [newTag, setNewTag] = useState<string>('');
    const [deleteTagModalState, setDeleteTagModalState] = useState<{ isOpen: boolean; tagId: string }>({
        isOpen: false,
        tagId: '',
    });

    const {
        data: tags,
        wrappedMethod: _getTags,
        fetching,
    } = usePromiseWithFlowMethods<{}, Array<Tag>>({
        method: (_input, flowMethods) => props.getTags(flowMethods),
        initialData: [],
        initFetch: { input: {} },
    });
    const getTags = useCallback(() => _getTags({}), [_getTags]);

    const { wrappedMethod: addTag, fetching: addingTag } = usePromiseWithFlowMethods<{ tag: string }, {}>({
        method: (input, flowMethods) =>
            props.addTag(
                input.tag,
                addToastMiddleware({
                    ...flowMethods,
                    onSuccess: (data) => {
                        flowMethods?.onSuccess?.(data);
                        getTags();
                        setNewTag('');
                    },
                })
            ),
        initialData: {},
    });

    const { wrappedMethod: removeTag, fetching: removingTag } = usePromiseWithFlowMethods<{ tagId: string }, {}>({
        method: (input, flowMethods) =>
            props.removeTag(
                input.tagId,
                addToastMiddleware({
                    ...flowMethods,
                    onSuccess: (data) => {
                        flowMethods?.onSuccess?.(data);
                        getTags();
                        setDeleteTagModalState({ isOpen: false, tagId: '' });
                    },
                })
            ),
        initialData: {},
    });

    const deleteTagProps = {
        annotationsNumber: tags.find((t) => t.id === deleteTagModalState.tagId)?.numberOfAnnotations || 0,
        isOpen: deleteTagModalState.isOpen,
        removeTag: () => removeTag({ tagId: deleteTagModalState.tagId }),
        removingTag,
        closeModal: () => setDeleteTagModalState({ isOpen: false, tagId: '' }),
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (newTag.trim().length !== 0) addTag({ tag: newTag });
    };

    const chips: Array<ChipBoxElement<{ id: string }>> = tags.map((t) => ({
        color: 'info',
        hasDelete: true,
        label: t.name,
        id: t.id,
    }));

    return (
        <>
            {deleteTagModalState.isOpen ? (
                <DeleteTagModalComponent {...deleteTagProps} />
            ) : (
                <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
                    <Dialog.Title>Tag Management</Dialog.Title>
                    <Dialog.Content>
                        <form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    marginBottom: 4,
                                }}
                                data-cy='tag-input-container'
                            >
                                <TextField
                                    label='Add New Tag'
                                    placeholder='Tag Name'
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    disabled={addingTag}
                                    data-cy='tag-input'
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginBottom: 4,
                                }}
                                data-cy='tag-list-container'
                            >
                                <ChipsBox<{ id: string }>
                                    chips={chips}
                                    label='Project Tags'
                                    onChipDelete={(chip) => setDeleteTagModalState({ isOpen: true, tagId: chip.id })}
                                    chipProps={{
                                        sx: {
                                            minWidth: 'initial',
                                        },
                                    }}
                                    loading={fetching}
                                />
                            </Box>

                            <Dialog.Actions>
                                <Button
                                    color='secondary'
                                    sx={{
                                        maxWidth: 160,
                                    }}
                                    fullWidth
                                    type='button'
                                    onClick={closeModal}
                                    data-cy='close-tag-management'
                                >
                                    Close
                                </Button>
                                <Button
                                    data-cy='add-tag'
                                    isLoading={addingTag}
                                    disabled={newTag.trim().length === 0}
                                    color='primary'
                                    sx={{
                                        maxWidth: 160,
                                    }}
                                    fullWidth
                                    type='submit'
                                >
                                    Add Tag
                                </Button>
                            </Dialog.Actions>
                        </form>
                    </Dialog.Content>
                </Dialog>
            )}
        </>
    );
}

export default TagManagementModalComponent;
