import SterlingApiClient, { PostRequestOptions } from '../../Sterling.ApiClient';
import { CreateAuditLogBody, CreateAuditLogInput } from './AuditLogs.ApiClient.Types';

export default class AuditLogsApiClient {
    _sterlingApiClient: SterlingApiClient;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    createAuditLog = (postRequestOptions: PostRequestOptions<CreateAuditLogInput>) =>
        this._sterlingApiClient.postAsync<CreateAuditLogBody>(`auditLogs`, {
            ...postRequestOptions,
            body: {
                ...postRequestOptions.body!,
                system: 'App',
                logTime: new Date(),
                brainType: 'Human',
            },
        });
}
