import { DocumentInfo, SupportingInformation } from 'ApiClients/SterlingApiClients/Types';
import useStateCallback from 'Hooks/useStateCallback';
import { SelectedHighlightChangedEvent } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { useCallback } from 'react';

export type SupportingInformationSelectingProps = {
    selectedSupportingHighlightId: string | null;
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void;
    switchSupportingInformation: (supportingInformation: SupportingInformation) => void;
    switchSuppDocAndSelectHighlight: (id: string, documentId: string) => void;
};

export const SupportingHighlightSelectedEventName = 'supportingHighlightSelected';

const useSupportingInformationSelecting = (
    supportingDocumentInfo: DocumentInfo | null,
    switchSupportingDocument: (documentId: string | null, callback?: (() => void) | undefined) => void
) => {
    const [selectedSupportingHighlightId, _setSelectedSupportingHighlightId] = useStateCallback<string | null>(null);
    const setSelectedSupportingHighlightId = useCallback(
        (id: string | null, options: { dispatchEvent: boolean }) => {
            _setSelectedSupportingHighlightId(id, () => {
                if (options.dispatchEvent) {
                    const event = new CustomEvent<SelectedHighlightChangedEvent>(SupportingHighlightSelectedEventName, { detail: { ids: id ? [id] : [] } });
                    document.dispatchEvent(event);
                }
            });
        },
        [_setSelectedSupportingHighlightId]
    );

    const switchSupportingInformation = (supportingInformation: SupportingInformation) => {
        if (supportingDocumentInfo?.id !== supportingInformation.documentId) {
            switchSupportingDocument(supportingInformation.documentId);
        }
        setSelectedSupportingHighlightId(supportingInformation.id, { dispatchEvent: true });
    };

    const switchSuppDocAndSelectHighlight = useCallback(
        (id: string, documentId: string) => {
            if (supportingDocumentInfo?.id !== documentId) {
                switchSupportingDocument(documentId, () => {
                    setSelectedSupportingHighlightId(id, { dispatchEvent: true });
                });
            } else {
                setSelectedSupportingHighlightId(id, { dispatchEvent: true });
            }
        },
        [setSelectedSupportingHighlightId, supportingDocumentInfo?.id, switchSupportingDocument]
    );

    return {
        selectedSupportingHighlightId,
        setSelectedSupportingHighlightId,
        switchSupportingInformation,
        switchSuppDocAndSelectHighlight,
    };
};

export default useSupportingInformationSelecting;
