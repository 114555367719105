import {
    Annotation,
    AnnotationStatus,
    BoundingBoxSection,
    DocumentInfo,
    FileInfo,
    SelectionType,
    SupportingInformation,
    SupportingInformationProposition,
    SupportingInformationSource,
} from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import SupportingInformationPanelWrapperComponent, {
    SupportingInformationPanelWrapperActionsState,
} from './SupportingInformationPanelWrapper/SupportingInformationPanelWrapper.Component';
import NewSupportingInformationPanelWrapperComponent, {
    NewSupportingInformationId,
    NewSupportingInformationPanelActionsState,
} from './NewSupportingInformationPanelWrapper/NewSupportingInformationPanelWrapper.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { IconType } from 'UI/Icon';
import { Icon } from 'UI';
import useNavigation from './Hooks/useNavigation';
import InformationDataToolbar from './InformationDataToolbar/InformationDataToolbar';
import { Box } from '@mui/material';
import PdfViewerWithToolbarComponent from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import { Highlight, HighlightColor } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';
import { SupportingHighlightSelectedEventName, SupportingInformationContext, SupportingInformationPropositionContext } from '../../Hooks/Module';
import useHighlightsActionsState from 'Views/Common/PdfViewerWithToolbar/Hooks/useHighlightsActionsState';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

type SupportingDocumentComponentProps = {
    documentInfo: DocumentInfo;
    docFileInfo: FileInfo | null;
    onDocumentLoad: () => void;
    selectedAnnotation: Annotation | null;
    supportingInformationProps: SupportingInformationProps;
    supportingInformationPropositionProps: SupportingInformationPropositionProps;
    highlightProps: HighlightProps;
    openSupportingInformationPropositions: () => void;
    openSuppInfoSummaryModal: (annotationId: string) => void;
    closeDocument: () => void;
    scrollContainerRef: React.MutableRefObject<Element | null>;
};

type SupportingInformationProps = {
    supportingInformationContext: SupportingInformationContext;
    getSupportingInformation: (annotationId: string) => void;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
    switchSupportingInformation: (supportingInformation: SupportingInformation) => void;
};

type SupportingInformationPropositionProps = {
    supportingInformationPropositionContext: SupportingInformationPropositionContext;
    rejectProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkModifiedPropositionToAnnotation: (
        annotationId: string,
        propositionId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    restoreProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
};

type HighlightProps = {
    selectedSupportingHighlightId: string | null;
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void;
};

export type SupportingHighlightCustomData = {
    type: SupportingHighlightType;
    source: SupportingInformationSource | null;
    propositionIsRejected?: boolean;
};

type SupportingHighlightType = 'linked' | 'proposition';

const RejectedPropositionColors: { default: HighlightColor; selected: HighlightColor } = {
    default: { R: 207, G: 207, B: 207, A: 0.2 },
    selected: { R: 207, G: 207, B: 207, A: 0.4 },
};

type HighlightActionsState = NewSupportingInformationPanelActionsState & SupportingInformationPanelWrapperActionsState;

function SupportingDocumentComponent(props: SupportingDocumentComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const {
        documentInfo,
        docFileInfo,
        onDocumentLoad,
        selectedAnnotation,
        openSupportingInformationPropositions,
        openSuppInfoSummaryModal,
        closeDocument,
        scrollContainerRef,
    } = props;
    const {
        supportingInformationContext,
        getSupportingInformation,
        addSupportingInformation,
        modifySupportingInformation,
        deleteSupportingInformation,
        switchSupportingInformation,
    } = props.supportingInformationProps;
    const {
        supportingInformationPropositionContext: suppInfoProp,
        rejectProposition,
        linkPropositionToAnnotation,
        linkModifiedPropositionToAnnotation,
        restoreProposition,
    } = props.supportingInformationPropositionProps;
    const { selectedSupportingHighlightId, setSelectedSupportingHighlightId } = props.highlightProps;

    useEffect(() => {
        if (!supportingInformationContext && selectedAnnotation) {
            getSupportingInformation(selectedAnnotation.id);
        }
    }, [supportingInformationContext, getSupportingInformation, selectedAnnotation]);

    const supportingInformationPropositions = useMemo(() => {
        return suppInfoProp && suppInfoProp.supportingInformationPropositions
            ? suppInfoProp.supportingInformationPropositions
                  .slice(0, suppInfoProp.countToDisplay)
                  .filter((el) => el.documentId === documentInfo.id)
                  .filter((el) => !el.propositionUsed)
            : ([] as Array<SupportingInformationProposition>);
    }, [suppInfoProp, documentInfo]);

    const suppInfoPropHighlights = useMemo(
        () =>
            supportingInformationPropositions.map((el): Highlight<SupportingHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.propositionNumber,
                    type: 'proposition',
                    boundingBoxSections: el.content.boundingBoxSections,
                    selectionType: el.content.selectionType,
                    source: null,
                    propositionIsRejected: el.isRejected,
                    isEditable: selectedAnnotation ? selectedAnnotation.status !== AnnotationStatus.Verified : false,
                };
            }),
        [supportingInformationPropositions, selectedAnnotation]
    );

    const suppInfoHighlights = useMemo(
        () =>
            supportingInformationContext && supportingInformationContext.supportingInformation
                ? supportingInformationContext.supportingInformation
                      .filter((el) => el.documentId === documentInfo.id)
                      .map((el): Highlight<SupportingHighlightCustomData> => {
                          return {
                              id: el.id,
                              orderNumber: el.orderNumber,
                              type: 'linked',
                              boundingBoxSections: el.content.boundingBoxSections,
                              selectionType: el.content.selectionType,
                              source: el.source,
                              isEditable: selectedAnnotation ? selectedAnnotation.status !== AnnotationStatus.Verified : false,
                          };
                      })
                : ([] as Array<Highlight<SupportingHighlightCustomData>>),
        [supportingInformationContext, documentInfo, selectedAnnotation]
    );

    const highlights = useMemo(() => [...suppInfoPropHighlights, ...suppInfoHighlights], [suppInfoPropHighlights, suppInfoHighlights]);

    const highlightColor = useCallback((highlight: Highlight<SupportingHighlightCustomData>, selectedHighlightIds: Array<string>) => {
        if (highlight.type === 'proposition') {
            if (highlight.propositionIsRejected) {
                if (selectedHighlightIds.includes(highlight.id)) return RejectedPropositionColors.selected;
                else return RejectedPropositionColors.default;
            }
        }
    }, []);

    // Supporting Information Navigation
    const annotationSupportingInformation = supportingInformationContext?.supportingInformation;
    const supportingInformationNavRef = useNavigation({
        selectedHighlightId: selectedSupportingHighlightId,
        highlights: annotationSupportingInformation?.map((el) => el.id) || [],
        switchHighlight: (index: number) => annotationSupportingInformation && switchSupportingInformation(annotationSupportingInformation[index]),
    });

    // Propositions Navigation
    const propositionsNavRef = useNavigation({
        selectedHighlightId: selectedSupportingHighlightId,
        highlights: supportingInformationPropositions.map((el) => el.id) || [],
        switchHighlight: (index: number) =>
            supportingInformationPropositions && setSelectedSupportingHighlightId(supportingInformationPropositions[index].id, { dispatchEvent: true }),
    });

    const selectedAnnotationRef = useRef(selectedAnnotation || null);
    selectedAnnotationRef.current = selectedAnnotation;

    const selectedSupportingHighlight = highlights.find((h) => h.id === selectedSupportingHighlightId) || null;
    const selectedSupportingHighlightRef = useRef(selectedSupportingHighlight);
    selectedSupportingHighlightRef.current = selectedSupportingHighlight;

    const { highlightsActionsState, setHighlightActionsState } = useHighlightsActionsState<HighlightActionsState>();

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        const anySelectedAnnotation = selectedAnnotation ? true : false;
        return [selectedSupportingHighlightId, selectedAnnotation, anySelectedAnnotation, highlights, highlightsActionsState];
    }, [selectedSupportingHighlightId, selectedAnnotation, highlights, highlightsActionsState]);

    const { webViewerInstance, viewer } = useWebViewerInstance();

    const title: string = useMemo(() => {
        const docName = documentInfo.originalName || documentInfo.name;
        return docName.length > 100 ? docName.substring(0, 100) + '...' : docName;
    }, [documentInfo]);

    const selectedHighlightIds = useMemo(() => (selectedSupportingHighlightId ? [selectedSupportingHighlightId] : []), [selectedSupportingHighlightId]);
    const setSelectedHighlightIds = useCallback(
        (ids: Array<string>) => setSelectedSupportingHighlightId(ids[0], { dispatchEvent: false }),
        [setSelectedSupportingHighlightId]
    );

    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            <InformationDataToolbar
                selectedAnnotation={selectedAnnotation}
                supportingInformationContext={
                    (supportingInformationContext && {
                        isFetching: supportingInformationContext.isFetching,
                        supportingInformation: supportingInformationContext?.supportingInformation?.filter((el) => el.documentId === documentInfo.id) || [],
                    }) ||
                    null
                }
                supportingInformationPropositionContext={
                    (suppInfoProp && {
                        ...suppInfoProp,
                        supportingInformationPropositions,
                    }) ||
                    null
                }
                openSupportingInformationPropositions={openSupportingInformationPropositions}
                openSuppInfoSummaryModal={openSuppInfoSummaryModal}
                setSelectedSupportingHighlightId={setSelectedSupportingHighlightId}
            />
            <PdfViewerWithToolbarComponent<SupportingHighlightCustomData>
                webViewerInstance={webViewerInstance}
                viewerRef={viewer}
                title={title}
                documentInfo={documentInfo}
                fileSource={'docFile'}
                docFileInfo={docFileInfo}
                onDocumentLoad={onDocumentLoad}
                closeIconOnClick={closeDocument}
                displayHighlightIcons={true}
                isCreatingHighlightEnabled={true}
                highlightsProps={{
                    highlights,
                    highlightColor,
                    highlightToolbars: {
                        newHighlightToolbar: {
                            render: (options) => (
                                <NewSupportingInformationPanelWrapperComponent
                                    newHighlightToolbarOptions={options}
                                    addSupportingInformation={addSupportingInformation}
                                    documentInfo={documentInfo}
                                    selectedAnnotationRef={selectedAnnotationRef}
                                    actionsState={highlightsActionsState[NewSupportingInformationId]}
                                    setHighlightActionsState={setHighlightActionsState}
                                />
                            ),
                        },
                        highlightToolbar: {
                            render: (options) => (
                                <SupportingInformationPanelWrapperComponent
                                    highlightToolbarOptions={options}
                                    selectedHighlightRef={selectedSupportingHighlightRef}
                                    selectedAnnotationRef={selectedAnnotationRef}
                                    supportingInformationNavRef={supportingInformationNavRef}
                                    modifySupportingInformation={modifySupportingInformation}
                                    deleteSupportingInformation={deleteSupportingInformation}
                                    propositionsNavRef={propositionsNavRef}
                                    rejectProposition={rejectProposition}
                                    linkPropositionToAnnotation={linkPropositionToAnnotation}
                                    linkModifiedPropositionToAnnotation={linkModifiedPropositionToAnnotation}
                                    restoreProposition={restoreProposition}
                                    actionsState={highlightsActionsState[selectedSupportingHighlightRef.current?.id || '']}
                                    setHighlightActionsState={setHighlightActionsState}
                                />
                            ),
                        },
                        deps: highlightToolbarsDeps,
                    },
                    highlightLeftTopInformation: (highlight) => {
                        let icon: IconType | null = null;

                        switch (highlight.type) {
                            case 'linked':
                                icon = Icon.Link45deg;
                                break;
                            case 'proposition':
                                icon = Icon.Flash;
                                break;
                        }

                        let leftTopInformation = [{ iconContent: icon }, { textContent: highlight.orderNumber.toString() }];
                        if (highlight.source && highlight.source === 1 && isEditor) {
                            leftTopInformation.push({ iconContent: Icon.Flash });
                        }

                        return leftTopInformation;
                    },
                    selectedHighlightIds,
                    setSelectedHighlightIds,
                    selectedHighlightChangedEventName: SupportingHighlightSelectedEventName,
                    clearSelectedHighlight: () => setSelectedSupportingHighlightId(null, { dispatchEvent: false }),
                }}
                scrollContainerRef={scrollContainerRef}
            />
        </Box>
    );
}

export default SupportingDocumentComponent;
