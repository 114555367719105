import PathComponent, { ProjectListPathSegment } from 'Views/Common/NavBar/Path/Path.Component';

export type ProjectPathComponentProps = {
    projectName: string;
};

function ProjectPathComponent(props: ProjectPathComponentProps) {
    const { projectName } = props;

    return <PathComponent pathSegments={[ProjectListPathSegment, { name: projectName }]} />;
}

export default ProjectPathComponent;
