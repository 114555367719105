import { SelectChangeEvent } from '@mui/material/Select';
import Select, { SelectProps } from '../Select/Select.Component';

export type MultiSelectProps<T> = Omit<SelectProps<T>, 'onChange' | 'value' | 'hasEmpty' | 'renderValue'> & {
    value: T[];
    onChange: (value: string[]) => void;
    renderValue: (value: T[]) => React.ReactNode;
    onClear?: () => void;
};

function Multiselect<T>({ label, onClear, renderValue, children, value, onChange, ...rest }: MultiSelectProps<T>) {
    const handleChange = (event: SelectChangeEvent<T[]>) => {
        const val = event.target.value;
        onChange?.(val as string[]);
    };
    return (
        <Select<T[]>
            {...rest}
            onClear={onClear}
            renderValue={renderValue}
            label={label}
            value={value}
            onChange={handleChange as SelectProps<T[]>['onChange']}
            multiple
        >
            {children}
        </Select>
    );
}

export default Multiselect;
