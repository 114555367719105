import { Box } from '@mui/material';
import { Highlight } from '../../../PdfViewerWithToolbar.Types';
import { HighlightLeftTopInformation } from '../../HighlightLeftTopInformation.Component';
import { palette } from 'UI/Provider/VerifiTheme';
import { Typography } from 'UI';

type HighlightLeftTopInformationNodeComponentProps<THighlightCustomData> = {
    highlight: Highlight<THighlightCustomData>;
    selected: boolean;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    selectHighlight: (highlightId: string) => void;
};

function HighlightLeftTopInformationNodeComponent<THighlightCustomData>(props: HighlightLeftTopInformationNodeComponentProps<THighlightCustomData>) {
    const { highlight, selected, leftTopInformation, selectHighlight } = props;
    const information = leftTopInformation(highlight);

    return (
        <Box
            onClick={(e) => {
                selectHighlight(highlight.id);
                e.stopPropagation();
            }}
            sx={{
                display: 'flex',
                minWidth: '1rem',
                height: '0.75rem',
                fontWeight: 700,
                fontSize: '0.3rem',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                borderRadius: '0.25rem',
                paddingLeft: '0.25rem',
                paddingRight: '0.25rem',
                cursor: 'pointer',
                boxSizing: 'border-box',
                ...(selected
                    ? {
                          backgroundColor: palette.blue.light,
                          border: `0.125rem solid ${palette.blue.dark}`,
                      }
                    : {
                          backgroundColor: palette.white.main,
                          border: `0.125rem solid ${palette.stroke.main}`,
                      }),
            }}
            data-cy='highlight-left-top-information'
        >
            {information.map((info, idx) => {
                const last: boolean = idx === information.length - 1;
                return (
                    <Box
                        key={`highlight-leftTopInformation-${idx}`}
                        sx={{ marginRight: last ? '0rem' : '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        {info.textContent && <Typography sx={{ fontSize: '0.3rem' }}>{info.textContent}</Typography>}
                        {info.iconContent && <info.iconContent key={`highlight-leftTopInformation-${idx}`} height='0.3rem' width='0.3rem' />}
                    </Box>
                );
            })}
        </Box>
    );
}

export default HighlightLeftTopInformationNodeComponent;
