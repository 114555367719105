import Sidebar from './Sidebar/Sidebar.Component';
import VerificationDocumentComponent from './DocumentViewers/VerificationDocument/VerificationDocument.Component';
import { useCallback, useContext, useMemo, useState } from 'react';
import SupportingInformationPropositionsComponent from './SupportingInformationPropositions/SupportingInformationPropositions.Component';
import SupportingDocumentComponent from './DocumentViewers/SupportingDocument/SupportingDocument.Component';
import { ProjectWorkspaceModals } from './Hooks/Module/useProjectWorkspaceModals';
import ProjectWorkspaceModalsComponent from './Modals/ProjectWorkspaceModals.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { createPortal } from 'react-dom';
import SettingsComponent from './Settings/Settings.Component';
import { ProjectSimple, VerificationDocument } from 'ApiClients/SterlingApiClients/Types';
import { useNavigate } from 'react-router-dom';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Icon } from 'UI';
import { Box } from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle, PanelResizeHandleProps } from 'react-resizable-panels';
import CommentDrawer from './Drawers/CommentDrawer/CommentDrawer.Component';
import { AppRoutes } from 'App/RoutesPaths';
import { SupportingInformationSelectingProps } from './Hooks/Module/SupportingInformation/useSupportingInformationSelecting';
import {
    AnnotationsProps,
    AssertionsProps,
    AutoAnnotationsProps,
    CommentsProps,
    MembersProps,
    SupportingDocumentsProps,
    SupportingInformationPropositionsListProps,
    SupportingInformationPropositionsProps,
    SupportingInformationProps,
    TagsProps,
    UsersProps,
} from './Hooks/Module';
import { ReadOnlyModalProps } from './Modals/ReadOnlyModal/ReadOnlyModal.Component';
import LoadingLayoutComponent from 'App/LoadingLayout/LoadingLayout.Component';
import WebSearchViewerComponent from './DocumentViewers/WebSearchViewer/WebSearchViewer.Component';
import { SupportingInformationWebSearchPropositionsProps } from './Hooks/Module/SupportingInformation/useSupportingInformationWebSearchPropositions';
import { WebSearchDocumentsProps } from './Hooks/Module/Documents/useWebSearchDocuments';
import { ViewersScrollContainerRefs } from '../ProjectModulesWrapper/ProjectModules.Component';

type ProjectWorkspaceComponentProps = {
    navBarContents: NavBarContents;
    project: ProjectSimple;
    membersProps: MembersProps;
    usersProps: UsersProps;
    verificationDocumentInfo: VerificationDocument | null;
    supportingDocumentsProps: SupportingDocumentsProps;
    webSearchDocumentsProps: WebSearchDocumentsProps;
    annotationsProps: AnnotationsProps;
    autoAnnotationsProps: AutoAnnotationsProps;
    supportingInformationProps: SupportingInformationProps;
    supportingInformationPropositionsProps: SupportingInformationPropositionsProps;
    supportingInformationWebSearchPropositionsProps: SupportingInformationWebSearchPropositionsProps;
    supportingInformationPropositionsListProps: SupportingInformationPropositionsListProps;
    supportingInformationSelectingProps: SupportingInformationSelectingProps;
    assertionsProps: AssertionsProps;
    commentsProps: CommentsProps;
    tagsProps: TagsProps;
    modalsProps: ProjectWorkspaceModals;
    createAuditLog: (actionType: string, content: string) => void;
    readOnlyModalProps: ReadOnlyModalProps;
    viewersScrollContainerRefs: ViewersScrollContainerRefs;
};

type BottomPanelType = 'none' | 'supportingDocument' | 'supportingInformationPropositions' | 'webSearchDocument';

const PaddingTop = 1.5;
const MinHeight = 20;
const Height = `calc(100% - ${PaddingTop}rem)`;
const ContentMinHeight = MinHeight - PaddingTop;
export const StylesConsts = { PaddingTop, MinHeight, Height, ContentMinHeight };

function ProjectWorkspaceComponent(props: ProjectWorkspaceComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const navigate = useNavigate();

    // PROPS
    const { navBarContents, project, verificationDocumentInfo, annotationsProps, autoAnnotationsProps, webSearchDocumentsProps, viewersScrollContainerRefs } =
        props;
    const { getProjectMembers, addMemberToProject, changeMemberProjectRole, removeMemberFromProject } = props.membersProps;
    const { getUsers } = props.usersProps;
    const {
        documents,
        uploadSupportingDocument,
        anySupportingDocumentsProcessingErrors,
        getDocument,
        supportingDocumentInfo,
        supportingDocumentFileInfo,
        switchSupportingDocument,
    } = props.supportingDocumentsProps;
    const { webSearchDocumentInfo, webSearchDocumentFileInfo, uploadWebSearchDocument, switchWebSearchDocument } = props.webSearchDocumentsProps;
    const {
        annotationFilters,
        selectedAnnotation,
        selectedAnnotationId,
        setSelectedAnnotationId,
        annotationsDs,
        assignMemberToAnnotation,
        deleteAnnotation,
        annotationTagsProps,
        getAvailablePreviousStatuses,
        setNeedsReview,
        annotationKeywordsProps,
        prevAnnotationIndex,
        nextAnnotationIndex,
        annotationsListMounted,
    } = annotationsProps;
    const { autoAnnotationsDs } = autoAnnotationsProps;
    const { data: annotations } = annotationsDs;
    const { addTagToAnnotation, getAnnotationAssignedTags, deleteAssignedTagFromAnnotation } = annotationTagsProps;
    const {
        supportingInformationByAnnotationId,
        addSupportingInformation,
        modifySupportingInformation,
        deleteSupportingInformation,
        getSupportingInformation,
    } = props.supportingInformationProps;
    const {
        supportingInformationPropositionByAnnotationId,
        increaseCountPropositionsToDisplay,
        getSupportingInformationPropositions,
        linkPropositionToAnnotation,
        linkModifiedPropositionToAnnotation,
        rejectProposition,
        restoreProposition,
    } = props.supportingInformationPropositionsProps;
    const {
        supportingInformationWebSearchPropositionByAnnotationId,
        increaseCountWebSearchPropositionsToDisplay,
        getSupportingInformationWebSearchPropositions,
        uploadDocAndAddSupportingDocument,
    } = props.supportingInformationWebSearchPropositionsProps;
    const suppInfoPropList = props.supportingInformationPropositionsListProps;
    const { switchSuppDocAndSelectHighlight, selectedSupportingHighlightId, setSelectedSupportingHighlightId, switchSupportingInformation } =
        props.supportingInformationSelectingProps;
    const { getAssertion, saveAssertion } = props.assertionsProps;
    const { commentsByAnnotationId, getComments, addComment, resolveComment, deleteComment, closeCommentDrawer, commentDrawerState } = props.commentsProps;
    const { addTagToProject, getProjectTags, removeTagFromProject } = props.tagsProps;
    const {
        annotationFiltersModal,
        assertionModal,
        assignmentModal,
        deleteModal,
        needsReviewModal,
        projectMembersModal,
        suppInfoSummaryModal,
        tagAssignmentModal,
        tagManagementModal,
    } = props.modalsProps;
    const createAuditLog = props.createAuditLog;
    const readOnlyModalProps = props.readOnlyModalProps;

    const goToProjectVersionsList = useCallback(() => {
        navigate(AppRoutes.projectInstance.projectVersion.list.specificPath({ projectId: project.id }));
    }, [navigate, project]);

    const [annotationsViewerLoaded, setAnnotationsViewerLoaded] = useState<boolean>(false);
    const onAnnotationsViewerLoaded = useCallback(() => setAnnotationsViewerLoaded(true), []);

    const isProjectWorkspaceReady: boolean = useMemo(() => {
        if (
            annotationsDs.fetching ||
            autoAnnotationsDs.fetching ||
            (!annotationsListMounted && (annotationsDs.data.length > 0 || autoAnnotationsDs.data.length > 0)) ||
            !annotationsViewerLoaded
        )
            return false;
        else return true;
    }, [annotationsDs.fetching, annotationsDs.data, autoAnnotationsDs.fetching, autoAnnotationsDs.data, annotationsListMounted, annotationsViewerLoaded]);

    const bottomPanel: BottomPanelType = useMemo(() => {
        if (selectedAnnotation && suppInfoPropList.isOpen) return 'supportingInformationPropositions';

        if (supportingDocumentInfo) return 'supportingDocument';

        if (webSearchDocumentInfo) return 'webSearchDocument';

        return 'none';
    }, [selectedAnnotation, suppInfoPropList.isOpen, supportingDocumentInfo, webSearchDocumentInfo]);

    return (
        <Box sx={{ minHeight: `${StylesConsts.MinHeight}rem`, display: 'flex', height: '100%', width: '100%' }}>
            <>
                {isEditor &&
                    navBarContents.right?.current &&
                    createPortal(
                        <SettingsComponent openProjectMembersModal={projectMembersModal.openModal} openTagManagementModal={tagManagementModal.openModal} />,
                        navBarContents.right.current
                    )}
            </>
            {!isProjectWorkspaceReady && <LoadingLayoutComponent opacity={true} description='Preparing Project Workspace...' />}
            <Box // Background
                sx={{
                    width: '100%',
                    height: `${StylesConsts.Height}rem`,
                    background: (theme) => theme.palette.body.main,
                    position: 'fixed',
                    zIndex: -1,
                }}
            />
            <CommentDrawer
                annotationCommentsContext={commentsByAnnotationId[commentDrawerState.annotationId ?? '']}
                getComments={() => getComments(commentDrawerState.annotationId ?? '')}
                addComment={addComment}
                resolveComment={resolveComment}
                deleteComment={deleteComment}
                closeCommentDrawer={closeCommentDrawer}
                commentSelected={commentDrawerState.annotationId === selectedAnnotation?.id}
                commentDrawerState={commentDrawerState}
            />
            <ProjectWorkspaceModalsComponent
                suppInfoSummaryModal={{
                    isOpen: suppInfoSummaryModal.state.isOpen,
                    supportingInformationAnnotationId: suppInfoSummaryModal.state.annotationId!,
                    supportingInformationContext: supportingInformationByAnnotationId[suppInfoSummaryModal.state.annotationId!],
                    getSupportingInformation: () => getSupportingInformation(suppInfoSummaryModal.state.annotationId!),
                    documentsById: documents.supportingDocumentsById,
                    closeModal: suppInfoSummaryModal.closeModal,
                    selectedAnnotationId,
                    setSelectedAnnotationId,
                    switchSuppDocAndSelectHighlight,
                }}
                assertionModal={{
                    isOpen: assertionModal.state.isOpen,
                    annotation: annotations.find((annotation) => annotation.id === assertionModal.state.annotationId)!,
                    getAssertion,
                    saveAssertion,
                    closeModal: assertionModal.closeModal,
                }}
                assignmentModal={{
                    isOpen: assignmentModal.state.isOpen,
                    annotation: annotations.find((annotation) => annotation.id === assignmentModal.state.annotationId)!,
                    assignMember: assignMemberToAnnotation,
                    closeModal: assignmentModal.closeModal,
                    getMembers: (flowMethods) => getProjectMembers(project!.id, flowMethods),
                }}
                deleteModal={{
                    isOpen: deleteModal.state.isOpen,
                    deleteAnnotation: (flowMethods: FlowMethods) => deleteAnnotation(deleteModal.state.annotationId!, flowMethods),
                    closeModal: deleteModal.closeModal,
                }}
                projectMembersModal={{
                    isOpen: projectMembersModal.state.isOpen,
                    closeModal: projectMembersModal.closeModal,
                    projectId: project.id,
                    getUsers,
                    getMembers: getProjectMembers,
                    addMember: addMemberToProject,
                    changeMemberRole: changeMemberProjectRole,
                    removeMember: removeMemberFromProject,
                }}
                tagManagementModal={{
                    isOpen: tagManagementModal.state.isOpen,
                    addTag: addTagToProject,
                    getTags: getProjectTags,
                    removeTag: removeTagFromProject,
                    closeModal: tagManagementModal.closeModal,
                }}
                tagAssignmentModal={{
                    isOpen: tagAssignmentModal.state.isOpen,
                    closeModal: tagAssignmentModal.closeModal,
                    getTags: (flowMethods) => getProjectTags(flowMethods),
                    addTag: addTagToAnnotation,
                    deleteAssignedTag: deleteAssignedTagFromAnnotation,
                    getAssignedTags: getAnnotationAssignedTags,
                    annotationId: tagAssignmentModal.state.annotationId!,
                }}
                annotationFiltersModal={{
                    isOpen: annotationFiltersModal.state.isOpen,
                    closeModal: annotationFiltersModal.closeModal,
                    annotationFilters,
                    getMembers: (flowMethods) => getProjectMembers(project.id!, flowMethods),
                    getTags: (flowMethods) => getProjectTags(flowMethods),
                }}
                needsReviewModal={{
                    annotation: annotations.find((annotation) => annotation.id === needsReviewModal.state.annotationId)!,
                    isOpen: needsReviewModal.state.isOpen,
                    closeModal: needsReviewModal.closeModal,
                    getAvailableStatuses: getAvailablePreviousStatuses,
                    setNeedsReview: setNeedsReview,
                }}
                readOnlyModalProps={readOnlyModalProps}
            />
            <Sidebar
                supportingDocuments={{
                    documents: documents.supportingDocumentsTree,
                    selectedDocumentId: supportingDocumentInfo?.id || null,
                    swichDocument: (id: string) => switchSupportingDocument(id),
                    uploadSupportingDocument,
                    anySupportingDocumentsProcessingErrors,
                }}
                annotationsProps={annotationsProps}
                autoAnnotationsProps={autoAnnotationsProps}
                verificationDocumentHeadings={verificationDocumentInfo?.headings || []}
                closeSupportingInformationPropositions={suppInfoPropList.close}
            />
            <Box
                sx={{
                    display: 'inline-block',
                    minHeight: `${StylesConsts.ContentMinHeight}rem`,
                    height: 'calc(100% - 1.5rem)', // - marginTop
                    minWidth: '54rem',
                    width: '100%',
                    marginTop: '1.5rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                }}
            >
                <PanelGroup direction='vertical'>
                    <Panel>
                        {verificationDocumentInfo && (
                            <VerificationDocumentComponent
                                documentInfo={verificationDocumentInfo}
                                headings={verificationDocumentInfo.headings}
                                getDocument={getDocument}
                                onDocumentLoad={() =>
                                    createAuditLog('OpenVerificationDocument', JSON.stringify({ projectId: project.id, verificationDocumentInfo }))
                                }
                                annotationsProps={annotationsProps}
                                autoAnnotationsProps={autoAnnotationsProps}
                                supportingInformationPropositionsOpen={suppInfoPropList.isOpen}
                                goToProjectVersionsList={goToProjectVersionsList}
                                onAnnotationsLoaded={onAnnotationsViewerLoaded}
                                scrollContainerRef={viewersScrollContainerRefs.verificationDocScrollContainerRef}
                            />
                        )}
                    </Panel>
                    {bottomPanel !== 'none' && (
                        <>
                            <CustomPanelResizeHandle />
                            <Panel>
                                {bottomPanel === 'supportingInformationPropositions' && selectedAnnotation && (
                                    <SupportingInformationPropositionsComponent
                                        selectedAnnotation={selectedAnnotation}
                                        selectPreviousAnnotation={() => setSelectedAnnotationId(annotations[prevAnnotationIndex].id, { dispatchEvent: true })}
                                        selectNextAnnotation={() => setSelectedAnnotationId(annotations[nextAnnotationIndex].id, { dispatchEvent: true })}
                                        supportingInformationPropositionsProps={{
                                            supportingInformationPropositionContext: supportingInformationPropositionByAnnotationId[selectedAnnotation.id],
                                            increaseCountPropositionsToDisplay,
                                            getSupportingInformationPropositions,
                                            showSupportingInformationPropositionOnDocument: (id: string, documentId: string) => {
                                                switchSupportingDocument(documentId);
                                                setSelectedSupportingHighlightId(id, { dispatchEvent: true });
                                            },
                                            linkPropositionToAnnotation,
                                            unlinkPropositionFromAnnotation: deleteSupportingInformation,
                                            rejectProposition,
                                            restoreProposition,
                                        }}
                                        supportingInformationWebSearchPropositionsProps={{
                                            supportingInformationWebSearchPropositionContext:
                                                supportingInformationWebSearchPropositionByAnnotationId[selectedAnnotation.id],
                                            increaseCountWebSearchPropositionsToDisplay,
                                            getSupportingInformationWebSearchPropositions,
                                            openWebSearchDocument: webSearchDocumentsProps.switchWebSearchDocument,
                                        }}
                                        documentsById={documents.supportingDocumentsById}
                                        defaultTab={suppInfoPropList.defaultTab}
                                        clearDefaultTab={suppInfoPropList.clearDefaultTab}
                                        closeSidebar={suppInfoPropList.close}
                                        annotationKeywordsProps={annotationKeywordsProps}
                                    />
                                )}
                                {bottomPanel === 'supportingDocument' && supportingDocumentInfo && (
                                    <SupportingDocumentComponent
                                        documentInfo={supportingDocumentInfo}
                                        docFileInfo={supportingDocumentFileInfo}
                                        onDocumentLoad={() =>
                                            createAuditLog('OpenSupportingDocument', JSON.stringify({ projectId: project.id, supportingDocumentInfo }))
                                        }
                                        selectedAnnotation={selectedAnnotation}
                                        supportingInformationProps={{
                                            supportingInformationContext: supportingInformationByAnnotationId[selectedAnnotation?.id || ''],
                                            getSupportingInformation,
                                            addSupportingInformation,
                                            modifySupportingInformation,
                                            deleteSupportingInformation,
                                            switchSupportingInformation,
                                        }}
                                        supportingInformationPropositionProps={{
                                            supportingInformationPropositionContext:
                                                supportingInformationPropositionByAnnotationId[selectedAnnotation?.id || ''],
                                            rejectProposition,
                                            linkPropositionToAnnotation,
                                            linkModifiedPropositionToAnnotation,
                                            restoreProposition,
                                        }}
                                        highlightProps={{
                                            selectedSupportingHighlightId,
                                            setSelectedSupportingHighlightId,
                                        }}
                                        openSupportingInformationPropositions={suppInfoPropList.open}
                                        openSuppInfoSummaryModal={suppInfoSummaryModal.openModal}
                                        closeDocument={() => switchSupportingDocument(null)}
                                        scrollContainerRef={viewersScrollContainerRefs.supportingDocScrollContainerRef}
                                    />
                                )}
                                {bottomPanel === 'webSearchDocument' && webSearchDocumentInfo && (
                                    <WebSearchViewerComponent
                                        onDocumentLoad={() =>
                                            createAuditLog('OpenWebSearchDocument', JSON.stringify({ projectId: project.id, webSearchDocumentInfo }))
                                        }
                                        webSearchProps={{
                                            webSearchDocumentInfo,
                                            webSearchDocumentFileInfo,
                                            uploadWebSearchDocument,
                                            uploadDocAndAddSupportingDocument,
                                        }}
                                        selectedAnnotation={selectedAnnotation}
                                        supportingInformationProps={{
                                            supportingInformationContext: supportingInformationByAnnotationId[selectedAnnotation?.id || ''],
                                            getSupportingInformation,
                                            addSupportingInformation,
                                            modifySupportingInformation,
                                            deleteSupportingInformation,
                                        }}
                                        highlightProps={{
                                            selectedSupportingHighlightId,
                                            setSelectedSupportingHighlightId,
                                        }}
                                        openSupportingInformationPropositions={suppInfoPropList.open}
                                        closeDocument={() => switchWebSearchDocument(null)}
                                        scrollContainerRef={viewersScrollContainerRefs.webSearchScrollContainerRef}
                                    />
                                )}
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Box>
        </Box>
    );
}

function CustomPanelResizeHandle(props: PanelResizeHandleProps) {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PanelResizeHandle {...props} style={{ width: '3rem' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Icon.ArrowsCollapse />
                </Box>
            </PanelResizeHandle>
        </Box>
    );
}

export default ProjectWorkspaceComponent;
