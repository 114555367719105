import { Box, Theme } from '@mui/material';
import { Typography } from 'UI';
import { Clock } from 'UI/Icon';
import { AnnotationStatus, AnnotationsByStatusStatistics } from 'ApiClients/SterlingApiClients/Types';
import GraphProgress from './GraphProgress/GraphProgress.Component';
import ProgressBarComponent from './ProgressBar/ProgressBar.Component';
import { AnnotationStatusDictionary } from 'Views/Common/Types';

type BoardTileComponentProps = {
    status: AnnotationStatus;
    orderNum: number;
    annotationsByStatusStatistics: AnnotationsByStatusStatistics;
    isSelected: boolean;
    handleStatusClick: () => void;
    estimatedTime: number;
};

const AnnotationStatusLabelsInfoDictionary: { [key in AnnotationStatus]: AnnotationStatusLabelsInfo | null } = {
    0: {
        labelLeft: 'Auto Annotation',
        labelRight: 'User Annotation',
    },
    1: {
        labelLeft: 'Assisted Search',
        labelRight: 'User Search',
    },
    2: {
        labelLeft: 'Total Assisted Verification',
        labelRight: 'User Verification',
    },
    3: null,
};

type AnnotationStatusLabelsInfo = {
    labelLeft: string;
    labelRight: string;
};

const CommonFlexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

function BoardTileComponent(props: BoardTileComponentProps) {
    const { status, annotationsByStatusStatistics: stats, isSelected, handleStatusClick, orderNum } = props;
    const statusInfo = AnnotationStatusDictionary[status];

    return (
        <Box
            sx={{
                boxShadow: '0px 2px 4px rgba(63, 68, 68, 0.15)',
                minWidth: '16.75rem',
                borderRadius: 1,
                cursor: 'pointer',
                overflow: 'hidden',
                flexGrow: 1,
                border: (theme: Theme) => `2px solid ${theme.palette.stroke.main}`,
                textTransform: 'none',
                paddingBottom: '2rem',
                ...(isSelected && {
                    border: (theme: Theme) => `2px solid ${theme.palette.blue.dark}`,
                    backgroundColor: (theme: Theme) => `${theme.palette.blue.light} !important`,
                }),
            }}
            onClick={handleStatusClick}
            data-testid={`board-tile-${status}`}
        >
            <Box sx={{ ...CommonFlexCenter, marginTop: 2 }}>{<statusInfo.icon color={statusInfo.color} />}</Box>
            <Box sx={{ ...CommonFlexCenter, marginTop: 2, gap: 1 }}>
                <Typography variant='h3'>{stats.total}</Typography>
                <Typography variant='h6'>{AnnotationStatusDictionary[status].name}</Typography>
            </Box>
            <Box sx={{ ...CommonFlexCenter, marginTop: 1 }}>
                <GraphProgress sequences={Array.from({ length: 4 }, (_, index) => orderNum >= index)} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {stats.assisted !== null && stats.user !== null ? (
                    <ProgressBarComponent
                        leftValue={{
                            label: AnnotationStatusLabelsInfoDictionary[status]!.labelLeft,
                            value: stats.assisted,
                        }}
                        rightValue={{
                            label: AnnotationStatusLabelsInfoDictionary[status]!.labelRight,
                            value: stats.user,
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            ...CommonFlexCenter,
                            flexDirection: 'column',
                            textAlign: 'center',
                            gap: 2,
                            marginTop: 3,
                            color: (theme) => theme.palette.blue.medium,
                        }}
                    >
                        <Clock />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default BoardTileComponent;
