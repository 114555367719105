import { Button, Tooltip } from 'UI';

type HighlightButtonComponentProps = {
    rootRef: React.RefObject<HTMLDivElement>;
    content: string;
    onClick: () => void;
    tooltip?: string;
    loading?: boolean;
    disabled?: boolean;
};

function HighlightButtonComponent(props: HighlightButtonComponentProps) {
    const { rootRef, content, onClick, tooltip, loading, disabled } = props;
    return (
        <Tooltip title={tooltip} placement='bottom' PopperProps={{ container: rootRef.current }}>
            <span>
                <Button
                    color='primary'
                    onClick={onClick}
                    isLoading={loading}
                    disabled={disabled}
                    sx={{
                        height: '2rem',
                        borderStyle: 'solid',
                        borderWidth: '0.125rem',
                        borderColor: (theme) => theme.palette.white,
                        whiteSpace: 'nowrap',
                        fontSize: '0.75rem',
                    }}
                >
                    {content}
                </Button>
            </span>
        </Tooltip>
    );
}

export default HighlightButtonComponent;
