import { Box } from '@mui/material';
import { ProjectVersionSimple, ProjectVersionStatus } from 'ApiClients/SterlingApiClients/Types';
import { Button, Dialog, Typography } from 'UI';

export type ReadOnlyModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    version: ProjectVersionSimple;
};

function ReadOnlyModalComponent({ isOpen, closeModal, version }: ReadOnlyModalProps) {
    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Read only mode</Dialog.Title>
            <Dialog.Content>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 3,
                        flexDirection: 'column',
                        minHeight: '7rem',
                    }}
                >
                    <Typography sx={{ lineHeight: '1.5rem' }}>{getStatement(version)}</Typography>
                </Box>
                <Dialog.Actions mt='2rem'>
                    <Button
                        fullWidth
                        sx={{
                            maxWidth: 160,
                        }}
                        onClick={closeModal}
                        type='button'
                    >
                        Close
                    </Button>
                </Dialog.Actions>
            </Dialog.Content>
        </Dialog>
    );
}

const getStatement = (version: ProjectVersionSimple) => {
    if (version.status === ProjectVersionStatus.Archived) return 'This Project Version is in read-only mode for all users because it is archived';
    if (version.status === ProjectVersionStatus.Draft) return 'This Project Version is in read-only mode for all users because it is draft version';
    if (version.status === ProjectVersionStatus.Active)
        return (
            <>
                This Project Version is currently in read-only mode for all users because the verification document version is being updated by{' '}
                <strong>{version.createdBy.fullName}</strong>.
            </>
        );

    return 'This Version is currently locked';
};

export default ReadOnlyModalComponent;
