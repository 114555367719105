export type RouteParams = { [key: string]: string };

export abstract class RootRoutePath<TParams extends RouteParams = RouteParams> {
    public pathTemplate: string;

    constructor(pathTemplate: string) {
        this.pathTemplate = pathTemplate;
    }

    specificPath(params: TParams) {
        let specificPath = this.pathTemplate;
        for (const key in params) {
            specificPath = specificPath.replace(`:${key}`, params[key]);
        }

        return specificPath;
    }
}

export abstract class NodeRoutePath<TParentParams extends RouteParams, TParams extends TParentParams = TParentParams> extends RootRoutePath<TParams> {
    protected _parentSpecificPath: (params: TParentParams) => string;

    constructor(pathTemplate: string, parentSpecificPath: (params: TParentParams) => string) {
        super(pathTemplate);
        this._parentSpecificPath = parentSpecificPath;
    }

    specificPath(params: TParams) {
        let specificPath = super.specificPath(params);
        const parent = this._parentSpecificPath(params);

        return `${parent}/${specificPath}`;
    }
}