import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ProjectSimple } from 'ApiClients/SterlingApiClients/Types';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import LoadingLayoutComponent from 'App/LoadingLayout/LoadingLayout.Component';
import ProjectModulesComponent, { ProjectModulesComponentProps } from './ProjectModules.Component';
import useProjectAuth from 'Hooks/useProjectAuth';
import { ProjectAuthContextProvider } from 'Contexts/ProjectAuth.Context';

export type ProjectModulesWrapperComponentProps = Omit<ProjectModulesComponentProps, 'projectModulesProps'>;

function ProjectModulesWrapperComponent(props: ProjectModulesWrapperComponentProps) {
    const { projectsApiClient } = props;
    const { projectId, versionId } = useParams();

    const [project, setProject] = useState<ProjectSimple | null>(null);

    const getProject = useCallback(() => {
        if (versionId === ActiveProjectVersionId) {
            projectsApiClient.getProject(projectId!, { onSuccess: setProject });
        } else {
            projectsApiClient.getProjectWithConcreteVersion(projectId!, versionId!, { onSuccess: setProject });
        }
    }, [projectsApiClient, projectId, versionId]);

    useEffect(() => {
        getProject();
    }, [getProject]);

    const projectAuthContextProviderValue = useProjectAuth({
        getMyProjectMemberInstance: projectsApiClient.getMyProjectMemberInstance,
        projectId: project?.id,
    });

    if (project && !projectAuthContextProviderValue.isLoading) {
        return (
            <ProjectAuthContextProvider projectAuthContextProviderValue={projectAuthContextProviderValue}>
                <ProjectModulesComponent {...props} projectModulesProps={{ project }} />
            </ProjectAuthContextProvider>
        );
    } else {
        return <LoadingLayoutComponent />;
    }
}

export default ProjectModulesWrapperComponent;
