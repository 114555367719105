import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import useSupportingInformation, { SupportingInformationProps } from './useSupportingInformation';
import useSupportingInformationPropositions, {
    GetSupportingInformationPropositionsMethodType,
    SupportingInformationPropositionByAnnotationId,
    SupportingInformationPropositionsProps,
} from './useSupportingInformationPropositions';
import { useRef } from 'react';
import useSupportingInformationPropositionsList, { SupportingInformationPropositionsListProps } from './useSupportingInformationPropositionsList';
import useSupportingInformationSelecting, { SupportingInformationSelectingProps } from './useSupportingInformationSelecting';
import { DocumentInfo, FileInfo } from 'ApiClients/SterlingApiClients/Types';
import useSupportingInformationWebSearchPropositions, {
    SupportingInformationWebSearchPropositionsProps,
} from './useSupportingInformationWebSearchPropositions';
import { WebSearchDocumentInfo } from '../Documents/useWebSearchDocuments';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

type SupportingInformationScope = {
    supportingInformationProps: SupportingInformationProps;
    supportingInformationPropositionsProps: SupportingInformationPropositionsProps;
    supportingInformationWebSearchPropositionsProps: SupportingInformationWebSearchPropositionsProps;
    supportingInformationPropositionsListProps: SupportingInformationPropositionsListProps;
    supportingInformationSelectingProps: SupportingInformationSelectingProps;
};

const useSupportingInformationScope = (
    projectId: string,
    projectVersionId: string,
    suppInfoApiClient: SupportingInformationApiClient,
    switchSupportingDocument: (documentId: string | null) => void,
    supportingDocumentInfo: DocumentInfo | null,
    switchWebSearchDocument: (url: string | null) => void,
    refreshAnnotationRef: React.MutableRefObject<(annotationId: string) => void>,
    uploadWebSearchDocument: (webSearchDocumentInfo: WebSearchDocumentInfo, webSearchDocumentFileInfo: FileInfo, flowMethods: FlowMethods<string>) => void
): SupportingInformationScope => {
    const supportingInformationPropositionByAnnotationIdRef = useRef<SupportingInformationPropositionByAnnotationId>({});
    const getSupportingInformationPropositionsRef = useRef<GetSupportingInformationPropositionsMethodType>(() => {});

    const supportingInformationProps = useSupportingInformation(
        projectId,
        projectVersionId,
        suppInfoApiClient,
        supportingInformationPropositionByAnnotationIdRef,
        getSupportingInformationPropositionsRef,
        refreshAnnotationRef
    );
    const supportingInformationPropositionsProps = useSupportingInformationPropositions(
        projectId,
        projectVersionId,
        suppInfoApiClient,
        supportingInformationProps.getSupportingInformation,
        refreshAnnotationRef
    );

    const supportingInformationWebSearchPropositionsProps = useSupportingInformationWebSearchPropositions(
        projectId,
        projectVersionId,
        suppInfoApiClient,
        uploadWebSearchDocument,
        supportingInformationProps.addSupportingInformation
    );

    supportingInformationPropositionByAnnotationIdRef.current = supportingInformationPropositionsProps.supportingInformationPropositionByAnnotationId;
    getSupportingInformationPropositionsRef.current = supportingInformationPropositionsProps.getSupportingInformationPropositions;

    const supportingInformationPropositionsListProps = useSupportingInformationPropositionsList(switchSupportingDocument, switchWebSearchDocument);

    const supportingInformationSelectingProps = useSupportingInformationSelecting(supportingDocumentInfo, switchSupportingDocument);

    return {
        supportingInformationProps,
        supportingInformationPropositionsProps,
        supportingInformationWebSearchPropositionsProps,
        supportingInformationPropositionsListProps,
        supportingInformationSelectingProps,
    };
};

export default useSupportingInformationScope;
