import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions, PutRequestOptions } from '../../Sterling.ApiClient';
import {
    AddMemberBody,
    AddProjectBody,
    AddTagBody,
    ChangeMemberRoleBody,
    ChangeProjectNameBody,
    ProjectMember,
    ProjectSimple,
    ProjectTag,
    ProjectListEntry,
    ProjectMemberExtended,
    ValidateProjectDocumentBody,
    ProjectDocumentValidationResult,
} from '../Types';

export default class ProjectsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = () => `projects`;
    _rootRouteTags = (projectId: string, versionId: string) => `${this._rootRoute()}/${projectId}/versions/${versionId}/tags`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getProjectsUseQuery = () => this._sterlingApiClient.getUseQuery<Array<ProjectListEntry>>(['getProjects'], this._rootRoute());

    getProject = (id: string, getRequestOptions: GetRequestOptions<ProjectSimple>) =>
        this._sterlingApiClient.getAsync<ProjectSimple>(`${this._rootRoute()}/${id}`, getRequestOptions);

    getProjectWithConcreteVersion = (id: string, versionId: string, getRequestOptions: GetRequestOptions<ProjectSimple>) =>
        this._sterlingApiClient.getAsync<ProjectSimple>(`${this._rootRoute()}/${id}/version/${versionId}`, getRequestOptions);

    addProject = async (postRequestOptions: PostRequestOptions<AddProjectBody, string>) =>
        this._sterlingApiClient.postAsync<AddProjectBody, string>(this._rootRoute(), {
            ...postRequestOptions,
            bodyAsDataForm: true,
            toastSuccessMessages: ['Project created.'],
        });

    validateProjectDocument = async (postRequestOptions: PostRequestOptions<ValidateProjectDocumentBody, ProjectDocumentValidationResult>) =>
        this._sterlingApiClient.postAsync<ValidateProjectDocumentBody, ProjectDocumentValidationResult>(`${this._rootRoute()}/validate`, {
            ...postRequestOptions,
            bodyAsDataForm: true
        });

    deleteProject = (projectId: string, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRoute()}/${projectId}`, { ...deleteRequestOptions, toastSuccessMessages: ['Project archived.'] });

    changeProjectName = (projectId: string, requestOptions: PutRequestOptions<ChangeProjectNameBody>) =>
        this._sterlingApiClient.putAsync<ChangeProjectNameBody>(`${this._rootRoute()}/${projectId}`, {
            ...requestOptions,
            bodyAsDataForm: true,
        });

    getMembers = (projectId: string, requestOptions?: GetRequestOptions<Array<ProjectMemberExtended>>) =>
        this._sterlingApiClient.getAsync<Array<ProjectMemberExtended>>(`${this._rootRoute()}/${projectId}/members`, requestOptions);

    addMember = (projectId: string, requestOptions?: PostRequestOptions<AddMemberBody>) =>
        this._sterlingApiClient.postAsync<AddMemberBody>(`${this._rootRoute()}/${projectId}/members`, {
            ...requestOptions,
            toastSuccessMessages: ['Member added to team.'],
        });

    changeMemberRole = (projectId: string, memberId: string, requestOptions?: PutRequestOptions<ChangeMemberRoleBody>) =>
        this._sterlingApiClient.putAsync<ChangeMemberRoleBody>(`${this._rootRoute()}/${projectId}/members/${memberId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Privileges modified.'],
        });

    removeMember = (projectId: string, memberId: string, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRoute()}/${projectId}/members/${memberId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Member removed.'],
        });

    getMyProjectMemberInstance = async (projectId: string, requestOptions?: GetRequestOptions<ProjectMember>) =>
        this._sterlingApiClient.getAsync<ProjectMember>(`${this._rootRoute()}/${projectId}/members/myInstance`, requestOptions);

    addTag = (projectId: string, versionId: string, requestOptions?: PostRequestOptions<AddTagBody>) =>
        this._sterlingApiClient.postAsync<AddTagBody>(`${this._rootRouteTags(projectId, versionId)}`, {
            ...requestOptions,
            toastSuccessMessages: ['Tag created.'],
        });

    getTags = (projectId: string, versionId: string, requestOptions?: GetRequestOptions<Array<ProjectTag>>) =>
        this._sterlingApiClient.getAsync<Array<ProjectTag>>(`${this._rootRouteTags(projectId, versionId)}`, requestOptions);

    removeTag = (projectId: string, versionId: string, tagId: string, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteTags(projectId, versionId)}/${tagId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Tag deleted.'],
        });
}
