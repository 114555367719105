import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import VersionsTableComponent, { VersionsTableColumn } from './VersionsTable/VersionsTable.Component';
import { Icon, Tooltip, formatDateTime } from 'UI';
import { ProjectVersionListRecord, ProjectVersionStatus } from 'ApiClients/SterlingApiClients/Types';
import { IconType } from 'UI/Icon';

export type VersionsTablesComponentProps = {
    versions: Array<ProjectVersionListRecord>;
    isFetching: boolean;
    restore: (version: ProjectVersionListRecord) => void;
    goToProjectVersionWorkspace: (version: ProjectVersionListRecord) => void;
    goToProjectVersionWorkspaceActive: (version: ProjectVersionListRecord) => void;
    editDraftVersion: (version: ProjectVersionListRecord) => void;
    deleteDraft: (version: ProjectVersionListRecord) => void;
};

const columnsSizes = {
    documentName: '20%',
    totalAnnotations: '15%',
    lastModified: '15%',
    dataUploaded: '15%',
    uploadedBy: '20%',
    action: '15%',
};

function VersionsTablesComponent(props: VersionsTablesComponentProps) {
    const { versions, isFetching, restore, goToProjectVersionWorkspace, goToProjectVersionWorkspaceActive, editDraftVersion, deleteDraft } = props;

    const commonColumns: Array<VersionsTableColumn> = useMemo(
        () => [
            {
                name: 'Document Name',
                width: columnsSizes.documentName,
                value: (value) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ color: (theme) => theme.palette.blue.medium }}>
                                <Icon.FileTypePdf width={32} height={32} />
                            </Box>
                            <Typography variant='caption'>{value.verificationDocumentOriginalName || value.verificationDocumentName}</Typography>
                        </Box>
                    );
                },
                justifyContent: 'left',
            },
            {
                name: 'Total Annotations',
                width: columnsSizes.totalAnnotations,
                value: (value) => value.annotationsCount.toString(),
                justifyContent: 'center',
            },
            {
                name: 'Last Modified',
                width: columnsSizes.lastModified,
                value: (value) => formatDateTime(value.lastModifiedDate),
                justifyContent: 'left',
            },
            {
                name: 'Data Uploaded',
                width: columnsSizes.dataUploaded,
                value: (value) => formatDateTime(value.creationDate),
                justifyContent: 'left',
            },
            {
                name: 'Uploaded By',
                width: columnsSizes.uploadedBy,
                value: (value) => value.createdBy.fullName,
                justifyContent: 'left',
            },
        ],
        []
    );

    const getColumnsWithLastAction = useCallback(
        (name: string, actions: Array<{ ActionIcon: IconType; action: (version: ProjectVersionListRecord) => void; tooltip: string }>) => {
            const columns: Array<VersionsTableColumn> = [
                ...commonColumns,
                {
                    name,
                    width: columnsSizes.action,
                    value: (value) => (
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {actions.map(({ ActionIcon, action, tooltip }, index) => (
                                <Box key={`version-action-${name}-${index}`}>
                                    <Tooltip title={tooltip}>
                                        <ActionIcon width={16} height={16} onClick={() => action(value)} style={{ cursor: 'pointer' }} />
                                    </Tooltip>
                                </Box>
                            ))}
                        </Box>
                    ),
                    justifyContent: 'center',
                },
            ];

            return columns;
        },
        [commonColumns]
    );

    const columnsDraftVersion: Array<VersionsTableColumn> = useMemo(
        () =>
            getColumnsWithLastAction('', [
                { ActionIcon: Icon.PencilFill, action: editDraftVersion, tooltip: 'Edit' },
                { ActionIcon: Icon.TrashBin, action: deleteDraft, tooltip: 'Delete' },
            ]),
        [getColumnsWithLastAction, editDraftVersion, deleteDraft]
    );

    const columnsCurrentVersion: Array<VersionsTableColumn> = useMemo(
        () => getColumnsWithLastAction('', [{ ActionIcon: Icon.ArrowReturnRight, action: goToProjectVersionWorkspaceActive, tooltip: 'Go to workspace' }]),
        [getColumnsWithLastAction, goToProjectVersionWorkspaceActive]
    );

    const columnsArchivedVersion: Array<VersionsTableColumn> = useMemo(
        () =>
            getColumnsWithLastAction('', [
                { ActionIcon: Icon.ArrowCounterClockWise, action: restore, tooltip: 'Restore' },
                { ActionIcon: Icon.ArrowReturnRight, action: goToProjectVersionWorkspace, tooltip: 'Go to workspace (read only)' },
            ]),
        [getColumnsWithLastAction, restore, goToProjectVersionWorkspace]
    );

    return (
        <Box sx={{ display: 'grid', rowGap: '2rem' }}>
            <VersionsTableComponent
                title='Draft Version'
                isFetching={isFetching}
                versions={versions.filter((version) => version.status === ProjectVersionStatus.Draft)}
                columns={columnsDraftVersion}
            />
            <VersionsTableComponent
                title='Current Version'
                isFetching={isFetching}
                versions={versions.filter((version) => version.status === ProjectVersionStatus.Active)}
                columns={columnsCurrentVersion}
                active={true}
            />
            <VersionsTableComponent
                title='Archived Versions'
                isFetching={isFetching}
                versions={versions.filter((version) => version.status === ProjectVersionStatus.Archived)}
                columns={columnsArchivedVersion}
            />
        </Box>
    );
}

export default VersionsTablesComponent;
