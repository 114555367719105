import { Box, LinearProgress } from '@mui/material';
import { Typography } from 'UI';

type ProgressBarProps = {
    value: number;
};

function ProgressBarComponent({ value }: ProgressBarProps) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} data-testid='custom-progress-bar-container'>
            <LinearProgress
                variant='determinate'
                value={value}
                data-testid='custom-linear-progress'
                sx={{
                    flex: 1,
                    height: 8,
                    borderRadius: 5,
                    backgroundColor: (theme) => theme.palette.blue.dark,
                }}
            />
            <Typography variant='body2' data-testid='percentage-label' sx={{ whiteSpace: 'nowrap' }}>
                {`${Math.round(value)}%`}
            </Typography>
        </Box>
    );
}

export default ProgressBarComponent;
