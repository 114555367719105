import { CompareResult } from 'Views/Common/PdfViewerMulti/Hooks/useCompare';
import { useEffect, useState } from 'react';

export type CompareState = {
    comparing: boolean;
    setComparing: (value: boolean) => void;
    docsCompareResult: CompareResult | null;
    setDocsCompareResult: (value: CompareResult | null) => void;
};

const useCompareState = (docIdActive: string, docIdDraft: string | undefined): CompareState => {
    const [comparing, setComparing] = useState<boolean>(false);
    const [docsCompareResult, setDocsCompareResult] = useState<CompareResult | null>(null);
    useEffect(() => {
        const compareResultLeft = docsCompareResult?.docCompareResultLeft;
        const compareResultRight = docsCompareResult?.docCompareResultRight;
        if (docIdActive && docIdDraft && compareResultLeft && compareResultRight && docsCompareResult.diffCount > 0) {
            if (compareResultLeft.docId !== docIdActive || compareResultRight.docId !== docIdDraft) {
                setDocsCompareResult(null);
            }
        }
    }, [docIdActive, docIdDraft, docsCompareResult]);

    return {
        comparing,
        setComparing,
        docsCompareResult,
        setDocsCompareResult,
    };
};

export default useCompareState;
