import { Box, SxProps } from '@mui/material';
import { Button, Dialog, Typography } from 'UI';

export type CreatingDraftConfirmationModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    docNameActive: string;
    docNameDraft: string;
    confirm: () => void;
    creatingDraft: boolean;
};

const TypographyStyleCommon: SxProps = {
    lineHeight: '1.5rem',
};

function CreatingDraftConfirmationModalComponent({
    isOpen,
    closeModal,
    docNameActive,
    docNameDraft,
    confirm,
    creatingDraft,
}: CreatingDraftConfirmationModalProps) {
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        confirm();
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Annotation transfer</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 4,
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Typography sx={TypographyStyleCommon} variant='body2'>
                                You are now ready to transfer annotations from:
                            </Typography>
                            <Typography
                                variant='body2'
                                sx={{
                                    ...TypographyStyleCommon,
                                }}
                            >
                                <strong>{docNameActive}</strong> to <strong>{docNameDraft}</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='body2' sx={{ ...TypographyStyleCommon, color: (theme) => theme.palette.attention.medium, fontWeight: 700 }}>
                                IMPORTANT
                            </Typography>
                            <Typography variant='body2' sx={TypographyStyleCommon}>
                                By proceeding, users will be temporarily unable to edit or work on the current document version, until the annotation transfer
                                process, including your review, is complete.
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '2rem' }}>
                            <Typography variant='body2' sx={TypographyStyleCommon}>
                                At that point, all users can resume work on the new version:
                            </Typography>
                            <Typography
                                variant='body2'
                                sx={{
                                    ...TypographyStyleCommon,
                                    fontWeight: '700',
                                }}
                            >
                                {docNameDraft}
                            </Typography>
                        </Box>
                    </Box>
                    <Dialog.Actions>
                        <Button
                            fullWidth
                            color='secondary'
                            type='button'
                            onClick={closeModal}
                            sx={{
                                maxWidth: 160,
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            isLoading={creatingDraft}
                            sx={{
                                maxWidth: 160,
                            }}
                            type='submit'
                        >
                            Continue
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default CreatingDraftConfirmationModalComponent;
