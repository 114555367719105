import { Box } from '@mui/material';
import { Button, VerifiSpinner } from 'UI';

type LoginComponentProps = {
    login: () => void;
    isLoading: boolean;
};

function LoginComponent(props: LoginComponentProps) {
    const { login, isLoading } = props;

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            {isLoading ? <VerifiSpinner /> : <Button onClick={() => login()}>Login</Button>}
        </Box>
    );
}

export default LoginComponent;
