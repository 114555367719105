import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import {
    AutoAnnotationsProcessStatus,
    AutoAnnotationsSectionProcessStatus,
    ProjectDocumentProcessingStatus,
    VerificationDocument,
} from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useMemo } from 'react';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import {
    AutoAnnotationGeneratedMessageData,
    AutoAnnotationsSectionProcessStatusChangedMessageData,
} from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';

export type VerificationDocumentExtendedMethods = {
    setVerificationDocumentProcessingStatus: (status: ProjectDocumentProcessingStatus) => void;
    handleAutoAnnotationGenerated: (msg: AutoAnnotationGeneratedMessageData) => void;
    setAutoAnnotationsProcessProgress: (progress: number) => void;
    setAutoAnnotationsProcessIsErrorReceived: (isErrorReceived: boolean) => void;
    handleAutoAnnotationsSectionProcessStatusChanged: (msg: AutoAnnotationsSectionProcessStatusChangedMessageData) => void;
    refetchVerificationDocument: () => void;
};

const useVerificationDocument = (projectId: string, projectVersionId: string, docsApi: ProjectDocumentsApiClient) => {
    const {
        data: verificationDocument,
        refetch: refetchVerificationDocument,
        setData: setVerificationDocument,
    } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, VerificationDocument | null>({
        method: (input, flowMethods) => docsApi.getVerificationDocument(input.projectId, input.projectVersionId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId, projectVersionId } },
    });

    const _setVerificationDocumentProperties = useCallback((values: Array<{ field: keyof VerificationDocument; value: number | boolean }>) => {
        setVerificationDocument((prevState) => {
            if (prevState) {
                let newState = { ...prevState };
                values.forEach(({ field, value }) => {
                    newState = {
                        ...newState!,
                        [field]: value,
                    };
                });

                return newState;
            } else return prevState;
        });
        // eslint-disable-next-line
    }, []);
    const setVerificationDocumentProcessingStatus = useCallback(
        (status: ProjectDocumentProcessingStatus) => _setVerificationDocumentProperties([{ field: 'processingStatus', value: status }]),
        [_setVerificationDocumentProperties]
    );

    const handleAutoAnnotationGenerated = useCallback(
        (msg: AutoAnnotationGeneratedMessageData) =>
            setVerificationDocument((prevState) => {
                if (prevState) {
                    let newState = { ...prevState };

                    newState!.autoAnnotationsProcess = {
                        ...newState!.autoAnnotationsProcess!,
                        status: msg.processStatus,
                        anySectionProcessFailed: msg.processStatus !== AutoAnnotationsProcessStatus.Processed,
                        progress: msg.processStatus === AutoAnnotationsProcessStatus.Failed ? 0 : 100,
                    };

                    newState!.isAnnotationModificationAllowed = true;

                    return newState;
                } else return prevState;
            }),
        [setVerificationDocument]
    );

    const setAutoAnnotationsProcessProgress = useCallback(
        (progress: number) =>
            setVerificationDocument((prevState) => {
                if (prevState) {
                    let newState = { ...prevState };
                    newState!.autoAnnotationsProcess = {
                        ...newState!.autoAnnotationsProcess!,
                        progress,
                    };

                    return newState;
                } else return prevState;
            }),
        [setVerificationDocument]
    );

    const setAutoAnnotationsProcessIsErrorReceived = useCallback(
        (isErrorReceived: boolean) =>
            setVerificationDocument((prevState) => {
                if (prevState) {
                    let newState = { ...prevState };
                    newState!.autoAnnotationsProcess = {
                        ...newState!.autoAnnotationsProcess!,
                        isErrorReceived,
                    };

                    return newState;
                } else return prevState;
            }),
        [setVerificationDocument]
    );

    const handleAutoAnnotationsSectionProcessStatusChanged = useCallback(
        (msg: AutoAnnotationsSectionProcessStatusChangedMessageData) =>
            setVerificationDocument((prevState) => {
                if (prevState) {
                    let newState = { ...prevState };

                    newState!.autoAnnotationsProcess = {
                        ...newState!.autoAnnotationsProcess!,
                        status:
                            msg.processStatus === AutoAnnotationsSectionProcessStatus.Processing
                                ? AutoAnnotationsProcessStatus.Processing
                                : newState!.autoAnnotationsProcess!.status,
                        anySectionProcessFailed:
                            msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed ? true : newState!.autoAnnotationsProcess!.anySectionProcessFailed,
                        progress: msg.progress ? msg.progress : newState!.autoAnnotationsProcess!.progress,
                    };

                    if (msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed) {
                        newState!.headings = newState!.headings.map((heading) => {
                            if (heading.id === msg.headingId) {
                                return {
                                    ...heading,
                                    areAnyAutoAnnotatingSectionsErrors: true,
                                };
                            } else return heading;
                        });
                    }

                    return newState;
                } else return prevState;
            }),
        [setVerificationDocument]
    );

    // verDoc -> VerificationDocument
    const verificationDocumentInfo: VerificationDocument | null = useMemo(() => {
        if (verificationDocument) {
            return {
                ...verificationDocument,
                headings: verificationDocument.headings.sort((a, b) => {
                    if (a.content.boundingBoxSections[0].pageNumber !== b.content.boundingBoxSections[0].pageNumber)
                        return a.content.boundingBoxSections[0].pageNumber - b.content.boundingBoxSections[0].pageNumber;
                    else return a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y - b.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y;
                }),
            };
        }

        return null;
    }, [verificationDocument]);

    return {
        verificationDocumentInfo,
        verificationDocumentExtendedMethods: {
            setVerificationDocumentProcessingStatus,
            handleAutoAnnotationGenerated,
            setAutoAnnotationsProcessProgress,
            setAutoAnnotationsProcessIsErrorReceived,
            handleAutoAnnotationsSectionProcessStatusChanged,
            refetchVerificationDocument,
        },
    };
};

export default useVerificationDocument;
