import { useCallback, useMemo } from 'react';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';

const useAnnotationMerging = (mergeHighlightsInput?: (ids: Array<string>, flowMethods: FlowMethods) => void) => {
    const mergeHighlights = useCallback(
        (ids: Array<string>, flowMethods: FlowMethods) => {
            if (mergeHighlightsInput) {
                return mergeHighlightsInput(ids, flowMethods);
            }
        },
        [mergeHighlightsInput]
    );

    const { action, fetching } = useApiModify<{ ids: Array<string> }>({
        method: async (input, flowMethods) => mergeHighlights(input.ids, flowMethods),
    });

    const mergingProps = useMemo(() => ({ merge: action, fetching }), [action, fetching]);

    return mergingProps;
};

export default useAnnotationMerging;