import { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useRef, useState } from 'react';
import { HighlightColors } from '../../PdfViewerWithToolbar.Types';
import { HtmlElementsResizing } from './useDrawing';
import RegisterZoomUpdatedEventListener from './ZoomUpdatedEventListener';
import RegisterAnnotationChangedEventListener from './AnnotationChangedEventListener';

const useInit = (
    webViewerInstance: WebViewerInstance | null,
    RegisterAnnotationSelectedViewerListener: (webViewerInstance: WebViewerInstance) => void,
    RegisterAnnotationSelectedAppListener: (webViewerInstance: WebViewerInstance) => () => void,
    RegisterAnnotationCreatingListener: (webViewerInstance: WebViewerInstance) => void,
    keepSelectedHighlightInCentre: boolean | undefined,
    onAnnotationsLoaded: (() => void) | undefined
) => {
    const [zoom, setZoom] = useState<number>(0);

    const zoomRef = useRef<number>();
    zoomRef.current = zoom;

    useEffect(() => {
        if (webViewerInstance) {
            setZoom(webViewerInstance.Core.documentViewer.getZoomLevel());
            SetToolsConfiguration(webViewerInstance);
            RegisterAnnotationSelectedViewerListener(webViewerInstance);
            const removeAnnotationSelectedAppListener = RegisterAnnotationSelectedAppListener(webViewerInstance);
            RegisterAnnotationCreatingListener(webViewerInstance);
            RegisterZoomUpdatedEventListener(webViewerInstance, zoomRef, setZoom, keepSelectedHighlightInCentre || false);
            RegisterScrollEventListener(webViewerInstance);
            RegisterAnnotationChangedEventListener(webViewerInstance);
            if (onAnnotationsLoaded) RegisterAnnotationsLoadedEventListener(webViewerInstance, onAnnotationsLoaded);

            return () => {
                // Rest of event listeners are removed by removing WebViewer
                removeAnnotationSelectedAppListener();
            };
        }
        // eslint-disable-next-line
    }, [webViewerInstance]);
};

const SetToolsConfiguration = (webViewerInstance: WebViewerInstance) => {
    const defaultColor = HighlightColors.default;
    const documentViewer = webViewerInstance.Core.documentViewer;
    documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.RECTANGLE).setStyles({ FillColor: defaultColor, StrokeColor: defaultColor });
    documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT).setStyles({ FillColor: defaultColor, StrokeColor: defaultColor });
};

const RegisterScrollEventListener = (webViewerInstance: WebViewerInstance) => {
    webViewerInstance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0].addEventListener('scroll', () => {
        HtmlElementsResizing(webViewerInstance);
    });
};

const RegisterAnnotationsLoadedEventListener = (webViewerInstance: WebViewerInstance, onAnnotationsLoaded: () => void) => {
    webViewerInstance.Core.documentViewer.addEventListener('annotationsLoaded', async () => onAnnotationsLoaded());
};

export default useInit;
