import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions, PutRequestOptions } from '../../Sterling.ApiClient';
import {
    AddSupportingInformationBody,
    LinkModifiedPropositionToAnnotationBody,
    ModifySupportingInformationBody,
    SupportingInformation,
    SupportingInformationProposition,
    SupportingInformationWebSearchProposition,
} from '../Types';

export type RootRouteParams = {
    projectId: string;
    projectVersionId: string;
    annotationId: string;
};

export type RootRoutePropositionsParams = RootRouteParams & {
    propositionId: string;
};

export default class SupportingInformationApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (params: RootRouteParams) =>
        `projects/${params.projectId}/versions/${params.projectVersionId}/annotations/${params.annotationId}/supportingInformation`;
    _rootRoutePropositions = (params: RootRoutePropositionsParams) => `${this._rootRoute(params)}/propositions/${params.propositionId}`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getSupportingInformationPropositions = (params: RootRouteParams, getRequestOptions: GetRequestOptions<Array<SupportingInformationProposition>>) =>
        this._sterlingApiClient.getAsync<Array<SupportingInformationProposition>>(`${this._rootRoute(params)}/propositions`, getRequestOptions);

    getSupportingInformationWebSearchPropositions = (
        params: RootRouteParams,
        getRequestOptions: GetRequestOptions<Array<SupportingInformationWebSearchProposition>>
    ) =>
        this._sterlingApiClient.getAsync<Array<SupportingInformationWebSearchProposition>>(
            `${this._rootRoute(params)}/propositions/webSearch`,
            getRequestOptions
        );

    getSupportingInformation = (params: RootRouteParams, getRequestOptions: GetRequestOptions<Array<SupportingInformation>>) =>
        this._sterlingApiClient.getAsync<Array<SupportingInformation>>(`${this._rootRoute(params)}`, getRequestOptions);

    addSupportingInformation = (params: RootRouteParams, postRequestOptions: PostRequestOptions<AddSupportingInformationBody>) =>
        this._sterlingApiClient.postAsync<AddSupportingInformationBody>(`${this._rootRoute(params)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Information linked.'],
        });

    modifySupportingInformation = (
        params: RootRouteParams & { supportingInformationId: string },
        postRequestOptions: PutRequestOptions<ModifySupportingInformationBody>
    ) =>
        this._sterlingApiClient.putAsync<ModifySupportingInformationBody>(`${this._rootRoute(params)}/${params.supportingInformationId}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Information modified.'],
        });

    deleteSupportingInformation = (params: RootRouteParams & { supportingInformationId: string }, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRoute(params)}/${params.supportingInformationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Information unlinked.'],
        });

    linkPropositionToAnnotation = (params: RootRoutePropositionsParams, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRoutePropositions(params)}`, postRequestOptions);

    linkModifiedPropositionToAnnotation = (
        params: RootRoutePropositionsParams,
        postRequestOptions: PostRequestOptions<LinkModifiedPropositionToAnnotationBody>
    ) => this._sterlingApiClient.postAsync<LinkModifiedPropositionToAnnotationBody>(`${this._rootRoutePropositions(params)}/modified`, postRequestOptions);

    rejectProposition = (params: RootRoutePropositionsParams, putRequestOptions: PutRequestOptions) =>
        this._sterlingApiClient.putAsync(`${this._rootRoutePropositions(params)}/reject`, putRequestOptions);

    restoreProposition = (params: RootRoutePropositionsParams, putRequestOptions: PutRequestOptions) =>
        this._sterlingApiClient.putAsync(`${this._rootRoutePropositions(params)}/restore`, putRequestOptions);
}
