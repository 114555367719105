import { AnnotationStatus, ProjectDocumentType, ProjectMemberRole } from 'ApiClients/SterlingApiClients/Types';
import { Anotate, Check, Approve, VerifyReview, IconType } from 'UI/Icon';
import { palette } from 'UI/Provider/VerifiTheme';

export type AnnotationStatusInfo = {
    name: string;
    icon: IconType;
    color: string;
};

export const AnnotationStatusDictionary: { [key in AnnotationStatus]: AnnotationStatusInfo } = {
    0: {
        name: 'Annotate',
        icon: Anotate,
        color: palette.blue.medium,
    },

    1: { name: 'Verify', icon: Check, color: palette.blue.medium },
    2: {
        name: 'Approve',
        icon: Approve,
        color: palette.blue.medium,
    },
    3: {
        name: 'Verified',
        icon: VerifyReview,
        color: palette.blue.dark,
    },
};

export type ProjectMemberRoleInfo = { name: string; description: string };

export const ProjectRolesDictionary: { [key in ProjectMemberRole]: ProjectMemberRoleInfo } = {
    1: { name: 'Editor', description: 'Can add, develop and remove all annotations, assign members to a project.' },
    2: { name: 'Collaborator', description: 'Can update annotations assigned to them and upload supporting documents.' },
} as const;

export const ProjectNameMaxLength: number = 50;

export type ProjectDocumentTypeInfo = { name: string };

export const ProjectDocumentTypeDictionary: { [key in ProjectDocumentType]: ProjectDocumentTypeInfo } = {
    0: { name: 'Other' },
    1: { name: 'RNS Announcement' },
    2: { name: 'Offer Document' },
    3: { name: 'Scheme Document' },
    4: { name: 'Response Document' },
    5: { name: 'Circular' },
    6: { name: 'Presentation' },
    7: { name: 'Press Release' },
    8: { name: 'Prospectus' },
    9: { name: 'Offering Memorandum' },
};
