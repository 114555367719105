import { Core } from '@pdftron/webviewer';

type AnnotationAttribute<TValues> = {
    get: (a: Core.Annotations.Annotation) => TValues;
    set: (a: Core.Annotations.Annotation, value: TValues) => void;
};
type AnnotationAttributeBooleanValues = 'true' | 'false';

export type AnnotationAttributeTypeValues =
    | 'textAnnotation'
    | 'boxAnnotation'
    | 'highlightLeftTopInformation'
    | 'highlightLeftTopInformationBackground'
    | 'additionalHtmlElement'
    | 'heading'
    | 'diff';
export const AnnotationAttributeType: AnnotationAttribute<AnnotationAttributeTypeValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeType') as AnnotationAttributeTypeValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeTypeValues) => a.setCustomData('AnnotationAttributeType', value),
};
export const isStandardAnnotation = (a: Core.Annotations.Annotation) => {
    return AnnotationAttributeType.get(a) === 'textAnnotation' || AnnotationAttributeType.get(a) === 'boxAnnotation';
};

export const AnnotationAttributeIsNewHighlight: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsNewHighlight') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsNewHighlight', value),
};
export const isNewHighlight = (a: Core.Annotations.Annotation) => {
    return AnnotationAttributeIsNewHighlight.get(a) === 'true';
};

export const AnnotationAttributeIsModifiedHighlight: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsModifiedHighlight') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsModifiedHighlight', value),
};
export const isModifiedHighlight = (a: Core.Annotations.Annotation) => {
    return AnnotationAttributeIsModifiedHighlight.get(a) === 'true';
};

export const AnnotationAttributeParentHighlightId: AnnotationAttribute<string> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeParentHighlightId'),
    set: (a: Core.Annotations.Annotation, value: string) => a.setCustomData('AnnotationAttributeParentHighlightId', value),
};
export const getParentHighlightId = (a: Core.Annotations.Annotation) => AnnotationAttributeParentHighlightId.get(a);

export const AnnotationAttributeHighlightId: AnnotationAttribute<string> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeHighlightId'),
    set: (a: Core.Annotations.Annotation, value: string) => a.setCustomData('AnnotationAttributeHighlightId', value),
};
export const getHighlightId = (a: Core.Annotations.Annotation) => AnnotationAttributeHighlightId.get(a);

export const AnnotationAttributeSiblingsId: AnnotationAttribute<string> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeSiblingsId'),
    set: (a: Core.Annotations.Annotation, value: string) => a.setCustomData('AnnotationAttributeSiblingsId', value),
};
export const getSiblingsId = (a: Core.Annotations.Annotation) => AnnotationAttributeSiblingsId.get(a);

export const AnnotationAttributeIsFirst: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsFirst') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsFirst', value),
};

export const AnnotationAttributeIsLast: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsLast') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsLast', value),
};
export const isFirstAnnotationOfHighlight = (a: Core.Annotations.Annotation) => AnnotationAttributeIsFirst.get(a) === 'true';
export const isLastAnnotationOfHighlight = (a: Core.Annotations.Annotation) => AnnotationAttributeIsLast.get(a) === 'true';

export const AnnotationAttributeHighlightPartIndex: AnnotationAttribute<number> = {
    get: (a: Core.Annotations.Annotation) => parseInt(a.getCustomData('AnnotationAttributeHighlightPartIndex')),
    set: (a: Core.Annotations.Annotation, value: number) => a.setCustomData('AnnotationAttributeHighlightPartIndex', value.toString()),
};
export const getHighlightPartIndex = (a: Core.Annotations.Annotation) => AnnotationAttributeHighlightPartIndex.get(a);

export const AnnotationAttributeHighlightOriginalPartsNumber: AnnotationAttribute<number> = {
    get: (a: Core.Annotations.Annotation) => parseInt(a.getCustomData('AnnotationAttributeHighlightOriginalPartsNumber')),
    set: (a: Core.Annotations.Annotation, value: number) => a.setCustomData('AnnotationAttributeHighlightOriginalPartsNumber', value.toString()),
};

export const AnnotationAttributeIsHtmlAnnotation: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsHtmlAnnotation') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsHtmlAnnotation', value),
};

export const AnnotationAttributeMultiSelectEnable: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeMultiSelectEnable') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeMultiSelectEnable', value),
};
