import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { MutableRefObject, useCallback, useState } from 'react';
import Log from '../../Logger';
import {
    AnnotationAttributeHighlightId,
    AnnotationAttributeHighlightOriginalPartsNumber,
    AnnotationAttributeHighlightPartIndex,
    AnnotationAttributeType,
} from './Drawing/ViewerAnnotations/Attributes';
import { HighlightColor } from '../../PdfViewerWithToolbar.Types';
import { highlightColorToAnnotationsColor } from './useDrawing';
import { SupportStateHighlight } from './useAnnotationsSelecting';

const AnnotationSplittedColor: HighlightColor = {
    R: 241,
    G: 129,
    B: 30,
    A: 0.3,
};

const useAnnotationSpliting = (
    webViewerInstance: WebViewerInstance | null,
    redrawHighlightRef: MutableRefObject<(id: string) => void>,
    selectAnnotationsRef: MutableRefObject<(highlights: Array<SupportStateHighlight>) => void>
) => {
    const [splittedHighlightId, setSplittedHighlightId] = useState<string | null>(null);

    const splitAnnotation = useCallback(
        (id: string) => {
            if (webViewerInstance) {
                Log(webViewerInstance, 'ACTION', ['useAnnotationSpliting', 'splitAnnotation'], [id]);
                redrawHighlightRef.current(id);
                const { Annotations, annotationManager } = webViewerInstance.Core;

                const annotToSplit = annotationManager.getSelectedAnnotations()[0] as Core.Annotations.TextHighlightAnnotation | null;
                if (!annotToSplit) {
                    return;
                }
                const quads = annotToSplit.Quads;
                const firstQuad = quads[0];
                const firstQuadWidth = firstQuad.x2 - firstQuad.x1;
                const halfFirstQuadWidth = firstQuadWidth / 2;

                const annotToSplitNewQuad = new webViewerInstance.Core.Math.Quad(
                    firstQuad.x1,
                    firstQuad.y2,
                    firstQuad.x2 - halfFirstQuadWidth,
                    firstQuad.y2,
                    firstQuad.x3 - halfFirstQuadWidth,
                    firstQuad.y3,
                    firstQuad.x4,
                    firstQuad.y4
                );
                annotToSplit.Quads = [annotToSplitNewQuad];

                const newAnnot = new Annotations.TextHighlightAnnotation();
                const newAnnotQuad = new webViewerInstance.Core.Math.Quad(
                    firstQuad.x1 + halfFirstQuadWidth,
                    firstQuad.y2,
                    firstQuad.x2,
                    firstQuad.y2,
                    firstQuad.x3,
                    firstQuad.y3,
                    firstQuad.x4 + halfFirstQuadWidth,
                    firstQuad.y4
                );
                const newAnnotQuads = [...quads];
                newAnnotQuads[0] = newAnnotQuad;
                newAnnot.Quads = newAnnotQuads;
                newAnnot.PageNumber = annotToSplit.PageNumber;
                newAnnot.StrokeColor = highlightColorToAnnotationsColor(Annotations, AnnotationSplittedColor);
                newAnnot.FillColor = highlightColorToAnnotationsColor(Annotations, AnnotationSplittedColor);
                newAnnot.NoResize = false;

                AnnotationAttributeHighlightId.set(newAnnot, id);
                AnnotationAttributeType.set(newAnnot, 'textAnnotation');
                AnnotationAttributeHighlightPartIndex.set(newAnnot, 1);
                AnnotationAttributeHighlightOriginalPartsNumber.set(newAnnot, 1);

                annotationManager.addAnnotation(newAnnot);
                annotationManager.drawAnnotationsFromList(newAnnot);

                selectAnnotationsRef.current([{ id, annotations: [annotToSplit, newAnnot], isNewHighlight: false }]);

                setSplittedHighlightId(id);
            }
        },
        // eslint-disable-next-line
        [webViewerInstance, setSplittedHighlightId]
    );

    const cancelSplitAnnotation = useCallback(() => {
        if (webViewerInstance && splittedHighlightId) {
            Log(webViewerInstance, 'ACTION', ['useAnnotationSpliting', 'cancelSplitAnnotation'], [splittedHighlightId]);
            redrawHighlightRef.current(splittedHighlightId);

            setSplittedHighlightId(null);

            setTimeout(() => {
                const annotationManager = webViewerInstance.Core.annotationManager;
                const annot = annotationManager.getSelectedAnnotations()[0];
                annotationManager.selectAnnotation(annot);
            }, 100);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, setSplittedHighlightId, splittedHighlightId]);

    return { splittedHighlightId, setSplittedHighlightId, splitAnnotation, cancelSplitAnnotation };
};

export default useAnnotationSpliting;
