import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';
import { Capability_ProjectCollaboration } from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient.Types';
import { useCallback, useEffect } from 'react';

const useAuditLog = (projectId: string, auditLogsApiClient: AuditLogsApiClient) => {
    const createAuditLog = useCallback(
        (actionType: string, content: string) =>
            auditLogsApiClient.createAuditLog({
                body: {
                    content,
                    actionType,
                    capability: Capability_ProjectCollaboration,
                },
            }),
        [auditLogsApiClient]
    );
    useEffect(() => {
        createAuditLog('OpenProjectWorkspace', JSON.stringify({ projectId }));
    }, [createAuditLog, projectId]);

    return {
        createAuditLog,
    };
};

export default useAuditLog;
