import React, { useMemo } from 'react';
import { Box, Theme } from '@mui/material';

type CircleSetProps = {
    left: number;
    isFilled: boolean;
};

type GraphProgressProps = {
    sequences: boolean[];
};

function CircleSet({ left, isFilled }: CircleSetProps) {
    const styles = useMemo(() => {
        const commonStyles = {
            position: 'absolute',
            top: '50%',
            left: `${left}px`,
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
        };

        return {
            outer: { ...commonStyles, width: '1.5rem', height: '1.5rem', backgroundColor: (theme: Theme) => theme.palette.stroke.main },
            inner: { ...commonStyles, width: '1.375rem', height: '1.375rem', zIndex: 1, backgroundColor: (theme: Theme) => theme.palette.white.main },
            filled: { ...commonStyles, width: '1.10rem', height: '1.10rem', zIndex: 2, backgroundColor: (theme: Theme) => theme.palette.blue.dark },
        };
    }, [left]);

    const { outer, inner, filled } = styles;

    return (
        <Box>
            <Box sx={outer}></Box>
            <Box sx={inner}></Box>
            {isFilled && <Box sx={filled}></Box>}
        </Box>
    );
}

function GraphProgress({ sequences }: GraphProgressProps) {
    const positions = [10, 70, 130, 190];

    return (
        <Box sx={{ position: 'relative', minWidth: 200, minHeight: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {positions.map((pos, index) => {
                const arrowPos = (pos + positions[index + 1]) / 2;

                return (
                    <React.Fragment key={pos}>
                        <CircleSet left={pos} isFilled={sequences[index]} />
                        {index < positions.length - 1 && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: `${arrowPos}px`,
                                    width: '26px',
                                    height: '2px',
                                    backgroundColor: (theme: Theme) => theme.palette.stroke.main,
                                    transform: 'translate(-50%, -50%)',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        right: 0,
                                        width: '0.5rem',
                                        height: '0.5rem',
                                        borderTop: (theme: Theme) => `2px solid ${theme.palette.stroke.main}`,
                                        borderRight: (theme: Theme) => `2px solid ${theme.palette.stroke.main}`,
                                        transform: 'translateY(-50%) rotate(45deg)',
                                    },
                                }}
                            ></Box>
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    );
}

export default React.memo(GraphProgress);
