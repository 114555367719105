import { Box } from '@mui/material';
import { Icon } from 'UI';

export type StepState = 'prev' | 'current' | 'next';

type StepLabelIconComponentProps = {
    state: StepState;
};

export const IconSize = '1.5rem';

function StepLabelIconComponent(props: StepLabelIconComponentProps) {
    const { state } = props;

    let icon: JSX.Element | null = null;

    switch (state) {
        case 'prev':
            icon = (
                <Box sx={{ color: (theme) => theme.palette.blue.dark, height: IconSize }}>
                    <Icon.CheckCircleFill />
                </Box>
            );
            break;
        case 'current':
        case 'next':
            icon = <Circle state={state} />;
            break;
        default:
            break;
    }

    return icon;
}

type CircleProps = {
    state: 'current' | 'next';
};

const CircleStyle = {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

function Circle(props: CircleProps) {
    const { state } = props;
    const active = state === 'current';

    const MiddleCircle = (
        <Box
            sx={{
                ...CircleStyle,
                width: IconSize,
                height: IconSize,
                backgroundColor: active ? 'blue.dark' : 'none',
                border: (theme) => (active ? 'none' : `2px solid ${theme.palette.text.disabled}`),
            }}
        >
            <Box
                sx={{
                    ...CircleStyle,
                    width: '0.5rem',
                    height: '0.5rem',
                    backgroundColor: (theme) => (active ? 'white !important' : theme.palette.text.disabled),
                }}
            />
        </Box>
    );

    return active ? (
        <Box
            sx={{
                ...CircleStyle,
                backgroundColor: '#D2D8EF',
                position: 'relative',
                boxShadow: '0px 0px 0px 4px rgba(30, 59, 176, 0.20)',
            }}
        >
            {MiddleCircle}
        </Box>
    ) : (
        MiddleCircle
    );
}

export default StepLabelIconComponent;
