import { BoundingBoxSection, FileInfo, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { HighlightLeftTopInformation } from './HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { RgbaColor } from 'UI/functions/hexToRgba';
import { TextSelectionColor } from './CustomWebViewerInstance';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { BulkActionInput } from './Hooks/Annotations/useAnnotationBulkActions';

export type DocumentProps = {
    documentInfo: DocumentInfo;
    headings?: Array<Heading>;
    fileSource: FileSource;
    getDocument?: (docId: string, docName: string) => Promise<FileInfo | null>;
    docFileInfo?: FileInfo | null;
    onDocumentLoad?: () => void;
    onFinishedRendering?: (documentInfo: DocumentInfo) => void;
};

export type DocumentInfo = {
    id: string;
    name: string;
    fileId: string;
};

export type FileSource = 'getDocument' | 'docFile';

export type HighlightsProps<THighlightCustomData> = {
    highlights: Array<Highlight<THighlightCustomData>>;
    highlightColor?: (highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined;
    selectedHighlightIds: Array<string>;
    setSelectedHighlightIds: (ids: Array<string>) => void;
    selectedHighlightChangedEventName: string;
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    highlightToolbars: HighlightToolbars;
    clearSelectedHighlight: () => void;
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>;
    focusOnSelectedHighlightAfterDraw?: boolean;
    keepSelectedHighlightInCentre?: boolean;
    mergeHighlights?: (ids: Array<string>, flowMethods: FlowMethods) => void;
    bulkHighlightsActions?: Array<BulkActionInput>;
    highlightMultiSelectEnabled?: boolean;
    onAnnotationsLoaded?: () => void;
    onAnnotationsRedraw?: () => void;
};

export type SelectedHighlightChangedEvent = {
    ids: Array<string>;
};

export type HighlightToolbars = {
    newHighlightToolbar: HighlightToolbar<NewHighlightToolbarOptions>;
    highlightToolbar: HighlightToolbar<HighlightToolbarOptions>;
    deps?: React.DependencyList;
};

export type HighlightToolbar<TOptions> = {
    render: (options: TOptions) => JSX.Element;
};

type BaseHighlightToolbarOptions = {
    setHighlightToolbarRef: (ref: React.RefObject<HTMLDivElement>) => void;
    addPart: () => void;
};

export type NewHighlightToolbarOptions = BaseHighlightToolbarOptions & {
    getNewHighlight: () => Promise<HighlightViewer>;
    clearSelection: () => void;
};

export type HighlightToolbarOptions = BaseHighlightToolbarOptions & {
    getHighlightViewer: () => Promise<HighlightViewer>;
    showHideAnnotationElements: (id: string, show: boolean) => void;
    isAnnotationSplitted: boolean;
    splitAnnotation: () => void;
    cancelSplitAnnotation: () => void;
};

export type Highlight<TCustomData = {}> = TCustomData & {
    id: string;
    orderNumber: number;
    selectionType: SelectionType;
    boundingBoxSections: Array<BoundingBoxSection>;
    siblingsId?: string;
    isEditable?: boolean;
    multiSelectEnabled?: boolean;
};

export type HighlightColor = RgbaColor;

type HighlightColorsType = {
    default: HighlightColor;
    selected: HighlightColor;
    simpleText: HighlightColor;
};

export const HighlightColors: HighlightColorsType = {
    default: { R: 255, G: 184, B: 0, A: 0.2 },
    selected: { R: 255, G: 184, B: 0, A: 0.5 },
    simpleText: TextSelectionColor,
};

export type HighlightViewer = {
    selectionType: SelectionType;
    boundingBoxSections: Array<BoundingBoxSectionViewer>;
    statement: string;
    isModified: boolean;
};

export type BoundingBoxSectionViewer = BoundingBoxSection & {
    statement: string;
};

export type AdditionalHtmlElementOptions<THighlightCustomData> = {
    highlight: Highlight<THighlightCustomData>;
    selectedHighlightIds: Array<string>;
};

export type AdditionalHtmlElement<THighlightCustomData> = {
    display: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => boolean;
    info: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => AdditionalHtmlElementInfo;
    component: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => JSX.Element;
};

export type AdditionalHtmlElementInfo = {
    pageNumber: number;
    x: number;
    y: number;
    height: number;
    width: number;
    position?: 'top left' | 'top right' | 'bottom left' | 'bottom right';
};

export type Heading = {
    id: string;
    title: string;
    boundingBoxSections: Array<BoundingBoxSection>;
};

export type HighlightNavigationProps = {
    disabled: boolean;
    previousHighlight: () => void;
    nextHighlight: () => void;
};

export type HighlightToolbarActionsState<TState> = {
    actionsState: TState | undefined;
    setHighlightActionsState: (highlightId: string, field: keyof TState, value: boolean) => void;
};
