import { useCallback, useLayoutEffect, useState } from 'react';
import LoginComponent from './Login.Component';
import AuthApiClient, { AuthUser } from 'ApiClients/Auth.ApiClient';

type LoginModuleProps = {
    authApiClient: AuthApiClient;
    setAuth: (user: AuthUser, isAuthenticated: boolean) => void;
};

function LoginModule(props: LoginModuleProps) {
    const { authApiClient, setAuth } = props;
    const [isLoading, setIsLoading] = useState(true);

    const login = useCallback(() => authApiClient.login(), [authApiClient]);
    useLayoutEffect(() => {
        authApiClient.getUser().then((auth) => {
            if (auth) {
                setAuth(auth.user, auth.isAuthenticated);
                if (!auth.isAuthenticated) login();
                else setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    }, [authApiClient, setAuth, login]);

    return <LoginComponent login={login} isLoading={isLoading} />;
}

export default LoginModule;
