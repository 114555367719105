import AnnotationsApiClient, { RootRouteParams } from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Annotation, AnnotationStatus, BoundingBoxSection, ProjectSimple, SelectionType, VerificationDocument } from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useMemo } from 'react';
import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ObjectChangeType } from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';
import { ApiGetDataset, useApiGet } from 'ApiClients/Hooks/ApiWrappers';
import { AnnotationFilters } from './useAnnotationsFilters';
import useFilteredAnnotations from './useFilteredAnnotations';
import { AnnotationTagsProps } from '../useAnnotationsTags';
import { AnnotationKeywordsProps } from '../useAnnotationsKeywords';
import { SelectedAnnotation } from './useAnnotationsSelecting';
import { GetPageAndCoordinates, OrderByHighlightPosition } from 'Views/Common/PdfViewerWithToolbar/Functions';

export type AnnotationsDataset = ApiGetDataset<Array<Annotation>, RootRouteParams>;

export type SelectAnnotationOptions = {
    dispatchEvent: boolean;
    callback?: () => void;
    shiftKey?: boolean;
};

export type AnnotationsProps = {
    annotationsDs: AnnotationsDataset;
    refreshAnnotation: (annotationId: string) => void;
    annotationFilters: AnnotationFilters;
    addAnnotation: (selectionType: SelectionType, boundingBoxSections: Array<BoundingBoxSection>, statement: string, flowMethods: FlowMethods) => void;
    deleteAnnotation: (annotationId: string, flowMethods: FlowMethods) => void;
    assignMemberToAnnotation: (annotationId: string, memberId: string, flowMethods?: FlowMethods) => void;
    markAnnotationNotificationAsRead: (annotationId: string) => void;
    getAvailablePreviousStatuses: (annotationId: string, flowMethods: FlowMethods<Array<AnnotationStatus>>) => void;
    reorderAnnotationsNumbers: (flowMethods: FlowMethods) => void;
    acceptAnnotationStage: (annotationId: string, currentStatus: AnnotationStatus, flowMethods: FlowMethods) => void;
    setNeedsReview: (annotationId: string, status: AnnotationStatus, comment: string, flowMethods: FlowMethods) => void;
    annotationTagsProps: AnnotationTagsProps;
    annotationKeywordsProps: AnnotationKeywordsProps;
    verifyAnnotation: (id: string) => void;
    selectedAnnotation: Annotation | null;
    selectedAnnotationId: string | null;
    prevAnnotationIndex: number;
    nextAnnotationIndex: number;
    setSelectedAnnotationId: (id: string | null, options: SelectAnnotationOptions) => void;
    openCommentDrawer: (annotationId: string) => void;
    modals: AnnotationModals;
    isAnnotationModificationAllowed: boolean;
    selectedAnnotationIds: Array<SelectedAnnotation>;
    setSelectedAnnotationIds: (annots: Array<SelectedAnnotation>, options: SelectAnnotationOptions) => void;
    selectAnnotations: (annots: Array<SelectedAnnotation>, options: SelectAnnotationOptions) => void;
    deselectAnnotations: (annots: Array<SelectedAnnotation>, options: SelectAnnotationOptions) => void;
    annotationsListMounted: boolean;
    setAnnotationsListMounted: (mounted: boolean) => void;
};

export type AnnotationModals = {
    openDeleteModal: (id: string) => void;
    openAssignmentModal: (id: string) => void;
    openAssertionModal: (id: string) => void;
    openSuppInfoSummaryModal: (id: string) => void;
    openTagAssignmentModal: (id: string) => void;
    openAnnotationFiltersModal: () => void;
    openNeedsReviewModal: (id: string) => void;
};

const useAnnotations = (
    project: ProjectSimple,
    annotationApi: AnnotationsApiClient,
    notifiApi: NotificationsApiClient,
    annotationFilters: AnnotationFilters,
    verificationDocumentInfo: VerificationDocument | null,
    createAuditLog: (actionType: string, content: string) => void,
    getSupportingInformationPropositions: (
        annotationId: string,
        options?:
            | {
                  keepPrevData?: boolean | undefined;
                  onInit?: (() => void) | undefined;
                  onSuccess?: (() => void) | undefined;
              }
            | undefined
    ) => void,
    openSupportingInformationPropositions: () => void
) => {
    const rootRouteParams: RootRouteParams = useMemo(() => ({ projectId: project.id, projectVersionId: project.workingVersion.id }), [project]);

    const _annotationsDs: AnnotationsDataset = useApiGet<Array<Annotation>, RootRouteParams>({
        method: annotationApi.getAnnotations,
        initialData: [],
        defaultInput: { input: rootRouteParams },
        initFetch: true,
    });
    const { data: __annotations, setData: setAnnotations } = _annotationsDs;

    const refreshAnnotation = useCallback(
        (annotationId: string) => {
            annotationApi.getAnnotation(
                { ...rootRouteParams, annotationId },
                {
                    onSuccess: (annotation) => {
                        if (annotation) {
                            setAnnotations((prev) => {
                                const index = prev.findIndex((a) => a.id === annotationId);
                                if (index >= 0) {
                                    prev[index] = annotation;
                                    return [...prev];
                                } else {
                                    return [...prev, annotation];
                                }
                            });
                        }
                    },
                }
            );
        },
        [rootRouteParams, annotationApi, setAnnotations]
    );
    const removeAnnotation = useCallback((annotationId: string) => setAnnotations((prev) => prev.filter((a) => a.id !== annotationId)), [setAnnotations]);

    const handleAnnotationChanged = useCallback(
        (annotationId: string, changeType: ObjectChangeType) => {
            switch (changeType) {
                case ObjectChangeType.Create:
                case ObjectChangeType.Update:
                    refreshAnnotation(annotationId);
                    break;
                case ObjectChangeType.Delete:
                    removeAnnotation(annotationId);
                    break;
            }
        },
        [removeAnnotation, refreshAnnotation]
    );

    const _annotations = useFilteredAnnotations(__annotations, annotationFilters, (filters) => createAuditLog('FilteringAnnotation', filters));
    const annotations = useMemo(() => {
        return _annotations.sort((a, b) => OrderByHighlightPosition(GetPageAndCoordinates(a), GetPageAndCoordinates(b)));
    }, [_annotations]);

    const addAnnotation = (selectionType: SelectionType, boundingBoxSections: Array<BoundingBoxSection>, statement: string, flowMethods: FlowMethods) =>
        annotationApi.addAnnotation(rootRouteParams, {
            ...flowMethods,
            body: { selectionType, boundingBoxSections, statement },
            onSuccess: (id: string) => {
                flowMethods.onSuccess?.(id);
                refreshAnnotation(id);
            },
        });
    const deleteAnnotation = (annotationId: string, flowMethods: FlowMethods) =>
        annotationApi.deleteAnnotation(
            { ...rootRouteParams, annotationId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    removeAnnotation(annotationId);
                },
            }
        );
    const assignMemberToAnnotation = (annotationId: string, memberId: string, flowMethods?: FlowMethods) => {
        annotationApi.assignMember(
            { projectId: project.id, annotationId, memberId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    refreshAnnotation(annotationId);
                },
            }
        );
    };
    const markAnnotationNotificationAsRead = useCallback(
        (annotationId: string) => notifiApi.markNotificationAsRead(annotationId, () => refreshAnnotation(annotationId)),
        [notifiApi, refreshAnnotation]
    );

    const getAvailablePreviousStatuses = (annotationId: string, flowMethods: FlowMethods<Array<AnnotationStatus>>) =>
        annotationApi.getAvailablePreviousStatuses({ ...rootRouteParams, annotationId }, flowMethods);

    const verifyAnnotation = useCallback(
        (id: string) => getSupportingInformationPropositions(id, { onInit: openSupportingInformationPropositions }),
        [getSupportingInformationPropositions, openSupportingInformationPropositions]
    );

    const annotationsDs = useMemo(() => ({ ..._annotationsDs, data: annotations }), [_annotationsDs, annotations]);
    const isAnnotationModificationAllowed = useMemo(() => verificationDocumentInfo?.isAnnotationModificationAllowed || false, [verificationDocumentInfo]);

    return {
        annotationsDs,
        refreshAnnotation,
        annotationFilters,
        addAnnotation,
        deleteAnnotation,
        assignMemberToAnnotation,
        markAnnotationNotificationAsRead,
        getAvailablePreviousStatuses,
        handleAnnotationChanged,
        verifyAnnotation,
        isAnnotationModificationAllowed,
    };
};

export default useAnnotations;
