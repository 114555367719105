export type VerifiSpinnerProps = {
    width?: string;
    height?: string;
    color?: string;
};

const Duration = '0.5s';

function VerifiSpinnerComponent(props: VerifiSpinnerProps) {
    const { width: _width, height: _height, color: _color } = props;
    const width = _width ? _width : _height ? '100%' : '92';
    const height = _height ? _height : _width ? '100%' : '80';
    const color = _color ? _color : '#0087FA';

    return (
        <svg width={width} height={height} viewBox='0 0 92 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M22.1993 23.1984L36.4955 31.4527V0H55.53V26.6269C55.53 29.6457 53.9172 32.4351 51.3006 33.9406L31.1962 45.5095C28.5856 47.0118 25.3727 47.0096 22.7643 45.5035L12.6821 39.6827L22.1993 23.1984L22.1993 23.1984Z'
                fill={color}
                fillOpacity='0.5'
            >
                <animate id='a1' attributeName='fill-opacity' attributeType='CSS' values='0.5;1;0.5' dur={Duration} repeatCount='1' begin='0;a3.end' />
            </path>
            <path
                d='M78.696 38.9312L64.3992 47.1849L91.6378 62.9112L82.1206 79.3955L59.0613 66.0824C56.4469 64.5724 54.838 61.7814 54.8418 58.7621L54.8754 35.567C54.8798 32.5554 56.4881 29.7737 59.0965 28.2677L69.1787 22.4469L78.6959 38.9312L78.696 38.9312Z'
                fill={color}
                fillOpacity='0.5'
            >
                <animate id='a2' attributeName='fill-opacity' attributeType='CSS' values='0.5;1;0.5' dur={Duration} repeatCount='1' begin='a1.end' />
            </path>
            <path
                d='M36.7558 79.9965V63.4885L9.51721 79.2143L0 62.7305L23.0598 49.4168C25.6742 47.9074 28.896 47.9096 31.5088 49.4228L51.5796 61.0495C54.1858 62.5589 55.7903 65.3428 55.7903 68.3544V79.9965H36.7558L36.7558 79.9965Z'
                fill={color}
                fillOpacity='0.5'
            >
                <animate id='a3' attributeName='fill-opacity' attributeType='CSS' values='0.5;1;0.5' dur={Duration} repeatCount='1' begin='a2.end' />
            </path>
        </svg>
    );
}

export default VerifiSpinnerComponent;
