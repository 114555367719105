import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import useSupportingDocuments, { SupportingDocumentsExtendedMethods, SupportingDocumentsProps } from './useSupportingDocuments';
import { VerificationDocument } from 'ApiClients/SterlingApiClients/Types';
import useVerificationDocument, { VerificationDocumentExtendedMethods } from './useVerificationDocument';
import useWebSearchDocuments, { WebSearchDocumentsProps } from './useWebSearchDocuments';
import { useRef } from 'react';

type DocumentsScope = {
    verificationDocumentInfo: VerificationDocument | null;
    verificationDocumentExtendedMethods: VerificationDocumentExtendedMethods;
    supportingDocumentsProps: SupportingDocumentsProps;
    supportingDocumentsExtendedMethods: SupportingDocumentsExtendedMethods;
    webSearchDocumentsProps: WebSearchDocumentsProps;
};

const useDocumentsScope = (
    projectId: string,
    projectVersionId: string,
    docsApi: ProjectDocumentsApiClient,
    setSelectedSupportingHighlightIdRef: React.MutableRefObject<(id: string | null, options: { dispatchEvent: boolean }) => void>,
    closeSupportingInformationPropositionsRef: React.MutableRefObject<() => void>
): DocumentsScope => {
    const switchWebSearchDocumentRef = useRef<(url: string | null) => void>(() => {});

    const verifivationDocumentProps = useVerificationDocument(projectId, projectVersionId, docsApi);
    const supportingDocumentsProps = useSupportingDocuments(
        projectId,
        projectVersionId,
        docsApi,
        setSelectedSupportingHighlightIdRef,
        closeSupportingInformationPropositionsRef,
        switchWebSearchDocumentRef
    );
    const webSearchDocumentsProps = useWebSearchDocuments(
        projectId,
        projectVersionId,
        docsApi,
        setSelectedSupportingHighlightIdRef,
        closeSupportingInformationPropositionsRef,
        supportingDocumentsProps.props.switchSupportingDocument,
        supportingDocumentsProps.extendedMethods.refetchSupportingDocuments
    );

    switchWebSearchDocumentRef.current = webSearchDocumentsProps.switchWebSearchDocument;

    return {
        verificationDocumentInfo: verifivationDocumentProps.verificationDocumentInfo,
        verificationDocumentExtendedMethods: verifivationDocumentProps.verificationDocumentExtendedMethods,
        supportingDocumentsProps: supportingDocumentsProps.props,
        supportingDocumentsExtendedMethods: supportingDocumentsProps.extendedMethods,
        webSearchDocumentsProps,
    };
};

export default useDocumentsScope;
