import { useState } from 'react';
import { Box, FormControl } from '@mui/material';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Annotation, AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { Button, Dialog, Menu, Select, TextField, Typography } from 'UI';
import { AnnotationStatusDictionary } from 'Views/Common/Types';

export type NeedsReviewModalComponentProps = {
    annotation: Annotation;
    isOpen: boolean;
    closeModal: () => void;
    getAvailableStatuses: (annotationId: string, flowMethods: FlowMethods<Array<AnnotationStatus>>) => void;
    setNeedsReview: (annotationId: string, status: AnnotationStatus, comment: string, flowMethods: FlowMethods) => void;
};

function NeedsReviewModalComponent({ isOpen, annotation, getAvailableStatuses, setNeedsReview, closeModal }: NeedsReviewModalComponentProps) {
    const [annotationStatus, setAnnotationStatus] = useState<AnnotationStatus>(annotation.status);
    const [comment, setComment] = useState<string>('');

    const { data: options } = usePromiseWithFlowMethods<{ annotationId: string }, Array<AnnotationStatus>>({
        method: (input, flowMethods) => getAvailableStatuses(input.annotationId, flowMethods),
        initialData: [],
        initFetch: { input: { annotationId: annotation.id } },
    });

    const { wrappedMethod: submitReview } = usePromiseWithFlowMethods<{ annotationId: string; status: AnnotationStatus; comment: string }, {}>({
        method: (input, flowMethods) =>
            setNeedsReview(input.annotationId, input.status, input.comment, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess && flowMethods.onSuccess(data);
                    closeModal();
                },
            }),
        initialData: {},
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        submitReview({ annotationId: annotation.id, status: annotationStatus, comment });
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Needs Review</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            marginBottom: '1rem',
                        }}
                    >
                        <Box>
                            <Typography variant='subtitle1'>Mark as needing review</Typography>
                            <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
                                And select which stage to send to
                            </Typography>
                        </Box>

                        <FormControl>
                            <Select
                                value={annotationStatus}
                                onChange={(event) => setAnnotationStatus(Number(event.target.value) as AnnotationStatus)}
                                data-testid={`dropdown-needs-review-select`}
                            >
                                {[...options, annotation.status].map((status, index) => (
                                    <Menu.Item key={`annotation-status-${index}`} value={status}>
                                        {AnnotationStatusDictionary[status].name}
                                    </Menu.Item>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            placeholder='Add comment'
                            multiline={true}
                            sx={{
                                '.MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                },
                            }}
                            value={comment}
                            rows={6}
                            onChange={(e) => setComment(e.target.value)}
                            inputProps={{
                                maxLength: 1000,
                            }}
                            required
                        />
                    </Box>
                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                        >
                            Close
                        </Button>
                        <Button
                            color='error'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                            disabled={!comment || annotationStatus === null}
                        >
                            Needs Review
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default NeedsReviewModalComponent;
