import { Button } from 'UI';
import { ButtonProps } from 'UI/Button';

function CommentTextButton(props: ButtonProps) {
    return (
        <Button variant='text' {...props} sx={{ fontSize: '0.625rem', fontWeight: 600, padding: '0.25rem 1rem', height: '1.5rem', ...props.sx }}>
            {props.children}
        </Button>
    );
}

export default CommentTextButton;
