import { useCallback, useMemo, useState } from 'react';

type ModalState<TState> = TState & {
    isOpen: boolean;
};

type ModalContextWithInput<TState, TOpenInput = string> = ModalContext<TState> & {
    openModal: (input: TOpenInput) => void;
};

type ModalContextWithoutInput<TState> = ModalContext<TState> & {
    openModal: () => void;
};

type ModalContext<TState> = {
    state: ModalState<TState>;
    closeModal: () => void;
};

export type ProjectWorkspaceModals = {
    assignmentModal: ModalContextWithInput<DefaultAnnotationModalState>;
    deleteModal: ModalContextWithInput<DefaultAnnotationModalState>;
    assertionModal: ModalContextWithInput<DefaultAnnotationModalState>;
    suppInfoSummaryModal: ModalContextWithInput<DefaultAnnotationModalState>;
    projectMembersModal: ModalContextWithoutInput<{}>;
    tagManagementModal: ModalContextWithoutInput<{}>;
    tagAssignmentModal: ModalContextWithInput<DefaultAnnotationModalState>;
    annotationFiltersModal: ModalContextWithoutInput<{}>;
    needsReviewModal: ModalContextWithInput<DefaultAnnotationModalState>;
};

type DefaultAnnotationModalState = {
    annotationId: string | null;
};

const useProjectWorkspaceModals = (): ProjectWorkspaceModals => {
    const assignmentModal = useDefaultAnnotationModal();
    const deleteModal = useDefaultAnnotationModal();
    const assertionModal = useDefaultAnnotationModal();
    const suppInfoSummaryModal = useDefaultAnnotationModal();
    const projectMembersModal = useDefaultProjectModal();
    const tagManagementModal = useDefaultProjectModal();
    const tagAssignmentModal = useDefaultAnnotationModal();
    const annotationFiltersModal = useDefaultProjectModal();
    const needsReviewModal = useDefaultAnnotationModal();

    const projectWorkspaceModals: ProjectWorkspaceModals = useMemo(() => {
        return {
            assignmentModal,
            deleteModal,
            assertionModal,
            suppInfoSummaryModal,
            projectMembersModal,
            tagManagementModal,
            tagAssignmentModal,
            annotationFiltersModal,
            needsReviewModal,
        };
    }, [
        assignmentModal,
        deleteModal,
        assertionModal,
        suppInfoSummaryModal,
        projectMembersModal,
        tagManagementModal,
        tagAssignmentModal,
        annotationFiltersModal,
        needsReviewModal,
    ]);

    return projectWorkspaceModals;
};

const useDefaultAnnotationModal = () => {
    const [modalState, setModalState] = useState<ModalState<DefaultAnnotationModalState>>({
        isOpen: false,
        annotationId: null,
    });
    const openModal = useCallback((annotationId: string) => setModalState({ isOpen: true, annotationId }), []);
    const closeModal = useCallback(() => setModalState({ isOpen: false, annotationId: null }), []);

    const defaultAnnotationModal = useMemo(() => ({ state: modalState, openModal, closeModal }), [modalState, openModal, closeModal]);

    return defaultAnnotationModal;
};

const useDefaultProjectModal = () => {
    const [modalState, setModalState] = useState<ModalState<{}>>({
        isOpen: false,
    });
    const openModal = useCallback(() => setModalState({ isOpen: true }), []);
    const closeModal = useCallback(() => setModalState({ isOpen: false }), []);

    const defaultProjectModal = useMemo(() => ({ state: modalState, openModal, closeModal }), [modalState, openModal, closeModal]);

    return defaultProjectModal;
};

export default useProjectWorkspaceModals;
