import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useState } from 'react';
import { Box, Divider, Input, InputAdornment } from '@mui/material';
import useStateCallback from 'Hooks/useStateCallback';
import useStateById from 'Hooks/useStateById';
import { Icon } from 'UI';
import { IconType } from 'UI/Icon';

type SearchPopupComponentProps = {
    webViewerInstance: WebViewerInstance;
    closePopup: () => void;
};

function SearchPopupComponent(props: SearchPopupComponentProps) {
    const { webViewerInstance, closePopup } = props;

    const [input, setInput] = useState<string>('');

    const searchResultColor = new webViewerInstance.Core.Annotations.Color(0, 141, 255, 0.3);
    const selectedSearchResultColor = new webViewerInstance.Core.Annotations.Color(0, 141, 255, 0.7);

    const [searchedPages, _setSearchedPagesById, _setSearchedPages] = useStateById<boolean>();
    const setSearchedPages = (pageNum: number) => _setSearchedPagesById.Value(pageNum.toString(), true);

    const [searchResults, _setSearchResults] = useStateCallback<Array<Core.Annotations.TextHighlightAnnotation>>([]);
    const setSearchResults = (annot: Core.Annotations.TextHighlightAnnotation) =>
        _setSearchResults((prev) => {
            let newState = [...prev];
            newState.push(annot);
            return newState;
        });

    const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState<number | null>(null);
    const [searchedValue, setSearchedValue] = useState<string | null>(null);

    useEffect(() => {
        if (Object.keys(searchedPages).length === webViewerInstance.Core.documentViewer.getPageCount()) {
            webViewerInstance.Core.annotationManager.addAnnotations(searchResults);
            webViewerInstance.Core.annotationManager.drawAnnotationsFromList(searchResults).then(() => {
                if (searchResults.length > 0) setSelectedSearchResultIndex(0);
                else setSelectedSearchResultIndex(-1);
            });
        }
    }, [webViewerInstance, searchedPages, searchResults]);

    useEffect(() => {
        if (selectedSearchResultIndex !== null && selectedSearchResultIndex >= 0) {
            const annot = searchResults[selectedSearchResultIndex];
            annot.StrokeColor = selectedSearchResultColor;
            webViewerInstance.Core.annotationManager.redrawAnnotation(annot);
            webViewerInstance.Core.annotationManager.jumpToAnnotation(annot);
        }
        // eslint-disable-next-line
    }, [selectedSearchResultIndex]);

    const clearSearchResults = () => {
        webViewerInstance.Core.annotationManager.deleteAnnotations(searchResults);
        _setSearchResults([]);
        _setSearchedPages({});
        setSearchedValue(null);
        setSelectedSearchResultIndex(null);
    };

    let inputEndAdornment: JSX.Element | undefined = undefined;
    if (selectedSearchResultIndex !== null) {
        const text = selectedSearchResultIndex >= 0 ? `${selectedSearchResultIndex + 1} of ${searchResults.length}` : '0 of 0';
        inputEndAdornment = (
            <InputAdornment
                sx={{
                    marginRight: '1rem',
                    fontWeight: 400,
                    fontSize: '0.9375rem',
                    lineHeight: '1.5rem',
                    color: (theme) => theme.palette.blue.medium,
                    '& .MuiTypography-root': {
                        fontFamily: 'inherit',
                        fontStyle: 'inherit',
                        fontWeight: 'inherit',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        color: 'inherit',
                    },
                }}
                position='end'
            >
                {text}
            </InputAdornment>
        );
    }

    const search = (val: string) => {
        if (val === '') return;

        clearSearchResults();
        setSearchedValue(val);
        _search(webViewerInstance, val, setSearchedPages, setSearchResults, searchResultColor);
    };

    const switchSearchResult = (type: 'prev' | 'next') => {
        if (input === searchedValue && selectedSearchResultIndex !== null) {
            if (searchResults.length > 1) {
                let newSearchResultIndex: number | null = null;
                switch (type) {
                    case 'prev':
                        newSearchResultIndex = selectedSearchResultIndex === 0 ? searchResults.length - 1 : selectedSearchResultIndex - 1;
                        break;
                    case 'next':
                        newSearchResultIndex = selectedSearchResultIndex === searchResults.length - 1 ? 0 : selectedSearchResultIndex + 1;
                        break;
                }
                if (newSearchResultIndex !== null) {
                    const annot = searchResults[selectedSearchResultIndex];
                    annot.StrokeColor = searchResultColor;
                    webViewerInstance.Core.annotationManager.redrawAnnotation(annot);
                    setSelectedSearchResultIndex(newSearchResultIndex);
                }
            }
        } else search(input);
    };

    const actions: Array<{ icon: IconType; onClick: () => void }> = [
        {
            icon: Icon.ArrowSimpleUp,
            onClick: () => switchSearchResult('prev'),
        },
        {
            icon: Icon.ArrowSimpleDown,
            onClick: () => switchSearchResult('next'),
        },
        {
            icon: Icon.CloseBold,
            onClick: () => {
                clearSearchResults();
                closePopup();
            },
        },
    ];

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                zIndex: '20',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '0.25rem',
            }}
        >
            {webViewerInstance && (
                <Box
                    sx={{
                        height: '2.5rem',
                        backgroundColor: (theme) => theme.palette.blue.dark,
                        display: 'flex',
                        padding: '0.125rem',
                        boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                        borderRadius: '0.25rem',
                    }}
                >
                    <Input
                        sx={{
                            background: '#fff',
                            border: (theme) => `1px solid ${theme.palette.blue.dark}`,
                            borderRadius: '0.25rem',
                            width: '14.5rem',
                            paddingLeft: '1rem',
                            borderBottomStyle: 'none',
                            fontWeight: '700',
                            fontSize: '0.75rem',
                            lineHeight: '1.5rem',
                            '::before': {
                                borderBottomStyle: 'none !important',
                            },
                            '::after': {
                                borderBottomStyle: 'none !important',
                            },
                            ':hover': {
                                borderBottomStyle: 'none !important',
                            },
                        }}
                        endAdornment={inputEndAdornment}
                        placeholder='Search in document'
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                let val = e.target.value;
                                search(val);
                            }
                        }}
                        autoFocus
                    />
                    {actions.map((action, index) => (
                        <Box key={`search-popup-action-${index}`}>
                            <Box
                                sx={{
                                    width: '2.5rem',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: (theme) => theme.palette.white.main,
                                }}
                            >
                                <action.icon cursor='pointer' width='0.75rem' height='auto' onClick={action.onClick} />
                            </Box>
                            {!(index === actions.length - 1) && <Divider orientation='vertical' flexItem />}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
}

const _search = (
    webViewerInstance: WebViewerInstance,
    searchPattern: string,
    setSearchedPages: (pageNum: number) => void,
    setSearchResults: (annot: Core.Annotations.TextHighlightAnnotation) => void,
    searchResultColor: Core.Annotations.Color
) => {
    const { Annotations, Search, Math, documentViewer } = webViewerInstance.Core;
    const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
    const searchOptions = {
        // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
        fullSearch: true,
        // The callback function that is called when the search returns a result.
        onResult: (result: any) => {
            // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
            if (result.resultCode === Search.ResultCode.FOUND) {
                const annot = new Annotations.TextHighlightAnnotation();
                annot.PageNumber = result.pageNum;
                let quads: Array<Core.Math.Quad> = [];
                result.quads.forEach((q: any) => {
                    const quad = q.getPoints();
                    quads.push(new Math.Quad(quad.x1, quad.y1, quad.x2, quad.y2, quad.x3, quad.y3, quad.x4, quad.y4));
                });
                annot.Quads = quads;
                annot.StrokeColor = searchResultColor;
                setSearchResults(annot);
            }
        },
        onPageEnd: (result: any) => setSearchedPages(result.pageNum),
    };

    documentViewer.textSearchInit(searchPattern, mode, searchOptions);
};

export default SearchPopupComponent;
