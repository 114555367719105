import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { AdditionalHtmlElement, Highlight, HighlightColor } from '../../PdfViewerWithToolbar.Types';
import {
    HighlightLeftTopInformation,
    HighlightLeftTopInformationComponentState,
} from '../../HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { getHighlightId, getParentHighlightId, isFirstAnnotationOfHighlight } from './Drawing/ViewerAnnotations/Attributes';
import { EmotionCache } from '@emotion/react';
import { drawHighlightPartNumber, eraseHighlightPartNumber, recalculateHighlightPartNumberPosition } from './Drawing/HtmlElements/HighlightPartNumber';
import { LeftTopInformationHeight, LeftTopInformationWidth } from './Drawing/ViewerAnnotations/HighlightLeftTopInformation';
import _ from 'lodash';
import Log from '../../Logger';
import { GetAnnotationsByHighlightId, SupportStateHighlight } from './useAnnotationsSelecting';
import RedrawHighlight from './Drawing/RedrawHighlight';

export type HighlightExtended<THighlightCustomData> = Highlight<THighlightCustomData> & {
    siblings: Array<Highlight<THighlightCustomData>>;
};

export type HighlightStatesMethods<THighlightCustomData> = {
    leftTopInformation: React.MutableRefObject<React.Dispatch<React.SetStateAction<HighlightLeftTopInformationComponentState<THighlightCustomData>>> | null>;
};

const useDrawing = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightIds: Array<string>,
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined,
    selectAnnotations: (highlights: Array<SupportStateHighlight>) => void,
    additionalHtmlElements: Array<AdditionalHtmlElement<THighlightCustomData>> | undefined,
    focusOnSelectedHighlightAfterDraw: boolean | undefined,
    onAnnotationsRedraw: (() => void) | undefined,
    annotationElementsEnabled: boolean
) => {
    const [localSelectedHighlightIds, setLocalSelectedHighlightIds] = useState<Array<string>>([]);
    const [localHighlights, setLocalHighlights] = useState<Array<Highlight<THighlightCustomData>>>([]);
    const [localHighlightsById, setLocalHighlightsById] = useState<{ [key: string]: HighlightExtended<THighlightCustomData> }>({});
    const [localHighlightsStatesMethodsById, setLocalHighlightsStatesMethodsById] = useState<{ [key: string]: HighlightStatesMethods<THighlightCustomData> }>(
        {}
    );
    const documentLoaded = useMemo(
        () => webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && !documentLoading,
        [webViewerInstance, documentLoading]
    );

    const highlightsRef = useRef<Array<Highlight<THighlightCustomData>>>(highlights);
    highlightsRef.current = highlights;

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useDrawing', 'highlights'], [highlights]);
        if (webViewerInstance && documentLoaded) {
            // Reverse the highlights array to draw the first highlight on top of the others
            const _highlights = [...highlights].reverse();
            let highlightsById: { [key: string]: HighlightExtended<THighlightCustomData> } = {};
            let highlightsStatesMethodsById: { [key: string]: HighlightStatesMethods<THighlightCustomData> } = {};
            let highlightsByPage: { [key: number]: Array<Highlight<THighlightCustomData>> } = {};

            _highlights.forEach((h) => {
                if (!highlightsByPage[h.boundingBoxSections[0].pageNumber]) highlightsByPage[h.boundingBoxSections[0].pageNumber] = [];

                highlightsByPage[h.boundingBoxSections[0].pageNumber].push(h);
            });

            _highlights.forEach((h) => {
                const siblings = getSiblings(highlightsByPage[h.boundingBoxSections[0].pageNumber], h);
                const highlight: HighlightExtended<THighlightCustomData> = { ...h, siblings };
                highlightsById[h.id] = highlight;

                let highlightStatesMethods = localHighlightsStatesMethodsById[h.id];
                if (!highlightStatesMethods) {
                    const leftTopInformationStateMethod =
                        createRef<React.Dispatch<React.SetStateAction<HighlightLeftTopInformationComponentState<THighlightCustomData>>>>();
                    highlightStatesMethods = {
                        leftTopInformation: leftTopInformationStateMethod,
                    };
                }

                highlightsStatesMethodsById[h.id] = highlightStatesMethods;

                if (!_.isEqual(highlight, localHighlightsById[highlight.id])) {
                    RedrawHighlight(
                        webViewerInstance,
                        highlight,
                        highlightStatesMethods,
                        selectedHighlightIds,
                        highlightLeftTopInformation,
                        emotionCache,
                        highlightColor,
                        selectAnnotations,
                        additionalHtmlElements,
                        annotationElementsEnabled
                    );

                    if (selectedHighlightIds.length === 1 && selectedHighlightIds[0] === highlight.id) {
                        const highlightAnnotations = GetAnnotationsByHighlightId(webViewerInstance, highlight.id);
                        const firstAnnotation = highlightAnnotations.firstAnnotation;
                        if (firstAnnotation) {
                            const annotationManager = webViewerInstance.Core.annotationManager;
                            annotationManager.jumpToAnnotation(firstAnnotation);
                            selectAnnotations([highlightAnnotations]);
                            drawHighlightPartNumber(webViewerInstance, highlight.id, highlight.isEditable, emotionCache, annotationElementsEnabled);
                        }
                    }
                }
            });

            localHighlights.forEach((h) => {
                if (!highlightsById[h.id]) {
                    deleteHighlight(webViewerInstance, h.id);
                }
            });

            setLocalHighlights(_highlights);
            setLocalHighlightsById(highlightsById);
            setLocalHighlightsStatesMethodsById(highlightsStatesMethodsById);

            onAnnotationsRedraw?.();
        }
        // eslint-disable-next-line
    }, [webViewerInstance, documentLoaded, highlights]);

    useEffect(() => {
        Log(webViewerInstance, 'HOOK', ['useDrawing', 'selectedHighlight'], [localSelectedHighlightIds, selectedHighlightIds]);
        if (
            webViewerInstance &&
            documentLoaded &&
            (localSelectedHighlightIds.length !== selectedHighlightIds.length || !localSelectedHighlightIds.every((id) => selectedHighlightIds.includes(id)))
        ) {
            if (localSelectedHighlightIds.length > 0) {
                localSelectedHighlightIds.forEach((id) => {
                    const prevSelectedHighlight = highlightsRef.current.find((h) => h.id === id);
                    const localHighlight = localHighlightsById[id];
                    const highlightStatesMethods = localHighlightsStatesMethodsById[id];
                    if (prevSelectedHighlight && localHighlight && highlightStatesMethods) {
                        RedrawHighlight(
                            webViewerInstance,
                            localHighlight,
                            highlightStatesMethods,
                            selectedHighlightIds,
                            highlightLeftTopInformation,
                            emotionCache,
                            highlightColor,
                            selectAnnotations,
                            additionalHtmlElements,
                            annotationElementsEnabled
                        );
                    }
                });

                eraseHighlightPartNumber(webViewerInstance);
            }

            if (selectedHighlightIds.length > 0) {
                selectedHighlightIds.forEach((id) => {
                    const newSelectedHighlight = highlightsRef.current.find((h) => h.id === id);
                    const localHighlight = localHighlightsById[id];
                    const highlightStatesMethods = localHighlightsStatesMethodsById[id];
                    if (newSelectedHighlight && localHighlight) {
                        RedrawHighlight(
                            webViewerInstance,
                            localHighlight,
                            highlightStatesMethods,
                            selectedHighlightIds,
                            highlightLeftTopInformation,
                            emotionCache,
                            highlightColor,
                            selectAnnotations,
                            additionalHtmlElements,
                            annotationElementsEnabled
                        );
                    }
                });

                if (selectedHighlightIds.length === 1) {
                    if (focusOnSelectedHighlightAfterDraw && selectedHighlightIds.length === 1) {
                        const annotationManager = webViewerInstance.Core.annotationManager;
                        const annot = annotationManager
                            .getAnnotationsList()
                            .find((a) => getHighlightId(a) === selectedHighlightIds[0] && isFirstAnnotationOfHighlight(a));
                        if (annot) {
                            annotationManager.jumpToAnnotation(annot);
                        }
                    }

                    const localHighlight = localHighlightsById[selectedHighlightIds[0]];
                    if (localHighlight) {
                        drawHighlightPartNumber(webViewerInstance, localHighlight.id, localHighlight.isEditable, emotionCache, annotationElementsEnabled);
                    }
                }
            }

            setLocalSelectedHighlightIds(selectedHighlightIds);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, documentLoaded, selectedHighlightIds]);

    const _redrawHighlight = (id: string) => {
        if (webViewerInstance && documentLoaded) {
            const highlight = localHighlightsById[id];
            const highlightStatesMethods = localHighlightsStatesMethodsById[id];
            if (highlight) {
                RedrawHighlight(
                    webViewerInstance,
                    highlight,
                    highlightStatesMethods,
                    selectedHighlightIds,
                    highlightLeftTopInformation,
                    emotionCache,
                    highlightColor,
                    selectAnnotations,
                    additionalHtmlElements,
                    annotationElementsEnabled
                );
            }
        }
    };

    return { redrawHighlight: _redrawHighlight };
};

const deleteHighlight = (webViewerInstance: WebViewerInstance, highlightId: string) => {
    Log(webViewerInstance, 'ACTION', ['useDrawing', 'deleteHighlight'], [highlightId]);
    const { annotationManager } = webViewerInstance.Core;
    const allAnnotations: Array<Core.Annotations.Annotation> = annotationManager.getAnnotationsList();
    const allHighlightsAnnotations: Array<Core.Annotations.Annotation> = allAnnotations.filter(
        (a) => getHighlightId(a) === highlightId || getParentHighlightId(a) === highlightId
    );

    if (allHighlightsAnnotations.length > 0) {
        Log(webViewerInstance, 'ACTION', ['useDrawing', 'deleteHighlight', 'deleteAnnotations'], [allHighlightsAnnotations]);
        annotationManager.deleteAnnotations(allHighlightsAnnotations);
    }
};

const getSiblings = <THighlightCustomData,>(
    highlights: Array<Highlight<THighlightCustomData>>,
    highlight: Highlight<THighlightCustomData>
): Array<Highlight<THighlightCustomData>> => {
    const highlightX = highlight.boundingBoxSections[0].boundingBoxes[0].topLeft.x;
    const highlightY = highlight.boundingBoxSections[0].boundingBoxes[0].topLeft.y;
    const siblings = highlights.filter((h) => {
        const hX = h.boundingBoxSections[0].boundingBoxes[0].topLeft.x;
        const hY = h.boundingBoxSections[0].boundingBoxes[0].topLeft.y;

        const x = hX - LeftTopInformationWidth / 2 < highlightX && highlightX < hX + LeftTopInformationWidth / 2;
        const y = hY - LeftTopInformationHeight / 2 < highlightY && highlightY < hY + LeftTopInformationHeight / 2;

        return x && y;
    });

    return siblings;
};

export const HtmlElementsResizing = (webViewerInstance: WebViewerInstance) => {
    recalculateHighlightPartNumberPosition(webViewerInstance);
};

export const getZoomRounded = (zoom: number) => Math.round(zoom / 0.01) * 0.01;

export const highlightColorToAnnotationsColor = (Annotations: typeof Core.Annotations, c: HighlightColor) => new Annotations.Color(c.R, c.G, c.B, c.A);

export default useDrawing;
