import { Box, Divider, Theme } from '@mui/material';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';
import { Typography } from 'UI';

type StatusWithCountLabelComponentProps = {
    status: AnnotationTransferPropositionStatus;
    number: number;
    active: boolean;
    onClick?: (status: AnnotationTransferPropositionStatus) => void;
    disabled: boolean;
};

function StatusWithCountLabelComponent(props: StatusWithCountLabelComponentProps) {
    const { status, number, active, onClick, disabled } = props;

    const config = StatusConfig[status];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '2rem',
                width: '11.5rem',
                borderRadius: '4px',
                border: (theme) => `2px solid ${disabled ? theme.palette.text.disabled : config.mainColor(theme, active)}`,
                backgroundColor: (theme) => (active ? (disabled ? theme.palette.text.disabled : config.mainColor(theme, active)) : theme.palette.white.main),
            }}
        >
            <Box
                onClick={() => !disabled && onClick?.(status)}
                sx={{
                    height: '100%',
                    width: '8.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: (theme) => (disabled ? theme.palette.text.main : config.titleColor(theme, active)),
                    ...(onClick && !disabled
                        ? {
                              cursor: 'pointer',
                          }
                        : {
                              cursor: 'default',
                          }),
                }}
            >
                <Typography sx={{ fontSize: '0.75rem' }}>{config.label}</Typography>
            </Box>
            <Divider orientation='vertical' sx={{ borderColor: (theme) => (disabled ? theme.palette.text.disabled : config.mainColor(theme, active)) }} />
            <Box
                sx={{
                    width: '3rem',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: (theme) => theme.palette.white.main,
                    borderRadius: '0px 4px 4px 0px',
                }}
            >
                <Typography sx={{ fontSize: '0.75rem' }}>{number}</Typography>
            </Box>
        </Box>
    );
}

type StatusConfigType = {
    label: string;
    description: string;
    mainColor: (theme: Theme, active: boolean) => string;
    titleColor: (theme: Theme, active: boolean) => string;
};

export const StatusConfig: { [key in AnnotationTransferPropositionStatus]: StatusConfigType } = {
    [AnnotationTransferPropositionStatus.Found]: {
        label: 'TO TRANSFER',
        description:
            'Where text in the new version is identical to annotated text in the old version, those annotations and all associated data (e.g. supporting information, status, assigned user etc.) will be automatically transferred across to the new version.',
        mainColor: () => '#00ff0033', // TODO: Fix when palette is ready
        titleColor: (theme) => theme.palette.text.main,
    },
    [AnnotationTransferPropositionStatus.FoundWithModifications]: {
        label: 'FOR REVIEW',
        description:
            'Where text in the new version is similar but not identical to annotated text in the old version, you will need to review these annotations and confirm if you would like to transfer them across to the new version.',
        mainColor: () => '#ffff00', // TODO: Fix when palette is ready
        titleColor: (theme) => theme.palette.text.main,
    },
    [AnnotationTransferPropositionStatus.Removed]: {
        label: 'NOT TRANSFERRING',
        description:
            'Where annotated text in the old version no longer appears in the new version, those annotations will not be transferred across to the new version.',
        mainColor: (theme) => theme.palette.attention.high,
        titleColor: (theme, active) => (active ? theme.palette.white.main : theme.palette.text.main),
    },
};

export default StatusWithCountLabelComponent;
