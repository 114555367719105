import { CircularProgress, Link } from '@mui/material';
import { SupportingDocumentInfo } from 'ApiClients/SterlingApiClients/Types';
import { SearchRoutes } from 'App/RoutesPaths';
import { TableList, Typography } from 'UI';
import ItemNumberComponent from '../Common/ItemNumber/ItemNumber.Component';

export type DocumentsTableComponentProps = {
    isFetching: boolean;
    supportingDocuments: Array<SupportingDocumentInfo>;
    projectId: string;
    projectVersionIdUrlParam: string;
};

function DocumentsTableComponent(props: DocumentsTableComponentProps) {
    const { isFetching, supportingDocuments, projectId, projectVersionIdUrlParam } = props;
    const hasData = supportingDocuments.length > 0;

    return (
        <TableList.Table maxHeight={256}>
            <TableList.Header>
                <TableList.TR isHeader>
                    <TableList.TH size={'md'}>Item</TableList.TH>
                    <TableList.TH>Description</TableList.TH>
                </TableList.TR>
            </TableList.Header>
            <TableList.Body data-cy='supporting-documents-table-body'>
                {!isFetching &&
                    hasData &&
                    supportingDocuments.map((doc, idx) => {
                        return (
                            <TableList.TR
                                key={doc.id}
                                data-cy='supporting-documents-table-row'
                                hover={false}
                                sx={{
                                    backgroundColor: (theme) => (idx % 2 === 0 ? theme.palette.blue.light : 'inherit'),
                                    height: '3rem',
                                }}
                            >
                                <TableList.TD size={'md'}>
                                    <ItemNumberComponent number={doc.number} />
                                </TableList.TD>
                                <TableList.TD>
                                    <Link underline='hover' href={SearchRoutes.goToSupportingDocument.getFullLink(projectId, projectVersionIdUrlParam, doc.id)}>
                                        {doc.originalName || doc.name}
                                    </Link>
                                </TableList.TD>
                            </TableList.TR>
                        );
                    })}
                {!isFetching && !hasData && (
                    <TableList.TR>
                        <TableList.TD textAlign='center'>
                            <Typography variant='inputLabel' sx={{ height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                No data.
                            </Typography>
                        </TableList.TD>
                    </TableList.TR>
                )}
                {isFetching && (
                    <TableList.TR>
                        <TableList.TD textAlign='center'>
                            <CircularProgress size={20} />
                        </TableList.TD>
                    </TableList.TR>
                )}
            </TableList.Body>
        </TableList.Table>
    );
}

export default DocumentsTableComponent;
