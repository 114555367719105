import { Icon } from 'UI';
import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import SplitingToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/SplitingToolbar/SplitingToolbar.Component';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';
import { AnnotationHighlightCustomData } from '../../VerificationDocument.Component';
import { Highlight, HighlightToolbarActionsState, HighlightViewer } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { SplitAutoAnnotationBody } from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient.Types';
import { ActionDesc } from 'Views/Common/PdfViewerWithToolbar/Hooks/Annotations/ShowHideAnnotationElements';
import { Toast, ToastEnum } from 'Hooks/useToasts';

export type AutoAnnotationPanelComponentProps = HighlightToolbarActionsState<AutoAnnotationPanelActionsState> & {
    highlight: Highlight<AnnotationHighlightCustomData>;
    createAnnotationFromAutoAnnotation: (autoAnnotationId: string, flowMethods: FlowMethods) => void;
    createAnnotationFromModifiedAutoAnnotation: (
        autoAnnotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: BoundingBoxSection[],
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    splitingEnabled: boolean;
    splitingMultiPartEnabled: boolean;
    isAnnotationSplitted: boolean;
    startSplittingAutoAnnotation: () => void;
    splitAutoAnnotation: (autoAnnotationId: string, body: SplitAutoAnnotationBody, flowMethods: FlowMethods) => void;
    cancelSplitAnnotation: () => void;
    rejectAnnotation: (autoAnnotationId: string, flowMethods: FlowMethods) => void;
    showHideAnnotationElements: (autoAnnotationId: string, show: boolean) => void;
    addPart: () => void;
    getHighlightViewer: () => Promise<HighlightViewer>;
    addToast: (toast: Toast) => void;
};

export type AutoAnnotationPanelActionsState = {
    createAnnotationFetching: boolean;
    rejectAnnotationFetching: boolean;
    saveSplittedAnnotationFetching: boolean;
    splittingMultiPartAutoAnnotationFetching: boolean;
};

function AutoAnnotationPanelComponent(props: AutoAnnotationPanelComponentProps) {
    const {
        highlight,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        splitingEnabled,
        splitingMultiPartEnabled,
        isAnnotationSplitted,
        startSplittingAutoAnnotation,
        splitAutoAnnotation,
        cancelSplitAnnotation,
        rejectAnnotation,
        showHideAnnotationElements,
        addPart,
        getHighlightViewer,
        actionsState,
        setHighlightActionsState,
        addToast,
    } = props;

    const { createAnnotationFetching, rejectAnnotationFetching, saveSplittedAnnotationFetching, splittingMultiPartAutoAnnotationFetching } = useMemo(
        () =>
            actionsState || {
                createAnnotationFetching: false,
                rejectAnnotationFetching: false,
                saveSplittedAnnotationFetching: false,
                splittingMultiPartAutoAnnotationFetching: false,
            },
        [actionsState]
    );
    const fetching = useMemo(
        () => createAnnotationFetching || rejectAnnotationFetching || saveSplittedAnnotationFetching || splittingMultiPartAutoAnnotationFetching,
        [createAnnotationFetching, rejectAnnotationFetching, saveSplittedAnnotationFetching, splittingMultiPartAutoAnnotationFetching]
    );

    const { action: createAnnotationWrapped } = useApiModify<{ autoAnnotationId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            if (highlightViewer.isModified) {
                createAnnotationFromModifiedAutoAnnotation(
                    input.autoAnnotationId,
                    highlightViewer.selectionType,
                    highlightViewer.boundingBoxSections,
                    highlightViewer.statement,
                    {
                        ...flowMethods,
                        setFetching: (value) => setHighlightActionsState(input.autoAnnotationId, 'createAnnotationFetching', value),
                    }
                );
            } else {
                createAnnotationFromAutoAnnotation(input.autoAnnotationId, {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.autoAnnotationId, 'createAnnotationFetching', value),
                });
            }
        },
    });

    const { action: rejectAnnotationWrapped } = useApiModify<{ autoAnnotationId: string }>({
        method: (input, flowMethods) =>
            rejectAnnotation(input.autoAnnotationId, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(input.autoAnnotationId, 'rejectAnnotationFetching', value),
            }),
    });

    const { action: _saveSplittedAnnotationWrapped } = useApiModify<{ autoAnnotationId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            splitAutoAnnotation(
                input.autoAnnotationId,
                {
                    baseAutoAnnotation: {
                        boundingBoxSections: [highlightViewer.boundingBoxSections[0]],
                        selectionType: highlightViewer.selectionType,
                        statement: highlightViewer.boundingBoxSections[0].statement,
                    },
                    newAutoAnnotations: [
                        {
                            boundingBoxSections: [highlightViewer.boundingBoxSections[1]],
                            selectionType: highlightViewer.selectionType,
                            statement: highlightViewer.boundingBoxSections[1].statement,
                        },
                    ],
                },
                {
                    ...flowMethods,
                    setFetching: (value) => setHighlightActionsState(input.autoAnnotationId, 'saveSplittedAnnotationFetching', value),
                }
            );
        },
    });
    const saveSplittedAnnotationWrapped = useCallback(
        () => _saveSplittedAnnotationWrapped({ autoAnnotationId: highlight.id }),
        [_saveSplittedAnnotationWrapped, highlight.id]
    );
    const { action: _splitMultiPartAutoAnnotation } = useApiModify<{ autoAnnotationId: string }>({
        method: async (input, flowMethods) => {
            const highlightViewer = await getHighlightViewer();

            if (highlightViewer.boundingBoxSections.length < 2) {
                addToast({ type: ToastEnum.WARNING, content: ['There is no part to split.'] });
            } else {
                splitAutoAnnotation(
                    input.autoAnnotationId,
                    {
                        baseAutoAnnotation: {
                            boundingBoxSections: [highlightViewer.boundingBoxSections[0]],
                            selectionType: highlightViewer.selectionType,
                            statement: highlightViewer.boundingBoxSections[0].statement,
                        },
                        newAutoAnnotations: highlightViewer.boundingBoxSections.slice(1).map((boundingBoxSection) => ({
                            boundingBoxSections: [boundingBoxSection],
                            selectionType: highlightViewer.selectionType,
                            statement: boundingBoxSection.statement,
                        })),
                    },
                    {
                        ...flowMethods,
                        setFetching: (value) => setHighlightActionsState(input.autoAnnotationId, 'splittingMultiPartAutoAnnotationFetching', value),
                    }
                );
            }
        },
    });
    const splitMultiPartAutoAnnotation = useCallback(
        () => _splitMultiPartAutoAnnotation({ autoAnnotationId: highlight.id }),
        [_splitMultiPartAutoAnnotation, highlight.id]
    );

    let utils: Array<Util> = [
        {
            icon: Icon.CloseCircle,
            onClick: () => rejectAnnotationWrapped({ autoAnnotationId: highlight.id }),
            description: 'Reject',
            tooltip: 'Reject proposal',
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
            disabled: fetching,
            loading: rejectAnnotationFetching,
        },
    ];

    if (splitingEnabled) {
        utils.push({
            icon: Icon.Scissors,
            onClick: () => startSplittingAutoAnnotation(),
            description: 'Split',
            tooltip: 'Split proposal',
            disabled: fetching,
        });
    }

    if (splitingMultiPartEnabled) {
        utils.push({
            icon: Icon.Split,
            onClick: () => splitMultiPartAutoAnnotation(),
            description: 'Split by parts',
            tooltip: 'Split proposal by parts',
            disabled: fetching,
            loading: splittingMultiPartAutoAnnotationFetching,
        });
    }

    utils.push({
        icon: Icon.Approve,
        onClick: () => createAnnotationWrapped({ autoAnnotationId: highlight.id }),
        description: 'Accept',
        tooltip: 'Accept proposal',
        utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        disabled: fetching,
        loading: createAnnotationFetching,
    });

    const [expanded, setExpanded] = useState<boolean>(true);
    const showHideAnnotationElementsHandle = () => {
        showHideAnnotationElements(highlight.id, !expanded);
        setExpanded(!expanded);
    };

    return (
        <>
            {!isAnnotationSplitted ? (
                <div style={{ display: 'flex' }}>
                    <HighlightToolbarComponent
                        utils={[
                            {
                                icon: Icon.FileEarmarkBinary,
                                onClick: showHideAnnotationElementsHandle,
                                tooltip: expanded ? ActionDesc.Hide : ActionDesc.Show,
                                disabled: fetching,
                            },
                        ]}
                    />
                    <Box sx={{ width: '0.25rem' }} />
                    <HighlightToolbarComponent utils={utils} />
                    <Box sx={{ width: '0.25rem' }} />
                    <PartsToolbarComponent addPart={addPart} disabled={fetching} />
                </div>
            ) : (
                <SplitingToolbarComponent cancel={cancelSplitAnnotation} split={saveSplittedAnnotationWrapped} fetching={saveSplittedAnnotationFetching} />
            )}
        </>
    );
}

export default AutoAnnotationPanelComponent;
