import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import Backdrop, { BackdropProps } from '@mui/material/Backdrop';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';

function DialogBackdrop(props: BackdropProps) {
    return (
        <Backdrop
            sx={{
                opacity: '0.4 !important',
                background: (theme) => theme.palette.blue.dark,
            }}
            {...props}
        />
    );
}

function Dialog({ children, ...rest }: React.PropsWithChildren<DialogProps>) {
    return (
        <MuiDialog
            {...rest}
            slots={{
                backdrop: DialogBackdrop,
            }}
        >
            <Box
                sx={{
                    minWidth: 480,
                }}
            >
                {children}
            </Box>
        </MuiDialog>
    );
}

function Title({ children }: React.PropsWithChildren<unknown>) {
    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.blue.light,
                display: 'flex',
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 1,
                paddingBottom: 1,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
            }}
        >
            <Typography variant='subtitle1' color={(th) => th.palette.text.primary}>
                {children}
            </Typography>
        </Box>
    );
}

function Actions({ children, ...rest }: React.PropsWithChildren<BoxProps>) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 4,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
}

function Content({ children, ...rest }: React.PropsWithChildren<BoxProps>) {
    return (
        <Box
            sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 2,
                paddingBottom: 4,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
}

Dialog.Title = Title;
Dialog.Actions = Actions;
Dialog.Content = Content;

export default Dialog;
