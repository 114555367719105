import { EmotionCache } from '@emotion/react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import {
    AnnotationAttributeIsHtmlAnnotation,
    AnnotationAttributeMultiSelectEnable,
    AnnotationAttributeParentHighlightId,
    AnnotationAttributeType,
    getParentHighlightId,
} from './Attributes';
import { createRoot } from 'react-dom/client';
import HighlightLeftTopInformationComponent, {
    HighlightLeftTopInformation,
    HighlightLeftTopInformationComponentState,
} from 'Views/Common/PdfViewerWithToolbar/HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { HighlightExtended } from '../../useDrawing';
import { SupportStateHighlight } from '../../useAnnotationsSelecting';

export const LeftTopInformationWidth = 24;
export const LeftTopInformationHeight = 16;
export const TooltipsContainerClassName = 'highlight-left-top-information-tooltips-container';

export const createOrRefreshLeftTopInformation = <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlight: HighlightExtended<THighlightCustomData>,
    selectedHighlightIds: Array<string>,
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    selectAnnotations: (highlights: Array<SupportStateHighlight>) => void,
    emotionCache: EmotionCache,
    setStateRef: React.MutableRefObject<React.Dispatch<React.SetStateAction<HighlightLeftTopInformationComponentState<THighlightCustomData>>> | null>,
    annotationElementsEnabled: boolean
) => {
    const { Annotations, annotationManager } = instance.Core;
    let allLeftTopInformation: Array<Core.Annotations.Annotation> = [];
    let newLeftTopInformation: Array<Core.Annotations.Annotation> = [];

    recreateTooltipsContainer(instance);

    const allHighlightsNodeAnnotations = annotationManager.getAnnotationsList().filter((a) => getParentHighlightId(a) === highlight.id);
    let annotationLeftTopInformation = allHighlightsNodeAnnotations.find(
        (a) => AnnotationAttributeType.get(a) === 'highlightLeftTopInformation'
    ) as Core.Annotations.HTMLAnnotation;
    let annotationLeftTopInformationBackground = allHighlightsNodeAnnotations.find(
        (a) => AnnotationAttributeType.get(a) === 'highlightLeftTopInformationBackground'
    ) as Core.Annotations.RectangleAnnotation;

    if (!annotationLeftTopInformation) {
        annotationLeftTopInformation = new Annotations.HTMLAnnotation();
        newLeftTopInformation.push(annotationLeftTopInformation);
    }
    if (!annotationLeftTopInformationBackground) {
        annotationLeftTopInformationBackground = new Annotations.RectangleAnnotation();
        newLeftTopInformation.push(annotationLeftTopInformationBackground);
    }

    const firstBoundingBoxSection = highlight.boundingBoxSections[0];
    annotationLeftTopInformation.disableRotationControl();
    annotationLeftTopInformation.NoRotate = true;
    annotationLeftTopInformation.NoMove = true;
    annotationLeftTopInformation.NoResize = true;
    annotationLeftTopInformation.PageNumber = firstBoundingBoxSection.pageNumber;
    annotationLeftTopInformation.X = firstBoundingBoxSection.boundingBoxes[0].topLeft.x - LeftTopInformationWidth;
    annotationLeftTopInformation.Y = firstBoundingBoxSection.boundingBoxes[0].topLeft.y - LeftTopInformationHeight;
    annotationLeftTopInformation.Width = LeftTopInformationWidth;
    annotationLeftTopInformation.Height = LeftTopInformationHeight;
    annotationLeftTopInformation.Hidden = annotationElementsEnabled ? false : true;
    annotationLeftTopInformation.createInnerElement = () => {
        const newDiv = document.createElement('div');
        const root = createRoot(newDiv);
        root.render(
            <HighlightLeftTopInformationComponent<THighlightCustomData>
                instance={instance}
                highlight={highlight}
                selectedHighlightIds={selectedHighlightIds}
                leftTopInformation={leftTopInformation}
                selectAnnotations={selectAnnotations}
                emotionCache={emotionCache}
                setStateRef={setStateRef}
            />
        );
        return newDiv;
    };
    AnnotationAttributeType.set(annotationLeftTopInformation, 'highlightLeftTopInformation');
    AnnotationAttributeIsHtmlAnnotation.set(annotationLeftTopInformation, 'true');
    AnnotationAttributeParentHighlightId.set(annotationLeftTopInformation, highlight.id);
    AnnotationAttributeMultiSelectEnable.set(annotationLeftTopInformation, highlight.multiSelectEnabled ? 'true' : 'false');
    // This is needed to have a background for the left top information because HTML Annotation is not clickable
    setupBackgroundForHtmlAnnotation(instance, annotationLeftTopInformation, annotationLeftTopInformationBackground, annotationElementsEnabled);

    allLeftTopInformation.push(annotationLeftTopInformation);
    allLeftTopInformation.push(annotationLeftTopInformationBackground);
    return { newLeftTopInformation, allLeftTopInformation };
};

const setupBackgroundForHtmlAnnotation = (
    instance: WebViewerInstance,
    htmlAnnot: Core.Annotations.HTMLAnnotation,
    annot: Core.Annotations.RectangleAnnotation,
    annotationElementsEnabled: boolean
) => {
    const { Annotations } = instance.Core;
    annot.disableRotationControl();
    annot.NoRotate = true;
    annot.NoMove = true;
    annot.NoResize = true;
    annot.PageNumber = htmlAnnot.PageNumber;
    annot.X = htmlAnnot.X;
    annot.Y = htmlAnnot.Y;
    annot.Height = htmlAnnot.Height;
    annot.Width = htmlAnnot.Width;
    annot.FillColor = new Annotations.Color(255, 255, 255, 1);
    annot.StrokeColor = new Annotations.Color(255, 255, 255, 1);
    annot.Opacity = 0.01;
    annot.Hidden = annotationElementsEnabled ? false : true;
    AnnotationAttributeType.set(annot, 'highlightLeftTopInformationBackground');
    AnnotationAttributeParentHighlightId.set(annot, getParentHighlightId(htmlAnnot));
    AnnotationAttributeMultiSelectEnable.set(annot, AnnotationAttributeMultiSelectEnable.get(htmlAnnot));
};

const recreateTooltipsContainer = (instance: WebViewerInstance): HTMLDivElement => {
    const tooltipsContainers = instance.UI.iframeWindow.document.getElementsByClassName(TooltipsContainerClassName);

    for (let i = 0; i < tooltipsContainers.length; i++) {
        tooltipsContainers[i].remove();
    }

    const appRoot = instance.UI.iframeWindow.document.body.getElementsByClassName('App')[0];
    const tooltipsContainer = document.createElement('div');
    tooltipsContainer.classList.add(TooltipsContainerClassName);
    appRoot.appendChild(tooltipsContainer);

    return tooltipsContainer as HTMLDivElement;
};
