import SterlingApiClient, { GetRequestOptions, PostRequestOptions } from '../../Sterling.ApiClient';
import { Documents, UploadSupportingDocumentBody, UploadWebSearchDocumentBody, VerificationDocument, WebSearchDocumentInfo } from '../Types';

export default class ProjectDocumentsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (projectId: string, versionId: string) => `projects/${projectId}/versions/${versionId}/documents`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getVerificationDocument = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<VerificationDocument>) =>
        this._sterlingApiClient.getAsync<VerificationDocument>(`${this._rootRoute(projectId, versionId)}/verificationDocument`, getRequestOptions);

    getSupportingDocuments = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<Documents>) =>
        this._sterlingApiClient.getAsync<Documents>(`${this._rootRoute(projectId, versionId)}/supportingDocuments`, getRequestOptions);

    getDocument = async (projectId: string, versionId: string, documentId: string, documentName: string) =>
        this._sterlingApiClient.getFileAsync(`${this._rootRoute(projectId, versionId)}/${documentId}`, documentId, documentName);

    getWebSearchDocument = async (
        projectId: string,
        versionId: string,
        url: string,
        getRequestOptions?: GetRequestOptions<Blob>
    ): Promise<WebSearchDocumentInfo> => {
        const result = await this._sterlingApiClient.getBlobAsync(`${this._rootRoute(projectId, versionId)}/webSearch`, {
            ...getRequestOptions,
            params: {
                url,
            },
        });

        const { blob, headers } = result!;

        const documentId: string | null = headers['verifi-document-id'] || null;
        const documentCreationDateString: string = headers['verifi-document-date'];

        const documentCreationDateTmp = new Date(documentCreationDateString);
        const documentCreationDate = new Date(documentCreationDateTmp.getTime() - documentCreationDateTmp.getTimezoneOffset() * 60000);

        const dateOptions: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        const documentName = `${documentCreationDate.toLocaleString(undefined, dateOptions)} - ${url}.pdf`;
        const file = new File([blob], documentName, {
            type: blob.type,
        });

        return {
            url,
            documentId,
            documentCreationDate,
            file,
        };
    };

    uploadSupportingDocument = async (projectId: string, versionId: string, postRequestOptions: PostRequestOptions<UploadSupportingDocumentBody, string>) =>
        this._sterlingApiClient.postAsync<UploadSupportingDocumentBody, string>(`${this._rootRoute(projectId, versionId)}`, {
            ...postRequestOptions,
            bodyAsDataForm: true,
            toastSuccessMessages: ['Document uploaded.'],
        });

    uploadWebSearchDocument = async (projectId: string, versionId: string, postRequestOptions: PostRequestOptions<UploadWebSearchDocumentBody, string>) =>
        this._sterlingApiClient.postAsync<UploadWebSearchDocumentBody, string>(`${this._rootRoute(projectId, versionId)}/webSearch`, {
            ...postRequestOptions,
            bodyAsDataForm: true,
            toastSuccessMessages: ['Document saved.'],
        });
}
