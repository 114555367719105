import { useCallback, useEffect, useRef, useState } from 'react';
import { AnnotationTransferData } from '../useAnnotationsTransferState';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';
import { SelectedHighlightChangedEvent } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { DraftAnnotationSelectedEventName } from './usePdfViewerDraft';
import { ActiveAnnotationSelectedEventName } from './usePdfViewerActive';

type ViewerSource = 'active' | 'draft';

const useSelectingAnnotation = (annotationsTransferData: Array<AnnotationTransferData> | null, filteredData: Array<AnnotationTransferData> | null) => {
    const [selectedAnnotationIdActiveVersion, __setSelectedAnnotationIdActiveVersion] = useState<string | null>(null);
    const _setSelectedAnnotationIdActiveVersion = useCallback(
        (id: string | null, options: { dispatchEvent: boolean }) => {
            __setSelectedAnnotationIdActiveVersion(id);
            if (options.dispatchEvent) {
                const event = new CustomEvent<SelectedHighlightChangedEvent>(ActiveAnnotationSelectedEventName, { detail: { ids: id ? [id] : [] } });
                document.dispatchEvent(event);
            }
        },
        [__setSelectedAnnotationIdActiveVersion]
    );
    const [selectedAnnotationIdDraftVersion, __setSelectedAnnotationIdDraftVersion] = useState<string | null>(null);
    const _setSelectedAnnotationIdDraftVersion = useCallback(
        (id: string | null, options: { dispatchEvent: boolean }) => {
            __setSelectedAnnotationIdDraftVersion(id);
            if (options.dispatchEvent) {
                const event = new CustomEvent<SelectedHighlightChangedEvent>(DraftAnnotationSelectedEventName, { detail: { ids: id ? [id] : [] } });
                document.dispatchEvent(event);
            }
        },
        [__setSelectedAnnotationIdDraftVersion]
    );

    const annotationsTransferDataRef = useRef(annotationsTransferData);
    annotationsTransferDataRef.current = annotationsTransferData;

    const setSelectedAnnotationIdBothVersions = useCallback(
        (id: string | null) => {
            const options = { dispatchEvent: true };
            _setSelectedAnnotationIdActiveVersion(id, options);
            const data = annotationsTransferDataRef.current;
            if (data) {
                const transfer = data.find((a) => a.annotationActive.id === id);
                if (transfer && transfer.status !== AnnotationTransferPropositionStatus.Removed) {
                    _setSelectedAnnotationIdDraftVersion(id, options);
                } else {
                    _setSelectedAnnotationIdDraftVersion(null, options);
                }
            }
        },
        [_setSelectedAnnotationIdActiveVersion, _setSelectedAnnotationIdDraftVersion]
    );

    const _setSelectedAnnotationIdViewer = useCallback(
        (id: string | null, viewerSource: ViewerSource) => {
            if (viewerSource === 'active') {
                _setSelectedAnnotationIdActiveVersion(id, { dispatchEvent: false });
                const data = annotationsTransferDataRef.current;
                if (data) {
                    const transfer = data.find((a) => a.annotationActive.id === id);
                    if (transfer && transfer.status !== AnnotationTransferPropositionStatus.Removed) {
                        _setSelectedAnnotationIdDraftVersion(id, { dispatchEvent: true });
                    } else {
                        _setSelectedAnnotationIdDraftVersion(null, { dispatchEvent: true });
                    }
                }
            }
            if (viewerSource === 'draft') {
                _setSelectedAnnotationIdDraftVersion(id, { dispatchEvent: false });
                if (id) {
                    _setSelectedAnnotationIdActiveVersion(id, { dispatchEvent: true });
                }
            }
        },
        [_setSelectedAnnotationIdActiveVersion, _setSelectedAnnotationIdDraftVersion]
    );

    const setSelectedAnnotationIdActiveVersionViewer = useCallback(
        (id: string | null) => _setSelectedAnnotationIdViewer(id, 'active'),
        [_setSelectedAnnotationIdViewer]
    );

    const setSelectedAnnotationIdDraftVersionViewer = useCallback(
        (id: string | null) => _setSelectedAnnotationIdViewer(id, 'draft'),
        [_setSelectedAnnotationIdViewer]
    );

    useEffect(() => {
        if (selectedAnnotationIdActiveVersion && filteredData) {
            const transfer = filteredData.find((a) => a.annotationActive.id === selectedAnnotationIdActiveVersion);
            if (!transfer) {
                setSelectedAnnotationIdBothVersions(null);
            }
        }
    }, [filteredData, selectedAnnotationIdActiveVersion, setSelectedAnnotationIdBothVersions]);

    const [initSelectedAnnotationId, setInitSelectedAnnotationId] = useState<string | null>(null);
    useEffect(() => {
        if (annotationsTransferData && annotationsTransferData.length > 0 && !initSelectedAnnotationId) {
            const transfer = annotationsTransferData[0];
            const annotation = transfer.annotationActive;
            setInitSelectedAnnotationId(annotation.id);
        }
        // eslint-disable-next-line
    }, [annotationsTransferData]);

    useEffect(() => {
        if (initSelectedAnnotationId) {
            setSelectedAnnotationIdBothVersions(initSelectedAnnotationId);
        }
    }, [initSelectedAnnotationId, setSelectedAnnotationIdBothVersions]);

    return {
        selectedAnnotationIdActiveVersion,
        setSelectedAnnotationIdActiveVersionViewer,
        selectedAnnotationIdDraftVersion,
        setSelectedAnnotationIdDraftVersionViewer,
        setSelectedAnnotationIdBothVersions,
    };
};

export default useSelectingAnnotation;
