import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Box, { BoxProps } from '@mui/material/Box';
import { typography } from 'UI/Provider/VerifiTheme';

type TabsProps = {
    value?: string | number;
    onChange?: (value?: string | number) => void;
};

type TabsContextType = {
    value?: string | number;
    onChange: (value?: string | number) => void;
    itemsCount?: number;
    valueIndexChildren: Record<string, number>;
};

const TabContext = React.createContext<TabsContextType>(
    Object.assign({
        itemsCount: 0,
        valueIndexChildren: {},
    })
);

function useTabsContext() {
    const context = React.useContext(TabContext);

    return context;
}

function Tabs({ children, value, onChange, ...props }: React.PropsWithChildren<TabsProps>) {
    const [selectedTab, setSelectedTab] = React.useState(value);

    React.useEffect(() => {
        setSelectedTab(value);
    }, [value]);

    const contextValue = React.useMemo(() => {
        const valueIndexChildren =
            React.Children.map(
                children,
                (z, ind) =>
                    React.isValidElement(z) && {
                        [z.props.value]: ind,
                    }
            )
                ?.filter((c) => c)
                .reduce((acc, val) => {
                    return {
                        ...acc,
                        ...val,
                    };
                }, {} as Record<string, number>) ?? {};
        return {
            value: selectedTab,
            itemsCount: React.Children.count(children),
            valueIndexChildren,
            onChange: (value?: string | number) => {
                if (typeof onChange === 'undefined') {
                    setSelectedTab(value); // uncontrolled
                }
                onChange?.(value);
            },
        };
    }, [selectedTab, onChange, children]);

    return (
        <TabContext.Provider value={contextValue}>
            <Box display='flex' {...props}>
                {children}
            </Box>
        </TabContext.Provider>
    );
}

type TabProps = {
    value?: string | number;
    hasBorder?: boolean;
} & Omit<BoxProps, 'value'>;

function Tab({ value, hasBorder, sx, children, ...rest }: React.PropsWithChildren<TabProps>) {
    const contextValue = useTabsContext();

    const handleTabClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        contextValue?.onChange(value);
    };

    const isActive = contextValue.value === value;

    const index = typeof value === 'undefined' ? 0 : contextValue.valueIndexChildren[value] ?? 0;

    return (
        <ButtonBase
            component='div'
            role='button'
            {...rest}
            sx={{
                ...sx,
                ...(isActive && { backgroundColor: (th) => th.palette.white.main, color: (th) => th.palette.text.primary }),
                ...(!isActive && {
                    borderColor: (th) => th.palette.stroke.main,
                    backgroundColor: (th) => th.palette.blue.light,
                    color: (th) => th.palette.blue.medium,
                }),
                ...typography.subtitle2,
                minWidth: 159,
                display: 'inline-block',
                padding: 1,
                flexWrap: 'wrap',
                cursor: 'pointer',
                ...(hasBorder && {
                    borderTopStyle: 'solid',
                    borderWidth: '1px',
                    ...(index !== 0 && { borderLeftColor: (th) => th.palette.stroke.main, borderLeftWidth: '1px', borderLeftStyle: 'solid' }),
                    ...(index === 0 && { borderTopLeftRadius: 4, borderLeftWidth: '1px', borderLeftStyle: 'solid' }),
                    borderTopColor: (th) => th.palette.stroke.main,
                    ...(index === (contextValue.itemsCount ?? 0) - 1 && { borderTopRightRadius: 4, borderRightWidth: '1px', borderRightStyle: 'solid' }),
                }),
                ...(hasBorder &&
                    isActive && {
                        borderBottom: '1px solid #fff', // panel color,
                        margin: '0 0 -1px',
                        borderWidth: '1px',
                        borderTopColor: (th) => th.palette.stroke.main,
                        ...(index === (contextValue.itemsCount ?? 0) - 1 && {
                            borderRightWidth: '1px',
                            borderTopRightRadius: 4,
                            borderRightStyle: 'solid',
                        }),
                        ...(index === 0 && { borderTopLeftRadius: 4, borderLeftWidth: '1px', borderLeftStyle: 'solid' }),
                        borderTopStyle: 'solid',
                        borderTopWidth: '1px',
                        borderRightColor: (th) => th.palette.stroke.main,
                        borderLeftColor: (th) => th.palette.stroke.main,
                    }),
            }}
            onClick={handleTabClick}
        >
            {children}
        </ButtonBase>
    );
}

type PanelProps = {
    isVisible?: boolean;
    width?: string | number;
    maxWidth?: string | number;
    height?: string | number;
} & Omit<BoxProps, 'value'>;

function Panel({ display, children, isVisible, width, height, maxWidth, sx, ...other }: PanelProps) {
    return (
        <Box
            sx={{
                ...sx,
                height,
                width,
                maxWidth,
                ...(isVisible && {
                    borderRadius: '0 4px 4px 4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: (th) => th.palette.stroke.main,
                    background: (th) => th.palette.white.main,
                    padding: 2,
                    display,
                    ...typography.body2,
                }),
            }}
            {...other}
            role='tabpanel'
            hidden={!isVisible}
        >
            {isVisible && children}
        </Box>
    );
}

Tabs.Tab = Tab;
Tabs.Panel = Panel;

export default Tabs;
