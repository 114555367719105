import { Switch, SwitchProps } from '@mui/material';

function SwitchComponent(props: SwitchProps) {
    return (
        <Switch
            sx={{
                width: '28px',
                height: '16px',
                padding: '0',
                display: 'flex',
                '&:active': {
                    '& .MuiSwitch-thumb': {
                        width: '15px',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        transform: 'translateX(9px)',
                    },
                },
                '& .MuiSwitch-switchBase': {
                    padding: '2px',
                    '&.Mui-checked': {
                        transform: 'translateX(12px)',
                        color: (theme) => theme.palette.common.white,
                        '& + .MuiSwitch-track': {
                            opacity: '1',
                            backgroundColor: (theme) => theme.palette.primary.main,
                        },
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxShadow: '0 2px 4px 0 rgba(0, 35, 11, 0.2)',
                    width: '12px',
                    height: '12px',
                    borderRadius: '6px',
                    transition: (theme) =>
                        theme.transitions.create(['width'], {
                            duration: 200,
                        }),
                },
                '& .MuiSwitch-track': {
                    borderRadius: '8px',
                    opacity: '1',
                    backgroundColor: (theme) => theme.palette.stroke.main,
                    boxSizing: 'border-box',
                },
            }}
            {...props}
        />
    );
}

export default SwitchComponent;
