import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { UserRole } from './SterlingApiClients/Types';

export type AuthUser = {
    id: string;
    name: string;
    email: string;
    roles: Array<UserRole>;
};

type OidcTokenUser = {
    oid: string;
    name: string;
    email: string;
    roles: Array<UserRole>;
};

export const RedirectPath: string = '/loginRedirect';

export default class AuthApiClient {
    _userManager: UserManager;

    constructor() {
        const config: UserManagerSettings = {
            authority: process.env.REACT_APP_AUTH_AUTHORITY,
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            scope: process.env.REACT_APP_AUTH_SCOPES,
            redirect_uri: `${window && window.location && window.location.origin}${RedirectPath}`,
            response_type: 'code',
            userStore: new WebStorageStateStore(),
        };

        this._userManager = new UserManager(config);
    }

    login = () => {
        this._userManager.signinRedirect({ state: window.location.pathname });
    };

    logout = () => {
        this._userManager.signoutRedirect({
            post_logout_redirect_uri: `${window && window.location && window.location.origin}`,
        });
    };

    getDataFromUrl = async (): Promise<{ user: AuthUser; redirectPath: string }> => {
        const response = await this._userManager.signinRedirectCallback();
        const redirectPath: string = response.state as string;
        const _user: OidcTokenUser = response.profile as any as OidcTokenUser;
        return {
            user: {
                id: _user.oid,
                name: _user.name,
                email: _user.email,
                roles: _user.roles,
            },
            redirectPath,
        };
    };

    getUser = async (): Promise<{ user: AuthUser; isAuthenticated: boolean } | null> => {
        const oidcUser = await this._userManager.getUser();
        if (oidcUser) {
            const _user = oidcUser.profile as any as OidcTokenUser;
            return {
                user: {
                    id: _user.oid,
                    name: _user.name,
                    email: _user.email,
                    roles: _user.roles,
                },
                isAuthenticated: !oidcUser.expired,
            };
        }
        return null;
    };

    getToken = async (): Promise<string> => {
        const oidcUser = await this._userManager.getUser();
        if (oidcUser) {
            if (this._tokenExpired(oidcUser)) {
                this._userManager.signinRedirect({ state: window.location.pathname });
            }
            return oidcUser.access_token;
        }

        return '';
    };

    _tokenExpired = (oidcUser: User) => {
        return oidcUser && oidcUser.expires_at && oidcUser.expires_at * 1000 < Date.now();
    };
}
