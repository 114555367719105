import { Box } from '@mui/material';
import { Typography } from 'UI';
import { useEffect, useRef } from 'react';

export type AutoAnnotationLabelComponentProps = {
    statementType: string;
    description: string;
};

function AutoAnnotationLabelComponent(props: AutoAnnotationLabelComponentProps) {
    const { statementType, description } = props;

    const rootRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const annotationRoot = rootRef.current?.parentElement?.parentElement?.parentElement;
        if (annotationRoot) annotationRoot.style.zIndex = '100';
    }, []);

    return (
        <Box
            ref={rootRef}
            sx={{
                backgroundColor: (theme) => theme.palette.white.main,
                border: (theme) => `0.125rem solid ${theme.palette.blue.dark}`,
                borderRadius: '0.25rem',
                position: 'absolute',
                bottom: '0rem',
                width: '7rem',
                fontWeight: 700,
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '0.25rem',
                paddingRight: '0.25rem',
            }}
        >
            <Typography sx={{ fontSize: '0.4rem', fontWeight: 700 }}>{statementType || 'Unknown statement type'}</Typography>
            <Typography sx={{ fontSize: '0.4rem' }}>{description || 'Unknown description'}</Typography>
        </Box>
    );
}

export default AutoAnnotationLabelComponent;
