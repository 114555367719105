import { useCallback, useMemo } from 'react';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Tag } from 'ApiClients/SterlingApiClients/Types';
import { useToastModalClose } from 'Hooks/useToasts';
import { Autocomplete, Button, ChipsBox, Dialog } from 'UI';
import { Box } from '@mui/material';
import { ChipBoxElement } from 'UI/ChipsBox/ChipBox.Component';

export type TagAssignmentModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    getTags: (flowMethods: FlowMethods<Array<Tag>>) => void;
    addTag: (annotationId: string, tagId: string, flowMethods: FlowMethods) => void;
    deleteAssignedTag: (annotationId: string, tagId: string, flowMethods: FlowMethods) => void;
    getAssignedTags: (annotationId: string, flowMethods: FlowMethods<Array<Tag>>) => void;
    annotationId: string;
};

type TagOption = {
    value: string;
    label: string;
};

function TagAssignmentModalComponent(props: TagAssignmentModalComponentProps) {
    const { isOpen, annotationId } = props;
    const { closeModal, flowMethodsMiddleware: addToastMiddleware } = useToastModalClose(props.closeModal);

    const { data: tags } = usePromiseWithFlowMethods<{}, Array<Tag>>({
        method: (_input, flowMethods) => props.getTags(flowMethods),
        initialData: [],
        initFetch: { input: { annotationId } },
    });

    const {
        data: assignedTags,
        wrappedMethod: _getAssignedTags,
        fetching,
    } = usePromiseWithFlowMethods<{ annotationId: string }, Array<Tag>>({
        method: (input, flowMethods) => props.getAssignedTags(input.annotationId, flowMethods),
        initialData: [],
        initFetch: { input: { annotationId } },
    });
    const getAssignedTags = useCallback(() => _getAssignedTags({ annotationId }), [_getAssignedTags, annotationId]);

    const tagsMapped: Array<TagOption> = useMemo(() => {
        let tagsMapped = tags
            .filter((tag) => !assignedTags.find((assignedTag) => assignedTag.id === tag.id))
            .sort((a, b) => {
                if (a.name > b.name) return 1;
                else return -1;
            })
            .map((tag) => ({
                value: tag.id,
                label: tag.name,
            }));

        return tagsMapped;
    }, [tags, assignedTags]);

    const { wrappedMethod: addTag } = usePromiseWithFlowMethods<{ annotationId: string; tagId: string }, {}>({
        method: (input, flowMethods) =>
            props.addTag(
                input.annotationId,
                input.tagId,
                addToastMiddleware({
                    ...flowMethods,
                    onSuccess: (data) => {
                        flowMethods?.onSuccess?.(data);
                        getAssignedTags();
                    },
                })
            ),
        initialData: {},
    });

    const { wrappedMethod: deleteTag } = usePromiseWithFlowMethods<{ annotationId: string; tagId: string }, {}>({
        method: (input, flowMethods) =>
            props.deleteAssignedTag(
                input.annotationId,
                input.tagId,
                addToastMiddleware({
                    ...flowMethods,
                    onSuccess: (data) => {
                        flowMethods?.onSuccess?.(data);
                        getAssignedTags();
                    },
                })
            ),
        initialData: {},
    });

    let chips: Array<ChipBoxElement<{ id: string }>> = assignedTags
        .sort((a, b) => {
            if (a.name > b.name) return 1;
            else return -1;
        })
        .map((t) => ({
            color: 'info',
            hasDelete: true,
            label: t.name,
            id: t.id,
        }));

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Assigned Tags</Dialog.Title>
            <Dialog.Content>
                <Box
                    sx={{
                        marginBottom: 4,
                    }}
                    data-cy='tag-input-container'
                >
                    <Autocomplete<TagOption>
                        data-cy='tags-dropdown'
                        label='Select Tag'
                        options={tagsMapped}
                        getOptionLabel={(option: TagOption) => option.label}
                        placeholder='Tag Name Here'
                        onChange={(_: React.ChangeEvent<{}>, newValue: TagOption | null) => {
                            if (newValue) {
                                addTag({ annotationId, tagId: newValue.value });
                            }
                        }}
                        isOptionEqualToValue={(option: TagOption, value: TagOption) => option.value === (value?.value ?? '')}
                        required
                        inputValue=''
                    />
                </Box>

                <Box
                    sx={{
                        marginBottom: 4,
                    }}
                    data-cy='tag-list-container'
                >
                    <ChipsBox<{ id: string }>
                        chips={chips}
                        label='Tags Assigned To Annotation'
                        onChipDelete={(chip) => deleteTag({ annotationId, tagId: chip.id })}
                        chipProps={{
                            sx: {
                                minWidth: 'initial',
                            },
                        }}
                        loading={fetching}
                    />
                </Box>

                <Dialog.Actions>
                    <Button
                        color='secondary'
                        sx={{
                            maxWidth: 160,
                        }}
                        fullWidth
                        type='button'
                        onClick={closeModal}
                        data-cy='close-tag-assignment'
                    >
                        Close
                    </Button>
                </Dialog.Actions>
            </Dialog.Content>
        </Dialog>
    );
}

export default TagAssignmentModalComponent;
