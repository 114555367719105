export type CreateAuditLogInput = {
    capability: string;
    actionType: string;
    content: string;
};

export type CreateAuditLogBody = {
    system: string;
    logTime: Date;
    capability: string;
    brainType: string;
    actionType: string;
    content: string;
};

export const Capability_ProjectCollaboration = 'ProjectCollaboration';
