import Box, { BoxProps } from '@mui/material/Box';
import { typography } from 'UI/Provider/VerifiTheme';
import React from 'react';

function Table({ children }: React.PropsWithChildren<unknown>) {
    return children;
}

function Root({ children, sx, ...rest }: React.PropsWithChildren<BoxProps>, ref: React.ForwardedRef<HTMLTableElement>) {
    return (
        <Box
            sx={{
                WebkitOverflowScrolling: 'touch',
                display: 'inline-flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
            ref={ref}
        >
            <Box
                sx={{
                    WebkitOverflowScrolling: 'touch',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    component='table'
                    sx={{
                        ...sx,
                        border: 0,
                        whiteSpace: 'nowrap',
                        borderSpacing: 0,
                        tableLayout: 'fixed',
                        minWidth: '100%',
                        borderCollapse: 'collapse',
                        display: 'table',
                    }}
                    {...rest}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

function Header(
    { children, ...rest }: React.PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>,
    ref: React.ForwardedRef<HTMLTableSectionElement>
) {
    return (
        <Box
            component='thead'
            sx={{
                display: 'table-header-group',
                textAlign: 'left',
                ...typography.overline,
            }}
            {...rest}
            ref={ref}
        >
            {children}
        </Box>
    );
}

const Sizes = {
    sm: 40,
    md: 120,
    lg: 230,
} as const;

type Size = {
    size?: keyof typeof Sizes | number;
};

function TH({ children, size, sx, ...rest }: React.PropsWithChildren<BoxProps & Size>, ref: React.ForwardedRef<HTMLTableCellElement>) {
    return (
        <Box
            component='th'
            sx={{
                fontWeight: 'inherit',
                padding: 1,
                whiteSpace: 'break-spaces',
                ...(Boolean(size) &&
                    typeof size === 'string' && {
                        width: `${Sizes[size!]}px !important`,
                        minWidth: `${Sizes[size!]}px !important`,
                        flexGrow: '0 !important',
                    }),
                ...(Boolean(size) &&
                    typeof size === 'number' && {
                        width: `${size}px !important`,
                        minWidth: `${size}px !important`,
                        flexGrow: '0 !important',
                    }),
                ...sx,
            }}
            {...rest}
            ref={ref}
        >
            {children}
        </Box>
    );
}

function Body({ children, sx, ...rest }: React.PropsWithChildren<BoxProps>, ref: React.ForwardedRef<HTMLTableSectionElement>) {
    return (
        <Box
            component='tbody'
            sx={{
                borderRadius: 1,
                outlineWidth: 1,
                outlineColor: (th) => th.palette.stroke.main,
                outlineStyle: 'solid',
                minWidth: '100%',
                display: 'block',
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: 'inherit',
                ...sx,
            }}
            {...rest}
            ref={ref}
        >
            {children}
        </Box>
    );
}

function TR(
    { children, sx, isHeader, hover = true, ...rest }: React.PropsWithChildren<BoxProps & { isHeader?: boolean; hover?: boolean }>,
    ref: React.ForwardedRef<HTMLTableRowElement>
) {
    return (
        <Box
            component='tr'
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                ...(!isHeader &&
                    hover && {
                        '&:hover': {
                            backgroundColor: (th) => th.palette.blue.light,
                        },
                    }),
                th: {
                    minWidth: `calc(100% / ${React.Children.count(children)})`,
                    flex: 1,
                },
                td: {
                    minWidth: `calc(100% / ${React.Children.count(children)})`,
                    flex: 1,
                },
                ...sx,
            }}
            {...rest}
            ref={ref}
        >
            {children}
        </Box>
    );
}

function TD({ children, size, sx, ...rest }: React.PropsWithChildren<BoxProps & Size>, ref: React.ForwardedRef<HTMLTableCellElement>) {
    return (
        <Box
            component='td'
            sx={{
                padding: 1,
                ...typography.inputLabel,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...(Boolean(size) &&
                    typeof size === 'string' && {
                        width: `${Sizes[size!]}px !important`,
                        minWidth: `${Sizes[size!]}px !important`,
                        flexGrow: '0 !important',
                    }),
                ...(Boolean(size) &&
                    typeof size === 'number' && {
                        width: `${size}px !important`,
                        minWidth: `${size}px !important`,
                        flexGrow: '0 !important',
                    }),
                ...sx,
            }}
            {...rest}
            ref={ref}
        >
            {children}
        </Box>
    );
}

Table.Header = React.forwardRef<HTMLTableSectionElement, React.PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>>(Header);

Table.TH = React.forwardRef<HTMLTableCellElement, React.PropsWithChildren<BoxProps & Size>>(TH);

Table.Body = React.forwardRef<HTMLTableSectionElement, React.PropsWithChildren<BoxProps>>(Body);

Table.TR = React.forwardRef<HTMLTableRowElement, React.PropsWithChildren<BoxProps & { isHeader?: boolean; hover?: boolean }>>(TR);

Table.TD = React.forwardRef<HTMLTableCellElement, React.PropsWithChildren<BoxProps & Size>>(TD);

Table.Table = React.forwardRef<HTMLTableElement, React.PropsWithChildren<BoxProps>>(Root);

export default Table;
