import { Icon } from 'UI';
import HighlightToolbarComponent, { Util } from '../HighlightToolbar/HighlightToolbar.Component';

export type SplitingToolbarComponentProps = {
    cancel: () => void;
    split: () => void;
    fetching: boolean;
};

function SplitingToolbarComponent(props: SplitingToolbarComponentProps) {
    const { cancel, split, fetching } = props;

    let utils: Array<Util> = [
        {
            icon: Icon.CloseCircle,
            onClick: () => cancel(),
            tooltip: 'Cancel',
            disabled: fetching,
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
        },
        {
            icon: Icon.Approve,
            onClick: () => split(),
            tooltip: 'Split',
            loading: fetching,
            disabled: fetching,
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        },
    ];

    return <HighlightToolbarComponent utils={utils} />;
}

export default SplitingToolbarComponent;
