import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions } from '../../Sterling.ApiClient';
import {
    AnnotationsTransferProposition,
    CreateAnnotationFromModifiedTransferPropositionBody,
    CreateDraftProjectVersion,
    ProjectVersionExtended,
    ProjectVersionListRecord,
} from './ProjectVersions.ApiClient.Types';

export default class ProjectVersionsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (projectId: string) => `projects/${projectId}/versions`;
    _rootRouteTransferPropositions = (projectId: string, draftId: string) => `${this._rootRoute(projectId)}/${draftId}/annotations/propositions`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    // _rootRoute
    getVersionsList = (projectId: string, getRequestOptions?: GetRequestOptions<Array<ProjectVersionListRecord>>) =>
        this._sterlingApiClient.getAsync<Array<ProjectVersionListRecord>>(`${this._rootRoute(projectId)}`, getRequestOptions);

    getVersion = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<ProjectVersionExtended>) =>
        this._sterlingApiClient.getAsync<ProjectVersionExtended>(`${this._rootRoute(projectId)}/${versionId}`, getRequestOptions);

    createDraftProjectVersion = (projectId: string, postRequestOptions: PostRequestOptions<CreateDraftProjectVersion, string>) =>
        this._sterlingApiClient.postAsync<CreateDraftProjectVersion, string>(`${this._rootRoute(projectId)}/draft`, {
            ...postRequestOptions,
            bodyAsDataForm: true,
        });

    restoreVersion = (projectId: string, versionId: string, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRoute(projectId)}/${versionId}/restore`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Version restored.'],
        });

    deleteDraftProjectVersion = (projectId: string, versionId: string, postRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRoute(projectId)}/draft/${versionId}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Draft removed.'],
        });

    acceptDraftProjectVersion = (projectId: string, versionId: string, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRoute(projectId)}/draft/${versionId}`, postRequestOptions);

    // _rootRouteTransferPropositions
    getAnnotationsTransferProposition = (projectId: string, draftId: string, getRequestOptions?: GetRequestOptions<AnnotationsTransferProposition>) =>
        this._sterlingApiClient.getAsync<AnnotationsTransferProposition>(`${this._rootRouteTransferPropositions(projectId, draftId)}`, getRequestOptions);

    createAnnotationFromTransferProposition = (projectId: string, draftId: string, annotationId: string, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteTransferPropositions(projectId, draftId)}/${annotationId}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotation transferred.'],
        });

    createAnnotationFromModifiedTransferProposition = (
        projectId: string,
        draftId: string,
        annotationId: string,
        postRequestOptions: PostRequestOptions<CreateAnnotationFromModifiedTransferPropositionBody>
    ) =>
        this._sterlingApiClient.postAsync<CreateAnnotationFromModifiedTransferPropositionBody>(
            `${this._rootRouteTransferPropositions(projectId, draftId)}/${annotationId}/modified`,
            {
                ...postRequestOptions,
                toastSuccessMessages: ['Annotation transferred.'],
            }
        );

    rejectAnnotationTransferProposition = (
        projectId: string,
        draftId: string,
        annotationId: string,
        displayToastSuccessMessages: boolean,
        deleteRequestOptions?: DeleteRequestOptions
    ) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteTransferPropositions(projectId, draftId)}/${annotationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: displayToastSuccessMessages ? ['Transfer proposition rejected.'] : undefined,
        });

    deleteAnnotationCreatedFromTransferProposition = (
        projectId: string,
        draftId: string,
        annotationId: string,
        displayToastSuccessMessages: boolean,
        deleteRequestOptions?: DeleteRequestOptions
    ) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteTransferPropositions(projectId, draftId)}/${annotationId}/recall`, {
            ...deleteRequestOptions,
            toastSuccessMessages: displayToastSuccessMessages ? ['Transferred annotation removed.'] : undefined,
        });
}
