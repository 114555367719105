import NotificationsConnector from '../NotificationsConnector';
import NotificationsSubscriber from '../NotificationsSubscriber';
import {
    AnnotationChangedNotificationMessageData,
    AnnotationsReorderedNotificationMessageData,
    AnnotationsTransferredNotificationMessageData,
    AnnotationsTransferringProgressNotificationMessageData,
    AutoAnnotationChangedNotificationMessageData,
    AutoAnnotationGeneratedMessageData,
    AutoAnnotationsAndAnnotationsListsChangedNotificationMessageData,
    AutoAnnotationsGenerationProgressMessageData,
    AutoAnnotationsListChangedNotificationMessageData,
    AutoAnnotationsSectionProcessStatusChangedMessageData,
    DocumentConversionFailedNotificationMessageData,
    ProjectMessageType,
    ProjectNotificationsHandlers,
    ProjectNotificationsMessageData,
    SupportingDocumentProcessingStatusUpdateMessageData,
    SupportingDocumentUploadedNotificationMessageData,
    VerificationDocumentProcessingStatusUpdateMessageData,
} from './ProjectNotificationsSubscriber.Types';

export default class ProjectNotificationsSubscriber extends NotificationsSubscriber<ProjectNotificationsMessageData> {
    constructor(notificationsConnector: NotificationsConnector, projectId: string, eventsHandlers: ProjectNotificationsHandlers, onSubscribeHndlers: Array<() => void> | null) {
        super(
            notificationsConnector,
            { hub: 'SendProjectNotification', subscribe: 'SubscribeToProjectNotifications', unsubscribe: 'UnsubscribeFromProjectNotifications' },
            [projectId],
            onSubscribeHndlers,
            (message) => {
                if (message.messageType === ProjectMessageType.VerificationDocumentProcessingStatusUpdate)
                    eventsHandlers.verificationDocumentProcessingStatusUpdate?.(message.data as VerificationDocumentProcessingStatusUpdateMessageData);
                if (message.messageType === ProjectMessageType.SupportingDocumentProcessingStatusUpdate)
                    eventsHandlers.supportingDocumentProcessingStatusUpdate?.(message.data as SupportingDocumentProcessingStatusUpdateMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationGenerated)
                    eventsHandlers.autoAnnotationGenerated?.(message.data as AutoAnnotationGeneratedMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationGenerationProgress)
                    eventsHandlers.autoAnnotationGenerationProgress?.(message.data as AutoAnnotationsGenerationProgressMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationsSectionProcessStatusChanged)
                    eventsHandlers.autoAnnotationsSectionProcessStatusChanged?.(message.data as AutoAnnotationsSectionProcessStatusChangedMessageData);
                if (message.messageType === ProjectMessageType.AnnotationsTransferred)
                    eventsHandlers.annotationsTransferred?.(message.data as AnnotationsTransferredNotificationMessageData);
                if (message.messageType === ProjectMessageType.AnnotationsTransferringProgress)
                    eventsHandlers.annotationsTransferringProgress?.(message.data as AnnotationsTransferringProgressNotificationMessageData);
                if (message.messageType === ProjectMessageType.AnnotationChanged)
                    eventsHandlers.annotationChanged?.(message.data as AnnotationChangedNotificationMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationChanged)
                    eventsHandlers.autoAnnotationChanged?.(message.data as AutoAnnotationChangedNotificationMessageData);
                if (message.messageType === ProjectMessageType.SupportingDocumentUploaded)
                    eventsHandlers.supportingDocumentUploaded?.(message.data as SupportingDocumentUploadedNotificationMessageData);
                if (message.messageType === ProjectMessageType.AnnotationsReordered)
                    eventsHandlers.annotationsReordered?.(message.data as AnnotationsReorderedNotificationMessageData);
                if (message.messageType === ProjectMessageType.DocumentConversionFailed)
                    eventsHandlers.supportingDocumentConversionFailed?.(message.data as DocumentConversionFailedNotificationMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationsAndAnnotationsListsChanged)
                    eventsHandlers.autoAnnotationsAndAnnotationsListsChanged?.(message.data as AutoAnnotationsAndAnnotationsListsChangedNotificationMessageData);
                if (message.messageType === ProjectMessageType.AutoAnnotationsListChanged)
                    eventsHandlers.autoAnnotationsListChanged?.(message.data as AutoAnnotationsListChangedNotificationMessageData);
            }
        );
    }
}
