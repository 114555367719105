import { Highlight } from '../PdfViewerWithToolbar.Types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { IconType } from 'UI/Icon';
import { Icon, ThemeProvider, Tooltip, Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';
import { WebViewerInstance } from '@pdftron/webviewer';
import { HighlightExtended, getZoomRounded } from '../Hooks/Annotations/useDrawing';
import { TooltipsContainerClassName } from '../Hooks/Annotations/Drawing/ViewerAnnotations/HighlightLeftTopInformation';
import HighlightLeftTopInformationTooltipComponent from './HighlightLeftTopInformationTooltip/HighlightLeftTopInformationTooltip.Component';
import { GetAnnotationsByHighlightId, SupportStateHighlight } from '../Hooks/Annotations/useAnnotationsSelecting';

type HighlightLeftTopInformationComponentProps<THighlightCustomData> = {
    instance: WebViewerInstance;
    highlight: HighlightExtended<THighlightCustomData>;
    selectedHighlightIds: Array<string>;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    selectAnnotations: (highlights: Array<SupportStateHighlight>) => void;
    emotionCache: EmotionCache;
    setStateRef: React.MutableRefObject<React.Dispatch<React.SetStateAction<HighlightLeftTopInformationComponentState<THighlightCustomData>>> | null>;
};

export type HighlightLeftTopInformationComponentState<THighlightCustomData> = {
    highlight: HighlightExtended<THighlightCustomData>;
    selectedHighlightIds: Array<string>;
};

export type HighlightLeftTopInformation<THighlightCustomData> = (highlight: Highlight<THighlightCustomData>) => Array<Info>;

type Info = {
    textContent?: string;
    iconContent?: IconType;
};

const _getScale = (instance: WebViewerInstance) => getZoomRounded(instance.Core.documentViewer.getZoomLevel());

const _getTooltipContainer = (instance: WebViewerInstance): HTMLDivElement => {
    const tooltipContainer = instance.UI.iframeWindow.document.getElementsByClassName(TooltipsContainerClassName).item(0) as HTMLDivElement;
    return tooltipContainer;
};

function HighlightLeftTopInformationComponent<THighlightCustomData>(props: HighlightLeftTopInformationComponentProps<THighlightCustomData>) {
    const {
        setStateRef,
        instance,
        highlight:highlightProps,
        selectedHighlightIds: selectedHighlightIdsProps,
        leftTopInformation,
        selectAnnotations,
        emotionCache,
    } = props;

    const rootRef = useRef<HTMLDivElement | null>(null);
    const [state, setState] = useState<HighlightLeftTopInformationComponentState<THighlightCustomData>>({ selectedHighlightIds: selectedHighlightIdsProps, highlight:highlightProps });
    setStateRef.current = setState;
    const { selectedHighlightIds, highlight } = state;
    const selected = useMemo(() => selectedHighlightIds.includes(highlight.id), [selectedHighlightIds, highlight.id]);
    const information = useMemo(() => leftTopInformation(highlight), [highlight, leftTopInformation]);

    useEffect(() => {
        const annotationRoot = rootRef.current?.parentElement?.parentElement;
        if (annotationRoot) {
            if (selected) {
                annotationRoot.style.zIndex = '100';
            } else if (highlight && highlight.siblings && highlight.siblings.length > 0 && highlight.siblings[highlight.siblings.length - 1].id === highlight.id) {
                annotationRoot.style.zIndex = '95';
            }
        }
    }, [highlight, selected]);

    const showTooltip = useMemo(() => highlight.siblings.length > 1, [highlight.siblings]);

    const getScale = useCallback(() => _getScale(instance), [instance]);
    const [scale, setScale] = useState(getScale());

    const selectAnnotationByHighlightId = useCallback(
        (highlightId: string) => {
            const highlightAnnotations = GetAnnotationsByHighlightId(instance, highlightId);
            selectAnnotations([highlightAnnotations]);
        },
        [instance, selectAnnotations]
    );

    const getTooltipContainer = useCallback(() => _getTooltipContainer(instance), [instance]);

    return (
        <ThemeProvider>
            <CacheProvider value={emotionCache}>
                <Tooltip
                    tooltipsx={{
                        transform: `scale(${scale}) !important`,
                        padding: '0.25rem',
                    }}
                    title={
                        showTooltip ? (
                            <HighlightLeftTopInformationTooltipComponent
                                siblings={highlight.siblings}
                                selectedHighlightIds={selectedHighlightIds}
                                leftTopInformation={leftTopInformation}
                                selectHighlight={selectAnnotationByHighlightId}
                            />
                        ) : (
                            ''
                        )
                    }
                    onOpen={() => setScale(getScale())}
                    PopperProps={{
                        container: getTooltipContainer,
                    }}
                >
                    <Box
                        ref={rootRef}
                        sx={{
                            position: 'absolute',
                            right: '0rem',
                            minWidth: '1.5rem',
                            height: '1rem',
                            fontWeight: 700,
                            fontSize: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRadius: '0.25rem',
                            paddingLeft: '0.25rem',
                            paddingRight: showTooltip ? '0.5rem' : '0.25rem',
                            cursor: 'pointer',
                            transform: `scale(${scale})`,
                            transformOrigin: `top right`,
                            ...(selected
                                ? {
                                      backgroundColor: palette.blue.light,
                                      border: `0.125rem solid ${palette.blue.dark}`,
                                  }
                                : {
                                      backgroundColor: palette.white.main,
                                      border: `0.125rem solid ${palette.stroke.main}`,
                                  }),
                        }}
                        data-cy='highlight-left-top-information'
                    >
                        {showTooltip && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '0rem',
                                    right: '0rem',
                                }}
                            >
                                <Icon.ArrowUpRightSquare height={6} width={6} />
                            </Box>
                        )}
                        {information.map((info, idx) => {
                            const last: boolean = idx === information.length - 1;
                            return (
                                <Box
                                    key={`highlight-leftTopInformation-${idx}`}
                                    sx={{ marginRight: last ? '0rem' : '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {info.textContent && <Typography sx={{ fontSize: '0.5rem' }}>{info.textContent}</Typography>}
                                    {info.iconContent && <info.iconContent key={`highlight-leftTopInformation-${idx}`} height='0.5rem' width='0.5rem' />}
                                </Box>
                            );
                        })}
                    </Box>
                </Tooltip>
            </CacheProvider>
        </ThemeProvider>
    );
}

export default HighlightLeftTopInformationComponent;
