import { DocumentInfo, FileInfo } from 'ApiClients/SterlingApiClients/Types';
import useStateCallback from 'Hooks/useStateCallback';
import { useEffect } from 'react';

export type DocumentsCache = {
    [key: string]: {
        name: string;
        lastSelectedDate: Date;
        fileId: string;
        fileInfo: FileInfo | null;
    };
};

export const useDocumentsState = (documents: { [key: string]: DocumentInfo }, getDocument: (docId: string, docName: string) => Promise<FileInfo | null>) => {
    // Displaying
    const [documentInfo, setDocumentInfo] = useStateCallback<DocumentInfo | null>(null);

    const setDocumentIdToDisplay = (id: string | null, callback?: () => void) => {
        if (id) setDocumentInfo(documents[id], () => callback && callback());
        else setDocumentInfo(null, () => callback && callback());
    };

    // Cache
    const [documentsCache, setDocumentsCache] = useStateCallback<DocumentsCache>({});
    useEffect(() => {
        if (documentInfo) {
            let newDocumentsCache = { ...documentsCache };
            if (documentsCache[documentInfo.id]) {
                newDocumentsCache[documentInfo.id].lastSelectedDate = new Date();
                setDocumentsCache(newDocumentsCache);
            } else {
                newDocumentsCache[documentInfo.id] = { name: documentInfo.name, fileId: documentInfo.fileId, fileInfo: null, lastSelectedDate: new Date() };
                setDocumentsCache(newDocumentsCache);
            }
        }
        // We should update cache only after switching supporting documents
        // eslint-disable-next-line
    }, [documentInfo]);

    useEffect(() => {
        const cacheLimit = 5;
        if (Object.keys(documentsCache).length > cacheLimit) {
            let newDocumentsCacheInfo: DocumentsCache = {};
            Object.keys(documentsCache)
                .map((d) => ({ id: d, ...documentsCache[d] }))
                .sort((a, b) => b.lastSelectedDate.getTime() - a.lastSelectedDate.getTime())
                .slice(0, cacheLimit)
                .forEach((d) => {
                    newDocumentsCacheInfo[d.id] = { name: d.name, fileId: d.fileId, lastSelectedDate: d.lastSelectedDate, fileInfo: d.fileInfo };
                });
            setDocumentsCache(newDocumentsCacheInfo);
        }
    }, [documentsCache, setDocumentsCache]);

    useEffect(() => {
        Object.keys(documentsCache)
            .map((d) => ({ id: d, ...documentsCache[d] }))
            .filter((d) => d.fileInfo === null)
            .forEach((d) => {
                getDocument(d.fileId, d.name).then((fileInfo) => {
                    if (fileInfo) {
                        setDocumentsCache((prevState) => {
                            let newState = { ...prevState };
                            if (newState[fileInfo.id]) {
                                newState[fileInfo.id].fileInfo = fileInfo;
                            }
                            return newState;
                        });
                    }
                });
            });
    }, [documentsCache, setDocumentsCache, getDocument]);

    return {
        documentInfo,
        setDocumentIdToDisplay,
        documentsCache,
    };
};

export default useDocumentsState;
