import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { palette } from 'UI/Provider/VerifiTheme';
import { hexToRGBA } from 'UI/functions';
import CustomWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/CustomWebViewerInstance';
import { AnnotationAttributeType } from 'Views/Common/PdfViewerWithToolbar/Hooks/Annotations/Drawing/ViewerAnnotations/Attributes';
import { useCallback, useEffect, useRef, useState } from 'react';

export type CompareProps = {
    compareEnabled: boolean;
    docIdLeft: string;
    docIdRight: string;
    setComparing?: (value: boolean) => void;
    onCompare?: (compareResult: CompareResult) => void;
    compareResult?: CompareResult | null;
    displayCompareResult: boolean;
};

export type ExternalCompareResult = {
    diffCount: number;
    doc1Annotations: Array<Core.Annotations.Annotation>;
    doc2Annotations: Array<Core.Annotations.Annotation>;
};

export type CompareResult = {
    diffCount: number;
    docCompareResultLeft: DocCompareResult;
    docCompareResultRight: DocCompareResult;
};

type DocCompareResult = {
    docId: string;
    diffs: Array<Core.Annotations.Annotation>;
};

const useCompare = (webViewerInstanceLeft: WebViewerInstance | null, webViewerInstanceRight: WebViewerInstance | null, compareProps: CompareProps) => {
    const { displayCompareResult, docIdLeft, docIdRight } = compareProps;

    const [docLeftRenderedId, setDocLeftRenderedId] = useState<string | null>(null);
    const [docRightRenderedId, setDocRightRenderedId] = useState<string | null>(null);

    const [compareResult, setCompareResult] = useState<CompareResult | null>(compareProps.compareResult || null);
    const compareResultRef = useRef(compareResult);
    compareResultRef.current = compareResult;

    useEffect(() => {
        setCompareResult(compareProps.compareResult || null);
    }, [compareProps.compareResult]);

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight && docLeftRenderedId && docRightRenderedId) {
            if (displayCompareResult && compareResult) {
                redrawDiffs(webViewerInstanceLeft, webViewerInstanceRight, compareResult);
            } else {
                eraseDiffs(webViewerInstanceLeft, webViewerInstanceRight);
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstanceLeft, webViewerInstanceRight, docLeftRenderedId, docRightRenderedId, compareResult, displayCompareResult]);

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight && docLeftRenderedId && docRightRenderedId) {
            if (docLeftRenderedId !== docIdLeft || docRightRenderedId !== docIdRight) {
                setCompareResult(null);
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstanceLeft, webViewerInstanceRight, docLeftRenderedId, docIdLeft, docRightRenderedId, docIdRight, setCompareResult]);

    const comparePropsRef = useRef(compareProps);
    comparePropsRef.current = compareProps;

    const startCompare = useCallback(async () => {
        if (webViewerInstanceLeft && webViewerInstanceRight && comparePropsRef.current && comparePropsRef.current.compareEnabled) {
            const viewerLeft = webViewerInstanceLeft.Core.documentViewer;
            const viewerRight = webViewerInstanceRight.Core.documentViewer;
            const docsReady = viewerLeft.getDocument() && viewerRight.getDocument();

            if (docsReady) {
                const _docIdLeft = comparePropsRef.current.docIdLeft;
                const _docIdRight = comparePropsRef.current.docIdRight;
                const _compareResult = compareResultRef.current;

                if (_docIdLeft !== _compareResult?.docCompareResultLeft.docId || _docIdRight !== _compareResult?.docCompareResultRight.docId) {
                    _startCompare(viewerLeft, viewerRight, comparePropsRef.current, setCompareResult);
                }
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstanceLeft, webViewerInstanceRight]);
    const startCompareRef = useRef(startCompare);
    startCompareRef.current = startCompare;

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight && docLeftRenderedId && docRightRenderedId && compareProps.compareEnabled) {
            startCompare();
        }
    }, [webViewerInstanceLeft, webViewerInstanceRight, docLeftRenderedId, docRightRenderedId, startCompare, compareProps.compareEnabled]);

    return {
        startCompareRef,
        setDocLeftRenderedId,
        setDocRightRenderedId,
    };
};

const redrawDiffs = async (webViewerInstanceLeft: WebViewerInstance, webViewerInstanceRight: WebViewerInstance, compareResult: CompareResult) => {
    await eraseDiffs(webViewerInstanceLeft, webViewerInstanceRight);
    await drawDiffs(webViewerInstanceLeft, webViewerInstanceRight, compareResult);
};

const eraseDiffs = async (webViewerInstanceLeft: WebViewerInstance, webViewerInstanceRight: WebViewerInstance) => {
    _removeDiffsAnnotations(webViewerInstanceLeft);
    _removeDiffsAnnotations(webViewerInstanceRight);
};

const _removeDiffsAnnotations = (webViewerInstance: WebViewerInstance) => {
    const { annotationManager } = webViewerInstance.Core;
    const annots = annotationManager.getAnnotationsList().filter((a) => AnnotationAttributeType.get(a) === 'diff');
    annotationManager.deleteAnnotations(annots);
};

const drawDiffs = async (webViewerInstanceLeft: WebViewerInstance, webViewerInstanceRight: WebViewerInstance, compareResult: CompareResult) => {
    let tasks: Array<Promise<void>> = [];
    _addDiffsAnnotations(webViewerInstanceLeft, compareResult.docCompareResultLeft.diffs, tasks, 'old');
    _addDiffsAnnotations(webViewerInstanceRight, compareResult.docCompareResultRight.diffs, tasks, 'new');
    await Promise.all(tasks);
};

const _addDiffsAnnotations = (
    webViewerInstance: WebViewerInstance,
    diffs: Array<Core.Annotations.Annotation>,
    tasks: Array<Promise<void>>,
    type: 'old' | 'new'
) => {
    const { Annotations, annotationManager } = webViewerInstance.Core;
    const annots: Array<Core.Annotations.TextStrikeoutAnnotation> = [];
    const colorHex = type === 'old' ? palette.attention.high : palette.attention.low;
    const colorRgba = hexToRGBA(colorHex);
    const color = new Annotations.Color(colorRgba.R, colorRgba.G, colorRgba.B, type === 'old' ? 1 : 0.3);
    diffs.forEach((r) => {
        const result = r as Core.Annotations.TextHighlightAnnotation;
        const annot = type === 'old' ? new Annotations.TextStrikeoutAnnotation() : new Annotations.TextHighlightAnnotation();
        annot.PageNumber = result.PageNumber;
        annot.Quads = result.Quads;
        annot.StrokeColor = color;
        annot.FillColor = color;
        annot.NoResize = true;
        AnnotationAttributeType.set(annot, 'diff');
        annots.push(annot);
    });
    annotationManager.addAnnotation(annots);
    getpages(annots).forEach((p) => tasks.push(annotationManager.drawAnnotations({ pageNumber: p, majorRedraw: true })));
};

const _startCompare = async (
    viewerLeft: Core.DocumentViewer,
    viewerRight: Core.DocumentViewer,
    compareProps: CompareProps,
    setCompareResult: (compareResult: CompareResult) => void
) => {
    const viewerElement = document.createElement('div');
    viewerElement.style.display = 'none';
    document.body.appendChild(viewerElement);

    /*
        If we use two separate instances of WebViewer, we need to sync namespaces between them. Without this, the semantic diff will not work.
        But after syncing namespaces, the WebViewer will not work properly. 'startSemanticDiff' doesn't return any diffs. 
    */
    CustomWebViewerInstance(viewerElement).then((instance) => {
        compareProps.setComparing?.(true);
        const { UI, Core } = instance;

        UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
            const [documentViewer1, documentViewer2] = Core.getDocumentViewers();

            const startCompare = async () => {
                const shouldCompare = documentViewer1.getDocument() && documentViewer2.getDocument();
                if (shouldCompare) {
                    const compareResult: ExternalCompareResult = (await documentViewer1.startSemanticDiff(documentViewer2)) as ExternalCompareResult;

                    compareProps.onCompare?.({
                        diffCount: compareResult.diffCount,
                        docCompareResultLeft: { docId: compareProps.docIdLeft, diffs: compareResult.doc1Annotations },
                        docCompareResultRight: { docId: compareProps.docIdRight, diffs: compareResult.doc2Annotations },
                    });
                    setCompareResult({
                        diffCount: compareResult.diffCount,
                        docCompareResultLeft: { docId: compareProps.docIdLeft, diffs: compareResult.doc1Annotations },
                        docCompareResultRight: { docId: compareProps.docIdRight, diffs: compareResult.doc2Annotations },
                    });

                    compareProps.setComparing?.(false);
                    viewerElement.remove();
                }
            };

            documentViewer1.addEventListener('documentLoaded', startCompare);
            documentViewer2.addEventListener('documentLoaded', startCompare);

            const doc1 = await viewerLeft.getDocument().getFileData();
            const doc2 = await viewerRight.getDocument().getFileData();
            documentViewer1.loadDocument(doc1);
            documentViewer2.loadDocument(doc2);
        });

        UI.enableFeatures([UI.Feature.MultiViewerMode]);
    });
};

const getpages = (annots: Array<Core.Annotations.Annotation>) => {
    let pagesTmp: Array<number> = [];
    annots.forEach((a) => {
        pagesTmp = [...pagesTmp, a.PageNumber];
    });
    const pages = [...new Set(pagesTmp)];

    return pages;
};

export default useCompare;
