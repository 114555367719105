import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ReportsApiClient from 'ApiClients/SterlingApiClients/ReportsApiClient/Reports.ApiClient';
import { useParams } from 'react-router-dom';
import ProjectDashboardComponent from './ProjectDashboard.Component';
import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';
import { useEffect, useMemo } from 'react';
import { Capability_ProjectCollaboration } from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ProjectIndexReport, ProjectMember, VerificationNote } from 'ApiClients/SterlingApiClients/Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ProjectModulesProps } from '../ProjectModulesWrapper/ProjectModules.Component';

export type ProjectDashboardModuleProps = {
    projectModulesProps: ProjectModulesProps;
    reportsApiClient: ReportsApiClient;
    projectsApiClient: ProjectsApiClient;
    auditLogsApiClient: AuditLogsApiClient;
    refreshData: boolean;
};

function ProjectDashboardModule(props: ProjectDashboardModuleProps) {
    const { versionId: projectVersionIdUrlParam } = useParams();
    const { projectModulesProps, reportsApiClient: reportsApi, projectsApiClient: projectsApi, auditLogsApiClient, refreshData } = props;
    const { project } = projectModulesProps;

    const {
        data: report,
        fetching: reportFetching,
        refetch: refetchReport,
    } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, ProjectIndexReport | null>({
        method: (input, flowMethods) => reportsApi.getIndexReport(input.projectId, input.projectVersionId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId: project.id, projectVersionId: project.workingVersion.id } },
    });

    const {
        data: members,
        fetching: membersFetching,
        refetch: refetchMembers,
    } = usePromiseWithFlowMethods<{ projectId: string }, Array<ProjectMember>>({
        method: (input, flowMethods) => projectsApi.getMembers(input.projectId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId: project.id } },
    });

    const fetching = useMemo(() => reportFetching || membersFetching, [reportFetching, membersFetching]);

    useEffect(() => {
        auditLogsApiClient.createAuditLog({
            body: {
                content: JSON.stringify({
                    projectId: project.id,
                }),
                actionType: 'OpenDashboard',
                capability: Capability_ProjectCollaboration,
            },
        });
    }, [auditLogsApiClient, project.id]);

    useEffect(() => {
        if (refreshData) {
            refetchReport();
            refetchMembers();
        }
    }, [refreshData, refetchReport, refetchMembers]);

    const getVerificationNote = (flowMethods?: FlowMethods<VerificationNote>) =>
        reportsApi.getVerificationNote(project.id, project.workingVersion.id, flowMethods);

    return (
        <ProjectDashboardComponent
            projectId={project.id}
            projectVersionIdUrlParam={projectVersionIdUrlParam!}
            report={report}
            members={members}
            getVerificationNote={getVerificationNote}
            fetching={fetching}
        />
    );
}

export default ProjectDashboardModule;
