import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsStatus = (
    projectId: string,
    projectVersionId: string,
    annotationApi: AnnotationsApiClient,
    refreshAnnotation: (annotationId: string) => void
) => {
    const acceptAnnotationStage = (annotationId: string, currentStatus: AnnotationStatus, flowMethods: FlowMethods) =>
        annotationApi.acceptAnnotationStage(
            { projectId, projectVersionId, annotationId },
            {
                body: { currentStatus },
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    refreshAnnotation(annotationId);
                },
            }
        );

    return {
        acceptAnnotationStage,
    };
};

export default useAnnotationsStatus;
