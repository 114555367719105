import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ProjectCreatorComponent from './ProjectCreator.Component';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { AppRoutes, SearchRoutes } from 'App/RoutesPaths';
import { ProjectConfigParam, ProjectDocumentType, ProjectDocumentValidationResult, TransactionType } from 'ApiClients/SterlingApiClients/Types';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ProjectTabRouteWorkspace } from 'App/RoutesPaths/Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance';

type ProjectCreatorModuleProps = {
    projectsApiClient: ProjectsApiClient;
};

function ProjectCreatorModule(props: ProjectCreatorModuleProps) {
    const { projectsApiClient: projectsApi } = props;
    const [uploading, setUploading] = useState<boolean>(false);

    const navigate = useNavigate();
    const goToProjectWorkspace = (projectId: string) =>
        navigate(
            `${AppRoutes.projectInstance.projectVersionInstance.specificPath({ projectId, versionId: ActiveProjectVersionId, tab: ProjectTabRouteWorkspace })}${
                SearchRoutes.goToEditProjectMembers.hash
            }`
        );

    const addProject = (
        name: string,
        file: File,
        transactionType: TransactionType,
        configParams: Array<ProjectConfigParam>,
        documentType: ProjectDocumentType
    ) =>
        projectsApi.addProject({
            body: { name, verificationDocument: file, transactionType, configParams, documentType },
            setFetching: setUploading,
            onSuccess: (projectId) => goToProjectWorkspace(projectId),
        });

    const validateProjectDocument = (file: File, documentType: ProjectDocumentType, flowMethods?: FlowMethods<ProjectDocumentValidationResult>) => {
        projectsApi.validateProjectDocument({
            body: { verificationDocument: file, documentType: documentType },
            setFetching: flowMethods?.setFetching,
            onSuccess: flowMethods?.onSuccess,
        });
    };

    return <ProjectCreatorComponent addProject={addProject} uploading={uploading} validateProjectDocument={validateProjectDocument} />;
}

export default ProjectCreatorModule;
