import { Box, CircularProgress } from '@mui/material';
import { Button } from 'UI';
import { HeadHeight } from '../../Head/Head.Component';
import { ToolbarHeight } from '../../Toolbar/Toolbar.Component';
import { PropsWithChildren } from 'react';

type PropositionsListComponentProps = {
    fetching: boolean;
    resultsLength: number;
    resultsLengthToDisplay: number;
    increaseResultsLengthToDisplay: () => void;
};

function PropositionsListComponent(props: PropsWithChildren<PropositionsListComponentProps>) {
    const { children, fetching, resultsLength, resultsLengthToDisplay, increaseResultsLengthToDisplay } = props;

    return (
        <Box
            sx={{
                position: 'relative',
                height: `calc(100% - ${HeadHeight} - ${ToolbarHeight})`,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {fetching && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        textAlign: 'center',
                        zIndex: 20,
                        backgroundColor: '#dfdfdfcc',
                    }}
                >
                    <CircularProgress sx={{ marginTop: '4rem', position: 'fixed' }} />
                </Box>
            )}
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        minWidth: '61.5rem',
                    }}
                >
                    {children}
                    {resultsLength > 0 && resultsLength > resultsLengthToDisplay && (
                        <Box sx={{ height: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button color='secondary' onClick={() => increaseResultsLengthToDisplay()}>
                                Show More
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default PropositionsListComponent;
