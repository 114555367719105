import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import { BoundingBoxSection, SelectionType, SupportingInformation } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import { GetSupportingInformationPropositionsMethodType, SupportingInformationPropositionByAnnotationId } from './useSupportingInformationPropositions';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useMemo } from 'react';

export type SupportingInformationProps = {
    supportingInformationByAnnotationId: SupportingInformationByAnnotationId;
    getSupportingInformation: (annotationId: string) => void;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
};

export type SupportingInformationByAnnotationId = {
    [key: string]: SupportingInformationContext;
};

export type SupportingInformationContext = {
    isFetching: boolean;
    supportingInformation: Array<SupportingInformation> | null;
};

const useSupportingInformation = (
    projectId: string,
    projectVersionId: string,
    suppInfoApiClient: SupportingInformationApiClient,
    supportingInformationPropositionByAnnotationIdRef: React.MutableRefObject<SupportingInformationPropositionByAnnotationId>,
    getSupportingInformationPropositionsRef: React.MutableRefObject<GetSupportingInformationPropositionsMethodType>,
    refreshAnnotationRef: React.MutableRefObject<(annotationId: string) => void>
) => {
    const rootRouteParams = useMemo(() => ({ projectId, projectVersionId }), [projectId, projectVersionId]);
    const [supportingInformationByAnnotationId, setSupportingInformationByAnnotationId] = useStateById<SupportingInformationContext>();
    const getSupportingInformation = (annotationId: string) => {
        setSupportingInformationByAnnotationId.Function(annotationId, (prev) => ({
            isFetching: true,
            supportingInformation: prev?.supportingInformation ? prev.supportingInformation : [],
        }));
        suppInfoApiClient.getSupportingInformation(
            { ...rootRouteParams, annotationId },
            {
                onSuccess: (data) =>
                    setSupportingInformationByAnnotationId.Value(annotationId, {
                        isFetching: false,
                        supportingInformation: data,
                    }),
            }
        );
    };

    const addSupportingInformation = (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) =>
        suppInfoApiClient.addSupportingInformation(
            { ...rootRouteParams, annotationId },
            {
                ...flowMethods,
                body: { supportingDocumentId, selectionType, boundingBoxSections, statement },
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    refreshAnnotationRef.current(annotationId);
                    getSupportingInformation(annotationId);
                },
            }
        );

    const modifySupportingInformation = (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) =>
        suppInfoApiClient.modifySupportingInformation(
            { ...rootRouteParams, annotationId, supportingInformationId },
            {
                ...flowMethods,
                body: { selectionType, boundingBoxSections, statement },
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    getSupportingInformation(annotationId);
                },
            }
        );

    const deleteSupportingInformation = (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) =>
        suppInfoApiClient.deleteSupportingInformation(
            { ...rootRouteParams, annotationId, supportingInformationId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess?.(data);
                    getSupportingInformation(annotationId);
                    if (supportingInformationPropositionByAnnotationIdRef.current[annotationId]) {
                        getSupportingInformationPropositionsRef.current(annotationId, {
                            keepPrevData: true,
                        });
                    }
                    refreshAnnotationRef.current(annotationId);
                },
            }
        );

    return {
        supportingInformationByAnnotationId,
        getSupportingInformation,
        addSupportingInformation,
        modifySupportingInformation,
        deleteSupportingInformation,
    };
};

export default useSupportingInformation;
