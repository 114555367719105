import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { Button, Dialog, Typography } from 'UI';

export type DeleteModalComponentProps = {
    isOpen: boolean;
    deleteAnnotation: (flowMethods: FlowMethods) => void;
    closeModal: () => void;
};

function DeleteModalComponent(props: DeleteModalComponentProps) {
    const { isOpen, closeModal } = props;

    const { wrappedMethod: deleteAnnotation, fetching: removingTag } = usePromiseWithFlowMethods<{}, {}>({
        method: (_input, flowMethods) =>
            props.deleteAnnotation({
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    closeModal();
                },
            }),
        initialData: {},
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        deleteAnnotation({});
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Delete Annotation</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Typography fontSize={14} fontWeight={600} marginBottom={4}>
                        Are you sure you want to delete this annotation?
                    </Typography>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-annotation-deleting'
                        >
                            Cancel
                        </Button>
                        <Button
                            data-cy='delete-annotation'
                            isLoading={removingTag}
                            color='error'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Delete
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default DeleteModalComponent;
