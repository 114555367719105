import { useLayoutEffect } from 'react';
import AuthApiClient, { AuthUser } from 'ApiClients/Auth.ApiClient';
import LoadingLayoutComponent from 'App/LoadingLayout/LoadingLayout.Component';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'App/RoutesPaths';

type LoginRedirectModuleProps = {
    authApiClient: AuthApiClient;
    setAuth: (user: AuthUser, isAuthenticated: boolean) => void;
};

function LoginRedirectModule(props: LoginRedirectModuleProps) {
    const { authApiClient, setAuth } = props;

    const navigate = useNavigate();

    useLayoutEffect(() => {
        authApiClient.getDataFromUrl().then(
            (result) => {
                navigate(result.redirectPath);
                setAuth(result.user, true);
            },
            () => {
                navigate(AppRoutes.homePage.specificPath({}));
            }
        );
    }, [authApiClient, navigate, setAuth]);

    return <LoadingLayoutComponent />;
}

export default LoginRedirectModule;
