import { ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProviderProps as MuiThemeProviderProps } from '@mui/system';
import combineThemes from './combine';

type Override<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

type ThemeProviderProps = Override<
    MuiThemeProviderProps,
    {
        theme?: ThemeOptions;
    }
>;

export default function ThemeProvider({ theme, ...rest }: ThemeProviderProps) {
    return <MuiThemeProvider {...rest} theme={combineThemes(theme)} />;
}
