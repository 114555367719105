import { RootRoutePath } from "App/RoutesPaths/RoutePath";
import ProjectVersionInstance from "./Routes/ProjectVersionInstance/ProjectVersionInstance";
import ProjectVersion from "./Routes/ProjectVersion/ProjectVersion";

type ProjectInstanceParams = { projectId: string };

export default class ProjectInstance extends RootRoutePath<ProjectInstanceParams> {
    public projectVersionInstance = new ProjectVersionInstance(this.specificPath.bind(this));
    public projectVersion = new ProjectVersion(this.specificPath.bind(this));

    constructor() {
        super('/project/:projectId');
    }
}

