import { useEffect, useRef } from 'react';
import { BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { HighlightToolbarActionsState, NewHighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { Box } from '@mui/material';
import PartsToolbarComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/PartsToolbar/PartsToolbar.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useApiModify } from 'ApiClients/Hooks/ApiWrappers';

export type NewAnnotationPanelWrapperComponentProps = HighlightToolbarActionsState<NewAnnotationPanelActionsState> & {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    addAnnotation: (selectionType: SelectionType, boundingBoxSections: BoundingBoxSection[], statement: string, flowMethods: FlowMethods) => void;
};

export type NewAnnotationPanelActionsState = {
    addAnnotationFetching: boolean;
};

export const NewAnnotationId = 'new-annotation';

function NewAnnotationPanelWrapperComponent(props: NewAnnotationPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, addAnnotation, actionsState, setHighlightActionsState } = props;

    const { setHighlightToolbarRef, addPart } = newHighlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const { action: addAnnotationWrapped } = useApiModify<{
        selectionType: SelectionType;
        boundingBoxSections: BoundingBoxSection[];
        statement: string;
    }>({
        method: (input, flowMethods) =>
            addAnnotation(input.selectionType, input.boundingBoxSections, input.statement, {
                ...flowMethods,
                setFetching: (value) => setHighlightActionsState(NewAnnotationId, 'addAnnotationFetching', value),
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    newHighlightToolbarOptions.clearSelection();
                },
            }),
    });

    let panel: JSX.Element = (
        <div style={{ display: 'flex' }}>
            <HighlightButtonComponent
                rootRef={ref}
                content='CREATE ANNOTATION'
                onClick={async () => {
                    const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                    addAnnotationWrapped(newHighlight);
                }}
                loading={actionsState?.addAnnotationFetching}
            />
            <Box sx={{ width: '0.25rem' }} />
            <PartsToolbarComponent addPart={addPart} disabled={actionsState?.addAnnotationFetching} />
        </div>
    );
    return <div ref={ref}>{panel}</div>;
}

export default NewAnnotationPanelWrapperComponent;
