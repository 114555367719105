import { NodeRoutePath, RouteParams } from "App/RoutesPaths/RoutePath";
import Creator from "./Routes/Creator";
import Draft from "./Routes/Draft";
import List from "./Routes/List";

export default class ProjectVersion<TParentParams extends RouteParams> extends NodeRoutePath<TParentParams> {
    public creator = new Creator(this.specificPath.bind(this));
    public draft = new Draft(this.specificPath.bind(this));
    public list = new List(this.specificPath.bind(this));

    constructor(parentSpecificPath: (params: TParentParams) => string) {
        super('version', parentSpecificPath);
    }
}