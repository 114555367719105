import { ProjectMember, ProjectMemberExtended, ProjectMemberRole, User } from 'ApiClients/SterlingApiClients/Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import React from 'react';
import { ProjectMemberRoleInfo, ProjectRolesDictionary } from '../Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { useToastModalClose } from 'Hooks/useToasts';
import Dialog from 'UI/Dialog/Dialog.Component';
import Button from 'UI/Button/Button.Component';
import Autocomplete from 'UI/Autocomplete/Autocomplete.Component';
import Box from '@mui/material/Box';
import Select, { SelectProps } from 'UI/Select/Select.Component';
import IconButton from '@mui/material/IconButton';
import { AuthContext } from 'Contexts/Auth.Context';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TableList from 'UI/TableList/TableList.Component';
import * as Icon from 'UI/Icon/Icon';
import MenuItem from '@mui/material/MenuItem';

export type ProjectMembersModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    projectId: string;
    getUsers: (flowMethods: FlowMethods<Array<User>>) => void;
    getMembers: (projectId: string, flowMethods: FlowMethods<Array<ProjectMemberExtended>>) => void;
    addMember: (projectId: string, userId: string, role: ProjectMemberRole, flowMethods: FlowMethods) => void;
    changeMemberRole: (projectId: string, memberId: string, role: ProjectMemberRole, flowMethods: FlowMethods) => void;
    removeMember: (projectId: string, memberId: string, flowMethods: FlowMethods) => void;
};

type ProjectMemberModel = ProjectMemberRoleInfo & { id: string };

function ProjectMembersModal({ isOpen, projectId, removeMember, closeModal: onCloseModal, ...props }: ProjectMembersModalProps) {
    const { closeModal, flowMethodsMiddleware: addToastMiddleware } = useToastModalClose(onCloseModal);
    const [newMember, setNewMember] = React.useState<User | null>(null);
    const [newMemberRole, setNewMemberRole] = React.useState<ProjectMemberModel | null>(null);
    const { user } = React.useContext(AuthContext);
    const {
        data: members,
        wrappedMethod: _getMembers,
        fetching: fetchingMembers,
    } = usePromiseWithFlowMethods<{ projectId: string }, Array<ProjectMemberExtended>>({
        method: (input, flowMethods) => props.getMembers(input.projectId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId } },
    });
    const getMembers = React.useCallback(() => _getMembers({ projectId }), [_getMembers, projectId]);

    const { data: users } = usePromiseWithFlowMethods<{}, Array<User>>({
        method: (_input, flowMethods) => props.getUsers(flowMethods),
        initialData: [],
        initFetch: { input: {} },
    });

    const { wrappedMethod: addMember, fetching: isAddingMember } = usePromiseWithFlowMethods<
        { projectId: string; userId: string; role: ProjectMemberRole },
        {}
    >({
        method: (input, flowMethods) => props.addMember(input.projectId, input.userId, input.role, addToastMiddleware(flowMethods)),
        initialData: {},
    });

    const { wrappedMethod: changeMemberRole, fetching: isChangeMemberRoleInProgress } = usePromiseWithFlowMethods<
        { projectId: string; memberId: string; role: ProjectMemberRole },
        {}
    >({
        method: (input, flowMethods) => props.changeMemberRole(input.projectId, input.memberId, input.role, addToastMiddleware(flowMethods)),
        initialData: {},
    });

    const rolesMapped = Object.keys(ProjectRolesDictionary).map((id) => {
        const key = id as unknown as ProjectMemberRole;
        const roleDef = ProjectRolesDictionary[key];
        return { ...roleDef, id };
    });

    const usersMapped: User[] = React.useMemo(
        () =>
            users
                .filter((u) => !members.find((m) => m.email === u.email))
                .sort((a, b) => {
                    if (a.fullName > b.fullName) {
                        return 1;
                    }
                    return -1;
                }),

        [users, members]
    );

    const handleSelectedMember = (_: React.SyntheticEvent, newValue: User | null) => {
        setNewMember(newValue);
    };
    const handleSelectedPermission = (_: React.SyntheticEvent, newValue: ProjectMemberModel | null) => {
        setNewMemberRole(newValue);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (newMember && newMemberRole) {
            addMember(
                { projectId, userId: newMember?.id, role: parseInt(newMemberRole?.id?.toString()) },
                {
                    onSuccess: () => {
                        setNewMember(null);
                        setNewMemberRole(null);
                        getMembers();
                    },
                }
            );
        }
    };

    const handlePermissionChange =
        (member: ProjectMember): SelectProps<(typeof rolesMapped)[0]>['onChange'] =>
        (newRole) => {
            const val = newRole.target.value as string;
            changeMemberRole(
                { projectId, memberId: member.id, role: parseInt(val) as unknown as ProjectMemberRole },
                {
                    onSuccess: () => {
                        getMembers();
                    },
                }
            );
        };

    const handleMemberRemove = (member: ProjectMember) => () => {
        removeMember(projectId, member.id, {
            onSuccess: () => {
                getMembers();
            },
        });
    };
    const membersSorted = members.sort((a, b) => {
        if (a.fullName > b.fullName) {
            return 1;
        }
        return -1;
    });
    const inProgress = fetchingMembers || isAddingMember;
    const hasMembers = membersSorted?.length > 0;
    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Project Members</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                    >
                        <Autocomplete<User>
                            data-cy='users-dropdown'
                            label='Select Members'
                            options={usersMapped}
                            getOptionLabel={(option: User) => option.fullName}
                            placeholder='Members'
                            value={newMember}
                            onChange={handleSelectedMember}
                            isOptionEqualToValue={(option: User, value: User) => option.id === (value?.id ?? '')}
                            required
                        />
                    </Box>
                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                    >
                        <Autocomplete<ProjectMemberModel>
                            label='Select the Permission Level'
                            data-cy='permission-dropdown'
                            options={rolesMapped}
                            placeholder='Permission Level'
                            value={newMemberRole}
                            getOptionLabel={(option: ProjectMemberModel) => option.name}
                            isOptionEqualToValue={(option: ProjectMemberModel, value: ProjectMemberModel) => option.id === (value?.id ?? '')}
                            onChange={handleSelectedPermission}
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                        position='relative'
                    >
                        {(isAddingMember || isChangeMemberRoleInProgress) && (
                            <Box
                                position='absolute'
                                sx={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                <CircularProgress size={20} />
                            </Box>
                        )}

                        {hasMembers && (
                            <TableList.Table
                                {...(isAddingMember && {
                                    style: {
                                        opacity: 0.5,
                                        pointerEvents: 'none',
                                    },
                                })}
                                maxHeight={230}
                            >
                                <TableList.Header>
                                    <TableList.TR isHeader>
                                        <TableList.TH>Name</TableList.TH>
                                        <TableList.TH>Email</TableList.TH>
                                        <TableList.TH size='md'>Permission Level</TableList.TH>
                                        <TableList.TH size='sm' />
                                    </TableList.TR>
                                </TableList.Header>
                                <TableList.Body data-cy='members-table-body'>
                                    {membersSorted.map((member) => {
                                        const currentRole = (rolesMapped.find((r) => r.id === member.role.toString()) ??
                                            '') as unknown as (typeof rolesMapped)[0];
                                        const isCurrentUser = member.userId === user.id;

                                        return (
                                            <TableList.TR key={member.id} data-cy='members-table-row'>
                                                <TableList.TD>{member.fullName}</TableList.TD>
                                                <TableList.TD>{member.email}</TableList.TD>
                                                {isCurrentUser && !member.isProjectLeader && <TableList.TD size='md'>{ProjectRolesDictionary[member.role].name}</TableList.TD>}
                                                {member.isProjectLeader && <TableList.TD size='md'>Project Lead</TableList.TD>}
                                                {!isCurrentUser && !member.isProjectLeader && (
                                                    <TableList.TD size='md'>
                                                        <Select<(typeof rolesMapped)[0]>
                                                            size='small'
                                                            data-cy='role-dropdown'
                                                            value={currentRole}
                                                            label='Permission level'
                                                            renderValue={(val) => val.name}
                                                            onChange={handlePermissionChange(member)}
                                                        >
                                                            {rolesMapped.map((item) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </TableList.TD>
                                                )}
                                                <TableList.TD size='sm' textAlign='center'>
                                                    {!isCurrentUser && !member.isProjectLeader && (
                                                        <IconButton
                                                            data-cy='member-remove'
                                                            size='small'
                                                            sx={{
                                                                padding: 0,
                                                            }}
                                                            onClick={handleMemberRemove(member)}
                                                        >
                                                            <Icon.CloseCircle />
                                                        </IconButton>
                                                    )}
                                                </TableList.TD>
                                            </TableList.TR>
                                        );
                                    })}
                                </TableList.Body>
                            </TableList.Table>
                        )}
                        {!hasMembers && <Typography variant='inputLabel'>There are no members assigned yet</Typography>}
                    </Box>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-members'
                        >
                            Close
                        </Button>
                        <Button
                            data-cy='add-member'
                            isLoading={isAddingMember}
                            disabled={inProgress}
                            color='primary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Add member
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default ProjectMembersModal;
