import { WebViewerInstance } from '@pdftron/webviewer';

type LogType = 'EVENT' | 'HOOK' | 'RENDER' | 'ACTION';

const LogEnabled: boolean = false;

export const Log = (webViewerInstance: WebViewerInstance | null, logType: LogType, path: Array<string>, values?: Array<any>) => {
    if (LogEnabled && window.location.hostname === 'localhost') {
        let log = `${logType} >>> `;
        path.forEach((p) => (log += `${p} -> `));

        let args: Array<any> = [];
        args.push(log);
        values?.forEach((v) => args.push(v));

        let messageConfig = '%c%s   ';

        args.forEach((argument) => {
            const type = typeof argument;
            switch (type) {
                case 'bigint':
                case 'number':
                case 'boolean':
                    messageConfig += '%d   ';
                    break;

                case 'string':
                    messageConfig += '%s   ';
                    break;

                case 'object':
                case 'undefined':
                default:
                    messageConfig += '%o   ';
            }
        });

        console.log(messageConfig, 'color: blue', '[PDF VIEWER]', ...args);
    }
};

export default Log;
