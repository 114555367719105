import Box from '@mui/material/Box';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import * as Icon from 'UI/Icon';
import { IconButton, Typography } from '@mui/material';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
    multiline?: boolean;
    success?: boolean;
    hasClear?: boolean;
    onClear?: () => void;
};

function TextField(props: TextFieldProps) {
    const { multiline, error, value, label, success, children, hasClear, onClear } = props;

    const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (hasClear) {
            onClear?.();
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            {multiline && label && (
                <Box marginBottom={1.5}>
                    <Typography variant='inputLabel' color={(th) => th.palette.text.primary}>
                        {label}
                    </Typography>
                </Box>
            )}
            <MuiTextField
                {...props}
                error={error}
                multiline={multiline}
                variant={multiline ? 'outlined' : 'standard'}
                value={value}
                {...(!multiline && {
                    label,
                })}
                InputLabelProps={{ shrink: true, variant: multiline ? 'outlined' : 'standard', disableAnimation: true }}
                {...(multiline && {
                    InputProps: {
                        endAdornment: (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 8,
                                    right: 8,
                                }}
                            >
                                <Typography variant='inputLabel' color='blue.medium'>
                                    {children}
                                </Typography>
                            </Box>
                        ),
                    },
                })}
                {...((success || error) &&
                    !multiline &&
                    Boolean(value) && {
                        InputProps: {
                            endAdornment: (
                                <Box
                                    display='flex'
                                    {...(success && {
                                        color: (th) => th.palette.success.main,
                                    })}
                                    {...(error && {
                                        color: (th) => th.palette.error.main,
                                    })}
                                >
                                    {success && !error && <Icon.Success />}
                                    {error && !success && <Icon.ExclamationCircle />}
                                </Box>
                            ),
                        },
                    })}
                {...(!success &&
                    !error &&
                    !multiline &&
                    Boolean(value) &&
                    hasClear && {
                        InputProps: {
                            endAdornment: (
                                <IconButton
                                    onClick={handleClear}
                                    sx={{
                                        color: (th) => th.palette.text.primary,
                                        width: 16,
                                        height: 16,
                                        padding: 0,
                                    }}
                                    size='small'
                                >
                                    <Icon.CloseCircle />
                                </IconButton>
                            ),
                        },
                    })}
            />
        </Box>
    );
}

export default TextField;
