import PropositionPanelComponent, { PropositionPanelActionsState } from './PropositionPanel/PropositionPanel.Component';
import SupportingInformationPanelComponent, { SupportingInformationPanelActionsState } from './SupportingInformationPanel/SupportingInformationPanel.Component';
import { Annotation, BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { SupportingHighlightCustomData } from '../SupportingDocument.Component';
import { useEffect, useRef } from 'react';
import { Navigation } from '../Hooks/useNavigation';
import { Highlight, HighlightToolbarActionsState, HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type SupportingInformationPanelWrapperComponentProps = HighlightToolbarActionsState<SupportingInformationPanelWrapperActionsState> & {
    highlightToolbarOptions: HighlightToolbarOptions;
    selectedHighlightRef: React.RefObject<Highlight<SupportingHighlightCustomData> | null>;
    selectedAnnotationRef: React.RefObject<Annotation | null>;
    supportingInformationNavRef: React.RefObject<Navigation>;
    modifySupportingInformation: (
        annotationId: string,
        supportingInformationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, flowMethods: FlowMethods) => void;
    propositionsNavRef: React.RefObject<Navigation>;
    rejectProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
    linkModifiedPropositionToAnnotation: (
        annotationId: string,
        propositionId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    restoreProposition: (annotationId: string, propositionId: string, flowMethods: FlowMethods) => void;
};

export type SupportingInformationPanelWrapperActionsState = SupportingInformationPanelActionsState & PropositionPanelActionsState;

function SupportingInformationPanelWrapperComponent(props: SupportingInformationPanelWrapperComponentProps) {
    const {
        highlightToolbarOptions,
        selectedHighlightRef,
        selectedAnnotationRef,
        supportingInformationNavRef,
        modifySupportingInformation,
        deleteSupportingInformation,
        propositionsNavRef,
        rejectProposition,
        linkPropositionToAnnotation,
        linkModifiedPropositionToAnnotation,
        restoreProposition,
        actionsState,
        setHighlightActionsState,
    } = props;
    const { setHighlightToolbarRef, addPart, getHighlightViewer } = highlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const selectedHighlight = selectedHighlightRef.current;
    const selectedAnnotation = selectedAnnotationRef.current;
    const supportingInformationNav = supportingInformationNavRef.current;
    const propositionsNav = propositionsNavRef.current;

    let panel: JSX.Element = <div />;
    if (selectedHighlight && selectedAnnotation && supportingInformationNav && propositionsNav) {
        switch (selectedHighlight.type) {
            case 'linked':
                panel = (
                    <SupportingInformationPanelComponent
                        selectedAnnotation={selectedAnnotation}
                        selectedHighlight={selectedHighlight}
                        navigation={supportingInformationNav}
                        modifySupportingInformation={modifySupportingInformation}
                        deleteSupportingInformation={deleteSupportingInformation}
                        actionsState={actionsState}
                        setHighlightActionsState={setHighlightActionsState}
                        addPart={addPart}
                        getHighlightViewer={getHighlightViewer}
                    />
                );
                break;
            case 'proposition':
                panel = (
                    <PropositionPanelComponent
                        selectedAnnotation={selectedAnnotation}
                        selectedHighlight={selectedHighlight}
                        navigation={propositionsNav}
                        isRejected={selectedHighlight.propositionIsRejected || false}
                        rejectProposition={rejectProposition}
                        linkPropositionToAnnotation={linkPropositionToAnnotation}
                        linkModifiedPropositionToAnnotation={linkModifiedPropositionToAnnotation}
                        restoreProposition={restoreProposition}
                        actionsState={actionsState}
                        setHighlightActionsState={setHighlightActionsState}
                        addPart={addPart}
                        getHighlightViewer={getHighlightViewer}
                    />
                );
                break;
        }
    }
    return <div ref={ref}>{panel}</div>;
}

export default SupportingInformationPanelWrapperComponent;
