import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import { DocumentInfo, Documents, DocumentsContainer, FileInfo, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { DocumentsContainerExtended } from 'Views/Project/ProjectWorkspace/Sidebar/Tabs/Documents/DocumentsList/Types';
import { useCallback, useMemo } from 'react';
import useDocumentsState from './useDocumentsState';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ObjectChangeType } from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';

export type SupportingDocumentsProps = {
    documents: {
        supportingDocumentsTree: DocumentsContainerExtended | null;
        supportingDocumentsById: { [key: string]: DocumentInfo };
    };
    getDocument: (docId: string, docName: string) => Promise<FileInfo | null>;
    supportingDocumentInfo: DocumentInfo | null;
    supportingDocumentFileInfo: FileInfo | null;
    switchSupportingDocument: (documentId: string | null, callback?: () => void) => void; // use switchSuppDoc() in ProjectWorkspace.Component.tsx instead of switchSupportingDocument()
    uploadSupportingDocument: (name: string, file: File, flowMethods: FlowMethods) => void;
    anySupportingDocumentsProcessingErrors: boolean;
};

export type SupportingDocumentsExtendedMethods = {
    setSupportingDocumentProcessingStatus: (supportingDocumentId: string, processingStatus: ProjectDocumentProcessingStatus) => void;
    handleSupportingDocumentUploaded: (supportingDocumentId: string, changeType: ObjectChangeType) => void;
    refetchSupportingDocuments: () => void;
};

const useSupportingDocuments = (
    projectId: string,
    projectVersionId: string,
    docsApi: ProjectDocumentsApiClient,
    setSelectedSupportingHighlightIdRef: React.MutableRefObject<(id: string | null, options: { dispatchEvent: boolean }) => void>,
    closeSupportingInformationPropositionsRef: React.MutableRefObject<() => void>,
    switchWebSearchDocumentRef: React.MutableRefObject<(url: string | null) => void>
): { props: SupportingDocumentsProps; extendedMethods: SupportingDocumentsExtendedMethods } => {
    const {
        data: supportingDocuments,
        refetch: refetchSupportingDocuments,
        setData: setSupportingDocuments,
    } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, Documents | null>({
        method: (input, flowMethods) => docsApi.getSupportingDocuments(input.projectId, input.projectVersionId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId, projectVersionId } },
    });

    const setSupportingDocumentProcessingStatus = useCallback((supportingDocumentId: string, processingStatus: ProjectDocumentProcessingStatus) => {
        setSupportingDocuments((prevState) => {
            if (prevState) {
                let newState = { ...prevState };
                ModifySupportingDocumentById(newState.supportingDocuments!, supportingDocumentId, (doc) => ({ ...doc, processingStatus }));

                if (processingStatus === ProjectDocumentProcessingStatus.Failed) {
                    newState.anySupportingDocumentsProcessingErrors = true;
                }

                return newState;
            } else return prevState;
        });
        // eslint-disable-next-line
    }, []);

    const uploadSupportingDocument = (name: string, file: File, flowMethods: FlowMethods) =>
        docsApi.uploadSupportingDocument(projectId!, projectVersionId, {
            body: { name, document: file },
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchSupportingDocuments();
            },
        });

    const getDocument = useCallback(
        (docId: string, docName: string) => docsApi.getDocument(projectId!, projectVersionId, docId, docName),
        [projectId, projectVersionId, docsApi]
    );
    const suppDocsById: { [key: string]: DocumentInfo } = useMemo(() => {
        if (supportingDocuments) {
            let suppDocsByIdTmp: { [key: string]: DocumentInfo } = {};
            const getDocsFromDirectory = (container: DocumentsContainer) => {
                container.documents.forEach((d) => (suppDocsByIdTmp[d.id] = d));
                container.directories.forEach((d) => getDocsFromDirectory(d));
            };
            getDocsFromDirectory(supportingDocuments.supportingDocuments!);

            return suppDocsByIdTmp;
        } else return {};
    }, [supportingDocuments]);

    const handleSupportingDocumentUploaded = useCallback(
        (_supportingDocumentId: string, changeType: ObjectChangeType) => {
            switch (changeType) {
                case ObjectChangeType.Create:
                    refetchSupportingDocuments();
                    break;
            }
        },
        [refetchSupportingDocuments]
    );

    const mapDirectories = useCallback((directories: Array<DocumentsContainer>): DocumentsContainerExtended[] => {
        return directories.map((d) => {
            return {
                documents: d.documents,
                name: d.name,
                path: d.path,
                directories: mapDirectories(d.directories),
                isOpened: false,
            };
        });
    }, []);

    const supportingDocumentsTreeExtended: DocumentsContainerExtended | null = useMemo(() => {
        const suppDocs = supportingDocuments?.supportingDocuments;
        if (suppDocs) {
            return {
                documents: suppDocs.documents,
                name: suppDocs.name,
                path: suppDocs.path,
                directories: mapDirectories(suppDocs.directories),
                isOpened: false,
            };
        } else return null;
    }, [supportingDocuments, mapDirectories]);

    const {
        documentInfo: supportingDocumentInfo,
        setDocumentIdToDisplay: _switchSupportingDocument,
        documentsCache: supDocsCache,
    } = useDocumentsState(suppDocsById, getDocument);
    const supportingDocumentFileInfo = useMemo(() => supDocsCache[supportingDocumentInfo?.id || '']?.fileInfo || null, [supportingDocumentInfo, supDocsCache]);
    const switchSupportingDocument = useCallback(
        (id: string | null, callback?: () => void) => {
            if (id !== supportingDocumentInfo?.id) {
                setSelectedSupportingHighlightIdRef.current(null, { dispatchEvent: true });
            }
            _switchSupportingDocument(id, callback);
            if (id) {
                closeSupportingInformationPropositionsRef.current();
                switchWebSearchDocumentRef.current(null);
            }
        },
        // eslint-disable-next-line
        [_switchSupportingDocument, supportingDocumentInfo?.id]
    );

    const anySupportingDocumentsProcessingErrors = useMemo(() => supportingDocuments?.anySupportingDocumentsProcessingErrors || false, [supportingDocuments]);

    return {
        props: {
            documents: {
                supportingDocumentsTree: supportingDocumentsTreeExtended,
                supportingDocumentsById: suppDocsById,
            },
            supportingDocumentInfo,
            supportingDocumentFileInfo,
            uploadSupportingDocument,
            switchSupportingDocument,
            getDocument,
            anySupportingDocumentsProcessingErrors,
        },
        extendedMethods: {
            setSupportingDocumentProcessingStatus,
            handleSupportingDocumentUploaded,
            refetchSupportingDocuments,
        },
    };
};

export default useSupportingDocuments;

const ModifySupportingDocumentById = (rootDirectory: DocumentsContainer, supportingDocumentId: string, newDoc: (doc: DocumentInfo) => DocumentInfo) => {
    const modifyDocumentInDirectory = (directory: DocumentsContainer, supportingDocumentId: string) => {
        let documentIndex = directory.documents.findIndex((document) => document.id === supportingDocumentId);

        if (documentIndex > -1) {
            directory.documents[documentIndex] = newDoc(directory.documents[documentIndex]);
            return;
        } else {
            for (let i = 0; i < directory.directories.length; i++) {
                modifyDocumentInDirectory(directory.directories[i], supportingDocumentId);
                return;
            }
        }
    };

    modifyDocumentInDirectory(rootDirectory, supportingDocumentId);
};
