import { Box } from '@mui/material';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';
import { Button, Dialog, Typography } from 'UI';
import StatusWithCountLabelComponent, { StatusConfig } from '../../../Common/StatusWithCountLabel/StatusWithCountLabel.Component';

export type TransferringAnnotationsResultModalProps = TransferringAnnotationsResultModalState & {
    closeModal: () => void;
    next: () => void;
    totalStats: number;
    statusStats: { [key in AnnotationTransferPropositionStatus]: number };
};

export type TransferringAnnotationsResultModalState = {
    isOpen: boolean;
};

function TransferringAnnotationsResultModalComponent(props: TransferringAnnotationsResultModalProps) {
    const { isOpen, closeModal, next, totalStats, statusStats } = props;

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        next();
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Transferring annotations</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Typography variant='title' sx={{ fontWeight: 700, fontSize: 14, marginBottom: '1.5rem' }}>
                        We have identified {totalStats} items:
                    </Typography>
                    <Box sx={{ display: 'grid', rowGap: 3, marginBottom: '2rem' }}>
                        <StatusData status={AnnotationTransferPropositionStatus.Found} number={statusStats[AnnotationTransferPropositionStatus.Found]} />
                        <StatusData
                            status={AnnotationTransferPropositionStatus.FoundWithModifications}
                            number={statusStats[AnnotationTransferPropositionStatus.FoundWithModifications]}
                        />
                        <StatusData status={AnnotationTransferPropositionStatus.Removed} number={statusStats[AnnotationTransferPropositionStatus.Removed]} />
                    </Box>
                    <Dialog.Actions>
                        <Button
                            fullWidth
                            type='submit'
                            sx={{
                                maxWidth: 160,
                            }}
                        >
                            Next
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

type StatusDataProps = {
    status: AnnotationTransferPropositionStatus;
    number: number;
};

function StatusData(props: StatusDataProps) {
    const { status, number } = props;

    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <StatusWithCountLabelComponent status={status} number={number} active={true} disabled={false} />
            </Box>
            <Typography variant='body2' sx={{ fontSize: '0.75rem', marginLeft: '1rem' }}>
                {StatusConfig[status].description}
            </Typography>
        </Box>
    );
}

export default TransferringAnnotationsResultModalComponent;
