import { Box } from '@mui/material';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationStatusDictionary } from '../Types';

export type AnnotationStatusComponentProps = {
    status: AnnotationStatus;
    statusNameEnabled?: boolean;
};

function AnnotationStatusComponent(props: AnnotationStatusComponentProps) {
    const { status, statusNameEnabled = true } = props;

    let state = 0;
    let statuses = 4;

    switch (status) {
        case AnnotationStatus.Annotate:
            state = 1;
            break;
        case AnnotationStatus.Verify:
            state = 2;
            break;
        case AnnotationStatus.Approve:
            state = 3;
            break;
        case AnnotationStatus.Verified:
            state = 4;
            break;
    }

    const dots = [];
    for (let i = 1; i <= statuses; i++) {
        dots.push(<DotComponent key={`dot-component-${i}`} filled={i <= state} isLast={i === statuses} />);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {dots}
            {statusNameEnabled && <Box sx={{ marginLeft: '0.75rem' }}>{AnnotationStatusDictionary[status].name}</Box>}
        </Box>
    );
}

type DotComponentProps = {
    filled: boolean;
    isLast: boolean;
};

function DotComponent(props: DotComponentProps) {
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: (theme) => theme.palette.white.main,
                    borderStyle: 'solid',
                    borderColor: (theme) => theme.palette.stroke.main,
                    borderRadius: '50%',
                    borderWidth: '0.1rem',
                    width: '1rem',
                    height: '1rem',
                    marginRight: props.isLast ? '0' : '0.25rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {props.filled && (
                    <Box
                        sx={{
                            backgroundColor: (theme) => theme.palette.blue.dark,
                            borderRadius: '50%',
                            width: '0.6rem',
                            height: '0.6rem',
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default AnnotationStatusComponent;
