import { useRef, useState } from 'react';

const useViewerScroll = () => {
    const scrollContainerRef = useRef<Element | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    return { scrollContainerRef, scrollPosition, setScrollPosition };
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const setViewerScroll = async (el: Element, scrollTop: number, retry: number = 0) => {
    el.scrollTop = scrollTop;
    // Wait until element will be visible
    // Try setting scrolling for 3 seconds
    if (el.scrollTop !== scrollTop && retry < 30) {
        await delay(100);
        setViewerScroll(el, scrollTop, retry + 1);
    }
};

export default useViewerScroll;
