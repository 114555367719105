import { useMemo } from 'react';
import { TableList } from 'UI';

type DashboardTableComponentProps<TModel> = {
    data: Array<TModel>;
    columns: Array<DashboardTableColumn<TModel>>;
    emptyContentInfo?: JSX.Element;
};

export type DashboardTableColumn<TModel> = {
    displayName: string;
    /**
     * Percentage
     */
    width: number;
    field?: keyof TModel;
    customContent?: (value: TModel, idx?: number) => JSX.Element | string;
};

function EmptyTable({ content }: { content?: JSX.Element }) {
    return (
        <TableList.TR isHeader>
            <TableList.TD sx={{ textAlign: 'center' }}>{content || 'No data'}</TableList.TD>
        </TableList.TR>
    );
}

function DashboardTableComponent<TModel>(props: DashboardTableComponentProps<TModel>) {
    const { data, columns, emptyContentInfo } = props;

    const headrowToRender = columns.map((col, colIdx) => {
        return (
            <TableList.TH sx={{ minWidth: `${col.width}% !important` }} key={`DashboardTable-th-${colIdx}`} data-testid={`header-cell-${colIdx}`}>
                {col.displayName}
            </TableList.TH>
        );
    });

    const rowsToRender = useMemo(() => {
        return data.map((val, valIdx) => {
            const renderCell = (col: DashboardTableColumn<TModel>, valIdx: number) => {
                if (col.field) {
                    return (val[col.field] as any).toString();
                } else if (col.customContent) {
                    return col.customContent(val, valIdx);
                }
                return null;
            };

            return (
                <TableList.TR
                    key={`DashboardTable-tr-${valIdx}`}
                    sx={{ backgroundColor: (theme) => (valIdx % 2 === 0 ? theme.palette.blue.light : 'inherit') }}
                >
                    {columns.map((col, colIdx) => (
                        <TableList.TD
                            sx={{ minWidth: `${col.width}% !important`, overflow: `${colIdx === 1 ? 'visible' : 'hidden'}` }}
                            key={`DashboardTable-td-${valIdx}-${colIdx}`}
                        >
                            {renderCell(col, valIdx)}
                        </TableList.TD>
                    ))}
                </TableList.TR>
            );
        });
    }, [data, columns]);

    return (
        <TableList.Table sx={{ marginBottom: '2rem' }}>
            <TableList.Header>
                <TableList.TR isHeader={true}>{headrowToRender}</TableList.TR>
            </TableList.Header>
            <TableList.Body data-cy='supporting-documents-table-body'>
                {data.length === 0 ? <EmptyTable content={emptyContentInfo} /> : rowsToRender}
            </TableList.Body>
        </TableList.Table>
    );
}

export default DashboardTableComponent;
