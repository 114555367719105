import { NodeRoutePath, RouteParams } from "App/RoutesPaths/RoutePath";

type ProjectInstanceParams<TParentParams> = TParentParams & { versionId: string; tab: ProjectTabRoute };

export type ProjectTabRoute = typeof ProjectTabRouteDashboard | typeof ProjectTabRouteWorkspace

export default class ProjectVersionInstance<TParentParams extends RouteParams> extends NodeRoutePath<TParentParams, ProjectInstanceParams<TParentParams>> {
    constructor(parentSpecificPath: (params: TParentParams) => string) {
        super('version/:versionId/:tab', parentSpecificPath);
    }
}

export const ActiveProjectVersionId = 'active'

export const ProjectTabRouteDashboard = 'dashboard'
export const ProjectTabRouteWorkspace = 'workspace'