import { WebViewerInstance } from '@pdftron/webviewer';
import { Highlight, HighlightNavigationProps } from '../../PdfViewerWithToolbar.Types';
import { useCallback, useMemo } from 'react';
import { getHighlightId, isLastAnnotationOfHighlight } from './Drawing/ViewerAnnotations/Attributes';
import { getAnnotationWindowPosition } from './Drawing/HtmlElements/Common';
import { OrderByHighlightPosition } from '../../Functions';
import { GetAnnotationsByHighlightId, SupportStateHighlight } from './useAnnotationsSelecting';

type HighlightNavigation = { [key: string]: { prev: string; next: string; page: number; coordinateY: number } };

const getPageAndCoordinates = (h: Highlight): { page: number; coordinateY: number; coordinateX: number } => ({
    page: h.boundingBoxSections[0].pageNumber,
    coordinateY: h.boundingBoxSections[0].boundingBoxes[0].topLeft.y,
    coordinateX: h.boundingBoxSections[0].boundingBoxes[0].topLeft.x,
});

type HighlightNavigationType = 'prev' | 'next';

const useHighlightNavigation = (
    webViewerInstance: WebViewerInstance | null,
    highlights: Array<Highlight>,
    selectAnnotations: (highlights: Array<SupportStateHighlight>) => void
): HighlightNavigationProps => {
    const navigation = useMemo(() => {
        const orderedHighlights = highlights.sort((a, b) => OrderByHighlightPosition(getPageAndCoordinates(a), getPageAndCoordinates(b)));

        let highlightsById: HighlightNavigation = {};
        orderedHighlights.forEach((highlight, index) => {
            const prev = index === 0 ? orderedHighlights[highlights.length - 1].id : orderedHighlights[index - 1].id;
            const next = index === orderedHighlights.length - 1 ? orderedHighlights[0].id : orderedHighlights[index + 1].id;
            highlightsById[highlight.id] = { prev, next, ...getPageAndCoordinates(highlight) };
        });

        return {
            orderedHighlights,
            highlightsById,
        };
    }, [highlights]);

    const goTo = useCallback(
        (highlightNavigationType: HighlightNavigationType) => {
            if (webViewerInstance) {
                const annotationManager = webViewerInstance.Core.annotationManager;
                const activeHighlight = annotationManager.getSelectedAnnotations()[0];
                const selectAnnotationByHighlightId = (highlightId: string) => {
                    const highlightAnnotations = GetAnnotationsByHighlightId(webViewerInstance, highlightId);
                    if (highlightAnnotations.firstAnnotation) {
                        annotationManager.jumpToAnnotation(highlightAnnotations.firstAnnotation);
                    }
                    selectAnnotations([highlightAnnotations]);
                };

                if (activeHighlight) {
                    const highlightId = getHighlightId(activeHighlight);
                    const { prev, next } = navigation.highlightsById[highlightId];
                    const newHighlightId = highlightNavigationType === 'prev' ? prev : next;
                    selectAnnotationByHighlightId(newHighlightId);
                } else {
                    const currentPage = webViewerInstance.Core.documentViewer.getCurrentPage();
                    const scrollTop = webViewerInstance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0].scrollTop;

                    const annots = webViewerInstance.Core.annotationManager.getAnnotationsList();
                    const highlightsWithWindowPosition = navigation.orderedHighlights.map((h) => {
                        const annot = annots.find((a) => getHighlightId(a) === h.id && isLastAnnotationOfHighlight(a));
                        const position = getAnnotationWindowPosition(webViewerInstance, annot!);
                        return { highlight: h, position };
                    });

                    let highlight: Highlight | undefined;
                    if (highlightNavigationType === 'prev') {
                        highlight = highlightsWithWindowPosition.findLast(
                            (h) => getPageAndCoordinates(h.highlight).page <= currentPage && h.position.topLeft.y <= scrollTop
                        )?.highlight;

                        if (!highlight) {
                            highlight = navigation.orderedHighlights[navigation.orderedHighlights.length - 1];
                        }
                    }

                    if (highlightNavigationType === 'next') {
                        highlight = highlightsWithWindowPosition.find(
                            (h) => getPageAndCoordinates(h.highlight).page >= currentPage && h.position.topLeft.y >= scrollTop
                        )?.highlight;

                        if (!highlight) {
                            highlight = navigation.orderedHighlights[0];
                        }
                    }

                    if (highlight) {
                        selectAnnotationByHighlightId(highlight.id);
                    }
                }
            }
        },
        [webViewerInstance, navigation, selectAnnotations]
    );

    const previousHighlight = useCallback(() => goTo('prev'), [goTo]);
    const nextHighlight = useCallback(() => goTo('next'), [goTo]);

    const result: HighlightNavigationProps = useMemo(() => {
        return {
            disabled: highlights.length === 0,
            previousHighlight,
            nextHighlight,
        };
    }, [highlights, previousHighlight, nextHighlight]);

    return result;
};

export default useHighlightNavigation;
