import { Box } from '@mui/material';
import { Icon } from 'UI';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AvailableToolMode } from '../../PdfViewerWithToolbar.Component';
import { ToolbarUtilComponent } from '../Toolbar.Component';

export type HighlightUtilsComponentProps = {
    webViewerInstance: WebViewerInstance;
    currentToolMode: AvailableToolMode;
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void;
    isCreatingHighlightEnabled: boolean;
};

function HighlightUtilsComponent(props: HighlightUtilsComponentProps) {
    const { webViewerInstance, currentToolMode, setToolMode, isCreatingHighlightEnabled } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarUtilComponent
                title='Create annotation (text)'
                icon={Icon.TextAreaT}
                active={currentToolMode === 'AnnotationCreateTextHighlight'}
                disabled={!isCreatingHighlightEnabled}
                onClick={() => {
                    if (isCreatingHighlightEnabled) {
                        if (currentToolMode === 'AnnotationCreateTextHighlight') setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
                        else setToolMode(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT, 'AnnotationCreateTextHighlight');
                    }
                }}
            />
            {/* POCSV-673: Temporarily hidden
            <ToolbarUtilComponent
                title='Create annotation (box)'
                icon={Icon.PlusSquareDotted}
                active={currentToolMode === 'AnnotationCreateRectangle'}
                disabled={!isCreatingHighlightEnabled}
                onClick={() => {
                    if (isCreatingHighlightEnabled) {
                        if (currentToolMode === 'AnnotationCreateRectangle') setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
                        else setToolMode(webViewerInstance.Core.Tools.ToolNames.RECTANGLE, 'AnnotationCreateRectangle');
                    }
                }}
            />*/}
        </Box>
    );
}

export default HighlightUtilsComponent;
