import { AutoAnnotationsSectionProcessStatus, AutoAnnotationsProcessStatus, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';

export enum ProjectMessageType {
    VerificationDocumentProcessingStatusUpdate = 1,
    SupportingDocumentProcessingStatusUpdate = 2,
    AutoAnnotationGenerated = 3,
    AutoAnnotationGenerationProgress = 4,
    AnnotationsTransferred = 5,
    AnnotationsTransferringProgress = 6,
    AutoAnnotationsSectionProcessStatusChanged = 7,
    AnnotationChanged = 8,
    AutoAnnotationChanged = 9,
    SupportingDocumentUploaded = 10,
    AnnotationsReordered = 11,
    DocumentConversionFailed = 12,
    AutoAnnotationsAndAnnotationsListsChanged = 13,
    AutoAnnotationsListChanged = 14,
}

export type ProjectNotificationsMessageData =
    | VerificationDocumentProcessingStatusUpdateMessageData
    | SupportingDocumentProcessingStatusUpdateMessageData
    | AutoAnnotationGeneratedMessageData
    | AutoAnnotationsGenerationProgressMessageData
    | AutoAnnotationsSectionProcessStatusChangedMessageData
    | AnnotationsTransferredNotificationMessageData
    | AnnotationsTransferringProgressNotificationMessageData
    | AnnotationChangedNotificationMessageData
    | AutoAnnotationChangedNotificationMessageData
    | SupportingDocumentUploadedNotificationMessageData
    | AnnotationsReorderedNotificationMessageData
    | DocumentConversionFailedNotificationMessageData
    | AutoAnnotationsListChangedNotificationMessageData
    | AutoAnnotationsAndAnnotationsListsChangedNotificationMessageData;

export type ProjectNotificationsHandlers = {
    verificationDocumentProcessingStatusUpdate?: (data: VerificationDocumentProcessingStatusUpdateMessageData) => void;
    supportingDocumentProcessingStatusUpdate?: (data: SupportingDocumentProcessingStatusUpdateMessageData) => void;
    autoAnnotationGenerated?: (data: AutoAnnotationGeneratedMessageData) => void;
    autoAnnotationGenerationProgress?: (data: AutoAnnotationsGenerationProgressMessageData) => void;
    autoAnnotationsSectionProcessStatusChanged?: (data: AutoAnnotationsSectionProcessStatusChangedMessageData) => void;
    annotationsTransferred?: (data: AnnotationsTransferredNotificationMessageData) => void;
    annotationsTransferringProgress?: (data: AnnotationsTransferringProgressNotificationMessageData) => void;
    annotationChanged?: (data: AnnotationChangedNotificationMessageData) => void;
    autoAnnotationChanged?: (data: AutoAnnotationChangedNotificationMessageData) => void;
    supportingDocumentUploaded?: (data: SupportingDocumentUploadedNotificationMessageData) => void;
    annotationsReordered?: (data: AnnotationsReorderedNotificationMessageData) => void;
    supportingDocumentConversionFailed?: (data: DocumentConversionFailedNotificationMessageData) => void;
    autoAnnotationsListChanged?: (data:AutoAnnotationsListChangedNotificationMessageData) => void;
    autoAnnotationsAndAnnotationsListsChanged?: (data:AutoAnnotationsAndAnnotationsListsChangedNotificationMessageData) => void;
};

type ObjectChangedNotificationMessage<TObjectId> = {
    objectId: TObjectId;
    changeType: ObjectChangeType;
};

export enum ObjectChangeType {
    Create = 1,
    Update = 2,
    Delete = 3,
}

export type VerificationDocumentProcessingStatusUpdateMessageData = {
    verificationDocumentId: string;
    processingStatus: ProjectDocumentProcessingStatus;
    message: string;
};

export type SupportingDocumentProcessingStatusUpdateMessageData = {
    supportingDocumentId: string;
    processingStatus: ProjectDocumentProcessingStatus;
    message: string;
};

export type AutoAnnotationGeneratedMessageData = {
    processStatus: AutoAnnotationsProcessStatus;
    message: string;
};

export type AutoAnnotationsGenerationProgressMessageData = {
    progress: number;
};

export type AutoAnnotationsSectionProcessStatusChangedMessageData = {
    processStatus: AutoAnnotationsSectionProcessStatus;
    progress: number | null;
    headingId: string | null;
    message: string | null;
};

export type AnnotationsTransferredNotificationMessageData = string;

export type AnnotationsTransferringProgressNotificationMessageData = {
    numberOfAnnotationsTransferred: number;
    totalNumberOfAnnotationsToTransfer: number;
};

export type DocumentConversionFailedNotificationMessageData = {
    fileName: string | null;
    path: string | null;
};

export type AnnotationsReorderedNotificationMessageData = {};

export type AnnotationChangedNotificationMessageData = ObjectChangedNotificationMessage<string>;

export type AutoAnnotationChangedNotificationMessageData = ObjectChangedNotificationMessage<string>;

export type SupportingDocumentUploadedNotificationMessageData = ObjectChangedNotificationMessage<string>;

export type AutoAnnotationsAndAnnotationsListsChangedNotificationMessageData = {};

export type AutoAnnotationsListChangedNotificationMessageData = {};
