import { Box } from '@mui/material';
import { BackButton, Button, Icon, Stepper } from 'UI';
import {
    Annotation,
    AnnotationsTransferProposition,
    CreateAnnotationFromModifiedTransferPropositionBody,
    ProjectDocumentProcessingStatus,
    ProjectSimple,
} from 'ApiClients/SterlingApiClients/Types';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { createPortal } from 'react-dom';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'App/RoutesPaths';

import { DocumentUploadComponent, useDocumentUploadState } from './Steps/DocumentUpload';
import { FormMode } from './NewVersionForm.Module';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import LoadingLayoutComponent from 'App/LoadingLayout/LoadingLayout.Component';
import { DocumentInfo } from './Steps/DocumentUpload/useDocumentUploadState';
import { DocsViewerStepsWrapperComponent, useDocsViewerStepsWrapperState } from './Steps/DocsViewerStepsWrapper';
import { TransferingAnnotationsTitle } from './Steps/DocsViewerStepsWrapper/DocumentsPreview/useDocumentsPreviewState';
import useFullscreenMode from '../../../Hooks/useFullscreenMode';
import { FullscreenModeContextProvider } from 'Contexts/FullscreenMode.Context';
import useProjectNotifications from '../Hooks/useProjectNotifications';
import ProjectPathComponent from '../Common/NavBar/ProjectPath/ProjectPath.Component';

export type NewVersionFormComponentProps = {
    notificationsConnector: NotificationsConnector;
    mode: FormMode;
    navBarContents: NavBarContents;
    project: ProjectSimple;
    activeVersion: ProjectVersionForm;
    draftVersion: ProjectVersionForm | null;
    createDraft: (file: File, flowMethods: FlowMethods<string>) => void;
    setDraftDocumentIsProcessed: () => void;
    getAnnotationsTransfer: (draftId: string, flowMethods?: FlowMethods<AnnotationsTransferProposition | null>) => void;
    getAnnotations: (versionId: string, flowMethods?: FlowMethods<Array<Annotation>>) => void;
    annotationsTransfer: AnnotationsTransferProposition | null;
    acceptDraftProjectVersionInput: (draftId: string, flowMethods?: FlowMethods) => void;
    createAnnotationFromTransferPropositionInput: (draftId: string, annotationId: string, flowMethods?: FlowMethods) => void;
    createAnnotationFromModifiedTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods?: FlowMethods
    ) => void;
    rejectAnnotationTransferPropositionInput: (draftId: string, annotationId: string, displayToastSuccessMessages: boolean, flowMethods?: FlowMethods) => void;
    deleteAnnotationCreatedFromTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        displayToastSuccessMessages: boolean,
        flowMethods?: FlowMethods
    ) => void;
    convertDocxToPdf: (file: File, flowMethods: FlowMethods<File>) => void;
};

export type ProjectVersionForm = {
    id: string;
    document: DocumentInfo;
    documentIsProcessed: boolean;
};

type Step = {
    title: string;
    backButton: BackButtonStep;
};

type BackButtonStep = {
    text: string;
    onClick: () => void;
};

const HeadHeight = '6.5rem';

function NewVersionFormComponent(props: NewVersionFormComponentProps) {
    const {
        notificationsConnector,
        mode,
        navBarContents,
        project,
        activeVersion,
        draftVersion,
        createDraft,
        setDraftDocumentIsProcessed,
        annotationsTransfer,
        getAnnotationsTransfer,
        getAnnotations,
        acceptDraftProjectVersionInput,
        createAnnotationFromTransferPropositionInput,
        createAnnotationFromModifiedTransferPropositionInput,
        rejectAnnotationTransferPropositionInput,
        deleteAnnotationCreatedFromTransferPropositionInput,
        convertDocxToPdf,
    } = props;

    const getInitStep = useCallback((mode: FormMode, draftVersion: ProjectVersionForm | null, annotationsTransfer: AnnotationsTransferProposition | null) => {
        if (mode === 'create') return 0;

        if (mode === 'edit') {
            if (draftVersion) {
                if (draftVersion.documentIsProcessed) {
                    if (annotationsTransfer) {
                        if (annotationsTransfer.totalAnnotationsToTransfer === annotationsTransfer.propositions.length) return 2;
                        else return 1;
                    }
                } else return 1;
            }
        }

        return -1;
        // eslint-disable-next-line
    }, []);
    const initStep = useMemo(
        () => getInitStep(mode, draftVersion, annotationsTransfer),
        // eslint-disable-next-line
        []
    );

    const [activeStep, setActiveStep] = useState<number>(initStep);
    const nextStep = useCallback(() => setActiveStep((s) => s + 1), [setActiveStep]);

    useEffect(() => {
        if (activeStep === -1) {
            const newActiveStep = getInitStep(mode, draftVersion, annotationsTransfer);
            if (newActiveStep !== -1) setActiveStep(newActiveStep);
        }
        // eslint-disable-next-line
    }, [mode, draftVersion, annotationsTransfer, setActiveStep]);

    const [renderStep, setRenderStep] = useState<{ [key: number]: boolean }>(activeStep === -1 ? {} : { [activeStep]: true });
    useEffect(() => {
        if (activeStep !== -1 && !renderStep[activeStep]) {
            setRenderStep((state) => ({ ...state, [activeStep]: true }));
        }
        // eslint-disable-next-line
    }, [activeStep]);

    const navigate = useNavigate();

    const navigateToDocumentVersionList = () => navigate(AppRoutes.projectInstance.projectVersion.list.specificPath({ projectId: project.id }));
    const documentUploadState = useDocumentUploadState(mode, draftVersion, nextStep, navigateToDocumentVersionList);

    const renderDocsViewerStepsWrapperState = useMemo(() => renderStep[1] || renderStep[2], [renderStep]);
    const docsViewerStepsWrapperState = useDocsViewerStepsWrapperState(
        renderDocsViewerStepsWrapperState,
        mode,
        activeStep,
        nextStep,
        project,
        activeVersion,
        draftVersion,
        documentUploadState.documentInfo,
        annotationsTransfer,
        createDraft,
        convertDocxToPdf,
        getAnnotationsTransfer,
        getAnnotations,
        acceptDraftProjectVersionInput,
        createAnnotationFromTransferPropositionInput,
        createAnnotationFromModifiedTransferPropositionInput,
        rejectAnnotationTransferPropositionInput,
        deleteAnnotationCreatedFromTransferPropositionInput,
        navigateToDocumentVersionList
    );
    const { documentsPreviewState, annotationsTransferState } = docsViewerStepsWrapperState;

    const draftIdRef = useRef<string>(draftVersion?.id || '');
    draftIdRef.current = draftVersion?.id || '';

    const refetchAnnotationsDraftRef = useRef(annotationsTransferState.refetchAnnotationsDraft);
    refetchAnnotationsDraftRef.current = annotationsTransferState.refetchAnnotationsDraft;

    useProjectNotifications(notificationsConnector, project.id, {
        verificationDocumentProcessingStatusUpdate: (msg) => {
            if (msg.processingStatus === ProjectDocumentProcessingStatus.Processed) {
                getAnnotationsTransfer(draftIdRef.current, {
                    onSuccess: (annotationsTransfer) => {
                        setDraftDocumentIsProcessed();
                        if (annotationsTransfer) {
                            const propositionsGenerated = annotationsTransfer.propositions.length;
                            const totalAnnotationsToTransfer = annotationsTransfer.totalAnnotationsToTransfer;
                            if (totalAnnotationsToTransfer === 0 || propositionsGenerated === totalAnnotationsToTransfer) {
                                documentsPreviewState.openTransferringAnnotationsResultModal();
                                documentsPreviewState.closeProcessingLayout();
                            } else {
                                documentsPreviewState.openProcessingLayout(
                                    TransferingAnnotationsTitle,
                                    (propositionsGenerated / totalAnnotationsToTransfer) * 100
                                );
                            }
                        }
                    },
                });
            }
        },
        annotationsTransferringProgress: (data) =>
            documentsPreviewState.setTransferringProgress((data.numberOfAnnotationsTransferred / data.totalNumberOfAnnotationsToTransfer) * 100),
        annotationsTransferred: () =>
            getAnnotationsTransfer(draftIdRef.current, {
                onSuccess: (annotationsTransfer) => {
                    if (annotationsTransfer) {
                        documentsPreviewState.closeProcessingLayout();
                        documentsPreviewState.openTransferringAnnotationsResultModal();
                        refetchAnnotationsDraftRef.current();
                    }
                },
            }),
    });

    const steps: Array<Step> = [
        {
            title: 'Upload Document',
            backButton: {
                text: 'Back to document version list',
                onClick: navigateToDocumentVersionList,
            },
        },
        {
            title: 'Preview document changes',
            backButton: {
                text: 'Back to document upload',
                onClick: () => setActiveStep(0),
            },
        },
        {
            title: 'Transfer annotations',
            backButton: {
                text: 'Back to document preview',
                onClick: () => setActiveStep(1),
            },
        },
    ];

    const fullscreenModeContextProviderValue = useFullscreenMode();
    const { isFullscreen, toggleFullscreen } = fullscreenModeContextProviderValue;
    const padding = isFullscreen ? '0.5rem' : '2.5rem';
    const marginTop = isFullscreen ? '0.5rem' : '2rem';

    return (
        <FullscreenModeContextProvider fullscreenModeContextProviderValue={fullscreenModeContextProviderValue}>
            <Box
                sx={{
                    position: 'relative',
                    minWidth: '64rem',
                    paddingLeft: padding,
                    paddingRight: padding,
                    marginTop: marginTop,
                    height: `calc(100% - ${marginTop})`,
                }}
            >
                <>{navBarContents.left?.current && createPortal(<ProjectPathComponent projectName={project.name} />, navBarContents.left.current)}</>
                {!isFullscreen && (
                    <Box sx={{ position: 'absolute', zIndex: 1, right: padding }}>
                        <FullscreenButton isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
                    </Box>
                )}
                {activeStep === -1 && <LoadingLayoutComponent />}
                <Box sx={{ display: activeStep >= 0 ? 'block' : 'none', height: '100%' }}>
                    {activeStep >= 0 && !isFullscreen && (
                        <Box sx={{ height: HeadHeight }}>
                            <Box>
                                <BackButton onClick={steps[activeStep].backButton.onClick} text={steps[activeStep].backButton.text} data-testid='back-button' />
                            </Box>
                            <Box>
                                <Stepper steps={steps.map((s) => ({ title: s.title }))} activeStep={activeStep} />
                            </Box>
                        </Box>
                    )}
                    <StepWrapper display={activeStep === 0} isFullscreen={isFullscreen}>
                        <DocumentUploadComponent {...documentUploadState} />
                    </StepWrapper>
                    <StepWrapper display={activeStep === 1 || activeStep === 2} isFullscreen={isFullscreen}>
                        <DocsViewerStepsWrapperComponent {...docsViewerStepsWrapperState} />
                    </StepWrapper>
                </Box>
            </Box>
        </FullscreenModeContextProvider>
    );
}

type FullscreenButtonProps = {
    isFullscreen: boolean;
    toggleFullscreen: () => void;
};

export function FullscreenButton(props: FullscreenButtonProps) {
    const { isFullscreen, toggleFullscreen } = props;

    return (
        <Button onClick={toggleFullscreen} shape='circle' sx={{ height: '3rem', width: '3rem', minWidth: '3rem' }}>
            {isFullscreen ? <Icon.FullscreenExit /> : <Icon.Fullscreen />}
        </Button>
    );
}

function StepWrapper(props: PropsWithChildren<{ display: boolean; isFullscreen: boolean }>) {
    const { display, isFullscreen } = props;

    return <Box sx={{ display: display ? 'block' : 'none', height: `calc(100% - ${isFullscreen ? '0rem' : HeadHeight})` }}>{props.children}</Box>;
}

export default NewVersionFormComponent;
