export const FileMimeTypes = {
    word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf'
}

export const DocumentFileConfiguration = {
    maxFileSizeMB: 200,
    allowedFileTypes: {
        word: {
            key: FileMimeTypes.word,
            extensions: ['.docx'],
        }
    }
}

export const ErrorsDescription = {
    verificationDocumentProcessingStatusFailed: 'Verification document processing failed.',
    anySupportingDocumentsProcessingErrors: 'Some of supporting document processing failed.',
    autoAnnotationsProcessStatusFailed: 'Auto Annotations Process failed.',
    autoAnnotationsProcessStatusProcessedWithErrors: 'Auto Annotations process processed with errors.',
}

export const AnnotationVerifiedDisabledTooltip = 'Annotation is verified.';