export type RgbaColor = {
    R: number;
    G: number;
    B: number;
    A: number;
};

const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (hex: string) => hex.match(new RegExp(`.{${2}}`, "g"))

const convertHexUnitTo256 = (hex: string) => parseInt(hex.repeat(2 / hex.length), 16)

const getAlphafloat = (a: number | undefined) => {
    if (typeof a !== "undefined") return a / 255
    else return 1
}

const hexToRGBA = (hex: string): RgbaColor => {
    if (!isValidHex(hex)) { throw new Error("Invalid HEX") }
    const hexArr = getChunksFromString(hex.slice(1))
    const [R, G, B, A] = hexArr!.map(convertHexUnitTo256)
    return { R, G, B, A: getAlphafloat(A) }
}

export default hexToRGBA;