import { Box } from '@mui/material';
import { ProjectSimple, ProjectVersionStatus } from 'ApiClients/SterlingApiClients/Types';
import ProjectWorkspaceModule, { ProjectWorkspaceModuleProps } from '../ProjectWorkspace/ProjectWorkspace.Module';
import ProjectDashboardModule, { ProjectDashboardModuleProps } from '../ProjectDashboard/ProjectDashboard.Module';
import { Typography } from 'UI';
import { Navigate, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    ProjectTabRoute,
    ProjectTabRouteDashboard,
    ProjectTabRouteWorkspace,
} from 'App/RoutesPaths/Routes/ProjectInstance/Routes/ProjectVersionInstance/ProjectVersionInstance';
import { createPortal } from 'react-dom';
import ProjectPathComponent from '../Common/NavBar/ProjectPath/ProjectPath.Component';
import TabsComponent from './NavBar/Tabs/Tabs.Component';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { AppRoutes } from 'App/RoutesPaths';
import useViewerScroll, { setViewerScroll } from './Hooks/useViewerScroll';
import useNavBarContents from 'App/NavBar/useNavBarContents';

export type ProjectModulesComponentProps = Omit<ProjectWorkspaceModuleProps, 'viewersScrollContainerRefs'> &
    Omit<ProjectDashboardModuleProps, 'refreshData'> & {
        navBarContents: NavBarContents;
        projectModulesProps: ProjectModulesProps;
    };

export type ProjectModulesProps = {
    project: ProjectSimple;
};

export type ViewersScrollContainerRefs = {
    verificationDocScrollContainerRef: React.RefObject<Element | null>;
    supportingDocScrollContainerRef: React.RefObject<Element | null>;
    webSearchScrollContainerRef: React.RefObject<Element | null>;
};

function ProjectModulesComponent(props: ProjectModulesComponentProps) {
    const { projectModulesProps, navBarContents } = props;
    const { project } = projectModulesProps;

    const { isEditor } = useContext(ProjectAuthContext);
    const { tab: _tab } = useParams();
    const tab = useMemo(() => _tab as ProjectTabRoute, [_tab]);
    const [tabState, setTabState] = useState<ProjectTabRoute | null>(null);
    const [renderTabs, setRenderTabs] = useState<Record<ProjectTabRoute, boolean>>({
        dashboard: false,
        workspace: false,
    });
    const setRenderTab = useCallback((tab: ProjectTabRoute) => setRenderTabs((prev) => ({ ...prev, [tab]: true })), [setRenderTabs]);

    const projectNameDescription: string | null = useMemo(() => {
        const version = project.workingVersion;
        if (version.status === ProjectVersionStatus.Archived) return 'Archived';
        if (version.status === ProjectVersionStatus.Draft) return 'Draft';
        if (version.status === ProjectVersionStatus.Active && version.isLocked) return 'Read Only';

        return null;
    }, [project]);

    const verificationDocScroll = useViewerScroll();
    const supportingDocScroll = useViewerScroll();
    const webSearchScroll = useViewerScroll();

    useEffect(() => {
        if (tab === ProjectTabRouteDashboard) {
            const verEl = verificationDocScroll.scrollContainerRef.current;
            if (verEl) {
                verificationDocScroll.setScrollPosition(verEl.scrollTop);
            }
            const supEl = supportingDocScroll.scrollContainerRef.current;
            if (supEl) {
                supportingDocScroll.setScrollPosition(supEl.scrollTop);
            }
            const webEl = webSearchScroll.scrollContainerRef.current;
            if (webEl) {
                webSearchScroll.setScrollPosition(webEl.scrollTop);
            }
        }

        setTabState(tab);
        if (!renderTabs[tab]) setRenderTab(tab);
        // eslint-disable-next-line
    }, [tab]);

    useEffect(() => {
        if (tabState === ProjectTabRouteWorkspace) {
            if (verificationDocScroll.scrollContainerRef.current) {
                const el = verificationDocScroll.scrollContainerRef.current;
                setViewerScroll(el, verificationDocScroll.scrollPosition);
            }
            if (supportingDocScroll.scrollContainerRef.current) {
                const el = supportingDocScroll.scrollContainerRef.current;
                setViewerScroll(el, supportingDocScroll.scrollPosition);
            }
            if (webSearchScroll.scrollContainerRef.current) {
                const el = webSearchScroll.scrollContainerRef.current;
                setViewerScroll(el, webSearchScroll.scrollPosition);
            }
        }
        // eslint-disable-next-line
    }, [tabState]);

    const wrappedWorkspaceNavBarContents = useNavBarContents();

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
            <>
                {navBarContents.left?.current &&
                    createPortal(
                        <Box sx={{ display: 'flex' }}>
                            <ProjectPathComponent projectName={project.name} />
                            <Typography variant='title' color={(th) => th.palette.blue.medium} sx={{ display: 'inline', ml: '8px' }}>
                                {projectNameDescription && `(${projectNameDescription} Version)`}
                            </Typography>
                        </Box>,
                        navBarContents.left.current
                    )}
                {isEditor && navBarContents.center?.current && createPortal(<TabsComponent tab={tab} />, navBarContents.center.current)}
                {navBarContents.right?.current &&
                    createPortal(
                        <>{tab === ProjectTabRouteWorkspace && <Box ref={wrappedWorkspaceNavBarContents.right}></Box>}</>,
                        navBarContents.right.current
                    )}
            </>
            <ModuleWrapper active={tabState === ProjectTabRouteWorkspace} render={renderTabs[ProjectTabRouteWorkspace]}>
                <ProjectWorkspaceModule
                    {...props}
                    navBarContents={wrappedWorkspaceNavBarContents}
                    viewersScrollContainerRefs={{
                        verificationDocScrollContainerRef: verificationDocScroll.scrollContainerRef,
                        supportingDocScrollContainerRef: supportingDocScroll.scrollContainerRef,
                        webSearchScrollContainerRef: webSearchScroll.scrollContainerRef,
                    }}
                />
            </ModuleWrapper>
            <ModuleWrapper active={tabState === ProjectTabRouteDashboard} render={renderTabs[ProjectTabRouteDashboard]}>
                {isEditor ? (
                    <ProjectDashboardModule {...props} refreshData={tabState === ProjectTabRouteDashboard} />
                ) : (
                    <Navigate to={AppRoutes.homePage.specificPath({})}></Navigate>
                )}
            </ModuleWrapper>
        </Box>
    );
}

function ModuleWrapper(props: React.PropsWithChildren<{ active: boolean; render: boolean }>) {
    const { active, render, children } = props;
    return <Box sx={{ display: active ? 'flex' : 'none', height: '100%', width: '100%' }}>{render && children}</Box>;
}

export default ProjectModulesComponent;
