import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Chip from 'UI/Chip/Chip.Component';
import Typography from 'UI/Typography';
import { CircularProgress } from '@mui/material';

type ChipBoxProps<TChipCustomData> = {
    label?: React.ReactNode;
    emptyLabel?: React.ReactNode;
    chips: ChipBoxElement<TChipCustomData>[];
    onChipDelete?: (chip: ChipBoxElement<TChipCustomData>) => void;
    hasOverflow?: boolean;
    loading?: boolean;
    chipProps?: Omit<React.ComponentProps<typeof Chip>, 'label' | 'color' | 'size' | 'onDelete'>;
} & BoxProps;

export type ChipBoxElement<TCustomData = {}> = {
    label: string;
    hasDelete?: boolean;
    color: 'info' | 'warning' | 'default';
} & TCustomData;

function ChipsBox<TChipCustomData = {}>({
    hasOverflow,
    label,
    emptyLabel,
    chips = [],
    onChipDelete,
    loading,
    chipProps,
    ...props
}: ChipBoxProps<TChipCustomData>) {
    const lastItemRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleDelete = (chip: ChipBoxElement<TChipCustomData>) => () => {
        onChipDelete?.(chip);
    };

    const uniqueTags = chips.filter((value, index, self) => index === self.findIndex((t) => t.label === value.label));

    React.useEffect(() => {
        if (lastItemRef.current && containerRef.current && chips.length > 0 && hasOverflow) {
            const bounds = lastItemRef.current.getBoundingClientRect();

            if (bounds?.left > 0 && containerRef.current) {
                containerRef.current.scrollLeft = bounds.left;
            }
        }
    }, [hasOverflow, chips]);

    return (
        <Box>
            {label && (
                <Box marginBottom={1}>
                    {typeof label === 'string' && <Typography variant='overline'>{label}</Typography>}
                    {typeof label !== 'string' && label}
                </Box>
            )}
            <Box
                {...props}
                ref={containerRef}
                sx={{
                    borderRadius: 1,
                    backgroundColor: (c) => c.palette.white.main,
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.10)',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: (c) => c.palette.stroke.main,
                    padding: '12px 8px',
                    gap: 1.5,
                    display: 'flex',
                    minHeight: 16,
                    ...(hasOverflow && {
                        overflowY: 'scroll',
                        WebkitOverflowScrolling: 'touch',
                    }),
                    ...(!hasOverflow && {
                        flexWrap: 'wrap',
                    }),
                }}
            >
                {loading ? (
                    <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
                        <CircularProgress size={16} />
                    </Box>
                ) : (
                    <>
                        {emptyLabel && uniqueTags.length === 0 ? (
                            <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
                                {typeof emptyLabel === 'string' && <Typography variant='caption'>{emptyLabel}</Typography>}
                                {typeof emptyLabel !== 'string' && emptyLabel}
                            </Box>
                        ) : (
                            <>
                                {uniqueTags.map((item, idx) => (
                                    <Chip
                                        key={`chip-${idx}`}
                                        color={item.color || 'info'}
                                        label={item.label}
                                        size='small'
                                        {...(item.hasDelete && {
                                            onDelete: handleDelete(item),
                                        })}
                                        {...chipProps}
                                    />
                                ))}
                                <div
                                    {...(hasOverflow && {
                                        ref: lastItemRef,
                                    })}
                                />
                            </>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}

export default ChipsBox;
