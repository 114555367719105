import { CustomErrorHandler, ErrorHandlerRegistration } from 'ApiClients/Sterling.ApiClient';
import { useCallback, useEffect, useState } from 'react';
import { ReadOnlyModalProps } from '../../Modals/ReadOnlyModal/ReadOnlyModal.Component';
import { ProjectVersionSimple } from 'ApiClients/SterlingApiClients/Types';

const useErrorHandler = (errorHandlerRegistration: ErrorHandlerRegistration, projectVersion: ProjectVersionSimple): ReadOnlyModalProps => {
    const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
    const closeReadOnlyModal = useCallback(() => setIsReadOnlyModalOpen(false), [setIsReadOnlyModalOpen]);
    const openReadOnlyModal = useCallback(() => setIsReadOnlyModalOpen(true), [setIsReadOnlyModalOpen]);
    const errorHandler: CustomErrorHandler = useCallback(
        (error, baseErrorHandler) => {
            if (error?.response?.status === 423) {
                openReadOnlyModal();
            } else {
                baseErrorHandler(error);
            }
        },
        [openReadOnlyModal]
    );

    useEffect(() => {
        errorHandlerRegistration.register(errorHandler);
        return () => errorHandlerRegistration.unregister();
    }, [errorHandlerRegistration, errorHandler]);

    return {
        isOpen: isReadOnlyModalOpen,
        closeModal: closeReadOnlyModal,
        version: projectVersion,
    };
};

export default useErrorHandler;
