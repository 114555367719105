import { useState } from 'react';
import { Box } from '@mui/material';
import Menu from 'UI/Menu';
import { Gear, IconType, Tags, PersonsAdd } from 'UI/Icon';

export type SettingsComponentProps = {
    openProjectMembersModal: () => void;
    openTagManagementModal: () => void;
};

type MyMenuItemProps = { icon: IconType; text: string; handleClose: () => void; onClick: () => void; dataTestId?: string };

function SettingsComponent(props: SettingsComponentProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const menuItems: Array<Omit<MyMenuItemProps, 'handleClose'>> = [
        {
            icon: Tags,
            text: 'Tag management',
            onClick: () => props.openTagManagementModal(),
            dataTestId: 'tag-management-menu-item',
        },
        {
            icon: PersonsAdd,
            text: 'Project Members',
            onClick: () => props.openProjectMembersModal(),
            dataTestId: 'project-members-menu-item',
        },
    ];

    return (
        <Menu open={isOpen}>
            <Menu.Trigger
                startIcon={<Gear />}
                onClick={handleClick}
                data-testid='settings-menu-trigger'
                variant='text'
                sx={{
                    height: '2.5rem',
                    padding: '0.75rem 1rem',
                    color: (theme) => theme.palette.primary.dark,
                    fontWeight: 600,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.lightest,
                    },
                }}
            >
                PROJECT SETTINGS
            </Menu.Trigger>
            <Menu.List hasContainerWidth={false} data-testid='menu-list'>
                {menuItems.map((i, idxI) => (
                    <Menu.Item key={`settings-list-item-${idxI}`} onClick={i.onClick} data-testid={i.dataTestId}>
                        <Box sx={{ padding: '0rem 0.5rem !important', display: 'flex', gap: 1.5, alignItems: 'center' }}>
                            <i.icon />
                            {i.text}
                        </Box>
                    </Menu.Item>
                ))}
            </Menu.List>
        </Menu>
    );
}

export default SettingsComponent;
