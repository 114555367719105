import { useCallback, useMemo } from 'react';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { hexToRGBA } from 'UI/functions';
import { AnnotationTransferPropositionStatus, CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationTransferData, StatusConfig } from '../useAnnotationsTransferState';
import { PdfViewerOtherProps } from '../../Hooks/usePdfViewer';
import CustomTransferPanelWrapperComponent, {
    CustomTransferPanelActionsState,
    NewAnnotationId,
} from '../PdfViewerComponents/CustomTransferPanelWrapper/CustomTransferPanelWrapper.Component';
import PropositionTransferPanelWrapperComponent, {
    PropositionTransferPanelActionsState,
} from '../PdfViewerComponents/PropositionTransferPanelWrapper/PropositionTransferPanelWrapper.Component';
import useHighlightsActionsState from 'Views/Common/PdfViewerWithToolbar/Hooks/useHighlightsActionsState';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type DraftAnnotationHighlightCustomData = {
    status: AnnotationTransferPropositionStatus;
};

export const DraftAnnotationSelectedEventName = 'draftAnnotationSelectedEventName';

type HighlightActionsState = CustomTransferPanelActionsState & PropositionTransferPanelActionsState;

const usePdfViewerDraft = (
    selectedAnnotationIdActiveVersion: string | null,
    selectedAnnotationIdDraftVersion: string | null,
    setSelectedAnnotationId: (annotationId: string | null) => void,
    data: Array<AnnotationTransferData>,
    onlyReject: boolean,
    createAnnotationFromTransferProposition: (annotationId: string, flowMethods: FlowMethods) => void,
    createAnnotationFromModifiedTransferProposition: (
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods: FlowMethods
    ) => void,
    reject: (annotationId: string, flowMethods: FlowMethods) => void,
    setAnnotationsListRedrawing: (value: boolean) => void
): PdfViewerOtherProps<DraftAnnotationHighlightCustomData> => {
    const highlights = useMemo(
        () => [
            ...data
                .filter((el) => el.status !== AnnotationTransferPropositionStatus.Removed)
                .map((el): Highlight<DraftAnnotationHighlightCustomData> => {
                    const annot = el.annotationActive;
                    const draftAnnot = el.annotationDraft;
                    const proposition = el.proposition;
                    return {
                        id: annot.id,
                        orderNumber: annot.orderNumber,
                        selectionType: draftAnnot ? draftAnnot.content.selectionType : proposition.content!.selectionType,
                        boundingBoxSections: draftAnnot ? draftAnnot.content.boundingBoxSections : proposition.content!.boundingBoxSections,
                        siblingsId: annot.id,
                        status: el.status,
                        isEditable: el.annotationDraft === null,
                    };
                }),
        ],
        [data]
    );
    const highlightColor = useCallback((highlight: Highlight<DraftAnnotationHighlightCustomData>, selectedHighlightIds: Array<string>) => {
        return selectedHighlightIds.includes(highlight.id)
            ? hexToRGBA(StatusConfig[highlight.status].selectedColor)
            : hexToRGBA(StatusConfig[highlight.status].baseColor);
    }, []);

    const { highlightsActionsState, setHighlightActionsState } = useHighlightsActionsState<HighlightActionsState>();

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [
            highlights,
            selectedAnnotationIdDraftVersion,
            selectedAnnotationIdActiveVersion,
            createAnnotationFromTransferProposition,
            createAnnotationFromModifiedTransferProposition,
            reject,
            onlyReject,
            highlightsActionsState,
        ];
    }, [
        highlights,
        selectedAnnotationIdDraftVersion,
        selectedAnnotationIdActiveVersion,
        createAnnotationFromTransferProposition,
        createAnnotationFromModifiedTransferProposition,
        reject,
        onlyReject,
        highlightsActionsState,
    ]);

    const isCreatingHighlightEnabled: boolean = useMemo(() => {
        if (selectedAnnotationIdActiveVersion) {
            const annotation = data.find((el) => el.annotationActive.id === selectedAnnotationIdActiveVersion);
            if (annotation) {
                return annotation.status !== AnnotationTransferPropositionStatus.Found;
            }
        }

        return false;
    }, [data, selectedAnnotationIdActiveVersion]);

    const selectedHighlightIds = useMemo(
        () => (selectedAnnotationIdDraftVersion ? [selectedAnnotationIdDraftVersion] : []),
        [selectedAnnotationIdDraftVersion]
    );
    const setSelectedHighlightIds = useCallback((ids: Array<string>) => setSelectedAnnotationId(ids[0]), [setSelectedAnnotationId]);
    const clearSelectedHighlight = useCallback(() => setSelectedAnnotationId(null), [setSelectedAnnotationId]);
    const onAnnotationsRedraw = useCallback(() => setAnnotationsListRedrawing(false), [setAnnotationsListRedrawing]);

    return {
        displayHighlightIcons: true,
        isCreatingHighlightEnabled,
        highlightsProps: {
            highlights,
            highlightColor,
            highlightToolbars: {
                newHighlightToolbar: {
                    render: (options) => {
                        if (!selectedAnnotationIdActiveVersion) return <div />;

                        return (
                            <CustomTransferPanelWrapperComponent
                                selectedAnnotationId={selectedAnnotationIdActiveVersion}
                                newHighlightToolbarOptions={options}
                                transferAnnotation={createAnnotationFromModifiedTransferProposition}
                                actionsState={highlightsActionsState[NewAnnotationId]}
                                setHighlightActionsState={setHighlightActionsState}
                            />
                        );
                    },
                },
                highlightToolbar: {
                    render: (options) => {
                        if (!selectedAnnotationIdActiveVersion) return <div />;

                        return (
                            <PropositionTransferPanelWrapperComponent
                                selectedAnnotationId={selectedAnnotationIdActiveVersion}
                                highlightToolbarOptions={options}
                                createAnnotationFromTransferProposition={createAnnotationFromTransferProposition}
                                createAnnotationFromModifiedTransferProposition={createAnnotationFromModifiedTransferProposition}
                                reject={reject}
                                onlyReject={onlyReject}
                                actionsState={highlightsActionsState[selectedAnnotationIdActiveVersion]}
                                setHighlightActionsState={setHighlightActionsState}
                            />
                        );
                    },
                },
                deps: highlightToolbarsDeps,
            },
            highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
            selectedHighlightIds,
            setSelectedHighlightIds,
            clearSelectedHighlight,
            selectedHighlightChangedEventName: DraftAnnotationSelectedEventName,
            focusOnSelectedHighlightAfterDraw: true,
            keepSelectedHighlightInCentre: true,
            onAnnotationsRedraw,
        },
    };
};

export default usePdfViewerDraft;
