import { Box, CircularProgress } from '@mui/material';
import { ProjectVersionListRecord } from 'ApiClients/SterlingApiClients/Types';
import { useMemo } from 'react';
import { TableList, Typography } from 'UI';

type VersionsTableComponentProps = {
    title: string;
    isFetching: boolean;
    columns: Array<VersionsTableColumn>;
    versions: Array<ProjectVersionListRecord>;
    active?: boolean;
};

export type VersionsTableColumn = {
    name: string;
    width: string;
    justifyContent: 'left' | 'center' | 'right';
    value: (p: ProjectVersionListRecord) => string | JSX.Element;
};

function VersionsTableComponent(props: VersionsTableComponentProps) {
    const { title, isFetching, versions, columns, active } = props;
    const hasVersions = useMemo(() => versions.length > 0, [versions]);

    return (
        <Box sx={{ borderRadius: '0.25rem', border: (theme) => `1px solid ${theme.palette.stroke.main}` }}>
            <Box
                sx={{
                    padding: '1.5rem 1.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: (theme) => `1px solid ${theme.palette.stroke.main}`,
                    ...(active && {
                        backgroundColor: (theme) => theme.palette.blue.light,
                    }),
                }}
            >
                <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
            </Box>
            <TableList.Table
                sx={{
                    height: '100%',
                }}
            >
                <TableList.Header>
                    <TableList.TR isHeader>
                        {columns.map((c, idx) => (
                            <TableList.TH
                                key={`projects-table-head-row-${idx}`}
                                sx={{
                                    width: `${c.width} !important`,
                                    minWidth: `${c.width} !important`,
                                    display: 'flex',
                                    justifyContent: c.justifyContent,
                                }}
                            >
                                {c.name}
                            </TableList.TH>
                        ))}
                    </TableList.TR>
                </TableList.Header>
                <TableList.Body data-cy='projects-table-body' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    {!isFetching &&
                        hasVersions &&
                        versions
                            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
                            .map((v, idx) => {
                                return (
                                    <TableList.TR
                                        key={v.id}
                                        sx={{
                                            minHeight: '4rem',
                                            ...(idx < versions.length - 1 && {
                                                borderBottomStyle: 'solid',
                                                borderBottomWidth: 1,
                                                borderColor: (theme) => theme.palette.stroke.main,
                                            }),
                                        }}
                                        hover={false}
                                    >
                                        {columns.map((c, idx) => (
                                            <TableList.TD
                                                key={`projects-table-row-${idx}`}
                                                sx={{
                                                    width: `${c.width} !important`,
                                                    minWidth: `${c.width} !important`,
                                                    display: 'flex',
                                                    justifyContent: c.justifyContent,
                                                }}
                                            >
                                                {c.value(v)}
                                            </TableList.TD>
                                        ))}
                                    </TableList.TR>
                                );
                            })}
                    {!isFetching && !hasVersions && (
                        <TableList.TR hover={false}>
                            <TableList.TD textAlign='center'>
                                <Typography variant='subtitle1' sx={{ height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    No versions.
                                </Typography>
                            </TableList.TD>
                        </TableList.TR>
                    )}
                    {isFetching && (
                        <TableList.TR hover={false}>
                            <TableList.TD textAlign='center'>
                                <CircularProgress size={20} />
                            </TableList.TD>
                        </TableList.TR>
                    )}
                </TableList.Body>
            </TableList.Table>
        </Box>
    );
}

export default VersionsTableComponent;
