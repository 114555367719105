import { Box, Divider, List, ListItem } from '@mui/material';
import { Annotation } from 'ApiClients/SterlingApiClients/Types';
import React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Icon, Tooltip, Typography } from 'UI';
import { IconType } from 'UI/Icon';
import { palette } from 'UI/Provider/VerifiTheme';
import AnnotationStatusComponent from 'Views/Common/AnnotationStatus/AnnotationStatus.Component';
import { HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { AnnotationStatusDictionary } from 'Views/Common/Types';

export type ActiveAnnotationPanelWrapperComponentProps = {
    highlightToolbarOptions: HighlightToolbarOptions;
    annotation: Annotation;
};

type AnnotationUtil = {
    icon: IconType;
    tooltip: string | ((annotation: Annotation) => string | JSX.Element);
    isActive?: (annotation: Annotation) => boolean;
    color?: string | null;
};

function ActiveAnnotationPanelWrapperComponent(props: ActiveAnnotationPanelWrapperComponentProps) {
    const { highlightToolbarOptions, annotation } = props;

    const { setHighlightToolbarRef } = highlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const tagsTooltip = useCallback((annotation: Annotation): JSX.Element => {
        const { tags } = annotation;

        if (tags.length > 0) {
            return (
                <Box>
                    <Typography variant='tooltip'>Assigned tags</Typography>
                    <Divider />
                    <List>
                        {tags.map((tag) => (
                            <React.Fragment key={tag.id}>
                                <ListItem>
                                    <Typography variant='tooltip'>{tag.name}</Typography>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            );
        } else {
            return <span>No tags</span>;
        }
    }, []);

    const annotationUtils = useMemo(() => {
        let utils: AnnotationUtil[] = [
            {
                icon: Icon.Link45deg,
                tooltip: 'Linked information',
                isActive: (a) => a.isAnySupportingInformationLinked,
            },
            {
                icon: Icon.Tag,
                tooltip: (a) => tagsTooltip(a),
                isActive: (a) => a.tags.length > 0,
            },
            {
                icon: Icon.Quote,
                tooltip: 'Assertion',
                isActive: (a) => a.assertion !== null,
            },
            {
                icon: Icon.ChatRight,
                tooltip: 'Comments',
                isActive: () => false,
                color: annotation.needsReview ? palette.attention.high : null,
            },
        ];

        return utils;
    }, [annotation, tagsTooltip]);

    const iconState = AnnotationStatusDictionary[annotation.status];

    let panel: JSX.Element = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '20rem',
                padding: '0.5rem 1rem',
                boxSizing: 'border-box',
                gap: 1,
                backgroundColor: (theme) => theme.palette.blue.light,
                borderLeft: (theme) => `0.25rem solid ${theme.palette.blue.dark}`,
                paddingLeft: '0.75rem',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} data-testid='annotation-header'>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '1.5rem',
                            height: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: 0,
                            borderRadius: '0.25rem',
                            backgroundColor: (theme) => theme.palette.white.main,
                        }}
                    >
                        <Typography
                            variant='buttonSmall'
                            sx={{
                                fontSize: 10,
                            }}
                        >
                            {annotation.orderNumber}
                        </Typography>
                    </Box>
                    <iconState.icon width={16} height={16} color={iconState.color} />
                    {annotation.needsReview && <Icon.FlagFill color={palette.attention.high} />}
                </Box>
                <AnnotationStatusComponent statusNameEnabled={false} status={annotation.status} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Icon.Person color={palette.text.main} />
                    <Typography variant='caption'>{annotation.assignedMember ? annotation.assignedMember.fullName : 'Unassigned'}</Typography>
                </Box>

                <Box sx={{ display: 'flex', marginRight: '-0.25rem', gap: 1 }}>
                    {annotationUtils.map((util, idxU) => {
                        const tooltip = typeof util.tooltip == 'string' ? util.tooltip : util.tooltip(annotation);
                        return (
                            <Tooltip title={tooltip} key={`annotation-utils-${idxU}`}>
                                <Box
                                    sx={{
                                        color: util.isActive && util.isActive(annotation) ? palette.blue.dark : palette.stroke.main,
                                        ...(util.color && { color: util.color }),
                                    }}
                                >
                                    <util.icon width={16} height={16} />
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
    return <div ref={ref}>{panel}</div>;
}

export default ActiveAnnotationPanelWrapperComponent;
