import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import { BoundingBoxSection, FileInfo, SelectionType, SupportingInformationWebSearchProposition } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import { useCallback, useMemo } from 'react';
import { WebSearchDocumentInfo } from '../Documents/useWebSearchDocuments';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type SupportingInformationWebSearchPropositionsProps = {
    supportingInformationWebSearchPropositionByAnnotationId: SupportingInformationWebSearchPropositionByAnnotationId;
    increaseCountWebSearchPropositionsToDisplay: (annotationId: string) => void;
    getSupportingInformationWebSearchPropositions: GetSupportingInformationWebSearchPropositionsMethodType;
    uploadDocAndAddSupportingDocument: (
        webSearchDocumentInfo: WebSearchDocumentInfo,
        webSearchDocumentFileInfo: FileInfo,
        annotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        addFlowMethods: FlowMethods
    ) => void;
};

export type SupportingInformationWebSearchPropositionByAnnotationId = {
    [key: string]: SupportingInformationWebSearchPropositionContext;
};

export type SupportingInformationWebSearchPropositionContext = {
    isFetching: boolean;
    supportingInformationWebSearchPropositions: Array<SupportingInformationWebSearchProposition> | null;
    countToDisplay: number;
};

export type GetSupportingInformationWebSearchPropositionsMethodType = (
    annotationId: string,
    options?: { keepPrevData?: boolean; onInit?: () => void; onSuccess?: () => void }
) => void;

const useSupportingInformationWebSearchPropositions = (
    projectId: string,
    projectVersionId: string,
    suppInfoApiClient: SupportingInformationApiClient,
    uploadWebSearchDocument: (webSearchDocumentInfo: WebSearchDocumentInfo, webSearchDocumentFileInfo: FileInfo, flowMethods: FlowMethods<string>) => void,
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void
) => {
    const rootRouteParams = useMemo(() => ({ projectId, projectVersionId }), [projectId, projectVersionId]);
    const [supportingInformationWebSearchPropositionByAnnotationId, setSupportingInformationWebSearchPropositionByAnnotationId] =
        useStateById<SupportingInformationWebSearchPropositionContext>();
    const getSupportingInformationWebSearchPropositions = (
        annotationId: string,
        options?: { keepPrevData?: boolean; onInit?: () => void; onSuccess?: () => void }
    ) => {
        const _setState = (data: Array<SupportingInformationWebSearchProposition>, isFetching: boolean) =>
            setSupportingInformationWebSearchPropositionByAnnotationId.Function(annotationId, (prev) => ({
                isFetching,
                supportingInformationWebSearchPropositions: isFetching && options?.keepPrevData ? prev.supportingInformationWebSearchPropositions : data,
                countToDisplay: prev?.countToDisplay ? prev.countToDisplay : 5,
            }));

        suppInfoApiClient.getSupportingInformationWebSearchPropositions(
            { ...rootRouteParams, annotationId },
            {
                onInit: () => {
                    _setState([], true);
                    options?.onInit && options.onInit();
                },
                onSuccess: (data) => {
                    _setState(data, false);
                    options?.onSuccess && options.onSuccess();
                },
                onError: () => _setState([], false),
            }
        );
    };

    const uploadDocAndAddSupportingDocument = (
        webSearchDocumentInfo: WebSearchDocumentInfo,
        webSearchDocumentFileInfo: FileInfo,
        annotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        addFlowMethods: FlowMethods
    ) =>
        uploadWebSearchDocument(webSearchDocumentInfo, webSearchDocumentFileInfo, {
            onSuccess: (data) => addSupportingInformation(annotationId, data, selectionType, boundingBoxSections, statement, addFlowMethods),
        });

    const increaseCountWebSearchPropositionsToDisplay = useCallback(
        (annotationId: string) => {
            setSupportingInformationWebSearchPropositionByAnnotationId.Function(annotationId, (prev) => ({
                ...prev,
                countToDisplay: prev.countToDisplay + 5,
            }));
        },
        [setSupportingInformationWebSearchPropositionByAnnotationId]
    );

    return {
        supportingInformationWebSearchPropositionByAnnotationId,
        increaseCountWebSearchPropositionsToDisplay,
        getSupportingInformationWebSearchPropositions,
        uploadDocAndAddSupportingDocument,
    };
};

export default useSupportingInformationWebSearchPropositions;
