import { Box } from '@mui/material';
import { AppRoutes } from 'App/RoutesPaths';
import { Button, Typography } from 'UI';
import { useNavigate } from 'react-router-dom';

function BlankPageComponent() {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ marginTop: '5rem', fontSize: '8rem', color: (theme) => theme.palette.primary.dark }}>404</Typography>
                <Typography sx={{ marginTop: '2rem', fontSize: '4rem' }}>{`Page not found :(`}</Typography>
                <Button sx={{ marginTop: '5rem', fontSize: '3rem', height: '5rem' }} onClick={() => navigate(AppRoutes.homePage.specificPath({}))}>
                    Go to home page
                </Button>
            </Box>
        </Box>
    );
}

export default BlankPageComponent;
