import { Box } from '@mui/material';
import { AppRoutes } from 'App/RoutesPaths';
import { Link } from 'react-router-dom';
import { Icon, Typography } from 'UI';

export type PathComponentProps = {
    pathSegments: Array<PathSegment>;
};

export type PathSegment = {
    name: string;
    link?: string;
};

export const ProjectListPathSegment: PathSegment = {
    name: 'Project List',
    link: AppRoutes.homePage.specificPath({}),
};

function PathComponent(props: PathComponentProps) {
    const { pathSegments } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {pathSegments.map((s, idx) => (
                <Box key={`path-segment-${idx}`} sx={{ display: 'flex', alignItems: 'center' }}>
                    {s.link ? (
                        <Link style={{ display: 'flex', textDecoration: 'none' }} to={s.link}>
                            <Typography
                                sx={{
                                    fontSize: '1.25rem',
                                    fontWeight: 600,
                                    color: (theme) => theme.palette.text.heading,
                                    ':hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {s.name}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography sx={{ fontSize: '1.25rem', fontWeight: 500, color: (theme) => theme.palette.text.light }}>{s.name}</Typography>
                    )}
                    {idx < pathSegments.length - 1 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '0.25rem',
                                marginRight: '0.25rem',
                                color: (theme) => theme.palette.text.light,
                            }}
                        >
                            <Icon.Slash height={24} width={24} />
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
}

export default PathComponent;
