import React from 'react';

function Check(props: React.SVGProps<SVGElement>, ref: React.Ref<SVGSVGElement>) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16' fill='none' {...props} ref={ref}>
            <g clipPath='url(#checkbox-check-a)'>
                <path
                    fill='#fff'
                    fillRule='evenodd'
                    d='M.586.586A2 2 0 0 1 2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2A2 2 0 0 1 .586.586Z'
                    clipRule='evenodd'
                />
                <path
                    fill='currentColor'
                    d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022h-.001Z'
                />
            </g>
            <defs>
                <clipPath id='checkbox-check-a'>
                    <path fill='#fff' d='M0 0h16v16H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.forwardRef<SVGSVGElement, React.SVGProps<SVGElement>>(Check);
