import { Box, CircularProgress } from '@mui/material';
import { Button, Typography } from 'UI';
import { IconType } from 'UI/Icon';

export type ActionButtonProps = {
    icon: IconType;
    text: string;
    onClick: () => void;
    loading?: boolean;
};

function ActionButton(props: ActionButtonProps) {
    const { icon: Icon, text, onClick, loading } = props;

    return (
        <Button
            size='small'
            variant='text'
            sx={{
                minWidth: 0,
                padding: '4px 4px 4px 0',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
            }}
            onClick={onClick}
            disabled={loading}
        >
            <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1, color: (theme) => theme.palette.link.main }}>
                {loading ? <CircularProgress size={16} /> : <Icon width={16} height={16} />}
                <Typography
                    sx={{
                        fontSize: '14px !important',
                        fontWeight: 600,
                        color: (theme) => theme.palette.link.main,
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Button>
    );
}

export default ActionButton;
