import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { getAnnotationWindowPosition } from 'Views/Common/PdfViewerWithToolbar/Hooks/Annotations/Drawing/HtmlElements/Common';
import {
    getHighlightId,
    getSiblingsId,
    isFirstAnnotationOfHighlight,
} from 'Views/Common/PdfViewerWithToolbar/Hooks/Annotations/Drawing/ViewerAnnotations/Attributes';
import { getScrollContainer } from '../useViewersSync';

const GetPositionDiff = (
    leftInstance: WebViewerInstance,
    rightInstance: WebViewerInstance,
    leftHighlightSelectedId: string | null,
    rightHighlightSelectedId: string | null,
    siblingSync?: boolean
) => {
    if (siblingSync) {
        const { leftAnnot, rightAnnot } = getSelectedAnnotations(leftInstance, rightInstance, leftHighlightSelectedId, rightHighlightSelectedId);

        if (leftAnnot && rightAnnot) {
            const leftAnnotPosition = getAnnotationWindowPosition(leftInstance, leftAnnot);
            const rightAnnotPosition = getAnnotationWindowPosition(rightInstance, rightAnnot);
            const annotationPositionDiff = leftAnnotPosition.topLeft.y - rightAnnotPosition.topLeft.y;
            return Math.round(annotationPositionDiff);
        }
    }

    const leftScrollContainer = getScrollContainer(leftInstance);
    const rightScrollContainer = getScrollContainer(rightInstance);
    const positionDiff = leftScrollContainer.scrollTop - rightScrollContainer.scrollTop;
    return positionDiff;
};

export const getSelectedAnnotations = (
    leftInstance: WebViewerInstance,
    rightInstance: WebViewerInstance,
    leftHighlightSelectedId: string | null,
    rightHighlightSelectedId: string | null
) => {
    let leftAnnot: Core.Annotations.Annotation | null = null;
    let rightAnnot: Core.Annotations.Annotation | null = null;
    if (leftHighlightSelectedId) {
        leftAnnot =
            leftInstance.Core.documentViewer
                .getAnnotationManager()
                .getAnnotationsList()
                .find((a) => getHighlightId(a) === leftHighlightSelectedId && isFirstAnnotationOfHighlight(a)) || null;
        if (leftAnnot) {
            const siblingsId: string = getSiblingsId(leftAnnot);
            rightAnnot =
                rightInstance.Core.documentViewer
                    .getAnnotationManager()
                    .getAnnotationsList()
                    .find((a) => getSiblingsId(a) === siblingsId && isFirstAnnotationOfHighlight(a)) || null;
        }
    }

    if (rightHighlightSelectedId && !leftAnnot) {
        rightAnnot =
            rightInstance.Core.documentViewer
                .getAnnotationManager()
                .getAnnotationsList()
                .find((a) => getHighlightId(a) === rightHighlightSelectedId && isFirstAnnotationOfHighlight(a)) || null;
        if (rightAnnot) {
            const siblingsId: string = getSiblingsId(rightAnnot);
            leftAnnot =
                leftInstance.Core.documentViewer
                    .getAnnotationManager()
                    .getAnnotationsList()
                    .find((a) => getSiblingsId(a) === siblingsId && isFirstAnnotationOfHighlight(a)) || null;
        }
    }

    return { leftAnnot, rightAnnot };
};

export default GetPositionDiff;
