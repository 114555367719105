import { Annotation } from 'ApiClients/SterlingApiClients/Types';
import { AdditionalAssertionFilter, AnnotationFilters } from './useAnnotationsFilters';
import { useCallback, useEffect, useMemo } from 'react';
import { DefaultAssertions } from '../../../../Modals/AssertionModal/AssertionModal.Component';

const useFilteredAnnotations = (
    annotations: Array<Annotation> | undefined,
    filters: AnnotationFilters,
    createAuditLog: (filters: string) => void
): Array<Annotation> => {
    useEffect(() => {
        if (filters.all.filtersList.length > 0) {
            createAuditLog(JSON.stringify(filters.all.filters));
        }
        // eslint-disable-next-line
    }, [filters]);

    const statusFilter = useCallback(
        (annot: Annotation): boolean => (filters.status.value.length > 0 ? filters.status.value.includes(annot.status) : true),
        [filters.status.value]
    );

    const tagFilterIds: Array<string> = useMemo(() => filters.tag.value.map((f) => f.id), [filters.tag.value]);
    const tagFilter = useCallback(
        (annot: Annotation): boolean => {
            if (filters.tag.value.length > 0) {
                const tags = annot.tags;
                for (let i = 0; i < tags.length; i++) {
                    if (tagFilterIds.includes(tags[i].id)) return true;
                }

                return false;
            } else return true;
        },
        [filters.tag, tagFilterIds]
    );

    const assignmentFilterIds: Array<string> = useMemo(() => filters.assignment.value.map((f) => f.id), [filters.assignment.value]);
    const assignmentFilterNull: boolean = useMemo(
        () => filters.assignment.value.find((f) => f.id === 'NoAssignment') !== undefined,
        [filters.assignment.value]
    );
    const assignmentFilter = useCallback(
        (annot: Annotation): boolean => {
            if (filters.assignment.value.length > 0) {
                const assignedMember = annot.assignedMember;
                if (assignedMember) return assignmentFilterIds.includes(assignedMember.id);
                else return assignmentFilterNull;
            } else return true;
        },
        [filters.assignment, assignmentFilterIds, assignmentFilterNull]
    );

    const assertionFilterContents: Array<string> = useMemo(() => {
        let choseContents: Array<string> = [];
        DefaultAssertions.forEach((da) => {
            if (filters.assertion.value.find((f) => f.label === da) !== undefined) choseContents.push(da);
        });
        return choseContents;
    }, [filters.assertion.value]);
    const assertionFilterNull: boolean = useMemo(
        () => filters.assertion.value.find((f) => f.label === AdditionalAssertionFilter.NoAssertion) !== undefined,
        [filters.assertion.value]
    );
    const assertionFilterNotNull: boolean = useMemo(
        () => filters.assertion.value.find((f) => f.label === AdditionalAssertionFilter.Other) !== undefined,
        [filters.assertion.value]
    );
    const assertionFilter = useCallback(
        (annot: Annotation): boolean => {
            if (filters.assertion.value.length > 0) {
                const assertion = annot.assertion;
                if (assertion) {
                    for (let i = 0; i < assertionFilterContents.length; i++) {
                        if (assertion.includes(assertionFilterContents[i])) return true;
                    }

                    return assertionFilterNotNull;
                } else return assertionFilterNull;
            } else return true;
        },
        [filters.assertion, assertionFilterContents, assertionFilterNull, assertionFilterNotNull]
    );

    const linkedInformationFilterNull: boolean = useMemo(
        () => filters.linkedInformation.value.find((f) => f.value === 'no') !== undefined,
        [filters.linkedInformation.value]
    );
    const linkedInformationFilterNotNull: boolean = useMemo(
        () => filters.linkedInformation.value.find((f) => f.value === 'any') !== undefined,
        [filters.linkedInformation.value]
    );
    const linkedInformationFilter = useCallback(
        (annot: Annotation): boolean => {
            if (filters.linkedInformation.value.length > 0) {
                if (annot.isAnySupportingInformationLinked && linkedInformationFilterNotNull) return true;
                if (!annot.isAnySupportingInformationLinked && linkedInformationFilterNull) return true;

                return false;
            } else return true;
        },
        [filters.linkedInformation, linkedInformationFilterNull, linkedInformationFilterNotNull]
    );

    const filteredAnnotations = useMemo(() => {
        if (annotations)
            return annotations.filter(statusFilter).filter(tagFilter).filter(assignmentFilter).filter(assertionFilter).filter(linkedInformationFilter);
        else return [];
    }, [annotations, statusFilter, tagFilter, assignmentFilter, assertionFilter, linkedInformationFilter]);

    return filteredAnnotations;
};

export default useFilteredAnnotations;
