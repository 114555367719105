import { Core, WebViewerInstance } from '@pdftron/webviewer';
import Log from 'Views/Common/PdfViewerWithToolbar/Logger';
import { HighlightExtended, HighlightStatesMethods } from '../useDrawing';
import { HighlightLeftTopInformation } from 'Views/Common/PdfViewerWithToolbar/HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { EmotionCache } from '@emotion/react';
import { SupportStateHighlight } from '../useAnnotationsSelecting';
import { Highlight, AdditionalHtmlElement, HighlightColor } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { createOrRefreshAnnotations, getHighlightPartAnnotationsToRemove } from './ViewerAnnotations/Annotations';
import { AnnotationAttributeType, getParentHighlightId } from './ViewerAnnotations/Attributes';
import { getAdditionalHtmlElements } from './ViewerAnnotations/AdditionalHtmlElements';
import { createOrRefreshLeftTopInformation } from './ViewerAnnotations/HighlightLeftTopInformation';

const RedrawHighlight = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance,
    highlight: HighlightExtended<THighlightCustomData>,
    highlightStatesMethods: HighlightStatesMethods<THighlightCustomData>,
    selectedHighlightIds: Array<string>,
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache,
    highlightColor: ((highlight: Highlight<THighlightCustomData>, selectedHighlightIds: Array<string>) => HighlightColor | undefined) | undefined,
    selectAnnotations: (highlights: Array<SupportStateHighlight>) => void,
    additionalHtmlElements: Array<AdditionalHtmlElement<THighlightCustomData>> | undefined,
    annotationElementsEnabled: boolean
) => {
    Log(webViewerInstance, 'ACTION', ['useDrawing', 'redrawHighlight'], [highlight]);
    const { annotationManager } = webViewerInstance.Core;

    const allAnnotations: Array<Core.Annotations.Annotation> = annotationManager.getAnnotationsList();
    const allHighlightsNodeAnnotations: Array<Core.Annotations.Annotation> = allAnnotations.filter((a) => getParentHighlightId(a) === highlight.id);

    const highlightPartAnnotationsToRemove = getHighlightPartAnnotationsToRemove(webViewerInstance, highlight);
    const additionalHtmlElementsToRemove: Array<Core.Annotations.Annotation> = allHighlightsNodeAnnotations.filter(
        (a) => AnnotationAttributeType.get(a) === 'additionalHtmlElement'
    );
    const toRemove = [...highlightPartAnnotationsToRemove, ...additionalHtmlElementsToRemove];
    if (toRemove.length > 0) {
        Log(webViewerInstance, 'ACTION', ['useDrawing', 'redrawHighlight', 'deleteAnnotations'], [toRemove]);
        annotationManager.deleteAnnotations(toRemove);
    }

    const { newTextAnnotations: highlightPartAnnotationsNew, allTextAnnotations } = createOrRefreshAnnotations(
        webViewerInstance,
        highlight,
        selectedHighlightIds,
        highlightColor
    );

    const { newLeftTopInformation, allLeftTopInformation } = createOrRefreshLeftTopInformation(
        webViewerInstance,
        highlight,
        selectedHighlightIds,
        leftTopInformation,
        selectAnnotations,
        emotionCache,
        highlightStatesMethods.leftTopInformation,
        annotationElementsEnabled
    );
    highlightStatesMethods.leftTopInformation.current?.({ selectedHighlightIds, highlight });

    const additionalHtmlElementsNew = getAdditionalHtmlElements(webViewerInstance, highlight, selectedHighlightIds, additionalHtmlElements || [], emotionCache);
    const toAdd = [...highlightPartAnnotationsNew, ...newLeftTopInformation, ...additionalHtmlElementsNew];

    if (toAdd.length > 0) {
        Log(webViewerInstance, 'ACTION', ['useDrawing', 'redrawHighlight', 'addAnnotations'], [toAdd]);
        annotationManager.addAnnotations(toAdd);
    }

    const toDraw = [...toAdd, ...allTextAnnotations, ...allLeftTopInformation];

    // Workaround for the issue with drawing annotations and rendering canvas for the paga in the same time.
    // "The image argument is a canvas element with a width or height of 0."
    // annotationManager.drawAnnotationsFromList(toDraw);
    const pages = [...new Set(toDraw.map((a) => a.PageNumber))];
    const doc = webViewerInstance.UI.iframeWindow.document;
    pages.forEach((pageNumber) => {
        const container = doc.getElementById(`pageContainer${pageNumber}`);
        if (container) {
            const canvas = container.getElementsByClassName('hacc')[0];
            if (canvas) {
                const height = parseInt(canvas.getAttribute('height') || '0');
                const width = parseInt(canvas.getAttribute('width') || '0');
                if (height > 0 || width > 0) {
                    Log(webViewerInstance, 'ACTION', ['useDrawing', 'redrawHighlight', 'drawAnnotations'], [pageNumber, toDraw]);
                    annotationManager.drawAnnotations({ pageNumber, majorRedraw: true });
                }
            }
        }
    });

    allTextAnnotations.reverse().forEach((a) => annotationManager.bringToBack(a));
    allLeftTopInformation.forEach((a) => annotationManager.bringToFront(a));
};

export default RedrawHighlight;
