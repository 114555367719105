import React, { memo, useCallback } from 'react';
import { Paper, Typography, Box, IconButton } from '@mui/material';
import { FlagFill, TrashBin } from 'UI/Icon';
import { palette } from 'UI/Provider/VerifiTheme';
import { Tooltip } from 'UI';
import { CommentReason } from 'ApiClients/SterlingApiClients/Types';

type CommentProps = {
    username: string;
    creationDate: Date;
    children: React.ReactNode;
    onDelete: () => void;
    reason: CommentReason;
    enableTrash: boolean;
    enableFlag: boolean;
    commentSelected: boolean;
};

function Comment(props: CommentProps) {
    const { username, creationDate, children, onDelete, reason, enableFlag, enableTrash, commentSelected } = props;
    const handleTrashClick = useCallback(() => {
        onDelete();
    }, [onDelete]);

    const ActionFooter = memo(() => (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            {reason === CommentReason.SettingNeedsReview ? <FlagFill color={enableFlag ? palette.attention.high : palette.stroke.main} /> : <Box />}
            {enableTrash && (
                <Tooltip title='Remove comment'>
                    <IconButton onClick={handleTrashClick}>
                        <TrashBin color={palette.blue.medium} />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    ));

    return (
        <Paper
            sx={{
                padding: '1rem',
                width: '25rem',
                borderRadius: '0.75rem',
                boxShadow: '0px 2px 10px 0px rgba(63, 68, 68, 0.4)',
                backgroundColor: (theme) => (commentSelected ? theme.palette.blue.light : theme.palette.white.main),
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='subtitle2'>{username}</Typography>
                <Typography variant='caption'>
                    {new Date(creationDate).toLocaleString('default', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
                </Typography>
            </Box>
            <Typography variant='body2' sx={{ marginTop: '0.5rem', wordBreak: 'break-word' }}>
                {children}
            </Typography>
            <ActionFooter />
        </Paper>
    );
}

export default Comment;
