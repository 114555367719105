import { Box, CircularProgress, Link, useTheme } from '@mui/material';
import { AnnotationInfo } from 'ApiClients/SterlingApiClients/Types';
import { TableList, Typography } from 'UI';
import ItemNumberComponent from '../Common/ItemNumber/ItemNumber.Component';
import { SearchRoutes } from 'App/RoutesPaths';
import AnnotationStatusComponent from 'Views/Common/AnnotationStatus/AnnotationStatus.Component';
import { FlagFill } from 'UI/Icon';

export type AnnotationsTableComponentProps = {
    isFetching: boolean;
    annotations: Array<AnnotationInfo>;
    projectId: string;
    projectVersionIdUrlParam: string;
};

function AnnotationsTableComponent(props: AnnotationsTableComponentProps) {
    const { isFetching, annotations, projectId, projectVersionIdUrlParam } = props;
    const hasData = annotations.length > 0;

    const theme = useTheme();

    return (
        <TableList.Table maxHeight={256}>
            <TableList.Header>
                <TableList.TR isHeader>
                    <TableList.TH size={60}>Item</TableList.TH>
                    <TableList.TH>Statement</TableList.TH>
                    <TableList.TH size={170}>Responsibility</TableList.TH>
                    <TableList.TH>Status</TableList.TH>
                    <TableList.TH>Source</TableList.TH>
                    <TableList.TH>Evidence</TableList.TH>
                    <TableList.TH>Comment</TableList.TH>
                </TableList.TR>
            </TableList.Header>
            <TableList.Body data-cy='annotations-table-body'>
                {!isFetching &&
                    hasData &&
                    annotations.map((annot, idx) => {
                        return (
                            <TableList.TR
                                key={annot.id}
                                data-cy='annotations-table-row'
                                hover={false}
                                sx={{
                                    backgroundColor: (theme) => (idx % 2 === 0 ? theme.palette.blue.light : 'inherit'),
                                    minHeight: '3rem',
                                }}
                            >
                                <TableList.TD size={60}>
                                    <ItemNumberComponent number={annot.orderNumber} />
                                </TableList.TD>
                                <TableList.TD sx={{ whiteSpace: 'normal' }}>{annot.statement}</TableList.TD>
                                <TableList.TD size={170}>{annot.assignedMember?.fullName}</TableList.TD>

                                <TableList.TD sx={{ position: 'relative', overflow: 'visible' }}>
                                    {annot.needsReview && (
                                        <FlagFill style={{ position: 'absolute', top: '25%', left: '-10%', zIndex: 1, color: theme.palette.attention.high }} />
                                    )}
                                    <AnnotationStatusComponent status={annot.status} />
                                </TableList.TD>
                                <TableList.TD sx={{ whiteSpace: 'normal' }}>
                                    {annot.supportingInformation.map((info, idx) => (
                                        <Box key={`supporting-information-source-${idx}`}>
                                            <Link
                                                underline='hover'
                                                href={SearchRoutes.goToLinkedInformation.getFullLink(
                                                    projectId,
                                                    projectVersionIdUrlParam,
                                                    annot.id,
                                                    info.documentId,
                                                    info.supportingInformationId
                                                )}
                                            >
                                                {`${info.name} (${info.pageNumber})`}
                                            </Link>
                                        </Box>
                                    ))}
                                </TableList.TD>
                                <TableList.TD sx={{ whiteSpace: 'normal' }}>
                                    {annot.supportingInformation.map((info, idx) => (
                                        <Box key={`supporting-information-evidence-${idx}`}>{info.supportingInformationStatement}</Box>
                                    ))}
                                </TableList.TD>
                                <TableList.TD sx={{ whiteSpace: 'normal' }}>{annot.assertion}</TableList.TD>
                            </TableList.TR>
                        );
                    })}
                {!isFetching && !hasData && (
                    <TableList.TR>
                        <TableList.TD textAlign='center'>
                            <Typography variant='inputLabel' sx={{ height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                No data.
                            </Typography>
                        </TableList.TD>
                    </TableList.TR>
                )}
                {isFetching && (
                    <TableList.TR>
                        <TableList.TD textAlign='center'>
                            <CircularProgress size={20} />
                        </TableList.TD>
                    </TableList.TR>
                )}
            </TableList.Body>
        </TableList.Table>
    );
}

export default AnnotationsTableComponent;
