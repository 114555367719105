import { Box, Collapse, Fab } from '@mui/material';
import { Icon, Typography } from 'UI';
import { useState } from 'react';
import { Heading } from '../PdfViewerWithToolbar.Types';

export type PdfHeadingsListComponentProps = {
    headings: Array<Heading>;
    currentHeading: Heading;
    jumpToHeading: (heading: Heading) => void;
};

function PdfHeadingsListComponent(props: PdfHeadingsListComponentProps) {
    const { headings, currentHeading, jumpToHeading } = props;

    const [showList, setShowList] = useState<boolean>(false);

    return (
        <Box sx={{ position: 'absolute', top: '3rem', left: '1rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Fab color='primary' size='small' onClick={() => setShowList((prev) => !prev)}>
                    <Icon.List />
                </Fab>
                <Collapse in={!showList} orientation='horizontal'>
                    <Box
                        sx={{
                            height: '2.5rem',
                            marginLeft: '0.5rem',
                            padding: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: (theme) => theme.palette.white.main,
                            border: (theme) => `2px solid ${theme.palette.blue.dark}`,
                            borderRadius: '1.25rem',
                        }}
                    >
                        <Typography sx={{ whiteSpace: 'nowrap', color: (theme) => theme.palette.blue.dark, fontWeight: 700, fontSize: '1rem' }}>
                            {currentHeading.title.length > 30 ? currentHeading.title.substring(0, 30) + '...' : currentHeading.title}
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Collapse in={showList} orientation='vertical'>
                <Box
                    sx={{
                        width: '20rem',
                        padding: '0.75rem',
                        marginTop: '0.5rem',
                        backgroundColor: (theme) => theme.palette.white.main,
                        border: (theme) => `2px solid ${theme.palette.blue.dark}`,
                        borderRadius: '1.25rem',
                    }}
                >
                    <Box
                        sx={{
                            maxHeight: '30rem',
                            overflowY: 'auto',
                            display: 'grid',
                            rowGap: '0.5rem',
                        }}
                    >
                        {headings.map((h) => (
                            <Box key={h.id} onClick={() => jumpToHeading(h)}>
                                <Typography
                                    sx={{
                                        cursor: 'pointer',
                                        fontWeight: 600,
                                        ...(h.id === currentHeading.id && {
                                            color: (theme) => theme.palette.blue.dark,
                                            fontWeight: 700,
                                        }),
                                    }}
                                >
                                    {h.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

export default PdfHeadingsListComponent;
