import { ApiGetDataset, useApiGet } from 'ApiClients/Hooks/ApiWrappers';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { RootRouteParams } from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import AutoAnnotationsApiClient from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient';
import { AutoAnnotation, SplitAutoAnnotationBody } from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient.Types';
import {
    AutoAnnotationsProcess,
    AutoAnnotationsProcessStatus,
    BoundingBoxSection,
    ProjectDocumentProcessingStatus,
    ProjectSimple,
    SelectionType,
    VerificationDocument,
} from 'ApiClients/SterlingApiClients/Types';
import { ObjectChangeType } from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';
import { useCallback, useMemo, useRef } from 'react';
import { SelectedAnnotation } from './useAnnotationsSelecting';
import { SelectAnnotationOptions } from './useAnnotations';
import { GetPageAndCoordinates, OrderByHighlightPosition } from 'Views/Common/PdfViewerWithToolbar/Functions';

export type AutoAnnotationsDataset = ApiGetDataset<Array<AutoAnnotation>, RootRouteParams>;

export type AutoAnnotationsProps = {
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
    autoAnnotationsDs: AutoAnnotationsDataset;
    createAnnotationFromAutoAnnotation: (autoAnnotationId: string, flowMethods: FlowMethods) => void;
    createAnnotationFromModifiedAutoAnnotation: (
        autoAnnotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    rejectAutoAnnotation: (autoAnnotationId: string, flowMethods?: FlowMethods) => void;
    markAutoAnnotationsProcessErrorAsReceived: () => void;
    verificationDocumentProcessingStatus: ProjectDocumentProcessingStatus;
    selectedAutoAnnotationId: string | null;
    setSelectedAutoAnnotationId: (id: string | null, options: { dispatchEvent: boolean; callback?: () => void }) => void;
    splitAutoAnnotation: (autoAnnotationId: string, body: SplitAutoAnnotationBody, flowMethods: FlowMethods) => void;
    mergeAutoAnnotations: (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => void;
    approveAutoAnnotations: (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => void;
    rejectAutoAnnotations: (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => void;
};

const useAutoAnnotations = (
    project: ProjectSimple,
    autoAnnotationsApiClient: AutoAnnotationsApiClient,
    verificationDocumentInfo: VerificationDocument | null,
    refreshAnnotation: (annotationId: string) => void,
    refreshAnnotations: () => void,
    setAutoAnnotationsProcessIsErrorReceived: (isErrorReceived: boolean) => void,
    setSelectedAnnotationIdsRef: (annots: Array<SelectedAnnotation>, options: SelectAnnotationOptions) => void
) => {
    const rootRouteParams: RootRouteParams = useMemo(() => ({ projectId: project.id, projectVersionId: project.workingVersion.id }), [project]);

    const autoAnnotationsEnabled = useMemo(() => {
        if (verificationDocumentInfo?.autoAnnotationsProcess) {
            const status = verificationDocumentInfo.autoAnnotationsProcess.status;

            return status === AutoAnnotationsProcessStatus.Processed || status === AutoAnnotationsProcessStatus.ProcessedWithErrors;
        }

        return false;
    }, [verificationDocumentInfo?.autoAnnotationsProcess]);

    const _autoAnnotationsDs: AutoAnnotationsDataset = useApiGet<Array<AutoAnnotation>, RootRouteParams>({
        method: autoAnnotationsApiClient.getAutoAnnotations,
        initialData: [],
        defaultInput: { input: rootRouteParams },
        initFetch: true,
        enabled: autoAnnotationsEnabled,
    });
    const { data: _autoAnnotations, setData: setAutoAnnotations } = _autoAnnotationsDs;

    const refreshAutoAnnotation = useCallback(
        (autoAnnotationId: string) => {
            autoAnnotationsApiClient.getAutoAnnotation(
                { ...rootRouteParams, autoAnnotationId },
                {
                    onSuccess: (annotation) => {
                        console.log('refresh', annotation);
                        if (annotation) {
                            setAutoAnnotations((prev) => {
                                const index = prev.findIndex((a) => a.id === autoAnnotationId);
                                if (index >= 0) {
                                    prev[index] = annotation;
                                    return [...prev];
                                } else {
                                    return [...prev, annotation];
                                }
                            });
                        }
                    },
                }
            );
        },
        [autoAnnotationsApiClient, rootRouteParams, setAutoAnnotations]
    );
    const removeAutoAnnotation = useCallback(
        (autoAnnotationId: string) => setAutoAnnotations((prev) => prev.filter((a) => a.id !== autoAnnotationId)),
        [setAutoAnnotations]
    );

    const handleAutoAnnotationChanged = useCallback(
        (autoAnnotationId: string, changeType: ObjectChangeType) => {
            switch (changeType) {
                case ObjectChangeType.Create:
                case ObjectChangeType.Update:
                    refreshAutoAnnotation(autoAnnotationId);
                    break;
                case ObjectChangeType.Delete:
                    removeAutoAnnotation(autoAnnotationId);
                    break;
            }
        },
        [removeAutoAnnotation, refreshAutoAnnotation]
    );

    const autoAnnotations = useMemo(() => {
        return _autoAnnotations.sort((a, b) => OrderByHighlightPosition(GetPageAndCoordinates(a), GetPageAndCoordinates(b)));
    }, [_autoAnnotations]);
    const autoAnnotationsRef = useRef<Array<AutoAnnotation>>(autoAnnotations);
    autoAnnotationsRef.current = autoAnnotations;

    const createAnnotationFromAutoAnnotation = (autoAnnotationId: string, flowMethods?: FlowMethods) =>
        autoAnnotationsApiClient.createAnnotationFromAutoAnnotation(
            { ...rootRouteParams, autoAnnotationId },
            {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess && flowMethods.onSuccess(data);
                    refreshAnnotation(autoAnnotationId);
                    removeAutoAnnotation(autoAnnotationId);
                },
            }
        );

    const createAnnotationFromModifiedAutoAnnotation = (
        autoAnnotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods?: FlowMethods
    ) =>
        autoAnnotationsApiClient.createAnnotationFromModifiedAutoAnnotation(
            { ...rootRouteParams, autoAnnotationId },
            {
                ...flowMethods,
                body: {
                    boundingBoxSections,
                    selectionType,
                    statement,
                },
                onSuccess: (data) => {
                    flowMethods?.onSuccess && flowMethods.onSuccess(data);
                    refreshAnnotation(autoAnnotationId);
                    removeAutoAnnotation(autoAnnotationId);
                },
            }
        );
    const rejectAutoAnnotation = useCallback(
        (autoAnnotationId: string, flowMethods?: FlowMethods) =>
            autoAnnotationsApiClient.rejectAutoAnnotation(
                { ...rootRouteParams, autoAnnotationId },
                {
                    ...flowMethods,
                    onSuccess: (data) => {
                        flowMethods?.onSuccess && flowMethods.onSuccess(data);
                        removeAutoAnnotation(autoAnnotationId);
                    },
                }
            ),
        [autoAnnotationsApiClient, rootRouteParams, removeAutoAnnotation]
    );

    const markAutoAnnotationsProcessErrorAsReceived = () => {
        autoAnnotationsApiClient.markAutoAnnotationsProcessErrorAsReceived(rootRouteParams, {
            onSuccess: () => setAutoAnnotationsProcessIsErrorReceived(true),
        });
    };

    const autoAnnotationsDs = useMemo(() => ({ ..._autoAnnotationsDs, data: autoAnnotations }), [_autoAnnotationsDs, autoAnnotations]);
    const verificationDocumentProcessingStatus = useMemo(
        () => verificationDocumentInfo?.processingStatus || ProjectDocumentProcessingStatus.ToBeProcessed,
        [verificationDocumentInfo]
    );
    const autoAnnotationsProcess = useMemo(() => verificationDocumentInfo?.autoAnnotationsProcess || null, [verificationDocumentInfo]);

    const splitAutoAnnotation = useCallback(
        (autoAnnotationId: string, body: SplitAutoAnnotationBody, flowMethods: FlowMethods) =>
            autoAnnotationsApiClient.splitAutoAnnotation(
                { ...rootRouteParams, autoAnnotationId },
                {
                    ...flowMethods,
                    body,
                    onSuccess: (newAutoAnnotationIds: Array<string>) => {
                        flowMethods.onSuccess?.(newAutoAnnotationIds);
                        refreshAutoAnnotation(autoAnnotationId);
                        newAutoAnnotationIds.forEach((id) => refreshAutoAnnotation(id));
                    },
                }
            ),
        [autoAnnotationsApiClient, rootRouteParams, refreshAutoAnnotation]
    );

    const mergeAutoAnnotations = useCallback(
        (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => {
            const annots = autoAnnotationsRef.current;
            const annotsTmp = autoAnnotationIds.map((id) => {
                const index = annots.findIndex((a) => a.id === id);

                return { id, index };
            });
            const ids = annotsTmp.sort((a, b) => a.index - b.index).map((a) => a.id);

            return autoAnnotationsApiClient.mergeAutoAnnotations(rootRouteParams, {
                ...flowMethods,
                body: {
                    autoAnnotationIds: ids,
                },
                onSuccess: () => {
                    refreshAutoAnnotation(ids[0]);
                    setSelectedAnnotationIdsRef([], { dispatchEvent: true });
                    const idsToRemove = ids.slice(1);
                    idsToRemove.forEach((id) => removeAutoAnnotation(id));
                },
            });
        },
        [autoAnnotationsApiClient, rootRouteParams, refreshAutoAnnotation, setSelectedAnnotationIdsRef, removeAutoAnnotation]
    );

    const approveAutoAnnotations = useCallback(
        (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => {
            const annots = autoAnnotationsRef.current;
            const annotsTmp = autoAnnotationIds.map((id) => {
                const index = annots.findIndex((a) => a.id === id);

                return { id, index };
            });
            const ids = annotsTmp.sort((a, b) => a.index - b.index).map((a) => a.id);

            return autoAnnotationsApiClient.approveAutoAnnotations(rootRouteParams, {
                ...flowMethods,
                body: {
                    autoAnnotationIds: ids,
                },
                onSuccess: () => {
                    refreshAnnotations();
                    setSelectedAnnotationIdsRef([], { dispatchEvent: true });
                    ids.forEach((id) => removeAutoAnnotation(id));
                },
            });
        },
        [autoAnnotationsApiClient, rootRouteParams, refreshAnnotations, setSelectedAnnotationIdsRef, removeAutoAnnotation]
    );

    const rejectAutoAnnotations = useCallback(
        (autoAnnotationIds: Array<string>, flowMethods: FlowMethods) => {
            const annots = autoAnnotationsRef.current;
            const annotsTmp = autoAnnotationIds.map((id) => {
                const index = annots.findIndex((a) => a.id === id);

                return { id, index };
            });
            const ids = annotsTmp.sort((a, b) => a.index - b.index).map((a) => a.id);

            return autoAnnotationsApiClient.rejectAutoAnnotations(rootRouteParams, {
                ...flowMethods,
                body: {
                    autoAnnotationIds: ids,
                },
                onSuccess: () => {
                    setSelectedAnnotationIdsRef([], { dispatchEvent: true });
                    ids.forEach((id) => removeAutoAnnotation(id));
                },
            });
        },
        [autoAnnotationsApiClient, rootRouteParams, setSelectedAnnotationIdsRef, removeAutoAnnotation]
    );

    return {
        autoAnnotationsDs,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        rejectAutoAnnotation,
        autoAnnotationsProcess,
        verificationDocumentProcessingStatus,
        markAutoAnnotationsProcessErrorAsReceived,
        handleAutoAnnotationChanged,
        splitAutoAnnotation,
        mergeAutoAnnotations,
        approveAutoAnnotations,
        rejectAutoAnnotations,
    };
};

export default useAutoAnnotations;
