import {
    AutoAnnotationsProcessStatus,
    AutoAnnotationsSectionProcessStatus,
    DocumentInfo,
    ProjectDocumentProcessingStatus,
    ProjectSimple,
    VerificationDocument,
} from 'ApiClients/SterlingApiClients/Types';
import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import {
    AutoAnnotationGeneratedMessageData,
    AutoAnnotationsSectionProcessStatusChangedMessageData,
    ObjectChangeType,
} from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';
import useToasts, { ToastEnum } from 'Hooks/useToasts';
import useProjectNotifications from 'Views/Project/Hooks/useProjectNotifications';
import { useRef } from 'react';

const useProjectWorkspaceNotifications = (
    notificationsConnector: NotificationsConnector,
    project: ProjectSimple,
    verificationDocumentInfo: VerificationDocument | null,
    suppDocsById: { [key: string]: DocumentInfo },
    setVerificationDocumentProcessingStatus: (status: ProjectDocumentProcessingStatus) => void,
    setSupportingDocumentProcessingStatus: (documentId: string, status: ProjectDocumentProcessingStatus) => void,
    refetchVerificationDocument: () => void,
    refetchSupportingDocuments: () => void,
    refetchAutoAnnotations: () => void,
    refetchAnnotations: () => void,
    setAutoAnnotationsProcessProgress: (progress: number) => void,
    handleAutoAnnotationGenerated: (msg: AutoAnnotationGeneratedMessageData) => void,
    handleAutoAnnotationsSectionProcessStatusChanged: (msg: AutoAnnotationsSectionProcessStatusChangedMessageData) => void,
    handleAnnotationChanged: (annotationId: string, changeType: ObjectChangeType) => void,
    handleAutoAnnotationChanged: (autoAnnotationId: string, changeType: ObjectChangeType) => void,
    handleSupportingDocumentUploaded: (supportingDocumentId: string, changeType: ObjectChangeType) => void
) => {
    const { addToast } = useToasts();
    const supportingDocumentsRef = useRef(suppDocsById);
    supportingDocumentsRef.current = suppDocsById;

    const refetchAutoAnnotationsRef = useRef(refetchAutoAnnotations);
    refetchAutoAnnotationsRef.current = refetchAutoAnnotations;

    const autoAnnotationsProcessStatusRef = useRef(verificationDocumentInfo?.autoAnnotationsProcess?.status || null);
    autoAnnotationsProcessStatusRef.current = verificationDocumentInfo?.autoAnnotationsProcess?.status || null;

    useProjectNotifications(
        notificationsConnector,
        project.id,
        {
            verificationDocumentProcessingStatusUpdate: (msg) => {
                const status = msg.processingStatus;
                switch (status) {
                    case ProjectDocumentProcessingStatus.Processing:
                        setVerificationDocumentProcessingStatus(status);
                        addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                        break;
                    case ProjectDocumentProcessingStatus.Processed:
                        refetchVerificationDocument();
                        addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                        break;
                    case ProjectDocumentProcessingStatus.Failed:
                        setVerificationDocumentProcessingStatus(status);
                        addToast({ type: ToastEnum.ERROR, content: [msg.message] });
                        break;
                }
            },
            supportingDocumentUploaded: (msg) => {
                const changeType = msg.changeType;
                if (changeType === ObjectChangeType.Create) {
                    handleSupportingDocumentUploaded(msg.objectId, msg.changeType);
                }
            },
            supportingDocumentConversionFailed: (msg) => {
                const message = msg.fileName
                    ? `Conversion of file ${msg.path}/${msg.fileName} failed. File hasn't been uploaded.`
                    : `Some file could not be converted. File hasn't been uploaded.`;
                addToast({ type: ToastEnum.WARNING, content: [message], autoClose: false });
            },
            supportingDocumentProcessingStatusUpdate: (msg) => {
                if (supportingDocumentsRef.current[msg.supportingDocumentId]) {
                    setSupportingDocumentProcessingStatus(msg.supportingDocumentId, msg.processingStatus);

                    switch (msg.processingStatus) {
                        case ProjectDocumentProcessingStatus.Processing:
                        case ProjectDocumentProcessingStatus.Processed:
                            addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                            break;
                        case ProjectDocumentProcessingStatus.Failed:
                            addToast({ type: ToastEnum.ERROR, content: [msg.message] });
                            break;
                    }
                }
            },
            autoAnnotationGenerated: (msg) => {
                if (autoAnnotationsProcessStatusRef.current === msg.processStatus) return;

                autoAnnotationsProcessStatusRef.current = msg.processStatus;

                handleAutoAnnotationGenerated(msg);

                let toastType: ToastEnum = ToastEnum.SUCCESS;
                switch (msg.processStatus) {
                    case AutoAnnotationsProcessStatus.ProcessedWithErrors:
                        toastType = ToastEnum.WARNING;
                        break;
                    case AutoAnnotationsProcessStatus.Failed:
                        toastType = ToastEnum.ERROR;
                        break;
                }
                addToast({ type: toastType, content: [msg.message] });
            },
            autoAnnotationGenerationProgress: (msg) => setAutoAnnotationsProcessProgress(msg.progress),
            autoAnnotationsSectionProcessStatusChanged: (msg) => {
                if (msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed) {
                    addToast({ type: ToastEnum.WARNING, content: [msg.message!] });
                }

                handleAutoAnnotationsSectionProcessStatusChanged(msg);
            },
            annotationChanged: (msg) => handleAnnotationChanged(msg.objectId, msg.changeType),
            autoAnnotationChanged: (msg) => handleAutoAnnotationChanged(msg.objectId, msg.changeType),
            annotationsReordered: () => {
                refetchAutoAnnotationsRef.current();
                refetchAnnotations();
            },
            autoAnnotationsAndAnnotationsListsChanged: () => {
                refetchAutoAnnotationsRef.current();
                refetchAnnotations();
            },
            autoAnnotationsListChanged: () => {
                refetchAutoAnnotationsRef.current();
            },
        },
        [refetchAutoAnnotations, refetchVerificationDocument, refetchSupportingDocuments, refetchAnnotations]
    );
};

export default useProjectWorkspaceNotifications;
