import { useState } from 'react';
import { Annotation, Assertion } from 'ApiClients/SterlingApiClients/Types';
import { Button, Chip, Dialog, Icon, TextField } from 'UI';
import { Box } from '@mui/material';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';

export type AssertionModalComponentProps = {
    isOpen: boolean;
    annotation: Annotation;
    getAssertion: (annotationId: string, flowMethods?: FlowMethods<Assertion>) => void;
    saveAssertion: (annotationId: string, assertion: Assertion, flowMethods?: FlowMethods) => void;
    closeModal: () => void;
};

type DisplayMode = 'readonly' | 'edit';

type ModalAssertion = Omit<Assertion, 'text'> & {
    text: string;
};

export const DefaultAssertions: Array<string> = ['Self Evident', 'Management Representation'];

function AssertionModalComponent(props: AssertionModalComponentProps) {
    const { isOpen, annotation, closeModal } = props;

    const [displayMode, setDisplayMode] = useState<DisplayMode | null>(null);
    const [assertion, setAssertion] = useState<ModalAssertion>({ text: '' });

    usePromiseWithFlowMethods<{}, Assertion>({
        method: (_input, flowMethods) =>
            props.getAssertion(annotation.id, {
                ...flowMethods,
                onSuccess: (data) => {
                    setAssertion({ ...data, text: data.text || '' });
                    setDisplayMode(data.text ? 'readonly' : 'edit');
                },
            }),
        initialData: { text: null },
        initFetch: { input: {} },
    });

    const { wrappedMethod: saveAssertion, fetching: isSaving } = usePromiseWithFlowMethods<{ annotationId: string; assertion: Assertion }, {}>({
        method: (input, flowMethods) =>
            props.saveAssertion(input.annotationId, input.assertion, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    closeModal();
                },
            }),
        initialData: {},
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        saveAssertion({ annotationId: annotation.id, assertion: { ...assertion, text: assertion.text === '' ? null : assertion.text } });
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Assertion</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            marginBottom: 2,
                        }}
                    >
                        <TextField
                            placeholder='Enter assertion'
                            multiline={true}
                            sx={{
                                '.MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                },
                                '.Mui-disabled': {
                                    color: (th) => th.palette.text.primary,
                                    WebkitTextFillColor: (th) => `${th.palette.text.primary} !important`,
                                },
                            }}
                            value={assertion.text}
                            onChange={(e) => setAssertion({ text: e.target.value })}
                            disabled={displayMode === 'readonly'}
                        />
                    </Box>
                    {displayMode === 'edit' && (
                        <Box
                            sx={{
                                marginBottom: 4,
                            }}
                        >
                            {DefaultAssertions.map((a, idx) => (
                                <Chip
                                    key={`${a}-${idx}`}
                                    color='info'
                                    size='small'
                                    label={a}
                                    onClick={() => setAssertion((prev) => ({ text: `${prev.text}${a}` }))}
                                    icon={<Icon.PlusCircleFill width={10} height={10} />}
                                    sx={{
                                        marginRight: '0.5rem',
                                        '.MuiChip-label': {
                                            order: -1,
                                        },
                                        '.MuiChip-icon': {
                                            marginRight: '0.5rem',
                                            marginLeft: 0,
                                            width: '0.6125rem',
                                            height: '0.6125rem',
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    )}

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-assertion'
                        >
                            Close
                        </Button>
                        {displayMode === 'edit' && (
                            <Button
                                data-cy='save-assertion'
                                isLoading={isSaving}
                                color='primary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                type='submit'
                            >
                                Save
                            </Button>
                        )}
                        {displayMode === 'readonly' && (
                            <Button
                                data-cy='set-edit'
                                color='primary'
                                sx={{
                                    maxWidth: 160,
                                }}
                                fullWidth
                                onClick={() => setDisplayMode('edit')}
                            >
                                Edit
                            </Button>
                        )}
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default AssertionModalComponent;
