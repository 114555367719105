import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { HtmlElementsResizing, getZoomRounded } from './useDrawing';
import { AnnotationAttributeIsHtmlAnnotation } from './Drawing/ViewerAnnotations/Attributes';

const RegisterZoomUpdatedEventListener = (
    webViewerInstance: WebViewerInstance,
    zoomRef: React.MutableRefObject<number | undefined>,
    setZoom: (value: number) => void,
    keepSelectedHighlightInCentre: boolean
) => {
    const { documentViewer, annotationManager } = webViewerInstance.Core;
    documentViewer.addEventListener('zoomUpdated', (zoom: number) => {
        ResizeHtmlAnnotations(annotationManager, zoom);
        HtmlElementsResizing(webViewerInstance);
        SetScroll(documentViewer, zoomRef, setZoom, zoom, keepSelectedHighlightInCentre);
        KeepSelectedHighlightInCentre(annotationManager, keepSelectedHighlightInCentre);
    });
};

const ResizeHtmlAnnotations = (annotationManager: Core.AnnotationManager, zoom: number) => {
    const annots = annotationManager
        .getAnnotationsList()
        .filter((a) => AnnotationAttributeIsHtmlAnnotation.get(a) === 'true') as Array<Core.Annotations.HTMLAnnotation>;
    const zoomRounded = getZoomRounded(zoom);
    if (annots.length > 0) {
        annots.forEach((annot) => {
            if (annot.innerElement) {
                const component = annot.innerElement.children[0] as HTMLElement;
                if (component) {
                    component.style.transform = `scale(${zoomRounded})`;
                }
            }
        });
    }
};

const SetScroll = (
    documentViewer: Core.DocumentViewer,
    zoomRef: React.MutableRefObject<number | undefined>,
    setZoom: (value: number) => void,
    zoom: number,
    keepSelectedHighlightInCentre: boolean
) => {
    //Update scroll location whenever zoom has been updated
    //to prevent scrolling to different page location
    if (!keepSelectedHighlightInCentre) {
        const scroll = documentViewer.getScrollViewElement();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const previousZoom = zoomRef.current || zoom;
        const previousTopLocation = displayMode.state.offset;
        const topLocationForZoom1 = previousTopLocation / previousZoom;
        const topLocationForCurrentZoomLevel = topLocationForZoom1 * zoom;

        const options = { behavior: 'auto' as ScrollBehavior, left: 0, top: topLocationForCurrentZoomLevel };

        scroll.scrollTo(options);
        documentViewer.scrollViewUpdated();
    }

    setZoom(zoom);
};

const KeepSelectedHighlightInCentre = (annotationManager: Core.AnnotationManager, keepSelectedHighlightInCentre: boolean) => {
    if (keepSelectedHighlightInCentre) {
        const selectedAnnot = annotationManager.getSelectedAnnotations()[0];
        if (selectedAnnot) {
            annotationManager.jumpToAnnotation(selectedAnnot);
        }
    }
};

export default RegisterZoomUpdatedEventListener;
