import SterlingApiClient, { GetRequestOptions } from '../../Sterling.ApiClient';
import { ProjectIndexReport, VerificationNote } from '../Types';

export default class ReportsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (projectId: string, versionId: string) => `projects/${projectId}/versions/${versionId}/reports`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getIndexReport = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<ProjectIndexReport>) =>
        this._sterlingApiClient.getAsync<ProjectIndexReport>(`${this._rootRoute(projectId, versionId)}/indexes`, getRequestOptions);

    getVerificationNote = (projectId: string, versionId: string, requestOptions?: GetRequestOptions<VerificationNote>) =>
        this._sterlingApiClient.getAsync<VerificationNote>(`${this._rootRoute(projectId, versionId)}/verificationNote`, requestOptions);
}
