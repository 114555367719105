import { useCallback, useMemo } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider } from '@mui/material';
import { DocumentUploadState } from './useDocumentUploadState';
import DocumentUploaderComponent from 'Views/Project/Common/DocumentUploader/DocumentUploader.Component';
import { Typography } from 'UI';

export type DocumentUploadComponentProps = DocumentUploadState;

function DocumentUploadComponent(props: DocumentUploadComponentProps) {
    const { mode, documentInfo, setDcumentInfo, onCancel, onNext } = props;
    const file = useMemo(() => documentInfo?.file || null, [documentInfo]);
    const setFile = useCallback(
        (file: File | null) =>
            setDcumentInfo(
                (file && {
                    id: new Date().getTime().toString(),
                    name: file.name,
                    fileId: new Date().getTime().toString(),
                    file,
                }) ||
                    null
            ),
        [setDcumentInfo]
    );

    const actionButtons = useMemo(
        () => (
            <Box data-testid='action-buttons' sx={{ display: 'flex', gap: 1 }}>
                <Button
                    variant='text'
                    sx={{
                        maxWidth: 160,
                    }}
                    fullWidth
                    type='button'
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    sx={{
                        minWidth: 160,
                    }}
                    fullWidth
                    type='button'
                    onClick={onNext}
                    disabled={!file}
                    data-testid='next-button'
                >
                    Next
                </Button>
            </Box>
        ),
        [onNext, onCancel, file]
    );

    return (
        <Container
            maxWidth='xl'
            sx={{
                padding: '0 !important',
            }}
        >
            <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', padding: '2.5rem 2rem' }} data-testid='project-card'>
                <CardHeader
                    title='Upload new document version'
                    titleTypographyProps={{ variant: 'h6', fontWeight: 500 }}
                    action={actionButtons}
                    sx={{
                        padding: '0',
                    }}
                    data-testid='card-header'
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', padding: 0 }} data-testid='card-content'>
                    <Divider />
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ width: '30%' }}>
                            <Typography variant='subtitle1'>Upload document to verify</Typography>
                            <Typography variant='body2'>Upload the new version of the document that you want to verify</Typography>
                        </Box>
                        <Box sx={{ width: '40%' }}>
                            <DocumentUploaderComponent file={file} setFile={setFile} uploadLabel='Upload new version of document' disabled={mode === 'edit'} />
                        </Box>
                    </Box>

                    <Divider />
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', padding: '0' }} data-testid='card-actions'>
                    {actionButtons}
                </CardActions>
            </Card>
        </Container>
    );
}

export default DocumentUploadComponent;
