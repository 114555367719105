import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions, PutRequestOptions } from '../../Sterling.ApiClient';
import {
    ApproveAutoAnnotationsBody,
    AutoAnnotation,
    CreateAnnotationFromModifiedAutoAnnotationBody,
    MergeAutoAnnotationsBody,
    RejectAutoAnnotationsBody,
    SplitAutoAnnotationBody,
} from './AutoAnnotations.ApiClient.Types';

export type RootRouteParams = {
    projectId: string;
    projectVersionId: string;
};

export type AutoAnnotationsRouteParams = RootRouteParams & {
    autoAnnotationId: string;
};

export default class AutoAnnotationsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRouteAutoAnnotations = (params: RootRouteParams) => `projects/${params.projectId}/versions/${params.projectVersionId}/autoannotations`;
    _rootRouteAutoAnnotationsProcess = (params: RootRouteParams) => `${this._rootRouteAutoAnnotations(params)}/autoAnnotationsProcess`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getAutoAnnotations = (params: RootRouteParams, getRequestOptions?: GetRequestOptions<Array<AutoAnnotation>>) =>
        this._sterlingApiClient.getAsync<Array<AutoAnnotation>>(`${this._rootRouteAutoAnnotations(params)}`, getRequestOptions);

    getAutoAnnotation = (params: AutoAnnotationsRouteParams, getRequestOptions?: GetRequestOptions<AutoAnnotation | null>) =>
        this._sterlingApiClient.getAsync<AutoAnnotation | null>(`${this._rootRouteAutoAnnotations(params)}/${params.autoAnnotationId}`, getRequestOptions);

    createAnnotationFromAutoAnnotation = (params: AutoAnnotationsRouteParams, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAutoAnnotations(params)}/${params.autoAnnotationId}`, postRequestOptions);

    createAnnotationFromModifiedAutoAnnotation = (
        params: AutoAnnotationsRouteParams,
        postRequestOptions: PostRequestOptions<CreateAnnotationFromModifiedAutoAnnotationBody>
    ) =>
        this._sterlingApiClient.postAsync<CreateAnnotationFromModifiedAutoAnnotationBody>(
            `${this._rootRouteAutoAnnotations(params)}/${params.autoAnnotationId}/modified`,
            postRequestOptions
        );

    rejectAutoAnnotation = (params: AutoAnnotationsRouteParams, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteAutoAnnotations(params)}/${params.autoAnnotationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Proposal removed.'],
        });

    // _rootRouteAutoAnnotationsProcess
    markAutoAnnotationsProcessErrorAsReceived = (params: RootRouteParams, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAutoAnnotationsProcess(params)}/markErrorAsReceived`, postRequestOptions);

    splitAutoAnnotation = (params: AutoAnnotationsRouteParams, putRequestOptions: PutRequestOptions<SplitAutoAnnotationBody, Array<string>>) =>
        this._sterlingApiClient.putAsync<SplitAutoAnnotationBody, Array<string>>(
            `${this._rootRouteAutoAnnotations(params)}/${params.autoAnnotationId}/split`,
            putRequestOptions
        );

    mergeAutoAnnotations = (params: RootRouteParams, postRequestOptions: PostRequestOptions<MergeAutoAnnotationsBody>) =>
        this._sterlingApiClient.postAsync<MergeAutoAnnotationsBody>(`${this._rootRouteAutoAnnotations(params)}/merge`, postRequestOptions);

    approveAutoAnnotations = (params: RootRouteParams, putRequestOptions: PutRequestOptions<ApproveAutoAnnotationsBody>) =>
        this._sterlingApiClient.putAsync<ApproveAutoAnnotationsBody>(`${this._rootRouteAutoAnnotations(params)}/approve`, putRequestOptions);

    rejectAutoAnnotations = (params: RootRouteParams, postRequestOptions: PostRequestOptions<RejectAutoAnnotationsBody>) =>
        this._sterlingApiClient.postAsync<RejectAutoAnnotationsBody>(`${this._rootRouteAutoAnnotations(params)}/reject`, postRequestOptions);
}
