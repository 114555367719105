import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type ApiGetDataset<TData, TInput> = {
    fetching: boolean;
    data: TData;
    setData: React.Dispatch<React.SetStateAction<TData>>;
    fetch: (input: TInput, flowMethods?: FlowMethods<TData>) => void;
    refetch: () => void;
};

export const useApiGet = <TData, TInput>(options: {
    method: (input: TInput, flowMethods: FlowMethods<TData>) => void;
    initialData: TData;
    defaultInput?: {
        input: TInput;
        flowMethods?: FlowMethods<TData>;
    };
    initFetch?: boolean;
    enabled?: boolean;
}): ApiGetDataset<TData, TInput> => {
    const { method, initialData, defaultInput, initFetch, enabled } = options;
    const _enabled = useMemo(() => {
        if (enabled === undefined) return true;
        else return enabled;
    }, [enabled]);

    const [data, setData] = useState<TData>(initialData);
    const [fetching, setFetching] = useState<boolean>(false);
    const fetch = useCallback(
        (input: TInput, flowMethods?: FlowMethods<TData>) => {
            if (_enabled) {
                method(input, {
                    ...flowMethods,
                    setFetching: (fetching) => {
                        setFetching(fetching);
                        flowMethods?.setFetching && flowMethods.setFetching(fetching);
                    },
                    onSuccess: (data) => {
                        setData(data);
                        flowMethods?.onSuccess && flowMethods.onSuccess(data);
                    },
                });
            }
        },
        // eslint-disable-next-line
        [_enabled]
    );
    const refetch = useCallback(
        () => {
            if (_enabled && defaultInput) fetch(defaultInput.input, defaultInput?.flowMethods);
        },
        // eslint-disable-next-line
        [_enabled]
    );

    useEffect(() => {
        if (_enabled && initFetch && defaultInput) {
            fetch(defaultInput.input, defaultInput.flowMethods);
        }
        // eslint-disable-next-line
    }, [_enabled]);

    return {
        fetching,
        data,
        fetch,
        refetch,
        setData,
    };
};
