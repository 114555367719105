import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { SyncState, getScrollContainer } from '../useViewersSync';

const SetUpViewer = (
    syncStateRef: React.MutableRefObject<SyncState>,
    viewerInstance1: WebViewerInstance,
    viewerInstance2: WebViewerInstance,
    positionDiffRef: React.MutableRefObject<number | null>,
    side: 'left' | 'right'
) => {
    const viewer1 = viewerInstance1.Core.documentViewer;
    const viewer2 = viewerInstance2.Core.documentViewer;

    // Zoom
    const onZoomHandler = (zoomLevel: number) => _onZoomHandler(zoomLevel, viewer2);
    viewer1.addEventListener('zoomUpdated', onZoomHandler);
    syncStateRef.current.removeHandlerFunctions.push(() => viewer1.removeEventListener('zoomUpdated', onZoomHandler));
    // Scroll
    const onScrollHandler = (e: Event) => _onScrollHandler(e, viewerInstance2, positionDiffRef, side);
    const container = getScrollContainer(viewerInstance1);
    container.addEventListener('scroll', onScrollHandler);
    syncStateRef.current.removeHandlerFunctions.push(() => container.removeEventListener('scroll', onScrollHandler));
    // Page
    const onPageHandler = (_page: number) => _onPageHandler(viewerInstance1, viewerInstance2, positionDiffRef, side);
    viewer1.addEventListener('pageChanged', onPageHandler);
    syncStateRef.current.removeHandlerFunctions.push(() => viewer1.removeEventListener('pageChanged', onPageHandler));
};

const _onZoomHandler = (zoomLevel: number, viewer: Core.DocumentViewer) => {
    if (viewer.getZoomLevel() !== zoomLevel) viewer.zoomTo(zoomLevel);
};

const _onScrollHandler = (e: Event, instance: WebViewerInstance, positionDiffRef: React.MutableRefObject<number | null>, side: 'left' | 'right') => {
    const container = getScrollContainer(instance);
    const positionDiff = positionDiffRef.current || 0;
    const scrollCorrection = side === 'left' ? -positionDiff : positionDiff;

    const currentScrollTop = Math.round(container.scrollTop);
    const newScrollTop = Math.round((e.target as HTMLElement).scrollTop + scrollCorrection);

    if (Math.abs(newScrollTop - currentScrollTop) > 2) {
        container.scrollTop = (e.target as HTMLElement).scrollTop + scrollCorrection;
    }
};

const _onPageHandler = (
    instance1: WebViewerInstance,
    instance2: WebViewerInstance,
    positionDiffRef: React.MutableRefObject<number | null>,
    side: 'left' | 'right'
) => {
    const container1 = getScrollContainer(instance1);
    const container2 = getScrollContainer(instance2);
    const positionDiff = positionDiffRef.current || 0;
    const scrollCorrection = side === 'left' ? -positionDiff : positionDiff;

    container2.scrollTop = container1.scrollTop + scrollCorrection;
};

export default SetUpViewer;
