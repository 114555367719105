import useStateCallback from 'Hooks/useStateCallback';
import { useCallback, useMemo, useRef } from 'react';
import { AnnotationsDataset } from './useAnnotations';
import { SelectedHighlightChangedEvent } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { Annotation, AutoAnnotation } from 'ApiClients/SterlingApiClients/Types';

export type SelectedAnnotation = {
    id: string;
    type: SelectedAnnotationType;
};

export type SelectedAnnotationType = 'annotation' | 'autoAnnotation';

export const AnnotationSelectedEventName = 'appAnnotationSelected';

const useAnnotationsSelecting = (
    annotationsDs: AnnotationsDataset,
    autoAnnotationsRef: React.MutableRefObject<Array<AutoAnnotation>>,
    markAnnotationNotificationAsRead: (annotationId: string) => void,
    closeSupportingInformationPropositions: () => void,
    setSelectedSupportingHighlightId: (id: string | null, options: { dispatchEvent: boolean }) => void
) => {
    const { data: annotations } = annotationsDs;
    const annotationsRef = useRef<Array<Annotation>>(annotations);
    annotationsRef.current = annotations;

    const [selectedAnnotationIds, _setSelectedAnnotationIds] = useStateCallback<Array<SelectedAnnotation>>([]);
    const selectedAnnotationIdsRef = useRef<Array<SelectedAnnotation>>(selectedAnnotationIds);
    selectedAnnotationIdsRef.current = selectedAnnotationIds;

    const setSelectedAnnotationIds = useCallback(
        (annots: Array<SelectedAnnotation>, options: { dispatchEvent: boolean; callback?: () => void }) => {
            if (annots.length !== 1) closeSupportingInformationPropositions();
            if (
                annots.length !== 1 ||
                (annots.length === 1 && selectedAnnotationIdsRef.current.length === 1 && selectedAnnotationIdsRef.current[0].id !== annots[0].id)
            ) {
                setSelectedSupportingHighlightId(null, { dispatchEvent: true });
            }
            _setSelectedAnnotationIds(annots, () => {
                if (options.dispatchEvent) {
                    const ids = annots.map((a) => a.id);
                    const event = new CustomEvent<SelectedHighlightChangedEvent>(AnnotationSelectedEventName, { detail: { ids } });
                    document.dispatchEvent(event);
                }
                options && options.callback && options.callback();
            });
        },
        // eslint-disable-next-line
        [_setSelectedAnnotationIds, setSelectedSupportingHighlightId]
    );

    const selectAnnotations = useCallback(
        (newSelectedAnnots: Array<SelectedAnnotation>, options: { dispatchEvent: boolean; callback?: () => void; shiftKey?: boolean }) => {
            const autoAnnotations = autoAnnotationsRef.current;
            const shiftKey = options.shiftKey || false;
            let selected = [...selectedAnnotationIdsRef.current];

            if (shiftKey && newSelectedAnnots.length === 1) {
                selected = selected.filter((annot) => annot.type === 'autoAnnotation');

                if (selected.length === 0) {
                    selected = [...newSelectedAnnots];
                } else {
                    const newSelectedAnnotation = newSelectedAnnots[0];
                    const newSelectedAnnotationIndex = autoAnnotations.findIndex((a) => a.id === newSelectedAnnotation.id);

                    const selectedIndexes = selected.map((annot) => autoAnnotations.findIndex((a) => a.id === annot.id));
                    const selectedIndexMin = Math.min(...selectedIndexes);
                    const selectedIndexMax = Math.max(...selectedIndexes);

                    selected = [];
                    if (newSelectedAnnotationIndex < selectedIndexMin) {
                        for (let i = newSelectedAnnotationIndex; i <= selectedIndexMax; i++) {
                            const autoAnnotation = autoAnnotations[i];
                            selected.push({ id: autoAnnotation.id, type: 'autoAnnotation' });
                        }
                    }
                    if (newSelectedAnnotationIndex > selectedIndexMin && newSelectedAnnotationIndex < selectedIndexMax) {
                        for (let i = newSelectedAnnotationIndex; i <= selectedIndexMax; i++) {
                            const autoAnnotation = autoAnnotations[i];
                            selected.push({ id: autoAnnotation.id, type: 'autoAnnotation' });
                        }
                    }
                    if (newSelectedAnnotationIndex > selectedIndexMax) {
                        for (let i = selectedIndexMin; i <= newSelectedAnnotationIndex; i++) {
                            const autoAnnotation = autoAnnotations[i];
                            selected.push({ id: autoAnnotation.id, type: 'autoAnnotation' });
                        }
                    }
                }
            } else {
                newSelectedAnnots.forEach((annot) => {
                    if (!selected.find((item) => item.id === annot.id)) {
                        selected.push(annot);
                    }
                });
            }

            if (selected.length > 1) {
                selected = selected.filter((annot) => annot.type === 'autoAnnotation');
            }

            setSelectedAnnotationIds(selected, options);
        },
        // eslint-disable-next-line
        [setSelectedAnnotationIds]
    );
    const deselectAnnotations = useCallback(
        (annots: Array<SelectedAnnotation>, options: { dispatchEvent: boolean; callback?: () => void }) => {
            const selected = selectedAnnotationIdsRef.current;
            let newSelected: Array<SelectedAnnotation> = [];
            selected.forEach((annot) => {
                if (!annots.find((item) => item.id === annot.id)) {
                    newSelected.push(annot);
                }
            });

            setSelectedAnnotationIds(newSelected, options);
        },
        [setSelectedAnnotationIds]
    );

    const __selectedAnnotationId = useMemo(() => (selectedAnnotationIds.length === 1 ? selectedAnnotationIds[0] : null), [selectedAnnotationIds]);
    const _setSelectedAnnotationId = useCallback(
        (id: string | null, type: SelectedAnnotationType, options: { dispatchEvent: boolean; callback?: () => void }) => {
            if (id && (annotationsRef.current.find((a) => a.id === id)?.notificationType || 0) !== 0) markAnnotationNotificationAsRead(id);
            if (!id) closeSupportingInformationPropositions();
            _setSelectedAnnotationIds(
                (prev) => {
                    if (prev.length === 1 && prev[0].id !== id) {
                        setSelectedSupportingHighlightId(null, { dispatchEvent: true });
                    }
                    return id ? [{ id, type }] : [];
                },
                () => {
                    if (options.dispatchEvent) {
                        const event = new CustomEvent<SelectedHighlightChangedEvent>(AnnotationSelectedEventName, { detail: { ids: id ? [id] : [] } });
                        document.dispatchEvent(event);
                    }
                    options && options.callback && options.callback();
                }
            );
        },
        // eslint-disable-next-line
        [_setSelectedAnnotationIds, markAnnotationNotificationAsRead, setSelectedSupportingHighlightId]
    );
    const setSelectedAnnotationId = useCallback(
        (id: string | null, options: { dispatchEvent: boolean; callback?: () => void }) => _setSelectedAnnotationId(id, 'annotation', options),
        [_setSelectedAnnotationId]
    );
    const selectedAnnotationId = (__selectedAnnotationId?.type === 'annotation' && __selectedAnnotationId.id) || null;

    const setSelectedAutoAnnotationId = useCallback(
        (id: string | null, options: { dispatchEvent: boolean; callback?: () => void }) => _setSelectedAnnotationId(id, 'autoAnnotation', options),
        [_setSelectedAnnotationId]
    );
    const selectedAutoAnnotationId = (__selectedAnnotationId?.type === 'autoAnnotation' && __selectedAnnotationId.id) || null;

    const selectedAnnotation = annotations.find((a) => a.id === selectedAnnotationId) || null;
    const selectedAnnotationIndex = (selectedAnnotation && annotations.indexOf(selectedAnnotation)) || 0;
    const prevAnnotationIndex = selectedAnnotationIndex - 1 < 0 ? annotations.length - 1 : selectedAnnotationIndex - 1;
    const nextAnnotationIndex = selectedAnnotationIndex + 1 > annotations.length - 1 ? 0 : selectedAnnotationIndex + 1;

    return {
        selectedAnnotation,
        selectedAnnotationId,
        selectedAnnotationIds,
        prevAnnotationIndex,
        nextAnnotationIndex,
        setSelectedAnnotationId,
        selectedAutoAnnotationId,
        setSelectedAutoAnnotationId,
        setSelectedAnnotationIds,
        selectAnnotations,
        deselectAnnotations,
    };
};

export default useAnnotationsSelecting;
