import { Box } from '@mui/material';
import { Typography, VerifiSpinner } from 'UI';

type LoadingLayoutComponentProps = {
    opacity?: boolean;
    description?: string;
};

function LoadingLayoutComponent(props: LoadingLayoutComponentProps) {
    const { opacity, description } = props;

    return (
        <Box
            sx={{
                zIndex: 1075,
                position: 'fixed',
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ zIndex: 1, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <VerifiSpinner />
                {description && (
                    <Typography sx={{ mt: '2rem', fontSize: '1.75rem', fontWeight: 600, color: (theme) => theme.palette.primary.dark }}>
                        {description}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{ position: 'absolute', height: '100%', width: '100%', backgroundColor: (theme) => theme.palette.white.main, opacity: opacity ? 0.8 : 1 }}
            />
        </Box>
    );
}

export default LoadingLayoutComponent;
