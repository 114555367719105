import { Box } from '@mui/material';
import { AutoAnnotationsProcess, AutoAnnotationsProcessStatus } from 'ApiClients/SterlingApiClients/Types';
import { ErrorsDescription } from 'App/Consts';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { Button, Checkbox, Icon, Tooltip, Typography } from 'UI';
import { useContext, useMemo, useState } from 'react';

function AutoAnnotationsProcessErrorInfoComponent(props: {
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
    markAutoAnnotationsProcessErrorAsReceived: () => void;
}) {
    const { autoAnnotationsProcess, markAutoAnnotationsProcessErrorAsReceived } = props;

    let autoAnnotationsProcessErrorInfo: string | null = useMemo(() => {
        if (autoAnnotationsProcess) {
            switch (autoAnnotationsProcess.status) {
                case AutoAnnotationsProcessStatus.Failed:
                    return ErrorsDescription.autoAnnotationsProcessStatusFailed;
                case AutoAnnotationsProcessStatus.ProcessedWithErrors:
                    return ErrorsDescription.autoAnnotationsProcessStatusProcessedWithErrors;
                default:
                    return null;
            }
        }

        return null;
    }, [autoAnnotationsProcess]);

    const [checked, setChecked] = useState(false);

    const { isEditor } = useContext(ProjectAuthContext);

    if (autoAnnotationsProcessErrorInfo && autoAnnotationsProcess && !autoAnnotationsProcess.isErrorReceived) {
        return (
            <Box sx={{ color: (theme) => theme.palette.attention.medium, display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
                <Tooltip
                    title={
                        <Box>
                            <Typography variant='body2' sx={{ color: (theme) => theme.palette.attention.medium, fontSize: '0.75rem', fontWeight: 600 }}>
                                {autoAnnotationsProcessErrorInfo}
                            </Typography>
                            {isEditor && (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', width: '100%' }}>
                                    <Checkbox
                                        sx={{
                                            padding: '0rem',
                                            height: '0.75rem',
                                            width: '0.75rem',
                                            color: (theme) => `${theme.palette.primary.main} !important`,
                                        }}
                                        checked={checked}
                                        onChange={(_event, checked) => setChecked(checked)}
                                    />
                                    <Typography sx={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>Never show this again</Typography>
                                    <Button
                                        variant='text'
                                        disabled={!checked}
                                        sx={{
                                            marginLeft: '0.5rem',
                                            height: '1rem',
                                            width: '3rem',
                                            minWidth: '3rem',
                                            paddingLeft: '0.25rem',
                                            paddingRight: '0.25rem',
                                            paddingTop: '0rem',
                                            paddingBottom: '0rem',
                                            fontSize: '0.75rem',
                                            fontWeight: 600,
                                        }}
                                        onClick={() => markAutoAnnotationsProcessErrorAsReceived()}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    }
                >
                    <Icon.ExclamationCircle width={24} height={24} />
                </Tooltip>
            </Box>
        );
    } else {
        return null;
    }
}

export default AutoAnnotationsProcessErrorInfoComponent;
