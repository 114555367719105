import { Box, Popover } from '@mui/material';
import { Icon, TableList, Typography } from 'UI';
import { useState } from 'react';
import AnnotationStatusComponent from 'Views/Common/AnnotationStatus/AnnotationStatus.Component';
import { AnnotationStatus, ProjectAnnotationsStatistics } from 'ApiClients/SterlingApiClients/Types';

export type AnnotationsStatusCellComponentProps = {
    statistics: ProjectAnnotationsStatistics;
};

function AnnotationsStatusCellComponent(props: AnnotationsStatusCellComponentProps) {
    const { statistics } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const handleExtendCollapse = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };
    const handleCollapse = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let rows: Array<{ status: AnnotationStatus; count: number; needsReviewCount: number }> = [];

    if (open) {
        const { annotateStatus, verifyStatus, approveStatus, verifiedStatus } = statistics;

        rows = [
            { status: AnnotationStatus.Annotate, count: annotateStatus.count, needsReviewCount: annotateStatus.needsReviewCount },
            { status: AnnotationStatus.Verify, count: verifyStatus.count, needsReviewCount: verifyStatus.needsReviewCount },
            { status: AnnotationStatus.Approve, count: approveStatus.count, needsReviewCount: approveStatus.needsReviewCount },
            { status: AnnotationStatus.Verified, count: verifiedStatus.count, needsReviewCount: verifiedStatus.needsReviewCount },
        ];
    }

    return (
        <>
            <Box
                onClick={(e) => handleExtendCollapse(e)}
                sx={{ display: 'flex', height: '2rem', width: '2rem', justifyContent: 'center', alignItems: 'center' }}
            >
                <Icon.BarChartLine />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCollapse}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Box
                    sx={{
                        width: '15rem',
                        height: '10rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <TableList.Table
                        style={{
                            pointerEvents: 'none',
                        }}
                    >
                        <TableList.Body data-cy='statuses-table-body'>
                            {rows.map((row, idx) => (
                                <StatusRowComponent key={`statuses-${idx}`} status={row.status} count={row.count} needsReviewCount={row.needsReviewCount} />
                            ))}
                        </TableList.Body>
                    </TableList.Table>
                </Box>
            </Popover>
        </>
    );
}

type StatusRowComponentProps = {
    status: AnnotationStatus;
    count: number;
    needsReviewCount: number;
};

function StatusRowComponent(props: StatusRowComponentProps) {
    const { status, count, needsReviewCount } = props;
    return (
        <TableList.TR
            data-cy='statuses-table-row'
            sx={{
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: (theme) => theme.palette.stroke.main,
                height: '2.5rem',
            }}
        >
            <TableList.TD>
                <AnnotationStatusComponent status={status} />
            </TableList.TD>
            <TableList.TD size={50} sx={{ display: 'flex', fontSize: '0.75rem', gap: 0.25 }}>
                <Typography
                    sx={{ color: (theme) => (needsReviewCount === 0 ? theme.palette.text.disabled : theme.palette.attention.high), fontSize: 'inherit' }}
                >
                    {needsReviewCount}
                </Typography>
                <Typography sx={{ color: (theme) => (needsReviewCount === 0 ? theme.palette.text.disabled : theme.palette.text.primary), fontSize: 'inherit' }}>
                    /
                </Typography>
                <Typography sx={{ fontSize: 'inherit' }}>{count}</Typography>
            </TableList.TD>
        </TableList.TR>
    );
}

export default AnnotationsStatusCellComponent;
