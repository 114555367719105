import { useEffect, useMemo, useState } from 'react';
import { Annotation, ProjectMember } from 'ApiClients/SterlingApiClients/Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Autocomplete, Button, Dialog } from 'UI';
import { Box } from '@mui/material';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';

export type AssignmentModalComponentProps = {
    isOpen: boolean;
    annotation: Annotation;
    assignMember: (annotationId: string, memberId: string, flowMethods?: FlowMethods) => void;
    closeModal: () => void;
    getMembers: (flowMethods: FlowMethods<Array<ProjectMember>>) => void;
};

type AssignmentOption = {
    value: string;
    label: string;
};

function AssignmentModalComponent(props: AssignmentModalComponentProps) {
    const { isOpen, annotation, closeModal, getMembers } = props;
    const [members, setMembers] = useState<Array<ProjectMember>>([]);
    const [newMember, setNewMember] = useState<AssignmentOption | null>(null);

    useEffect(() => {
        getMembers({ onSuccess: (data) => setMembers(data) });
        // eslint-disable-next-line
    }, []);

    const membersMapped: Array<AssignmentOption> = useMemo(() => {
        return members.map((member) => ({
            value: member.id,
            label: member.fullName,
        }));
    }, [members]);

    useEffect(() => {
        if (membersMapped.length > 0) {
            const defaultValueId: string | null = (annotation && annotation.assignedMember?.id) || null;
            const defaultValue: AssignmentOption | null = membersMapped.find((member) => member.value === defaultValueId) || null;
            setNewMember(defaultValue);
        }
    }, [membersMapped, annotation]);

    const { wrappedMethod: assignMember, fetching: isAssigning } = usePromiseWithFlowMethods<{ annotationId: string; memberId: string }, {}>({
        method: (input, flowMethods) =>
            props.assignMember(input.annotationId, input.memberId, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods?.onSuccess?.(data);
                    closeModal();
                },
            }),
        initialData: {},
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (newMember) assignMember({ annotationId: annotation.id, memberId: newMember.value });
    };

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Assign Member</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                    >
                        <Autocomplete<AssignmentOption>
                            data-cy='users-dropdown'
                            label='Select Member'
                            options={membersMapped}
                            getOptionLabel={(option: AssignmentOption) => option.label}
                            placeholder='Member'
                            value={newMember}
                            onChange={(_: React.ChangeEvent<{}>, newValue: AssignmentOption | null) => setNewMember(newValue)}
                            isOptionEqualToValue={(option: AssignmentOption, value: AssignmentOption) => option.value === (value?.value ?? '')}
                            required
                        />
                    </Box>

                    <Dialog.Actions>
                        <Button
                            color='secondary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='button'
                            onClick={closeModal}
                            data-cy='close-members'
                        >
                            Close
                        </Button>
                        <Button
                            data-cy='assign-member'
                            isLoading={isAssigning}
                            disabled={!newMember}
                            color='primary'
                            sx={{
                                maxWidth: 160,
                            }}
                            fullWidth
                            type='submit'
                        >
                            Assign member
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default AssignmentModalComponent;
