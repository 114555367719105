import { useMemo } from 'react';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';
import { WebViewerInstance } from '@pdftron/webviewer';
import { DocumentInfo as PdfViewerDocumentInfo, FileSource, HighlightsProps } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { FileInfo } from 'ApiClients/SterlingApiClients/Types';
import { DocumentInfo } from '../../DocumentUpload/useDocumentUploadState';

type PdfViewerBaseProps = {
    viewerRef: React.RefObject<HTMLDivElement>;
    webViewerInstance: WebViewerInstance | null;
    title: string | JSX.Element;
    documentInfo: PdfViewerDocumentInfo;
    fileSource: FileSource;
    getDocument?: (docId: string, docName: string) => Promise<FileInfo | null>;
    docFileInfo?: FileInfo | null;
    onDocumentLoad?: () => void;
    onFinishedRendering?: (documentInfo: PdfViewerDocumentInfo) => void;
};

export type PdfViewerOtherProps<THighlightCustomData = {}> = {
    highlightsProps: HighlightsProps<THighlightCustomData>;
    displayHighlightIcons: boolean;
    displayHighlightNavigationIcons?: boolean;
    isCreatingHighlightEnabled: boolean;
};

const usePdfViewer = (documentInfo: DocumentInfo | null, enableWebViewerInstance: boolean): PdfViewerBaseProps | null => {
    const { webViewerInstance, viewer } = useWebViewerInstance(enableWebViewerInstance);
    const title = useMemo(() => documentInfo?.name || '', [documentInfo]);

    const pdfViewerBaseProps: PdfViewerBaseProps | null = useMemo(() => {
        if (!documentInfo) return null;

        let result: PdfViewerBaseProps = {
            webViewerInstance,
            viewerRef: viewer,
            title,
            documentInfo: {
                id: documentInfo.id,
                name: documentInfo.name,
                fileId: documentInfo.fileId,
            },
            fileSource: 'docFile',
            docFileInfo: {
                id: documentInfo.fileId,
                file: documentInfo.file,
            },
        };

        return result;
    }, [webViewerInstance, viewer, documentInfo, title]);

    return pdfViewerBaseProps;
};

export default usePdfViewer;
