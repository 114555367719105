import { useEffect, useRef } from 'react';
import EditPanelComponent, { EditPanelActionsState } from './EditPanel/EditPanel.Component';
import { AnnotationHighlightCustomData } from '../VerificationDocument.Component';
import AutoAnnotationPanelComponent, { AutoAnnotationPanelActionsState } from './AutoAnnotationPanel/AutoAnnotationPanel.Component';
import { AnnotationStatus, BoundingBoxSection, SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { Highlight, HighlightToolbarActionsState, HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { SplitAutoAnnotationBody } from 'ApiClients/SterlingApiClients/AutoAnnotationsApiClient/AutoAnnotations.ApiClient.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Toast } from 'Hooks/useToasts';

export type AnnotationPanelWrapperComponentProps = HighlightToolbarActionsState<AnnotationPanelActionsState> & {
    highlightToolbarOptions: HighlightToolbarOptions;
    highlight: Highlight<AnnotationHighlightCustomData>;
    setRef: (ref: React.RefObject<HTMLDivElement>) => void;
    supportingInformationPropositionsOpen: boolean;
    openDeleteModal: (id: string) => void;
    openAssignmentModal: (id: string) => void;
    verifyAnnotation: (id: string) => void;
    openAssertionModal: (id: string) => void;
    isEditor: boolean;
    openTagAssignmentModal: (id: string) => void;
    isVerificationDocumentProcessed: boolean;
    autoAnnotationProps: AutoAnnotationProps;
    isAnnotateStatus: boolean;
    openSuppInfoSummaryModal: (id: string) => void;
    acceptAnnotationStage: (id: string, currentStatus: AnnotationStatus, flowMethods: FlowMethods) => void;
    annotationStatus: AnnotationStatus | null;
    nextAnnotationStatus: AnnotationStatus | null;
    openNeedsReviewModal: (id: string) => void;
    needsReview: boolean;
    addToast: (toast: Toast) => void;
};

type AutoAnnotationProps = {
    createAnnotationFromAutoAnnotation: (id: string, flowMethods: FlowMethods) => void;
    createAnnotationFromModifiedAutoAnnotation: (
        autoAnnotationId: string,
        selectionType: SelectionType,
        boundingBoxSections: BoundingBoxSection[],
        statement: string,
        flowMethods: FlowMethods
    ) => void;
    rejectAnnotation: (id: string, flowMethods: FlowMethods) => void;
    addPart: () => void;
    splitAutoAnnotation: (autoAnnotationId: string, body: SplitAutoAnnotationBody, flowMethods: FlowMethods) => void;
};

export type AnnotationPanelActionsState = EditPanelActionsState & AutoAnnotationPanelActionsState;

function AnnotationPanelWrapperComponent(props: AnnotationPanelWrapperComponentProps) {
    const {
        highlightToolbarOptions,
        highlight,
        setRef,
        supportingInformationPropositionsOpen,
        openDeleteModal,
        openAssignmentModal,
        openNeedsReviewModal,
        verifyAnnotation,
        openAssertionModal,
        openTagAssignmentModal,
        isEditor,
        isVerificationDocumentProcessed,
        autoAnnotationProps,
        isAnnotateStatus,
        openSuppInfoSummaryModal,
        acceptAnnotationStage,
        annotationStatus,
        nextAnnotationStatus,
        needsReview,
        actionsState,
        setHighlightActionsState,
        addToast,
    } = props;
    const {
        showHideAnnotationElements,
        getHighlightViewer,
        splitAnnotation: splitAnnotationViewer,
        cancelSplitAnnotation,
        isAnnotationSplitted,
    } = highlightToolbarOptions;
    const { createAnnotationFromAutoAnnotation, createAnnotationFromModifiedAutoAnnotation, rejectAnnotation, addPart, splitAutoAnnotation } =
        autoAnnotationProps;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setRef(ref);
    }, [setRef]);

    return (
        <div ref={ref}>
            {highlight && (
                <>
                    {highlight.type === 'annotation' && (
                        <EditPanelComponent
                            highlight={highlight}
                            openDeleteModal={openDeleteModal}
                            openAssignmentModal={openAssignmentModal}
                            openTagAssignmentModal={openTagAssignmentModal}
                            openNeedsReviewModal={openNeedsReviewModal}
                            supportingInformationPropositionsOpen={supportingInformationPropositionsOpen}
                            verifyAnnotation={verifyAnnotation}
                            openAssertionModal={openAssertionModal}
                            isEditor={isEditor}
                            isVerificationDocumentProcessed={isVerificationDocumentProcessed}
                            showHideAnnotationElements={showHideAnnotationElements}
                            isAnnotateStatus={isAnnotateStatus}
                            openSuppInfoSummaryModal={openSuppInfoSummaryModal}
                            acceptAnnotationStage={(annotationId: string, flowMethods: FlowMethods) =>
                                nextAnnotationStatus && acceptAnnotationStage(annotationId, annotationStatus!, flowMethods)
                            }
                            nextAnnotationStatus={nextAnnotationStatus}
                            needsReview={needsReview}
                            actionsState={actionsState}
                            setHighlightActionsState={setHighlightActionsState}
                        />
                    )}
                    {highlight.type === 'autoAnnotation' && (
                        <AutoAnnotationPanelComponent
                            highlight={highlight}
                            createAnnotationFromAutoAnnotation={createAnnotationFromAutoAnnotation}
                            createAnnotationFromModifiedAutoAnnotation={createAnnotationFromModifiedAutoAnnotation}
                            splitingEnabled={highlight.boundingBoxSections.length === 1}
                            splitingMultiPartEnabled={highlight.boundingBoxSections.length > 1}
                            isAnnotationSplitted={isAnnotationSplitted}
                            startSplittingAutoAnnotation={splitAnnotationViewer}
                            splitAutoAnnotation={splitAutoAnnotation}
                            cancelSplitAnnotation={cancelSplitAnnotation}
                            rejectAnnotation={rejectAnnotation}
                            showHideAnnotationElements={showHideAnnotationElements}
                            addPart={addPart}
                            getHighlightViewer={getHighlightViewer}
                            actionsState={actionsState}
                            setHighlightActionsState={setHighlightActionsState}
                            addToast={addToast}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default AnnotationPanelWrapperComponent;
